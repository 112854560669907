import React, { useState } from 'react';
import { Pencil, Trash } from 'lucide-react';
import EntityTable from '../../../../components/tables/EntityTable';
import {
  EntityTypeEnum,
  FilterOperatorEnum
} from '../../../../utilities/Enumerables';
import { Modal } from '@core/components';
import Address from '../../../../models/Address';
import AddAddress from '../../../../components/popups/locations/AddAddress';
import EditAddress from '../../../../components/popups/locations/EditAddress';
import { formatDate } from '../../../../utilities/Formatting';

const ProviderAddresses = ({ providerId }) => {
  const [isAddAddressOpen, setIsAddAddressOpen] = useState(false);
  const [isEditAddressOpen, setIsEditAddressOpen] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [addressToDelete, setAddressToDelete] = useState(null);

  const handleDeleteAddress = async () => {
    try {
      const updatedAddress = new Address({
        ...(await addressToDelete.toDatabase()),
        status: 81
      });

      await updatedAddress.update();

      setIsDeleteModalOpen(false);
      setAddressToDelete(null);
    } catch (error) {
      console.error('Error archiving address:', error);
    }
  };

  const menuItems = [
    {
      label: 'Delete',
      icon: <Trash size={14} className='text-danger-700' />,
      onClick: data => {
        setAddressToDelete(data);
        setIsDeleteModalOpen(true);
      }
    }
  ];

  return (
    <>
      <EntityTable
        name='Addresses'
        customId={`provider:${providerId}:addresses`}
        filter={[
          { field: 'display_id', label: 'ID', type: 'text' },
          {
            field: 'line1',
            label: 'Line 1',
            type: 'text',
            operator: FilterOperatorEnum.ILIKE
          },
          {
            field: 'line2',
            label: 'Line 2',
            type: 'text',
            operator: FilterOperatorEnum.ILIKE
          },
          {
            field: 'line3',
            label: 'Line 3',
            type: 'text',
            operator: FilterOperatorEnum.ILIKE
          },
          {
            field: 'city',
            label: 'City',
            type: 'text',
            operator: FilterOperatorEnum.ILIKE
          },
          {
            field: 'postcode',
            label: 'Postcode',
            type: 'text',
            operator: FilterOperatorEnum.ILIKE
          },
          {
            field: 'country',
            label: 'Country',
            type: 'text',
            operator: FilterOperatorEnum.ILIKE
          },
          {
            field: 'created_date',
            label: 'Created Date',
            type: 'daterange',
            operator: FilterOperatorEnum.BETWEEN
          },
          {
            field: 'updated_date',
            label: 'Updated Date',
            type: 'daterange',
            operator: FilterOperatorEnum.BETWEEN
          },
          {
            field: 'showArchived',
            label: 'Show Archived',
            hint: 'Show archived addresses',
            type: 'conditional_toggle',
            operator: FilterOperatorEnum.IS,
            options: {
              value: 'true'
            }
          }
        ]}
        columns={[
          { label: 'ID', field: 'displayId' },
          { label: 'Line 1', field: 'line1' },
          { label: 'Line 2', field: 'line2' },
          { label: 'Line 3', field: 'line3' },
          { label: 'City', field: 'city' },
          { label: 'Postcode', field: 'postcode' },
          { label: 'Country', field: 'country' },
          {
            label: 'Created Date',
            field: 'createdDate',
            cell: value => formatDate(value)
          },
          {
            label: 'Updated Date',
            field: 'updatedDate',
            cell: value => formatDate(value)
          }
        ]}
        menuItems={menuItems}
        model={Address}
        entityType={EntityTypeEnum.Address}
        defaultFilters={{
          entity_type: { value: EntityTypeEnum.Provider },
          entity_id: { value: providerId },
          status: { value: '81', operator: FilterOperatorEnum.NOT_EQUALS }
        }}
        defaultOrder={{ field: 'display_id', order: 'asc' }}
        onNewClick={() => setIsAddAddressOpen(true)}
        onRowClick={address => {
          setSelectedAddress(address);
          setIsEditAddressOpen(true);
        }}
        canCreate={true}
      />
      <AddAddress
        isOpen={isAddAddressOpen}
        entityId={providerId}
        entityType={EntityTypeEnum.Provider}
        onClose={() => setIsAddAddressOpen(false)}
        onLocationAdded={() => setIsAddAddressOpen(false)}
      />
      <EditAddress
        isOpen={isEditAddressOpen}
        location={selectedAddress}
        onClose={() => setIsEditAddressOpen(false)}
        onLocationUpdated={() => setIsEditAddressOpen(false)}
      />
      <Modal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        title='Delete Address'
        style='danger'
        actions={[
          {
            label: 'Cancel',
            onClick: () => setIsDeleteModalOpen(false),
            style: 'base'
          },
          {
            label: 'Delete',
            onClick: handleDeleteAddress,
            style: 'danger'
          }
        ]}
      >
        <p className='text-sm text-base-600'>
          Are you sure you want to delete this address? This action cannot be
          undone.
        </p>
      </Modal>
    </>
  );
};

export default ProviderAddresses;
