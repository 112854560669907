import React, { useState, useEffect } from 'react';
import {
  FilledButton,
  SoftButton,
  Popup,
  Input,
  TextButton
} from '@core/components';
import { supabase } from '../../../utilities/supabase';
import { useNotification } from '@core/components';
import { ExternalLink } from 'lucide-react';

const ViewVideo = ({ isOpen, onClose, entityData }) => {
  const [videos, setVideos] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(true);
  const { addNotification } = useNotification();

  // Function to replace merge tags with entity data
  const replaceMergeTags = (text, entityData) => {
    const mergeTagValues = {};
    const replacedText = text.replace(/\{\{(.*?)\}\}/g, (_, tag) => {
      const keys = tag.split(/[\[\].]+/).filter(Boolean);
      let value = entityData;
      for (const key of keys) {
        if (value && key in value) {
          value = value[key];
        } else {
          return `{{${tag}}}`;
        }
      }
      mergeTagValues[tag] = value;
      return value;
    });

    return { replacedText, mergeTagValues };
  };

  useEffect(() => {
    const fetchVideos = async () => {
      try {
        const { data, error } = await supabase.functions.invoke(
          'audience/video/view'
        );
        if (error) throw error;

        // Replace merge tags in name and description
        const processedData = data.map(video => {
          const { replacedText: replacedname } = replaceMergeTags(
            video.name,
            entityData
          );
          const { replacedText: replaceddescription } = replaceMergeTags(
            video.description,
            entityData
          );
          return {
            ...video,
            replacedname,
            replaceddescription
          };
        });

        setVideos(processedData);
      } catch (error) {
        console.error('Error fetching videos:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchVideos();
  }, [entityData]);

  useEffect(() => {
    const loadZiggeoResources = () => {
      const script = document.createElement('script');
      script.src = '//assets.ziggeo.com/v2-stable/ziggeo.js';
      script.async = true;
      document.body.appendChild(script);

      const link = document.createElement('link');
      link.rel = 'stylesheet';
      link.href = '//assets.ziggeo.com/v2-stable/ziggeo.css';
      document.head.appendChild(link);

      return { script, link };
    };

    const initZiggeoApp = () => {
      if (!window.ziggeoApp) {
        window.ziggeoApp = new ZiggeoApi.V2.Application({
          token: 'r1dfc2cf813f9583923c545ca3e88ce6'
        });
      }
    };

    const { script, link } = loadZiggeoResources();
    script.onload = initZiggeoApp;

    return () => {
      document.body.removeChild(script);
      document.head.removeChild(link);
    };
  }, []);

  const handleCopyLink = videoId => {
    const video = videos.find(v => v.id === videoId);
    if (video) {
      let { name, description } = video;
      const params = new URLSearchParams();

      // Add merge tag values to params
      const { mergeTagValues: nameMergeTagValues } = replaceMergeTags(
        name,
        entityData
      );
      const { mergeTagValues: descriptionMergeTagValues } = replaceMergeTags(
        description,
        entityData
      );

      const mergeTagValues = {
        ...nameMergeTagValues,
        ...descriptionMergeTagValues
      };

      console.log(mergeTagValues);

      Object.entries(mergeTagValues).forEach(([key, value]) => {
        console.log(key, value);
        params.append(key, value);
      });

      // Construct the final link with parameters
      const finalLink = `https://tools.add.ai/video/${video.video
        .split('/')
        .pop()}?${params.toString()}`;

      navigator.clipboard.writeText(finalLink);
      addNotification({
        title: 'Success',
        description: 'Video details copied to clipboard!',
        type: 'success'
      });
    }
  };

  const filteredVideos = videos.filter(video =>
    video.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Popup isOpen={isOpen} onClose={onClose} title='Videos' size='lg'>
      <div className='mb-4'>
        <Input
          type='text'
          placeholder='Search videos...'
          value={searchTerm}
          onChange={e => setSearchTerm(e.target.value)}
        />
      </div>
      {loading ? (
        <div className='grid grid-cols-2 gap-4 mb-4'>
          {[...Array(4)].map((_, index) => (
            <div
              key={index}
              className='p-4 border rounded-lg transition-colors duration-300 bg-gray-200 animate-pulse'
            >
              <div className='flex flex-col space-y-2'>
                <div className='w-3/4 h-4 bg-gray-300 rounded'></div>
                <div className='w-1/2 h-4 bg-gray-300 rounded'></div>
              </div>
            </div>
          ))}
        </div>
      ) : filteredVideos.length === 0 ? (
        <p className='text-sm text-gray-500'>No videos available.</p>
      ) : (
        <div className='grid grid-cols-1 sm:grid-cols-3 gap-4 mh-[56vh] overflow-y-auto'>
          {filteredVideos.map(video => (
            <div key={video.id} className='bg-white shadow sm:rounded-lg p-2'>
              <ziggeoplayer
                ziggeo-video={video.video.split('/').pop()}
                ziggeo-width='100%'
                ziggeo-height='180'
                id={`player-${video.video.split('/').pop()}`}
                ziggeo-theme='modern'
                ziggeo-themecolor='#3498db'
              ></ziggeoplayer>
              <div className='px-2 py-3 sm:p-4'>
                <h3 className='text-base font-semibold text-gray-900'>
                  {video.replacedname}
                </h3>
                <div className='mt-2 max-w-xl text-sm text-gray-500 max-h-50 overflow-hidden'>
                  <p className='line-clamp-3'>{video.replaceddescription}</p>
                </div>
                <div className='-mx-3 mt-3 text-sm'>
                  <TextButton
                    onClick={() => handleCopyLink(video.id)}
                    className='font-semibold'
                  >
                    Custom Link
                    <span aria-hidden='true'> &rarr;</span>
                  </TextButton>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
      <div className='flex justify-between mt-8'>
        <SoftButton onClick={onClose}>Close</SoftButton>
        <FilledButton
          onClick={() =>
            window.open('https://tools.add.ai/videos/record', '_blank')
          }
          rightIcon={<ExternalLink />}
        >
          Create Video
        </FilledButton>
      </div>
    </Popup>
  );
};

export default ViewVideo;
