import React, { useEffect, useState, useCallback } from 'react';
import { Popup } from '@core/components';
import { supabase } from '../../../utilities/supabase';
import { FilledButton } from '../../../../core/src/components/inputs/buttons/Button';

const PrintPopup = ({
  search,
  isOpen,
  onClose,
  onSubmit,
  extraAttachements,
  autoSelectAttachements = []
}) => {
  const [mergedPdfUrl, setMergedPdfUrl] = useState(null);
  const [attachments, setAttachments] = useState([]);

  const fetchAttachmentsForSearch = useCallback(async () => {
    if (!isOpen || !search?.id) {
      setAttachments([]);
      setMergedPdfUrl(null);
      return;
    }

    try {
      const { data: entityAttachments, error: entityError } = await supabase
        .from('documents')
        .select('*')
        .contains('connections', { 13: [search.id] });

      if (entityError) throw entityError;

      const extraAttachmentsPromises = extraAttachements.map(attachment =>
        supabase
          .from('documents')
          .select('*')
          .contains('connections', {
            [attachment.entityType]: [attachment.entityId]
          })
      );

      const extraAttachmentsResults = await Promise.all(
        extraAttachmentsPromises
      );
      const extraAttachmentsData = extraAttachmentsResults.flatMap(
        ({ data, error }) => {
          if (error) throw error;
          return data || [];
        }
      );

      setAttachments([...entityAttachments, ...extraAttachmentsData]);
    } catch (error) {
      console.error('Error fetching attachments:', error);
    }
  }, [isOpen, search, extraAttachements]);

  useEffect(() => {
    fetchAttachmentsForSearch();
  }, [fetchAttachmentsForSearch]);

  const fetchAndMergeAttachments = useCallback(async () => {
    if (attachments.length === 0) return;

    try {
      const filteredAttachments = attachments.filter(attachment =>
        autoSelectAttachements.some(
          criteria =>
            attachment.type === criteria ||
            attachment.file_name.includes(criteria)
        )
      );

      const orderedAttachments = filteredAttachments.sort((a, b) => {
        const indexA = autoSelectAttachements.findIndex(
          criteria => a.type === criteria || a.file_name.includes(criteria)
        );
        const indexB = autoSelectAttachements.findIndex(
          criteria => b.type === criteria || b.file_name.includes(criteria)
        );
        return indexA - indexB;
      });

      const urls = orderedAttachments.map(
        attachment =>
          `https://storage.googleapis.com/pensiontracingservice/${attachment.file_path}/${attachment.id}/${attachment.file_name}`
      );

      if (urls.length === 0) return;

      const response = await fetch(
        'https://jikcvxkcnnyfblqbdmhr.supabase.co/functions/v1/merger',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ urls })
        }
      );

      if (!response.ok) {
        throw new Error('Failed to merge PDFs');
      }

      const blob = await response.blob();
      const objectUrl = URL.createObjectURL(blob);
      setMergedPdfUrl(objectUrl);
    } catch (error) {
      console.error('Error merging attachments:', error);
    }
  }, [attachments, autoSelectAttachements]);

  useEffect(() => {
    fetchAndMergeAttachments();
  }, [fetchAndMergeAttachments]);

  const handleComplete = () => {
    if (onSubmit) {
      onSubmit(search);
    }
    onClose();
  };

  return (
    <Popup
      isOpen={isOpen}
      onClose={onClose}
      title='Print'
      size='lg'
      footer={
        <div className='flex justify-end'>
          <FilledButton onClick={handleComplete}>Complete</FilledButton>
        </div>
      }
    >
      {!mergedPdfUrl ? (
        <div className='flex justify-center items-center h-96'>Loading...</div>
      ) : (
        <iframe src={mergedPdfUrl} width='100%' height='1000' />
      )}
    </Popup>
  );
};

export default PrintPopup;
