import React, { useEffect, useState } from 'react';
import { Input, SoftButton, Checkbox, DropdownInput } from '@core/components';
import { Plus, Trash2 } from 'lucide-react';
import CoreEntityType from '../../../../models/CoreEntityType';
import { EntityTypeEnum } from '../../../../utilities/Enumerables';

const AdditionalDetails = ({ userProfileData, handleInputChange }) => {
  const [segmentationOptions, setSegmentationOptions] = useState([]);

  useEffect(() => {
    const fetchSegmentationOptions = async () => {
      try {
        const segmentations = await CoreEntityType.getByEntityId(
          EntityTypeEnum.Segmentation
        );

        setSegmentationOptions(
          segmentations.map(({ id, type }) => ({
            value: id,
            label: type
          }))
        );
      } catch (error) {
        console.error('Error fetching segmentation options:', error);
      }
    };

    fetchSegmentationOptions();
  }, []);

  const updateArrayField = (field, index, value) => {
    const items = Array.isArray(userProfileData[field])
      ? [...userProfileData[field]]
      : [];
    if (value === undefined) {
      items.splice(index, 1);
    } else if (index === items.length) {
      items.push(value);
    } else {
      items[index] = value;
    }
    handleInputChange(field, items);
  };

  return (
    <div className='space-y-4'>
      <div className='space-y-2'>
        <label className='block text-sm font-medium'>
          National Insurance Number
        </label>
        <Input
          value={userProfileData.nationalInsurance || ''}
          onChange={e => handleInputChange('nationalInsurance', e.target.value)}
        />
      </div>

      <div className='space-y-2'>
        <label className='block text-sm font-medium'>Date of Birth</label>
        <Input
          type='date'
          value={userProfileData.dateOfBirth || ''}
          onChange={e => handleInputChange('dateOfBirth', e.target.value)}
        />
      </div>

      <div className='space-y-2'>
        <label className='block text-sm font-medium'>Previous Names</label>
        {Array.isArray(userProfileData.previousNames) &&
          userProfileData.previousNames.map((name, index) => (
            <div key={index} className='flex gap-2'>
              <Input
                value={name}
                onChange={e =>
                  updateArrayField('previousNames', index, e.target.value)
                }
                placeholder='Previous Name'
              />
              <button
                onClick={() => updateArrayField('previousNames', index)}
                className='p-2 text-red-600 hover:text-red-800'
              >
                <Trash2 size={16} />
              </button>
            </div>
          ))}
        <SoftButton
          onClick={() =>
            updateArrayField(
              'previousNames',
              userProfileData.previousNames.length,
              ''
            )
          }
          className='mb-2'
        >
          <Plus size={16} /> Add Previous Name
        </SoftButton>
      </div>

      <div className='space-y-2'>
        <label className='block text-sm font-medium'>Segmentation</label>
        <DropdownInput
          options={segmentationOptions}
          onChange={e => handleInputChange('segmentation', e.target.value)}
          placeholder='Select Segmentation'
        />
      </div>
      <label className='block text-sm font-medium'>Marketing Preferences</label>
      <div className='space-y-2'>
        <Checkbox
          colour='brand'
          label='Post'
          checked={userProfileData.marketing?.post}
          onChange={e => handleInputChange('marketing.post', e.target.checked)}
        />
        <Checkbox
          colour='brand'
          label='Text'
          checked={userProfileData.marketing?.text}
          onChange={e => handleInputChange('marketing.text', e.target.checked)}
        />
        <Checkbox
          colour='brand'
          label='Email'
          checked={userProfileData.marketing?.email}
          onChange={e => handleInputChange('marketing.email', e.target.checked)}
        />
      </div>
    </div>
  );
};

export default AdditionalDetails;
