import React, { useState, useEffect } from 'react';
import {
  FilledButton,
  Input,
  ComboBox,
  DropdownInput,
  Alert,
  SoftButton,
  LogoLoader
} from '@core/components';
import { Popup } from '@core/components';
import { FileText, Files, X } from 'lucide-react';
import Pension from '../../../models/Pension';
import CoreEntityType from '../../../models/CoreEntityType';
import {
  EntityTypeEnum,
  FilterOperatorEnum
} from '../../../utilities/Enumerables';
import Company from '../../../models/Company';
import Provider from '../../../models/Provider';
import Scheme from '../../../models/Scheme';
import { supabase } from '../../../utilities/supabase';
import axios from 'axios';
import Document from '../../../models/Document';
import { formatProfile } from '../../../utilities/Formatting';

const CreatePension = ({
  isOpen,
  onClose,
  onPensionCreated,
  user,
  pension
}) => {
  const [formData, setFormData] = useState({
    name: '',
    owner: '',
    status: 73,
    company: null,
    provider: null,
    scheme: null,
    documents: []
  });

  const [statusOptions, setStatusOptions] = useState([]);
  const [step, setStep] = useState(1);
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  useEffect(() => {
    setFormData({
      name: pension?.name || '',
      owner: pension?.owner || user || '',
      status: pension?.status?.id || 73,
      company: pension?.company || null,
      provider: pension?.provider || null,
      scheme: pension?.scheme || null,
      documents: pension?.documents || []
    });
  }, [pension, user]);

  useEffect(() => {
    const loadStatusOptions = async () => {
      try {
        const options = await CoreEntityType.getByEntityId(
          EntityTypeEnum.PensionStatus
        );
        const formattedOptions = options.map(option => ({
          label: option.type,
          value: option.id
        }));
        setStatusOptions(formattedOptions);
      } catch (error) {
        console.error('Error loading status options:', error);
      }
    };

    loadStatusOptions();
  }, []);

  const loadUserOptions = async query => {
    if (!query) return;

    try {
      const { data } = await supabase
        .from('user_profiles')
        .select('id, full_name, email')
        .textSearch('fts', query.replaceAll(' ', '+').concat('', ':*'))
        .limit(10);

      return data.map(user => ({
        label: user.full_name,
        value: user.id,
        secondaryLabel: user.email
      }));
    } catch (error) {
      console.error('Error loading user options:', error);
      return [];
    }
  };

  const loadCompanyOptions = async name => {
    if (!name) return;

    try {
      const { data: companies } = await Company.getAll(
        { name: { value: name, operator: FilterOperatorEnum.ILIKE } },
        1,
        10,
        'name',
        'asc'
      );
      return companies.map(company => ({
        label: company.name,
        value: company.id
      }));
    } catch (error) {
      console.error('Error loading company options:', error);
      return [];
    }
  };

  const loadProviderOptions = async name => {
    if (!name) return;

    try {
      const { data: providers } = await Provider.getAll(
        { name: { value: name, operator: FilterOperatorEnum.ILIKE } },
        1,
        10,
        'name',
        'asc'
      );
      return providers.map(provider => ({
        label: provider.name,
        value: provider.id
      }));
    } catch (error) {
      console.error('Error loading provider options:', error);
      return [];
    }
  };

  const loadSchemeOptions = async name => {
    if (!name) return;

    try {
      const { data: schemes } = await Scheme.getAll(
        { name: { value: name, operator: FilterOperatorEnum.ILIKE } },
        1,
        10,
        'name',
        'asc'
      );
      return schemes.map(scheme => ({
        label: scheme.name,
        value: scheme.id
      }));
    } catch (error) {
      console.error('Error loading scheme options:', error);
      return [];
    }
  };

  const handleSubmit = async () => {
    setUploading(true);
    try {
      const pensionData = new Pension({
        name: formData.name,
        owner: formData.owner.value
          ? { id: formData.owner.value }
          : formData.owner,
        status: { id: formData.status },
        company: formData.company?.value
          ? { id: formData.company.value }
          : null,
        provider: { id: formData.provider.value },
        scheme: formData.scheme?.value ? { id: formData.scheme.value } : null,
        documents: formData.documents
      });

      let savedPension;
      if (pension) {
        pensionData.id = pension.id;
        savedPension = await pensionData.update();
      } else {
        savedPension = await pensionData.insert();
      }

      // Upload documents and create connections
      for (let i = 0; i < formData.documents.length; i++) {
        const doc = formData.documents[i];
        const { data: signedUrlData, error: signedUrlError } =
          await supabase.functions.invoke('storage/getSigned/upload', {
            body: {
              fileName: doc.name,
              originalFileName: doc.file.name,
              destinationFolder: 'pension-documents'
            }
          });

        if (signedUrlError) throw signedUrlError;

        await axios.put(signedUrlData.url, doc.file, {
          headers: {
            'Content-Type': doc.file.type
          },
          onUploadProgress: progressEvent => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setUploadProgress(percentCompleted);
          }
        });

        const document = await Document.getById(signedUrlData.file.id);
        await document.update({
          connections: { [EntityTypeEnum.Pension]: [savedPension.id] }
        });
      }

      onPensionCreated(savedPension);
      handleClose();
    } catch (error) {
      console.error('Error saving pension:', error);
    } finally {
      setUploading(false);
      setUploadProgress(0);
    }
  };

  const handleClose = () => {
    setFormData({
      name: '',
      owner: user || '',
      status: '',
      company: '',
      provider: '',
      scheme: '',
      documents: []
    });
    onClose();
  };

  const handleInputChange = (field, value) => {
    setFormData(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const handleNextStep = () => {
    if (formData.documents.length > 0) {
      setStep(2);
    }
  };

  const handlePreviousStep = () => {
    setStep(1);
  };

  const handleFileChange = event => {
    const files = Array.from(event.target.files).map(file => ({
      file,
      name: file.name
    }));
    handleInputChange('documents', [...formData.documents, ...files]);
  };

  const handleDocumentNameChange = (index, newName) => {
    setFormData(prev => {
      const updatedDocuments = [...prev.documents];
      updatedDocuments[index].name = newName;
      return { ...prev, documents: updatedDocuments };
    });
  };

  const handleDeleteDocument = index => {
    setFormData(prev => {
      const updatedDocuments = prev.documents.filter((_, i) => i !== index);
      return { ...prev, documents: updatedDocuments };
    });
  };

  const isFormValid = () => {
    return (
      formData.name?.trim() &&
      (formData.owner.value || user) &&
      formData.provider?.value
    );
  };

  return (
    <Popup
      isOpen={isOpen}
      onClose={handleClose}
      title={pension ? 'Edit Pension' : 'New Pension'}
      footer={
        <div className='flex justify-end space-x-2 mt-1'>
          {step === 1 ? (
            <FilledButton type='button' colour='base' onClick={handleClose}>
              Cancel
            </FilledButton>
          ) : (
            <FilledButton
              type='button'
              colour='base'
              onClick={handlePreviousStep}
            >
              Back
            </FilledButton>
          )}
          {step === 1 ? (
            <FilledButton
              onClick={handleNextStep}
              disabled={formData.documents.length === 0}
            >
              Next
            </FilledButton>
          ) : (
            <FilledButton onClick={handleSubmit} disabled={!isFormValid()}>
              {pension ? 'Update Pension' : 'Create Pension'}
            </FilledButton>
          )}
        </div>
      }
    >
      <div className='space-y-6'>
        {uploading ? (
          <div className='flex flex-col items-center p-8'>
            <Files className='w-24 h-24 text-gray-500 mb-4 animate-bounce duration-1500' />
            <p className='text-lg mb-2'>
              Please wait, we are uploading the documents...
            </p>
            <p className='text-sm mb-8'>This may take a moment...</p>
            <div className='w-full bg-gray-200 rounded-full h-2.5'>
              <div
                className='bg-primary-600 h-2.5 rounded-full'
                style={{ width: `${uploadProgress}%` }}
              />
            </div>
          </div>
        ) : step === 1 ? (
          <div className='grid grid-cols-1 gap-5'>
            <Alert
              title='Upload Required'
              description='You have to upload the pension documents to add a pension.'
              style='danger'
            />
            <div className='grid grid-cols-1 gap-2'>
              <span className='text-gray-700'>Upload Documents</span>
              {formData.documents.length > 0 && (
                <div>
                  {formData.documents.map((doc, index) => (
                    <div
                      key={index}
                      className='border p-2 rounded-md mb-2 flex items-center gap-3'
                    >
                      <FileText className='w-7 h-7' />
                      <Input
                        value={doc.name}
                        onChange={e =>
                          handleDocumentNameChange(index, e.target.value)
                        }
                      />
                      <SoftButton
                        colour='danger'
                        size='lg'
                        onClick={() => handleDeleteDocument(index)}
                      >
                        <X />
                      </SoftButton>
                    </div>
                  ))}
                </div>
              )}
              <div className='block w-full border-2 border-dashed border-gray-300 rounded-md p-4'>
                <input
                  type='file'
                  multiple
                  onChange={handleFileChange}
                  className='w-full h-full opacity-0 absolute cursor-pointer'
                />
                <p className='text-sm text-gray-500'>Click to select files</p>
              </div>
            </div>
          </div>
        ) : (
          <div className='grid grid-cols-1 gap-4'>
            <Input
              label='Name'
              value={formData.name}
              onChange={e => handleInputChange('name', e.target.value)}
              required
            />

            {user ? (
              <div>
                <label className='block text-sm font-medium text-black mb-1'>
                  User
                </label>
                <div>{formatProfile(user)}</div>
              </div>
            ) : (
              <ComboBox
                label='User'
                value={formData.owner}
                loadOptions={loadUserOptions}
                onChange={selected => handleInputChange('owner', selected)}
                placeholder='Search Users...'
              />
            )}

            <DropdownInput
              label='Status'
              value={formData.status}
              options={statusOptions}
              onChange={e => handleInputChange('status', e.target.value)}
              disablePlaceholder
            />

            <ComboBox
              label='Company'
              value={formData.company}
              loadOptions={loadCompanyOptions}
              onChange={selectedOption =>
                handleInputChange('company', selectedOption)
              }
              placeholder='Search Companies...'
            />

            <ComboBox
              label='Provider'
              value={formData.provider}
              loadOptions={loadProviderOptions}
              onChange={selectedOption =>
                handleInputChange('provider', selectedOption)
              }
              placeholder='Search Providers...'
              required
            />

            <ComboBox
              label='Scheme'
              value={formData.scheme}
              loadOptions={loadSchemeOptions}
              onChange={selectedOption =>
                handleInputChange('scheme', selectedOption)
              }
              placeholder='Search Schemes...'
            />
          </div>
        )}
      </div>
    </Popup>
  );
};

export default CreatePension;
