import BaseModel from './BaseModel';

/**
 * Represents an Address in the system.
 * @extends BaseModel
 */
class Address extends BaseModel {
  /** @type {string} The name of the database table for Addresses */
  static table = 'addresses';
  /** @type {string} The SQL query to select Address data */
  static selectQuery = `*, type:addresses_type_fkey!inner(*)`;

  /**
   * Creates an instance of Address.
   * @param {Object} data - The address data.
   */
  constructor(data = {}) {
    super(data);
    this.type = data.type || null;
    this.status = data.status || null;
    this.line1 = data.line1 || null;
    this.line2 = data.line2 || null;
    this.line3 = data.line3 || null;
    this.city = data.city || null;
    this.postcode = data.postcode || null;
    this.country = data.country || null;
    this.createdDate = data.created_date || new Date();
    this.updatedDate = data.updated_date || new Date();
    this.entityType = data.entity_type || null;
    this.entityId = data.entity_id || null;
    this.displayId = data.display_id || null;
    this.metadata = data.metadata || {};
  }

  /**
   * Converts the Address instance to a database-friendly format.
   * @returns {Object} The address data ready for database operations.
   */
  toDatabase() {
    return {
      ...super.toDatabase(),
      type: typeof this.type == 'object' ? this.type.id : this.type,
      status: this.status,
      line1: this.line1,
      line2: this.line2,
      line3: this.line3,
      city: this.city,
      postcode: this.postcode,
      country: this.country,
      created_date: this.createdDate,
      updated_date: this.updatedDate,
      entity_type: this.entityType,
      entity_id: this.entityId,
      metadata: this.metadata
    };
  }

  /**
   * Get a record by its ID.
   * @param {string} id - The UUID of the record to fetch.
   * @returns {Promise<Address>} A promise that resolves to a new instance of the model.
   */
  static async getById(id) {
    return this.fetchOne('id', id, this.selectQuery);
  }
}

export default Address;
