import React, { useEffect, useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Pencil, SquareArrowOutUpRight, Trash } from 'lucide-react';
import EntityTable from '../../../../components/tables/EntityTable';
import {
  EntityTypeEnum,
  FilterOperatorEnum
} from '../../../../utilities/Enumerables';
import {
  Modal,
  TextButton,
  Alert,
  SoftButton,
  Popup,
  FilledButton
} from '@core/components';
import Search from '../../../../models/Search';
import CoreEntityType from '../../../../models/CoreEntityType';
import { formatDate } from '../../../../utilities/Formatting';
import CreateSearch from '../../../../components/popups/searches/CreateSearch';
import { UserProfileContext } from '../../../../App';
import SendEmailPopup from '../../../../components/popups/searchprocess/SendEmailPopup';
import PortalPopup from '../../../../components/popups/portal/Portal';
import PrintPopup from '../../../../components/popups/print/Print';
import { supabase } from '../../../../utilities/supabase';

const TraceSearches = ({ traceId, trace }) => {
  const navigate = useNavigate();
  const [searchStatus, setSearchStatus] = useState([]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [searchToDelete, setSearchToDelete] = useState(null);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isEmailPopupOpen, setIsEmailPopupOpen] = useState(false);
  const [isPortalPopupOpen, setIsPortalPopupOpen] = useState(false);
  const [isPrintPopupOpen, setIsPrintPopupOpen] = useState(false);
  const [isProviderConfigPopupOpen, setIsProviderConfigPopupOpen] =
    useState(false);
  const [selectedEntity, setSelectedEntity] = useState(null);
  const [emailAddresses, setEmailAddresses] = useState([]);
  const [extraAttachments, setExtraAttachments] = useState([]);
  const { userProfile: currentUser } = useContext(UserProfileContext);

  useEffect(() => {
    const fetchSearchStatus = async () => {
      try {
        const types = await CoreEntityType.getByEntityId(
          EntityTypeEnum.SearchStatus
        );

        const formattedTypes = types.map(type => ({
          value: type.type,
          key: type.id
        }));

        setSearchStatus(formattedTypes);
      } catch (error) {
        console.error('Error fetching search types:', error);
      }
    };

    fetchSearchStatus();
  }, []);

  const handleDeleteSearch = async () => {
    try {
      const updatedSearch = new Search({
        ...(await searchToDelete.toDatabase()),
        status: 151
      });

      await updatedSearch.update();

      setIsDeleteModalOpen(false);
      setSearchToDelete(null);
    } catch (error) {
      console.error('Error archiving search:', error);
    }
  };

  const menuItems = [
    {
      label: 'Open in new tab',
      icon: <SquareArrowOutUpRight size={14} />,
      onClick: data => window.open(`/searches/${data.displayId}`, '_blank')
    },
    {
      label: 'Delete',
      icon: <Trash size={14} className='text-danger-700' />,
      onClick: data => {
        setSearchToDelete(data);
        setIsDeleteModalOpen(true);
      }
    }
  ];

  return (
    <>
      <EntityTable
        name='Searches'
        customId={`trace:${traceId}:searches`}
        filter={[
          {
            field: 'display_id',
            label: 'ID',
            type: 'text',
            operator: FilterOperatorEnum.EQUALS
          },
          {
            field: 'status',
            label: 'Status',
            type: 'select',
            options: searchStatus
          },
          {
            field: 'trace.name',
            label: 'Trace',
            type: 'text',
            operator: FilterOperatorEnum.ILIKE
          },
          {
            field: 'provider.name',
            label: 'Provider',
            type: 'text',
            operator: FilterOperatorEnum.ILIKE
          },
          {
            field: 'scheme.name',
            label: 'Scheme',
            type: 'text',
            operator: FilterOperatorEnum.ILIKE
          },
          {
            field: 'showArchived',
            label: 'Show Archived',
            hint: 'Show archived searches',
            type: 'conditional_toggle',
            operator: FilterOperatorEnum.IS,
            options: {
              value: 'true'
            }
          }
        ]}
        columns={[
          {
            label: 'ID',
            field: 'displayId',
            cell: value => (
              <Link
                to={`/searches/${value}`}
                className='text-brand-700 hover:text-brand-800'
              >
                {value}
              </Link>
            )
          },
          {
            label: 'Status',
            field: 'status',
            cell: (value, row) => (
              <div className='flex items-center'>
                <span>{value.type}</span>
                {row.status.id == 75 && (
                  <SoftButton
                    onClick={e => {
                      e.stopPropagation();
                      const fetchEmail = async () => {
                        try {
                          let emailSource = 'provider';
                          let data, error;

                          if (row.scheme && row.scheme.id) {
                            // Fetch email and signatures from scheme if it exists
                            ({ data, error } = await supabase
                              .from('schemes')
                              .select('name, emails, signatures')
                              .eq('id', row.scheme.id)
                              .single());
                            emailSource = 'scheme';

                            // Check scheme signatures first
                            const schemeSignatures = data.signatures;
                            if (
                              schemeSignatures &&
                              (schemeSignatures.portal ||
                                schemeSignatures.email_digital_signature ||
                                schemeSignatures.email_docusign ||
                                schemeSignatures.post_signature ||
                                schemeSignatures.post_wet)
                            ) {
                              // Use scheme if any signature is true
                              if (schemeSignatures.portal) {
                                setIsPortalPopupOpen(true);
                              } else if (
                                schemeSignatures.post_signature ||
                                schemeSignatures.post_wet
                              ) {
                                setIsPrintPopupOpen(true);
                              } else {
                                const formattedEmails = data.emails.map(
                                  email => ({
                                    value: email,
                                    label: `${row.scheme.name} (${email})`
                                  })
                                );

                                setEmailAddresses(formattedEmails);
                                setIsEmailPopupOpen(true);
                              }
                            } else {
                              // If no valid scheme signatures, check provider
                              const { data: provider, error: providerError } =
                                await supabase
                                  .from('providers')
                                  .select('name, emails, signatures')
                                  .eq('id', row.provider.id)
                                  .single();

                              if (providerError) throw providerError;

                              const providerSignatures = provider.signatures;
                              if (
                                providerSignatures &&
                                providerSignatures.portal
                              ) {
                                setIsPortalPopupOpen(true);
                              } else if (
                                providerSignatures &&
                                (providerSignatures.post_signature ||
                                  providerSignatures.post_wet)
                              ) {
                                setIsPrintPopupOpen(true);
                              } else {
                                const formattedEmails = provider.emails.map(
                                  email => ({
                                    value: email,
                                    label: `${row.provider.name} (${email})`
                                  })
                                );

                                setEmailAddresses(formattedEmails);
                                setIsEmailPopupOpen(true);
                              }
                            }
                          } else {
                            // If no scheme, check provider directly
                            const { data: provider, error: providerError } =
                              await supabase
                                .from('providers')
                                .select('name, emails, signatures')
                                .eq('id', row.provider.id)
                                .single();

                            if (providerError) throw providerError;

                            const providerSignatures = provider.signatures;
                            if (
                              providerSignatures &&
                              providerSignatures.portal
                            ) {
                              setIsPortalPopupOpen(true);
                            } else if (
                              providerSignatures &&
                              (providerSignatures.post_signature ||
                                providerSignatures.post_wet)
                            ) {
                              setIsPrintPopupOpen(true);
                            } else {
                              const formattedEmails = provider.emails.map(
                                email => ({
                                  value: email,
                                  label: `${row.provider.name} (${email})`
                                })
                              );

                              setEmailAddresses(formattedEmails);
                              setIsEmailPopupOpen(true);
                            }
                          }

                          setExtraAttachments([
                            { entityId: row.owner.id, entityType: 20 }
                          ]);
                        } catch (error) {
                          console.error('Error fetching email:', error);
                        }
                      };
                      fetchEmail();
                    }}
                    className='ml-2'
                  >
                    Process
                  </SoftButton>
                )}
              </div>
            )
          },
          {
            label: 'Trace',
            field: 'trace',
            cell: value =>
              value ? (
                <Link
                  to={`/traces/${value.displayId}`}
                  target='_blank'
                  onClick={e => e.stopPropagation()}
                  className='hover:underline'
                >
                  {value.name}
                </Link>
              ) : (
                'N/A'
              )
          },
          {
            label: 'Provider',
            field: 'provider',
            cell: value =>
              value ? (
                <Link
                  to={`/providers/${value.id}`}
                  target='_blank'
                  onClick={e => e.stopPropagation()}
                  className='hover:underline'
                >
                  {value.name}
                </Link>
              ) : (
                'N/A'
              )
          },
          {
            label: 'Scheme',
            field: 'scheme',
            cell: value =>
              value ? (
                <Link
                  to={`/schemes/${value.id}`}
                  target='_blank'
                  onClick={e => e.stopPropagation()}
                  className='hover:underline'
                >
                  {value.name}
                </Link>
              ) : (
                'N/A'
              )
          },
          {
            label: 'Created Date',
            field: 'createdDate',
            cell: value => formatDate(value)
          },
          {
            label: 'Updated Date',
            field: 'updatedDate',
            cell: value => formatDate(value)
          }
        ]}
        menuItems={menuItems}
        model={Search}
        entityType={EntityTypeEnum.Search}
        defaultFilters={{
          trace: { value: traceId, operator: FilterOperatorEnum.EQUALS },
          status: { value: '151', operator: FilterOperatorEnum.NOT_EQUALS }
        }}
        sortDefault={{ field: 'display_id', order: 'asc' }}
        onRowClick={search => {
          navigate(`/searches/${search.displayId}`);
        }}
        canCreate={true}
        onNewClick={() => setIsCreateModalOpen(true)}
      />
      <Modal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        title='Delete Search'
        style='danger'
        actions={[
          {
            label: 'Cancel',
            onClick: () => setIsDeleteModalOpen(false),
            style: 'base'
          },
          {
            label: 'Delete',
            onClick: handleDeleteSearch,
            style: 'danger'
          }
        ]}
      >
        <p className='text-sm text-base-600'>
          Are you sure you want to delete this search? This action cannot be
          undone.
        </p>
      </Modal>
      <CreateSearch
        isOpen={isCreateModalOpen}
        onClose={() => setIsCreateModalOpen(false)}
        onSearchCreated={search => {
          setIsCreateModalOpen(false);

          navigate(`/searches/${search.displayId}`);
        }}
        trace={trace}
      />
      <SendEmailPopup
        title='Email'
        isOpen={isEmailPopupOpen}
        onClose={() => {
          setIsEmailPopupOpen(false);
          setSelectedEntity(null);
          setEmailAddresses([]);
          setExtraAttachments([]);
        }}
        entity={selectedEntity}
        entityType={EntityTypeEnum.Search}
        autoSelectTemplateId={187}
        to={emailAddresses}
        extraAttachements={extraAttachments}
        autoSelectAttachements={[
          'letter-of-authority.pdf',
          'signature_fingerprint.pdf'
        ]}
        autoRunAutomationId={21}
        onSubmit={() => {
          supabase
            .from('searches')
            .update({ status: 76 })
            .eq('id', selectedEntity.id)
            .then(({ error }) => {
              if (error) {
                console.error('Error updating search status:', error);
              } else {
                console.log('Search status updated successfully');
              }
            });
        }}
      />
      <PortalPopup
        urls={selectedEntity?.provider?.portals}
        user={selectedEntity}
        extraAttachements={extraAttachments}
        autoSelectAttachements={[
          'letter-of-authority.pdf',
          'signature_fingerprint.pdf'
        ]}
        isOpen={isPortalPopupOpen}
        onClose={() => setIsPortalPopupOpen(false)}
        onSubmit={selectedEntity => {
          supabase
            .from('searches')
            .update({ status: 76 })
            .eq('id', selectedEntity.id)
            .then(({ error }) => {
              if (error) {
                console.error('Error updating search status:', error);
              } else {
                console.log('Search status updated successfully');
              }
            });
        }}
      />
      <PrintPopup
        search={selectedEntity}
        extraAttachements={extraAttachments}
        autoSelectAttachements={[
          'provider-cover-letter.pdf',
          'letter-of-authority.pdf',
          'signature_fingerprint.pdf'
        ]}
        isOpen={isPrintPopupOpen}
        onClose={() => setIsPrintPopupOpen(false)}
        onSubmit={selectedSearch => {
          supabase
            .from('searches')
            .update({ status: 76 })
            .eq('id', selectedSearch.id)
            .then(({ error }) => {
              if (error) {
                console.error('Error updating search status:', error);
              } else {
                console.log('Search status updated successfully');
              }
            });
        }}
      />
      <Popup
        isOpen={isProviderConfigPopupOpen}
        onClose={() => setIsProviderConfigPopupOpen(false)}
        title='Provider Configuration Required'
        footer={
          <div className='flex justify-end'>
            <FilledButton
              onClick={() => {
                setIsProviderConfigPopupOpen(false);
                navigate(`/providers/${selectedEntity.provider.id}`);
              }}
              colour='primary'
            >
              Go to Provider
            </FilledButton>
          </div>
        }
      >
        <p className='text-base-600'>
          You need to configure the provider before processing this search.
          Please go to the provider check.
        </p>
      </Popup>
    </>
  );
};

export default TraceSearches;
