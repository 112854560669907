import React, { useEffect, useState } from 'react';
import { Input, DropdownInput } from '@core/components';
import CoreEntityType from '../../../../models/CoreEntityType';
import { EntityTypeEnum } from '../../../../utilities/Enumerables';

const BasicDetails = ({ companyData, handleInputChange }) => {
  const [statusOptions, setStatusOptions] = useState([]);

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const statusTypes = await CoreEntityType.getByEntityId(
          EntityTypeEnum.CompanyStatus
        );

        setStatusOptions(
          statusTypes.map(status => ({
            value: status.id,
            label: status.type
          }))
        );
      } catch (error) {
        console.error('Error fetching status options:', error);
      }
    };

    fetchOptions();
  }, []);

  return (
    <div className='space-y-4'>
      <Input
        label='Company Name'
        value={companyData.name}
        onChange={e => handleInputChange('name', e.target.value)}
        required
      />

      <Input
        label='Company ID'
        value={companyData.companyId}
        onChange={e => handleInputChange('companyId', e.target.value)}
        required
      />

      <DropdownInput
        label='Status'
        value={companyData.status}
        onChange={e => handleInputChange('status', e.target.value)}
        options={statusOptions}
        disablePlaceholder
      />
    </div>
  );
};

export default BasicDetails;
