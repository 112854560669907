import React, { useState, useEffect } from 'react';
import { Popup } from '@core/components';
import { Input, FilledButton, Checkbox } from '@core/components';
import { supabase } from '../../../../utilities/supabase';
import { EntityTypeEnum } from '../../../../utilities/Enumerables';
import { PERMISSIONS } from '../../../../utilities/Permissions';

const EditRole = ({ isOpen, onClose, onSuccess, role }) => {
  const [label, setLabel] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [permissions, setPermissions] = useState({});

  // Initialize form data when role changes
  useEffect(() => {
    if (role) {
      setLabel(role.label);
      setPermissions(role.permissions || {});
    }
  }, [role]);

  // Initialize permissions state for each entity type
  useEffect(() => {
    const initialPermissions = role?.permissions || {};
    if (Object.keys(initialPermissions).length === 0) {
      Object.values(EntityTypeEnum).forEach(entity => {
        initialPermissions[entity] = 0;
      });
    }
    setPermissions(initialPermissions);
  }, [role]);

  const handlePermissionChange = (entity, permission) => {
    setPermissions(prev => ({
      ...prev,
      [entity]: prev[entity] ^ permission // Toggle the bit using XOR
    }));
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      setError(null);

      // Validate inputs
      if (!label.trim()) {
        throw new Error('Role name is required');
      }

      // Update existing role
      const { error: updateError } = await supabase
        .from('permission_groups')
        .update({
          label: label.trim(),
          permissions
        })
        .eq('id', role.id);

      if (updateError) throw updateError;

      onSuccess?.();
      onClose();
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Popup
      isOpen={isOpen}
      onClose={onClose}
      title='Edit Role'
      size='lg'
      footer={
        <div className='flex justify-end gap-3'>
          <FilledButton onClick={onClose} colour='base' disabled={loading}>
            Cancel
          </FilledButton>
          <FilledButton onClick={handleSubmit} loading={loading}>
            Save Changes
          </FilledButton>
        </div>
      }
    >
      <div className='space-y-6'>
        {error && <div className='text-sm text-red-600'>{error}</div>}

        <div>
          <Input
            label='Role Name'
            value={label}
            onChange={e => setLabel(e.target.value)}
            placeholder='Enter role name'
            required
          />
        </div>

        <div>
          <h3 className='text-sm font-medium text-gray-900 mb-4'>
            Permissions
          </h3>
          <div className='grid grid-cols-2 gap-6'>
            {Object.entries(EntityTypeEnum).map(
              ([key, entity]) =>
                PERMISSIONS[entity] && (
                  <div key={entity} className='space-y-3'>
                    <h4 className='text-sm font-medium text-gray-700'>{key}</h4>
                    <div className='space-y-2'>
                      {Object.entries(PERMISSIONS[entity]).map(
                        ([name, value]) => (
                          <Checkbox
                            key={name}
                            label={name.replace(/_/g, ' ')}
                            checked={Boolean(permissions[entity] & value)}
                            onChange={() =>
                              handlePermissionChange(entity, value)
                            }
                          />
                        )
                      )}
                    </div>
                  </div>
                )
            )}
          </div>
        </div>
      </div>
    </Popup>
  );
};

export default EditRole;
