import { SquareArrowOutUpRight, X } from 'lucide-react';
import EntityTable from '../../../../components/tables/EntityTable';
import {
  EntityTypeEnum,
  FilterOperatorEnum
} from '../../../../utilities/Enumerables';
import { useNavigate } from 'react-router-dom';
import { formatDate } from '../../../../utilities/Formatting';
import Algorithm from '../../../../models/Algorithm';
import { useState, useEffect } from 'react';
import {
  Modal,
  SoftButton,
  FilledButton,
  Badge,
  ComboBox,
  Alert,
  UserProfilePicture
} from '@core/components';
import CoreEntityType from '../../../../models/CoreEntityType';
import { supabase } from '../../../../utilities/supabase';

const CompanyProviders = ({ companyId }) => {
  const navigate = useNavigate();
  const [isRemoveProviderModalOpen, setIsRemoveProviderModalOpen] =
    useState(false);
  const [isAddProviderModalOpen, setIsAddProviderModalOpen] = useState(false);
  const [providerToRemove, setProviderToRemove] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [algorithmToRemove, setAlgorithmToRemove] = useState(null);
  const [newProvider, setNewProvider] = useState({});
  const [statusOptions, setStatusOptions] = useState([]);
  const [archivedWarning, setArchivedWarning] = useState(null);

  useEffect(() => {
    const fetchStatusOptions = async () => {
      const types = await CoreEntityType.getByEntityId(
        EntityTypeEnum.AlgorithmStatus
      );

      const formattedTypes = types.map(type => ({
        value: type.type,
        key: type.id
      }));

      setStatusOptions(formattedTypes);
    };

    fetchStatusOptions();
  }, []);

  const handleRestore = async data => {
    try {
      const { data: linkedCompanies, error: linkError } = await supabase
        .from('companies')
        .select('companies')
        .eq('id', data.company.id);

      if (linkError) {
        console.error('Error fetching linked companies:', linkError);
        return;
      }

      const companyIds = [data.company.id, ...linkedCompanies[0].companies];

      const { data: updatedData, error } = await supabase
        .from('algorithm')
        .update({ status: 147 })
        .in('company', companyIds)
        .eq('provider', data.provider.id);

      if (error) {
        console.error('Error restoring provider:', error);
      }
    } catch (err) {
      console.error('Unexpected error during restore:', err);
    }
  };

  const menuItems = [
    {
      label: 'Open in new tab',
      icon: <SquareArrowOutUpRight size={14} />,
      onClick: data => window.open(`/providers/${data.provider.id}`, '_blank')
    },
    {
      condition: data => data.status.id !== 148 && data.status.id !== 145,
      label: 'Delete',
      onClick: data => {
        setAlgorithmToRemove(data);
        setProviderToRemove(data.provider);
        setSelectedCompany(data.company);
        setIsRemoveProviderModalOpen(true);
      }
    },
    {
      condition: data => data.status.id == 148,
      label: 'Restore',
      onClick: handleRestore
    }
  ];

  const handleRowClick = algorithm => {
    navigate(`/providers/${algorithm.provider.id}`);
  };

  const handleNewClick = () => {
    setIsAddProviderModalOpen(true);
  };

  const removeProvider = async provider => {
    try {
      const { data: linkedCompanies, error: linkError } = await supabase
        .from('companies')
        .select('companies')
        .eq('id', selectedCompany.id);

      if (linkError) {
        console.error('Error fetching linked companies:', linkError);
        return;
      }

      const companyIds = [selectedCompany.id, ...linkedCompanies[0].companies];

      const { data, error } = await supabase
        .from('algorithm')
        .update({ status: 148 })
        .in('company', companyIds)
        .eq('provider', provider.id);

      if (error) {
        console.error('Error updating provider type:', error);
      } else {
        console.log(`Successfully updated provider with ID: ${provider.id}`);
      }
    } catch (err) {
      console.error('Unexpected error:', err);
    }
  };

  const loadProviderOptions = async (query, page) => {
    const { data: currentAlgorithms, error: algoError } = await supabase
      .from('algorithm')
      .select('provider')
      .eq('company', companyId)
      .neq('status', 148);

    if (algoError) {
      console.error('[Error] Fetching current algorithms: ', algoError);

      return [];
    }

    const currentProviderIds = currentAlgorithms.map(algo => algo.provider);

    let queryBuilder = supabase
      .from('providers')
      .select('id, name')
      .textSearch('fts', query)
      .range((page - 1) * 5, page * 5 - 1)
      .neq('status', 98);

    if (currentProviderIds.length > 0) {
      queryBuilder = queryBuilder.not(
        'id',
        'in',
        `(${currentProviderIds.join(',')})`
      );
    }

    const { data, error } = await queryBuilder;

    if (error) {
      console.error('[Error] Loading Provider Options: ', error);

      return [];
    }

    return data.map(provider => ({
      label: provider.name,
      value: provider.id
    }));
  };

  return (
    <>
      <EntityTable
        name='Providers'
        customId={`company:${companyId}:providers`}
        filter={[
          {
            field: 'provider.id',
            label: 'ID',
            type: 'text',
            operator: FilterOperatorEnum.EQUALS
          },
          {
            field: 'provider.name',
            label: 'Provider Name',
            type: 'text',
            operator: FilterOperatorEnum.ILIKE
          },
          {
            field: 'status',
            label: 'Status',
            type: 'select',
            options: statusOptions
          },
          {
            field: 'created_date',
            label: 'Created Date',
            type: 'daterange',
            operator: FilterOperatorEnum.BETWEEN
          },
          {
            field: 'updated_date',
            label: 'Updated Date',
            type: 'daterange',
            operator: FilterOperatorEnum.BETWEEN
          },
          {
            field: 'showArchived',
            label: 'Show Archived',
            hint: 'Show archived providers',
            type: 'conditional_toggle',
            operator: FilterOperatorEnum.IS,
            options: {
              value: 'true'
            }
          }
        ]}
        columns={[
          { label: 'ID', field: 'provider.id' },
          { label: 'Provider', field: 'provider.name' },
          {
            label: 'Status',
            field: 'status',
            cell: value => {
              return value?.type;
            }
          },
          {
            label: 'Created Date',
            field: 'provider',
            cell: value => {
              return formatDate(value?.createdDate);
            }
          },
          {
            label: 'Updated Date',
            field: 'provider',
            cell: value => {
              return formatDate(value?.updatedDate);
            }
          }
        ]}
        menuItems={menuItems}
        model={Algorithm}
        entityType={EntityTypeEnum.Provider}
        defaultFilters={{
          status: { value: '148', operator: FilterOperatorEnum.NOT_EQUALS },
          company: { value: companyId }
        }}
        sortDefault={{ field: 'id', order: 'asc' }}
        onRowClick={handleRowClick}
        canCreate={true}
        onNewClick={handleNewClick}
      />
      <Modal
        isOpen={isRemoveProviderModalOpen}
        onClose={() => setIsRemoveProviderModalOpen(false)}
        title={<h2 className='text-2xl'>Change Algorithm</h2>}
      >
        <table className='w-full'>
          <tbody>
            {providerToRemove && selectedCompany && (
              <tr
                key={providerToRemove.name}
                className='flex w-full items-center justify-center'
              >
                <td className='w-[45%] flex justify-center'>
                  <div className='space-y-2 flex flex-col items-center'>
                    <div
                      key={selectedCompany.id}
                      className='text-sm bg-success-100 p-2 rounded-md inline-block'
                    >
                      {selectedCompany.name}{' '}
                      <Badge>ID: {selectedCompany.id}</Badge>
                    </div>
                  </div>
                </td>
                <td className='w-[15%] text-center flex items-center justify-center'>
                  <div className='border-t-4 border-success-200 w-full'></div>
                  <X className='text-xl text-danger-500 h-20 w-20' />
                  <div className='border-t-4 border-warning-200 w-full'></div>
                </td>
                <td className='w-[45%] flex justify-center'>
                  <div className='space-y-2 flex flex-col items-center'>
                    <div className='text-sm bg-warning-100 p-2 rounded-md inline-block'>
                      {providerToRemove?.match?.label ?? providerToRemove.name}{' '}
                      <Badge>
                        ID:{' '}
                        {providerToRemove.match?.value ?? providerToRemove.id}
                      </Badge>
                    </div>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <p>
          This action will permanently disconnect the company from the provider
          in the algorithm. Are you absolutely sure you want to proceed?
        </p>
        <div className='flex justify-end space-x-2 mt-4'>
          <SoftButton onClick={() => setIsRemoveProviderModalOpen(false)}>
            Cancel
          </SoftButton>
          <FilledButton
            onClick={() => {
              if (providerToRemove) {
                removeProvider(providerToRemove);
              }
              setIsRemoveProviderModalOpen(false);
            }}
            colour='danger'
          >
            Confirm
          </FilledButton>
        </div>
      </Modal>
      <Modal
        isOpen={isAddProviderModalOpen}
        onClose={() => setIsAddProviderModalOpen(false)}
        title={<h2 className='text-2xl'>Add Provider</h2>}
      >
        <ComboBox
          loadOptions={(query, page) => loadProviderOptions(query, page)}
          value={newProvider}
          onChange={async selectedProvider => {
            setNewProvider(selectedProvider);

            const { data, error } = await supabase
              .from('algorithm')
              .select(
                'provider, updated_by:user_profiles!algorithm_updated_by_fkey(id, first_name, last_name, full_name, profile_photo)'
              )
              .eq('company', companyId)
              .eq('provider', selectedProvider?.value)
              .eq('status', 148);

            if (error) {
              console.error('[Error] Fetching Archived Connections: ', error);
              return;
            }

            const algo = {
              isArchived: data.length === 1,
              updatedBy: data.length === 1 ? data[0].updated_by : {}
            };

            setArchivedWarning(algo);
          }}
          placeholder='Search Providers...'
        />
        {archivedWarning?.isArchived && (
          <Alert
            title='Archived Algorithm'
            description={
              <div>
                <p>
                  This is an archived connection. Do you want to unarchive this
                  connection?
                </p>
                <div className='flex items-center justify-center mt-2'>
                  <span className='mr-2'>Archived By:</span>
                  <UserProfilePicture
                    firstName={archivedWarning.updatedBy?.first_name}
                    lastName={archivedWarning.updatedBy?.last_name}
                    profilePicture={archivedWarning.updatedBy?.profile_photo}
                    size='sm'
                  />
                  <span className='ml-2 text-sm'>
                    {archivedWarning.updatedBy?.full_name || 'Unknown'}
                  </span>
                </div>
              </div>
            }
            style='danger'
            className='mt-4'
          />
        )}
        <div className='flex justify-end space-x-2 mt-4'>
          <SoftButton onClick={() => setIsAddProviderModalOpen(false)}>
            Cancel
          </SoftButton>
          <FilledButton
            onClick={async () => {
              if (archivedWarning?.isArchived) {
                try {
                  const { data: linkedCompanies, error: linkError } =
                    await supabase
                      .from('companies')
                      .select('companies')
                      .eq('id', companyId);

                  if (linkError) {
                    console.error(
                      'Error fetching linked companies:',
                      linkError
                    );
                    return;
                  }

                  const companyIds = [
                    companyId,
                    ...linkedCompanies[0].companies
                  ];

                  const { error: updateError } = await supabase
                    .from('algorithm')
                    .update({ status: 147 })
                    .in('company', companyIds)
                    .eq('provider', newProvider.value);

                  if (updateError) {
                    console.error(
                      '[Error] Unarchiving Provider: ',
                      updateError
                    );
                  } else {
                    setArchivedWarning(false);

                    setIsAddProviderModalOpen(false);
                    setNewProvider({});
                  }
                } catch (error) {
                  console.error('[Error] Unarchiving Provider: ', error);
                }
              } else if (newProvider.value) {
                try {
                  const { data: linkedCompanies, error: linkError } =
                    await supabase
                      .from('companies')
                      .select('companies')
                      .eq('id', companyId);

                  if (linkError) {
                    console.error(
                      'Error fetching linked companies:',
                      linkError
                    );
                    return;
                  }

                  const companyIds = [
                    companyId,
                    ...linkedCompanies[0].companies
                  ];

                  const insertData = companyIds.map(id => ({
                    company: id,
                    provider: newProvider.value,
                    status: 147
                  }));

                  const { error } = await supabase
                    .from('algorithm')
                    .insert(insertData);

                  if (error) {
                    console.error('Error adding provider:', error);
                  }

                  setIsAddProviderModalOpen(false);
                  setNewProvider({});
                } catch (error) {
                  console.error('[Error] Adding Provider: ', error);
                }
              }
            }}
            colour={archivedWarning?.isArchived ? 'warning' : 'primary'}
          >
            {archivedWarning?.isArchived ? 'Unarchive' : 'Add'}
          </FilledButton>
        </div>
      </Modal>
    </>
  );
};

export default CompanyProviders;
