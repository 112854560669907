import React, { useState, useEffect } from 'react';
import { FilledButton, TextButton, Popup } from '@core/components';
import TabView from '../../navigation/TabView';
import Details from './Details';
import Comments from './Comments';
import { useNavigate, useParams, useLocation } from 'react-router-dom';

const TaskPopup = ({ task: initialTask, onClose }) => {
  const [task, setTask] = useState(initialTask);
  const [editedTask, setEditedTask] = useState({ ...initialTask });
  const [isLoading, setIsLoading] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { '*': wildcard } = useParams();

  useEffect(() => {
    if (!wildcard) {
      navigate(`details`, { replace: true });
    }
  }, [wildcard, navigate]);

  const handleSave = async () => {
    setIsLoading(true);
    try {
      const assignees = editedTask.assignees.map(assignee => assignee.value);
      const updatedTask = await task.update({
        ...editedTask,
        assignees
      });
      setTask(updatedTask);
      onClose();
    } catch (error) {
      console.error('Error updating task:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const tabs = [
    {
      label: 'Details',
      path: `${task.id}/details`,
      content: <Details editedTask={editedTask} setEditedTask={setEditedTask} />
    },
    {
      label: 'Comments',
      path: `${task.id}/comments`,
      content: <Comments taskId={task.id} />
    }
  ];

  return (
    <Popup
      isOpen={true}
      title={editedTask.title}
      onClose={onClose}
      footer={
        <div className='flex justify-end space-x-2'>
          <TextButton colour='base' onClick={onClose}>
            Cancel
          </TextButton>
          <FilledButton
            colour='primary'
            onClick={handleSave}
            disabled={isLoading}
          >
            {isLoading ? 'Saving...' : 'Save'}
          </FilledButton>
        </div>
      }
    >
      <TabView
        tabs={tabs}
        baseUrl={`${task.id}`}
        fullWidth={true}
        default={'details'}
      />
    </Popup>
  );
};

export default TaskPopup;
