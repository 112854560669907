import React, { useState, useEffect } from 'react';
import { SquareArrowOutUpRight, X } from 'lucide-react';
import EntityTable from '../../../../components/tables/EntityTable';
import {
  EntityTypeEnum,
  FilterOperatorEnum
} from '../../../../utilities/Enumerables';
import { useNavigate } from 'react-router-dom';
import CoreEntityType from '../../../../models/CoreEntityType';
import { formatDate } from '../../../../utilities/Formatting';
import Algorithm from '../../../../models/Algorithm';
import {
  Modal,
  SoftButton,
  FilledButton,
  Badge,
  ComboBox
} from '@core/components';
import { supabase } from '../../../../utilities/supabase';

const ProviderCompanies = ({ providerId }) => {
  const navigate = useNavigate();
  const [isRemoveCompanyModalOpen, setIsRemoveCompanyModalOpen] =
    useState(false);
  const [isAddCompanyModalOpen, setIsAddCompanyModalOpen] = useState(false);
  const [companyToRemove, setCompanyToRemove] = useState(null);
  const [selectedProvider, setSelectedProvider] = useState(null);
  const [algorithmToRemove, setAlgorithmToRemove] = useState(null);
  const [newCompany, setNewCompany] = useState({});
  const [statusOptions, setStatusOptions] = useState([]);

  useEffect(() => {
    const fetchStatusOptions = async () => {
      try {
        const statuses = await CoreEntityType.getByEntityId(
          EntityTypeEnum.CompanyStatus
        );
        setStatusOptions(
          statuses.map(status => ({ value: status.type, key: status.id }))
        );
      } catch (error) {
        console.error('Error fetching status options:', error);
      }
    };

    fetchStatusOptions();
  }, []);

  const handleRestore = async data => {
    const { data: updatedData, error } = await supabase
      .from('algorithm')
      .update({ status: 147 })
      .eq('id', data.id);
  };

  const menuItems = [
    {
      label: 'Open in new tab',
      icon: <SquareArrowOutUpRight size={14} />,
      onClick: data => window.open(`/companies/${data.company.id}`, '_blank')
    },
    {
      condition: data => data.status.id != 148,
      label: 'Delete',
      onClick: data => {
        setAlgorithmToRemove(data);
        setCompanyToRemove(data.company);
        setSelectedProvider(data.provider);
        setIsRemoveCompanyModalOpen(true);
      }
    },
    {
      condition: data => data.status.id == 148,
      label: 'Restore',
      onClick: handleRestore
    }
  ];

  const handleRowClick = algorithm => {
    navigate(`/companies/${algorithm.company.id}`);
  };

  const handleNewClick = () => {
    setIsAddCompanyModalOpen(true);
  };

  const removeCompany = async company => {
    try {
      const { data, error } = await supabase
        .from('algorithm')
        .update({ status: 148 })
        .eq('id', algorithmToRemove.id);

      if (error) {
        console.error('Error updating company:', error);
      } else {
        console.log(`Successfully updated company with ID: ${company.id}`);
      }
    } catch (err) {
      console.error('Unexpected error:', err);
    }
  };

  const loadCompanyOptions = async (query, page) => {
    const { data: currentAlgorithms, error: algoError } = await supabase
      .from('algorithm')
      .select('company')
      .eq('provider', providerId);

    if (algoError) {
      console.error('[Error] Fetching current algorithms: ', algoError);
      return [];
    }

    const currentCompanyIds = currentAlgorithms.map(algo => algo.company);

    let queryBuilder = supabase
      .from('companies')
      .select('id, name')
      .textSearch('fts', query)
      .range((page - 1) * 5, page * 5 - 1);

    if (currentCompanyIds.length > 0) {
      queryBuilder = queryBuilder.not(
        'id',
        'in',
        `(${currentCompanyIds.join(',')})`
      );
    }

    const { data, error } = await queryBuilder;

    if (error) {
      console.error('[Error] Loading Company Options: ', error);
      return [];
    }

    return data.map(company => ({
      label: company.name,
      value: company.id
    }));
  };

  return (
    <>
      <EntityTable
        name='Companies'
        customId={`provider:${providerId}:companies`}
        filter={[
          {
            field: 'company.id',
            label: 'ID',
            type: 'text',
            operator: FilterOperatorEnum.EQUALS
          },
          {
            field: 'company.name',
            label: 'Company Name',
            type: 'text',
            operator: FilterOperatorEnum.ILIKE
          },
          {
            field: 'company.number',
            label: 'Company Number',
            type: 'text',
            operator: FilterOperatorEnum.ILIKE
          },
          {
            field: 'created_date',
            label: 'Created Date',
            type: 'daterange',
            operator: FilterOperatorEnum.BETWEEN
          },
          {
            field: 'updated_date',
            label: 'Updated Date',
            type: 'daterange',
            operator: FilterOperatorEnum.BETWEEN
          }
        ]}
        columns={[
          { label: 'ID', field: 'company.id' },
          { label: 'Company', field: 'company.name' },
          { label: 'Company Number', field: 'company.number' },
          {
            label: 'Created Date',
            field: 'company',
            cell: value => {
              return formatDate(value?.createdDate);
            }
          },
          {
            label: 'Updated Date',
            field: 'company',
            cell: value => {
              return formatDate(value?.updatedDate);
            }
          }
        ]}
        menuItems={menuItems}
        model={Algorithm}
        entityType={EntityTypeEnum.Company}
        defaultFilters={{
          status: { value: '148', operator: FilterOperatorEnum.NOT_EQUALS },
          provider: { value: providerId }
        }}
        sortDefault={{ field: 'id', order: 'asc' }}
        onRowClick={handleRowClick}
        canCreate={true}
        onNewClick={handleNewClick}
      />
      <Modal
        isOpen={isRemoveCompanyModalOpen}
        onClose={() => setIsRemoveCompanyModalOpen(false)}
        title={<h2 className='text-2xl'>Change Algorithm</h2>}
      >
        <table className='w-full'>
          <tbody>
            {companyToRemove && selectedProvider && (
              <tr
                key={companyToRemove.name}
                className='flex w-full items-center justify-center'
              >
                <td className='w-[45%] flex justify-center'>
                  <div className='space-y-2 flex flex-col items-center'>
                    <div
                      key={selectedProvider.id}
                      className='text-sm bg-success-100 p-2 rounded-md inline-block'
                    >
                      {selectedProvider.name}{' '}
                      <Badge>ID: {selectedProvider.id}</Badge>
                    </div>
                  </div>
                </td>
                <td className='w-[15%] text-center flex items-center justify-center'>
                  <div className='border-t-4 border-success-200 w-full'></div>
                  <X className='text-xl text-danger-500 h-20 w-20' />
                  <div className='border-t-4 border-warning-200 w-full'></div>
                </td>
                <td className='w-[45%] flex justify-center'>
                  <div className='space-y-2 flex flex-col items-center'>
                    <div className='text-sm bg-warning-100 p-2 rounded-md inline-block'>
                      {companyToRemove?.match?.label ?? companyToRemove.name}{' '}
                      <Badge>
                        ID: {companyToRemove.match?.value ?? companyToRemove.id}
                      </Badge>
                    </div>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <p>
          This action will permanently disconnect the provider from the company
          in the algorithm. Are you absolutely sure you want to proceed?
        </p>
        <div className='flex justify-end space-x-2 mt-4'>
          <SoftButton onClick={() => setIsRemoveCompanyModalOpen(false)}>
            Cancel
          </SoftButton>
          <FilledButton
            onClick={() => {
              if (companyToRemove) {
                removeCompany(companyToRemove);
              }
              setIsRemoveCompanyModalOpen(false);
            }}
            colour='danger'
          >
            Confirm
          </FilledButton>
        </div>
      </Modal>
      <Modal
        isOpen={isAddCompanyModalOpen}
        onClose={() => setIsAddCompanyModalOpen(false)}
        title={<h2 className='text-2xl'>Add Company</h2>}
      >
        <ComboBox
          loadOptions={(query, page) => loadCompanyOptions(query, page)}
          value={newCompany}
          onChange={setNewCompany}
          placeholder='Search Companies...'
        />
        <div className='flex justify-end space-x-2 mt-4'>
          <SoftButton onClick={() => setIsAddCompanyModalOpen(false)}>
            Cancel
          </SoftButton>
          <FilledButton
            onClick={async () => {
              if (newCompany.value) {
                try {
                  // Logic to add the new company
                  setIsAddCompanyModalOpen(false);
                  setNewCompany({});
                } catch (error) {
                  console.error('[Error] Adding Company: ', error);
                }
              }
            }}
            colour='primary'
          >
            Add
          </FilledButton>
        </div>
      </Modal>
    </>
  );
};

export default ProviderCompanies;
