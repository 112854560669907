import { useState, useEffect } from 'react';
import {
  Input,
  FilledButton,
  TextButton,
  ColourPicker,
  IconPicker,
  Popup,
  Modal
} from '@core/components';
import CoreEntityType from '../../../models/CoreEntityType';

export default function EditDocCategory({ isOpen, onClose, category }) {
  const [loading, setLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [formData, setFormData] = useState({
    type: '',
    description: '',
    metadata: {
      icon: 'Book',
      colour: 'gray',
      slug: ''
    }
  });

  useEffect(() => {
    if (category) {
      setFormData({
        type: category.type || '',
        description: category.description || '',
        metadata: {
          icon: category.metadata?.icon || 'Book',
          colour: `${category.metadata?.colour || 'gray'}-500`,
          slug: category.metadata?.slug || ''
        }
      });
    }
  }, [category]);

  const handleSubmit = async e => {
    e.preventDefault();
    setLoading(true);

    try {
      // Update existing category
      const updatedCategory = new CoreEntityType({
        ...category,
        ...formData,
        metadata: {
          ...formData.metadata,
          colour: formData.metadata.colour.replace('-500', '')
        }
      });
      await updatedCategory.update();

      onClose();
    } catch (error) {
      console.error('Error updating category:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    setLoading(true);
    try {
      await category.delete();
      onClose();
    } catch (error) {
      console.error('Error deleting category:', error);
    } finally {
      setLoading(false);
      setShowDeleteModal(false);
    }
  };

  const handleChange = (field, value) => {
    if (field.includes('.')) {
      const [parent, child] = field.split('.');
      setFormData(prev => ({
        ...prev,
        [parent]: {
          ...prev[parent],
          [child]: value
        }
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        [field]: value
      }));
    }
  };

  return (
    <>
      <Modal
        isOpen={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        title='Delete Category'
        style='danger'
        actions={[
          {
            label: 'Delete',
            onClick: handleDelete,
            style: 'danger'
          },
          {
            label: 'Cancel',
            onClick: () => setShowDeleteModal(false),
            style: 'base'
          }
        ]}
      >
        <p className='text-base-600'>
          Are you sure you want to delete this category? All guides within this
          category will become uncategorized. This action cannot be undone.
        </p>
      </Modal>

      <Popup
        isOpen={isOpen}
        onClose={onClose}
        title='Edit Documentation Category'
        size='sm'
        footer={
          <div className='flex justify-between gap-2 w-full'>
            <TextButton
              onClick={() => setShowDeleteModal(true)}
              disabled={loading}
              colour='danger'
            >
              Delete Category
            </TextButton>
            <div className='flex gap-2'>
              <TextButton onClick={onClose} disabled={loading}>
                Cancel
              </TextButton>
              <FilledButton onClick={handleSubmit} loading={loading}>
                Save Changes
              </FilledButton>
            </div>
          </div>
        }
      >
        <div className='space-y-4'>
          <Input
            label='Category Name'
            value={formData.type}
            onChange={e => handleChange('type', e.target.value)}
            required
          />

          <Input
            label='Description'
            value={formData.description}
            onChange={e => handleChange('description', e.target.value)}
            required
          />

          <Input
            label='Slug'
            value={formData.metadata.slug}
            onChange={e => handleChange('metadata.slug', e.target.value)}
            hint='The slug cannot be changed once created'
            required
            disabled
          />

          <div>
            <label className='block text-sm font-medium text-gray-700 mb-1'>
              Icon
            </label>
            <IconPicker
              selectedIcon={formData.metadata.icon}
              onChange={value => handleChange('metadata.icon', value)}
            />
          </div>

          <div>
            <label className='block text-sm font-medium text-gray-700 mb-1'>
              Colour
            </label>
            <ColourPicker
              showShades={false}
              colour={formData.metadata.colour}
              onChange={value => handleChange('metadata.colour', value)}
            />
          </div>
        </div>
      </Popup>
    </>
  );
}
