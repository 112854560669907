import React, { useEffect, useState, useContext } from 'react';
import UserProfile from '../../../models/UserProfile';
import EntityTable from '../../../components/tables/EntityTable';
import { formatDate, formatBirthDate } from '../../../utilities/Formatting';
import { Link, useNavigate } from 'react-router-dom';
import {
  Pencil,
  Trash,
  SquareArrowOutUpRight,
  Building,
  Ban
} from 'lucide-react';
import {
  EntityTypeEnum,
  FilterOperatorEnum
} from '../../../utilities/Enumerables';
import CoreEntityType from '../../../models/CoreEntityType';
import { Modal, Alert } from '@core/components';
import { UserProfileContext } from '../../../App';
import { PERMISSIONS } from '../../../utilities/Permissions';
import { supabase } from '../../../utilities/supabase';

const Users = () => {
  document.title = 'Users | CRM | PTS';
  const navigate = useNavigate();
  const [titleOptions, setTitleOptions] = useState([]);
  const [segmentationOptions, setSegmentationOptions] = useState([]);
  const [statusOptions, setStatusOptions] = useState([]);
  const [subStatusOptions, setSubStatusOptions] = useState([]);
  const [subtagOptions, setSubtagOptions] = useState([]); // Added state for subtag options
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);
  const { userProfile: currentUser } = useContext(UserProfileContext);

  if (
    !currentUser.hasPermission(
      EntityTypeEnum.User,
      PERMISSIONS[EntityTypeEnum.User].VIEW
    )
  ) {
    return (
      <Alert
        title='Permission Denied'
        description='You do not have permission to view users.'
        style='danger'
      />
    );
  }

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const titles = await CoreEntityType.getByEntityId(EntityTypeEnum.Title);
        const segmentations = await CoreEntityType.getByEntityId(
          EntityTypeEnum.Segmentation
        );
        const tags = await CoreEntityType.getByEntityId(
          EntityTypeEnum.UserTags
        );
        const subtags = await CoreEntityType.getByEntityId(
          EntityTypeEnum.UserSubTags
        );
        const coreSubtags = await CoreEntityType.getByEntityId(54); // Fetch subtags options

        setTitleOptions(
          titles.map(title => ({ value: title.type, key: title.id }))
        );
        setSegmentationOptions(
          segmentations.map(segmentation => ({
            value: segmentation.type,
            key: segmentation.id
          }))
        );
        setStatusOptions(
          tags.map(tag => ({
            value: tag.type,
            key: tag.id
          }))
        );
        setSubStatusOptions(
          subtags.map(tag => ({
            value: tag.type,
            key: tag.id
          }))
        );
        setSubtagOptions(
          coreSubtags.map(subtag => ({
            value: subtag.type,
            key: subtag.id
          }))
        );
      } catch (error) {
        console.error('Error fetching options:', error);
      }
    };

    fetchOptions();
  }, []);

  const handleDeleteUser = async () => {
    try {
      const { error } = await supabase
        .from('user_profiles')
        .update({ status: 97 })
        .eq('id', userToDelete.id);

      if (error) throw error;

      setIsDeleteModalOpen(false);
      setUserToDelete(null);
    } catch (error) {
      console.error('Error archiving user:', error);
    }
  };

  const menuItems = [
    {
      label: 'Open in new tab',
      icon: <SquareArrowOutUpRight size={14} />,
      onClick: data => window.open(`/user-profiles/${data.displayId}`, '_blank')
    },
    {
      label: 'Delete',
      icon: <Trash size={14} className='text-danger-700' />,
      onClick: data => {
        setUserToDelete(data);
        setIsDeleteModalOpen(true);
      }
    }
  ];

  return (
    <div className='p-4'>
      <EntityTable
        name='Users'
        customId='users'
        filter={[
          { field: 'display_id', label: 'ID', type: 'text', operator: 'eq' },
          {
            field: 'title',
            label: 'Title',
            type: 'multiselect',
            options: titleOptions,
            operator: FilterOperatorEnum.IN,
            isOr: true
          },
          {
            field: 'first_name',
            label: 'First Name',
            type: 'text',
            operator: FilterOperatorEnum.ILIKE
          },
          {
            field: 'last_name',
            label: 'Last Name',
            type: 'text',
            operator: FilterOperatorEnum.ILIKE
          },
          {
            field: 'statuses',
            label: 'Statuses',
            type: 'multiselect',
            options: statusOptions,
            operator: 'cs',
            isOr: true,
            isArray: true
          },
          {
            field: 'substatuses',
            label: 'Substatuses',
            type: 'multiselect',
            options: subStatusOptions,
            operator: 'cs',
            isOr: true,
            isArray: true
          },
          {
            field: 'subtags',
            label: 'Case Status',
            type: 'multiselect',
            options: subtagOptions, // Updated to use subtagOptions
            operator: 'cs',
            isOr: true,
            isArray: true
          },
          {
            field: 'email',
            label: 'Email',
            type: 'text',
            operator: FilterOperatorEnum.ILIKE
          },
          {
            field: 'phone_number',
            label: 'Phone Number ',
            type: 'text',
            operator: FilterOperatorEnum.ILIKE
          },
          // {
          //   field: 'previous_names',
          //   label: 'Previous Names',
          //   type: 'text',
          //   operator: FilterOperatorEnum.IN
          // },
          {
            field: 'national_insurance',
            label: 'National Insurance',
            type: 'text',
            operator: FilterOperatorEnum.ILIKE
          },
          {
            field: 'segmentation',
            label: 'Segmentation',
            type: 'select',
            options: segmentationOptions
          },
          {
            field: 'marketing->post',
            label: 'Marketing Post',
            type: 'select',
            options: [
              { value: 'Yes', key: 'true' },
              { value: 'No', key: 'false' }
            ]
          },
          {
            field: 'marketing->text',
            label: 'Marketing Text',
            type: 'select',
            options: [
              { value: 'Yes', key: 'true' },
              { value: 'No', key: 'false' }
            ]
          },
          {
            field: 'marketing->email',
            label: 'Marketing Email',
            type: 'select',
            options: [
              { value: 'Yes', key: 'true' },
              { value: 'No', key: 'false' }
            ]
          },
          {
            field: 'birth_date',
            label: 'Birth Date',
            type: 'daterange',
            operator: FilterOperatorEnum.BETWEEN
          },
          {
            field: 'created_date',
            label: 'Created Date',
            type: 'daterange',
            operator: FilterOperatorEnum.BETWEEN
          },
          {
            field: 'updated_date',
            label: 'Updated Date',
            type: 'daterange',
            operator: FilterOperatorEnum.BETWEEN
          },
          {
            field: 'showArchived',
            label: 'Show Archived',
            hint: 'Show archived users',
            type: 'conditional_toggle',
            operator: FilterOperatorEnum.IS,
            options: {
              value: 'true'
            }
          },
          {
            field: 'stats->traces_total',
            label: 'Total Traces',
            type: 'numberrange',
            operator: FilterOperatorEnum.BETWEEN
          },
          {
            field: 'stats->traces_completed',
            label: 'Total Traces Completed',
            type: 'numberrange',
            operator: FilterOperatorEnum.BETWEEN
          },
          {
            field: 'stats->pensions_total',
            label: 'Total Pensions',
            type: 'numberrange',
            operator: FilterOperatorEnum.BETWEEN
          },
          {
            field: 'stats->pensions_value',
            label: 'Pensions Value',
            type: 'numberrange',
            operator: FilterOperatorEnum.BETWEEN
          }
        ]}
        sortDefault={{ field: 'display_id', order: 'asc' }}
        columns={[
          {
            label: 'ID',
            field: 'displayId',
            cell: (value, row) => (
              <div className='flex items-center'>
                {row.tags.includes(5) && (
                  <Ban className='w-4 h-4 mr-2 text-red-600' />
                )}
                <Link
                  to={`/user-profiles/${value}`}
                  className='text-brand-700 hover:text-brand-800'
                >
                  {value}
                </Link>
              </div>
            )
          },
          { label: 'Title', field: 'title.type' },
          { label: 'First Name', field: 'firstName' },
          { label: 'Last Name', field: 'lastName' },
          { label: 'Email', field: 'email' },
          { label: 'Phone Number', field: 'phoneNumber' },
          {
            label: 'Previous Names',
            field: 'previousNames',
            cell: value => (value ? value.join(', ') : '')
          },
          { label: 'National Insurance', field: 'nationalInsurance' },
          {
            label: 'Post',
            field: 'marketing',
            sortable: false,
            cell: value => (value.post ? 'Yes' : 'No')
          },
          {
            label: 'Text',
            field: 'marketing',
            sortable: false,
            cell: value => (value.text ? 'Yes' : 'No')
          },
          {
            label: 'Email',
            field: 'marketing',
            sortable: false,
            cell: value => (value.email ? 'Yes' : 'No')
          },
          {
            label: 'Birth Date',
            field: 'birthDate',
            cell: value => formatBirthDate(value)
          },
          {
            label: 'Created Date',
            field: 'createdDate',
            cell: value => formatDate(value)
          },
          {
            label: 'Updated Date',
            field: 'updatedDate',
            cell: value => formatDate(value)
          }
        ]}
        defaultFilters={{
          status: { value: '97', operator: FilterOperatorEnum.NOT_EQUALS },
          admin: { value: false },
          ...(currentUser?.role.id == 14
            ? {
                advisors: {
                  value: [currentUser.id],
                  operator: 'contains'
                }
              }
            : {})
        }}
        menuItems={menuItems}
        model={UserProfile}
        entityType={EntityTypeEnum.User}
        onRowClick={data => navigate(`/user-profiles/${data.displayId}`)}
        canCreate={true}
        onNewClick={() => {
          navigate('/user-profiles/create');
        }}
      />
      <Modal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        title='Delete User'
        style='danger'
        actions={[
          {
            label: 'Cancel',
            onClick: () => setIsDeleteModalOpen(false),
            style: 'base'
          },
          {
            label: 'Delete',
            onClick: handleDeleteUser,
            style: 'danger'
          }
        ]}
      >
        <p className='text-sm text-base-600'>
          Are you sure you want to delete this user? This action cannot be
          undone.
        </p>
      </Modal>
    </div>
  );
};

export default Users;
