import { SquareArrowOutUpRight } from 'lucide-react';
import EntityTable from '../../../../components/tables/EntityTable';
import {
  EntityTypeEnum,
  FilterOperatorEnum
} from '../../../../utilities/Enumerables';
import { useNavigate } from 'react-router-dom';
import { formatDate } from '../../../../utilities/Formatting';
import Search from '../../../../models/Search';
import Scheme from '../../../../models/Scheme';

const ProviderSchemes = ({ providerId }) => {
  const navigate = useNavigate();

  const menuItems = [
    {
      label: 'Open in new tab',
      icon: <SquareArrowOutUpRight size={14} />,
      onClick: data => window.open(`/schemes/${data.scheme.id}`, '_blank')
    }
  ];

  const handleRowClick = search => {
    navigate(`/schemes/${search.scheme.id}`);
  };

  return (
    <EntityTable
      name='Schemes'
      customId={`provider:${providerId}:schemes`}
      filter={[
        {
          field: 'id',
          label: 'ID',
          type: 'text',
          operator: FilterOperatorEnum.EQUALS
        },
        {
          field: 'name',
          label: 'Scheme Name',
          type: 'text',
          operator: FilterOperatorEnum.ILIKE
        },
        {
          field: 'created_date',
          label: 'Created Date',
          type: 'daterange',
          operator: FilterOperatorEnum.BETWEEN
        },
        {
          field: 'updated_date',
          label: 'Updated Date',
          type: 'daterange',
          operator: FilterOperatorEnum.BETWEEN
        }
      ]}
      columns={[
        { label: 'ID', field: 'id' },
        { label: 'Scheme', field: 'name' },
        {
          label: 'Created Date',
          field: 'scheme',
          cell: (value, row) => {
            return formatDate(row?.createdDate);
          }
        },
        {
          label: 'Updated Date',
          field: 'scheme',
          cell: (value, row) => {
            return formatDate(row?.updatedDate);
          }
        }
      ]}
      menuItems={menuItems}
      model={Scheme}
      entityType={EntityTypeEnum.Scheme}
      defaultFilters={{
        provider: { value: providerId }
        // scheme: { operator: 'not', innerOperator: 'is', value: 'null' }
      }}
      sortDefault={{ field: 'id', order: 'asc' }}
      onRowClick={handleRowClick}
    />
  );
};

export default ProviderSchemes;
