'use client';

import { useState, useEffect } from 'react';
import { format } from 'date-fns';
import { CalendarIcon, ChevronLeft, ChevronRight } from 'lucide-react';

import { cn } from '@/lib/utils';
import { Button } from '@core/components/ui';
import { Calendar } from '@core/components/ui';
import { Popover, PopoverContent, PopoverTrigger } from '@core/components/ui';

export function DatePickerV2({
  initialDate = null,
  onChange,
  disabled = false,
  required = false,
  label,
  hint
}) {
  const [date, setDate] = useState(initialDate);
  const [selectedYear, setSelectedYear] = useState(
    initialDate ? initialDate.getFullYear() : new Date().getFullYear()
  );
  const [yearViewCenter, setYearViewCenter] = useState(
    initialDate ? initialDate.getFullYear() : new Date().getFullYear()
  );
  const [calendarMonth, setCalendarMonth] = useState(initialDate || new Date());

  useEffect(() => {
    setDate(initialDate);
    if (initialDate) {
      setSelectedYear(initialDate.getFullYear());
      setYearViewCenter(initialDate.getFullYear());
      setCalendarMonth(initialDate);
    }
  }, [initialDate]);

  const handleSelect = selectedDate => {
    setDate(selectedDate);
    if (onChange) {
      onChange(selectedDate);
    }
  };

  const handleYearSelect = year => {
    // Create a new date for the calendar view only
    const newCalendarMonth = new Date(calendarMonth);
    newCalendarMonth.setFullYear(year);

    setCalendarMonth(newCalendarMonth);
    setSelectedYear(year);
  };

  const renderYearCarousel = () => {
    const prevYear = yearViewCenter - 1;
    const nextYear = yearViewCenter + 1;

    return (
      <div className='flex justify-between items-center p-2 border-b'>
        <Button
          variant='ghost'
          onClick={() => setYearViewCenter(yearViewCenter - 3)}
          className='h-7 w-7 bg-transparent p-0'
        >
          <ChevronLeft className='h-4 w-4' />
        </Button>
        <div className='flex space-x-2'>
          <Button
            variant={prevYear === selectedYear ? 'default' : 'ghost'}
            className='h-9'
            onClick={() => handleYearSelect(prevYear)}
          >
            {prevYear}
          </Button>
          <Button
            variant={yearViewCenter === selectedYear ? 'default' : 'ghost'}
            className='h-9'
            onClick={() => handleYearSelect(yearViewCenter)}
          >
            {yearViewCenter}
          </Button>
          <Button
            variant={nextYear === selectedYear ? 'default' : 'ghost'}
            className='h-9'
            onClick={() => handleYearSelect(nextYear)}
          >
            {nextYear}
          </Button>
        </div>
        <Button
          variant='ghost'
          onClick={() => setYearViewCenter(yearViewCenter + 3)}
          className='h-7 w-7 bg-transparent p-0'
        >
          <ChevronRight className='h-4 w-4' />
        </Button>
      </div>
    );
  };

  return (
    <div className='w-full'>
      {label && (
        <div className='flex justify-between'>
          <label className='block text-sm font-medium leading-6 text-gray-900'>
            {label}
            {required && <span className='text-danger-700 ml-1'>*</span>}
          </label>
        </div>
      )}
      <Popover>
        <PopoverTrigger asChild>
          <Button
            variant={'outline'}
            className={cn(
              'w-full justify-start text-left font-normal px-2.5 py-1.5 text-sm',
              !date && 'text-muted-foreground',
              required && !date && 'border-red-500',
              disabled && 'cursor-not-allowed'
            )}
            disabled={disabled}
          >
            <CalendarIcon className='mr-2 h-4 w-4' />
            {date ? (
              format(date, 'do MMMM yyyy')
            ) : (
              <span>Pick a date{required && '*'}</span>
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent className='w-auto p-0'>
          <div>
            {renderYearCarousel()}
            <Calendar
              mode='single'
              selected={date}
              onSelect={handleSelect}
              initialFocus
              disabled={disabled}
              defaultMonth={calendarMonth}
              month={calendarMonth}
              onMonthChange={setCalendarMonth}
            />
          </div>
        </PopoverContent>
      </Popover>
      {hint && <p className='mt-1 text-sm text-gray-500'>{hint}</p>}
    </div>
  );
}
