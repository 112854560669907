import {
  Building,
  CheckCircle,
  PenLine,
  MousePointer,
  FileCheck,
  Plus,
  X,
  Phone,
  Mail,
  MailOpen,
  Mailbox
} from 'lucide-react';
import Entity from '../../../components/entity/Entity';
import Scheme from '../../../models/Scheme';
import DateCard from '../../../components/entity/DateCard';
import Field from '../../../components/entity/Field';
import CoreEntityTypes from '../../../models/CoreEntityType';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { EntityTypeEnum } from '../../../utilities/Enumerables';
import Providers from './tabs/Providers';
import SchemeCompanies from './tabs/Companies';
import SchemeAddresses from './tabs/Addresses';
import SchemeSearches from './tabs/Searches';
import SchemeTraces from './tabs/Traces';
import SchemePensions from './tabs/Pensions';
import SchemeUsers from './tabs/Users';
import { Input, SoftButton, Modal } from '@core/components';
import { supabase } from '../../../utilities/supabase';

const SchemeDetails = ({ currentUser }) => {
  const { id } = useParams();
  const [statusOptions, setStatusOptions] = useState([]);
  const [previousName, setPreviousName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [pendingUpdate, setPendingUpdate] = useState(null);

  document.title = `Scheme #${id} | CRM | PTS`;

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const options = await CoreEntityTypes.getByEntityId(
          EntityTypeEnum.SchemeStatus
        );

        const formattedOptions = options.map(option => ({
          label: option.type,
          value: option.id
        }));

        setStatusOptions(formattedOptions);
      } catch (error) {
        console.error('Error fetching status options:', error);
      }
    };

    fetchOptions();
  }, []);

  const loadProviders = async query => {
    const { data, error } = await supabase.rpc('get_provider_name_similarity', {
      p_name: query
    });

    if (error) {
      console.error('[Error] Loading Provider Options: ', error);

      return [];
    }

    return data.map(provider => ({
      label: provider.name,
      value: provider.id
    }));
  };

  const handleUpdate = (field, value, onUpdate) => {
    if (field === 'signatures.rejected' && value) {
      setPendingUpdate({ field, value, onUpdate });
      setShowConfirmModal(true);
    } else {
      onUpdate(field, value);
    }
  };

  const confirmUpdate = () => {
    if (pendingUpdate) {
      const { field, value, onUpdate } = pendingUpdate;
      onUpdate(field, value);
      setPendingUpdate(null);
    }
    setShowConfirmModal(false);
  };

  const infoItems = [
    {
      icon: Building,
      label: 'Scheme',
      field: 'name',
      dataType: 'text',
      isEditable: true
    },
    {
      icon: CheckCircle,
      label: 'Status',
      displayField: 'status.type',
      editField: 'status.id',
      dataType: 'select',
      isEditable: true,
      options: statusOptions
    },
    {
      icon: FileCheck,
      label: 'Provider',
      displayField: 'provider.name',
      editField: 'provider.id',
      dataType: 'search',
      isEditable: true,
      loadOptions: loadProviders,
      displayLink: data => `/providers/${data?.provider?.id}`
    }
  ];

  const accordionItems = [
    {
      title: 'Key Dates',
      content: ({ data }) => (
        <div className='grid grid-cols-1 md:grid-cols-3 gap-2'>
          <DateCard date={data.createdDate} label='Created' />
          <DateCard date={data.updatedDate} label='Updated' />
        </div>
      )
    },
    {
      title: 'Requests',
      content: ({ data, onUpdate }) => (
        <div className='space-y-2'>
          <Field
            Icon={CheckCircle}
            label='Third Party Failed'
            value={data.signatures.rejected ?? false}
            editField='signatures.rejected'
            dataType='checkbox'
            isEditable={true}
            onUpdate={(field, value) => handleUpdate(field, value, onUpdate)}
          />
          {!data.signatures?.rejected && (
            <div>
              <Field
                Icon={MousePointer}
                label='Portal'
                value={data.signatures?.portal}
                editField='signatures.portal'
                dataType='checkbox'
                isEditable={true}
                onUpdate={(field, value) => {
                  onUpdate('signatures', {
                    ...data.signatures,
                    portal: value
                  });
                }}
              />
              <Field
                Icon={Mail}
                label='Email With Digital Signature'
                value={data.signatures?.email_digital_signature}
                editField='signatures.email_digital_signature'
                dataType='checkbox'
                isEditable={true}
                onUpdate={(field, value) => {
                  onUpdate('signatures', {
                    ...data.signatures,
                    email_digital_signature: value
                  });
                }}
              />
              <Field
                Icon={FileCheck}
                label='Email With DocuSign'
                value={data.signatures?.email_docusign}
                editField='signatures.email_docusign'
                dataType='checkbox'
                isEditable={true}
                onUpdate={(field, value) => {
                  onUpdate('signatures', {
                    ...data.signatures,
                    email_docusign: value
                  });
                }}
              />
              <Field
                Icon={Mailbox}
                label='Post With Signature'
                value={data.signatures?.post_signature}
                editField='signatures.post_signature'
                dataType='checkbox'
                isEditable={true}
                onUpdate={(field, value) => {
                  onUpdate('signatures', {
                    ...data.signatures,
                    post_signature: value
                  });
                }}
              />
              <Field
                Icon={MailOpen}
                label='Post With Original Wet Signature'
                value={data.signatures?.post_wet}
                editField='signatures.post_wet'
                dataType='checkbox'
                isEditable={true}
                onUpdate={(field, value) => {
                  onUpdate('signatures', {
                    ...data.signatures,
                    post_wet: value
                  });
                }}
              />
            </div>
          )}
        </div>
      )
    },
    {
      title: 'Contact Details',
      content: ({ data, onUpdate }) => (
        <div className='space-y-4'>
          <div>
            <h3 className='font-medium mb-2'>Email Addresses</h3>
            <div className='flex items-center gap-2 mb-2'>
              <Input
                placeholder='Enter email address'
                value={email}
                onChange={e => setEmail(e.target.value)}
              />
              <SoftButton
                onClick={() => {
                  const updatedEmails = [...data.emails, email];
                  onUpdate('emails', updatedEmails);
                  setEmail('');
                }}
              >
                <Plus />
              </SoftButton>
            </div>
            {data.emails?.map((email, index) => (
              <div key={index} className='flex items-center gap-2 group'>
                <div className='bg-gray-100 rounded w-full flex justify-between items-center mb-2 p-1'>
                  <span>{email}</span>
                  <div
                    className='opacity-0 group-hover:opacity-100 transition-opacity text-danger-500 cursor-pointer'
                    onClick={() => {
                      const updatedEmails = data.emails.filter(
                        (_, i) => i !== index
                      );
                      onUpdate('emails', updatedEmails);
                    }}
                  >
                    <X className='h-5 w-5 mr-1'></X>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div>
            <h3 className='font-medium mb-2'>Phone Numbers</h3>
            <div className='flex items-center gap-2 mb-2'>
              <Input
                placeholder='Enter phone number'
                value={phone}
                onChange={e => setPhone(e.target.value)}
              />
              <SoftButton
                onClick={() => {
                  const updatedPhones = [...data.phones, phone];
                  onUpdate('phones', updatedPhones);
                  setPhone('');
                }}
              >
                <Plus />
              </SoftButton>
            </div>
            {data.phones?.map((phone, index) => (
              <div key={index} className='flex items-center gap-2 group'>
                <div className='bg-gray-100 rounded w-full flex justify-between items-center mb-2 p-1'>
                  <span>{phone}</span>
                  <div
                    className='opacity-0 group-hover:opacity-100 transition-opacity text-danger-500 cursor-pointer'
                    onClick={() => {
                      const updatedPhones = data.phones.filter(
                        (_, i) => i !== index
                      );
                      onUpdate('phones', updatedPhones);
                    }}
                  >
                    <X className='h-5 w-5 mr-1'></X>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )
    },
    {
      title: 'Previous Names',
      content: ({ data, onUpdate }) => (
        <div>
          <div className='flex items-center gap-2 mb-2'>
            <Input
              placeholder='Enter previous name'
              value={previousName}
              onChange={e => setPreviousName(e.target.value)}
            />
            <SoftButton
              onClick={() => {
                const updatedNames = [...data.previousNames, previousName];
                onUpdate('previousNames', updatedNames);
                setPreviousName('');
              }}
            >
              <Plus />
            </SoftButton>
          </div>
          {data.previousNames?.map((name, index) => (
            <div key={index} className='flex items-center gap-2 group'>
              <div className='bg-gray-100 rounded w-full flex justify-between items-center mb-2 p-1'>
                <span>{name}</span>
                <div
                  className='opacity-0 group-hover:opacity-100 transition-opacity text-danger-500 cursor-pointer'
                  onClick={() => {
                    const updatedNames = data.previousNames.filter(
                      (_, i) => i !== index
                    );
                    onUpdate('previousNames', updatedNames);
                  }}
                >
                  <X className='h-5 w-5 mr-1'></X>
                </div>
              </div>
            </div>
          ))}
        </div>
      )
    }
  ];

  const additionalTabs = [
    {
      label: 'Addresses',
      path: 'addresses',
      content: <SchemeAddresses schemeId={id} />
    },
    {
      label: 'Providers',
      path: 'providers',
      content: <Providers schemeId={id} />
    },
    {
      label: 'Companies',
      path: 'companies',
      content: <SchemeCompanies schemeId={id} />
    },
    {
      label: 'Traces',
      path: 'traces',
      content: <SchemeTraces schemeId={id} />
    },
    {
      label: 'Searches',
      path: 'searches',
      content: <SchemeSearches schemeId={id} />
    },
    {
      label: 'Pensions',
      path: 'pensions',
      content: <SchemePensions schemeId={id} />
    },
    {
      label: 'Users',
      path: 'users',
      content: <SchemeUsers schemeId={id} />
    }
  ];

  return (
    <>
      <Entity
        currentUser={currentUser}
        entityType={EntityTypeEnum.Scheme}
        model={Scheme}
        infoItems={infoItems}
        accordionItems={accordionItems}
        additionalTabs={additionalTabs}
      />
      {showConfirmModal && (
        <Modal
          isOpen={showConfirmModal}
          onClose={() => setShowConfirmModal(false)}
          title='Confirm Action'
        >
          <p className='mb-2'>
            Disabling requests means third parties can't make any requests to
            this scheme.
          </p>
          <p>
            Are you absolutely certain you want to proceed with this change?
          </p>
          <div className='flex justify-end space-x-2 mt-4'>
            <SoftButton onClick={() => setShowConfirmModal(false)}>
              Cancel
            </SoftButton>
            <SoftButton onClick={confirmUpdate}>Confirm</SoftButton>
          </div>
        </Modal>
      )}
    </>
  );
};

export default SchemeDetails;
