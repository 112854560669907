import BaseModel from './BaseModel';

/**
 * Represents a Resource in the system.
 * @extends BaseModel
 */
class Resource extends BaseModel {
  /** @type {string} The name of the database table for Resources */
  static table = 'resources';

  /** @type {string} The SQL query to select Resource data */
  static selectQuery =
    '*, owner:user_profiles!inner (id, auth, first_name, last_name, email, profile_photo)';

  /**
   * Creates an instance of Resource.
   * @param {Object} data - The resource data.
   */
  constructor(data = {}) {
    super(data);
    this.image = data.image || null;
    this.name = data.name || null;
    this.description = data.description || null;
    this.content = data.content || null;
    this.tags = data.tags || [];
    this.files = data.files || [];
    this.createdDate = data.created_date || null;
    this.updatedDate = data.updated_date || null;
    this.owner = data.owner
      ? typeof data.owner == 'object'
        ? {
            id: data.owner.id,
            auth: data.owner?.auth,
            firstName: data.owner?.first_name,
            lastName: data.owner?.last_name,
            fullName: `${data.owner?.first_name} ${data.owner?.last_name}`,
            email: data.owner?.email,
            profilePhoto: data.owner?.profile_photo
          }
        : data.owner
      : null;
  }

  /**
   * Converts the Resource instance to a database-friendly format.
   * @returns {Object} The resource data ready for database operations.
   */
  toDatabase() {
    return {
      ...super.toDatabase(),
      image: this.image,
      name: this.name,
      description: this.description,
      content: this.content,
      tags: this.tags,
      files: this.files,
      created_date: this.createdDate,
      updated_date: this.updatedDate,
      owner: this.owner
    };
  }
}

export default Resource;
