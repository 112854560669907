import { supabase } from '../utilities/supabase';
import BaseModel from './BaseModel';
import UserProfile from './UserProfile';
import Company from './Company';
import Provider from './Provider';
import Scheme from './Scheme';

/**
 * Represents a Pension in the system.
 * @extends BaseModel
 */
class Pension extends BaseModel {
  /** @type {string} The name of the database table for Pensions */
  static table = 'pensions';
  /** @type {string} The SQL query to select Pension data */
  static selectQuery =
    '*, owner:user_profiles!inner(*), status:core_entity_types!inner(*), company:companies(*), provider:providers(*), scheme:schemes(*)';

  /**
   * Creates an instance of Pension.
   * @param {Object} data - The pension data.
   */
  constructor(data = {}) {
    super(data);
    this.displayId = data.display_id || null;
    this.name = data.name || null;
    this.status = data.status || null;
    this.owner = data.owner ? new UserProfile(data.owner) : null;
    this.company = data.company ? new Company(data.company) : null;
    this.provider = data.provider ? new Provider(data.provider) : null;
    this.scheme = data.scheme ? new Scheme(data.scheme) : null;
    this.metadata = data.metadata || {};
    this.createdDate = data.created_date || null;
    this.updatedDate = data.updated_date || null;
  }

  /**
   * Converts the Pension instance to a database-friendly format.
   * @returns {Object} The pension data ready for database operations.
   */
  toDatabase() {
    return {
      ...super.toDatabase(),
      name: this.name,
      status: this.status ? this.status.id : this.status,
      owner: typeof this.owner === 'object' ? this.owner?.id : this.owner,
      company: this.company ? this.company.id : this.company,
      provider: this.provider ? this.provider.id : this.provider,
      scheme: this.scheme ? this.scheme.id : this.scheme,
      metadata: this.metadata,
      created_date: this.createdDate,
      updated_date: this.updatedDate
    };
  }

  /**
   * Get all records using a specific function with filtering, sorting, and pagination.
   * @param {Object} filters - The filters to apply.
   * @param {number} page - The page number.
   * @param {number} size - The page size.
   * @param {string} sortBy - The field to sort by.
   * @param {string} sortType - The sort type ('asc' or 'desc').
   * @returns {Promise<Object>} A promise that resolves to an object containing the data and pagination info.
   */
  static async getAllByFunction(
    filters = {},
    page = 1,
    size = 10,
    sortBy = 'id',
    sortType = 'asc'
  ) {
    try {
      const {
        p_company_id,
        p_provider_id,
        p_scheme_id,
        p_entity_type,
        ...remainingFilters
      } = filters;

      let query = supabase.rpc(
        'get_searches_by_entity',
        {
          p_company_id,
          p_provider_id,
          p_scheme_id,
          p_entity_type
        },
        { count: 'exact' }
      );

      query = this.filters(query, remainingFilters);
      query = this.sorting(query, sortBy, sortType);
      query = this.range(query, page, size);

      const { data, error, count } = await query;

      if (error) throw error;

      return {
        data: data.map(item => new this(item)),
        count: count,
        page: page,
        size: size,
        total: Math.ceil(count / size)
      };
    } catch (error) {
      console.error(`[Error] Fetching All by Function ${this.name}: `, error);
      throw error;
    }
  }

  /**
   * Get a record by its ID.
   * @param {string} id - The UUID of the record to fetch.
   * @returns {Promise<Pension>} A promise that resolves to a new instance of the model.
   */
  static async getById(id) {
    return this.fetchOne('display_id', id, this.selectQuery);
  }

  /**
   * Override the getAll method from BaseModel to handle inner join filtering.
   * @param {Object} filters - The filters to apply.
   * @param {number} page - The page number.
   * @param {number} size - The page size.
   * @param {string} sortBy - The field to sort by.
   * @param {string} sortType - The sort type ('asc' or 'desc').
   * @returns {Promise<Object>} A promise that resolves to an object containing the data and pagination info.
   */
  static async getAll(
    filters = {},
    page = 1,
    size = 10,
    sortBy = 'id',
    sortType = 'asc'
  ) {
    const innerJoinFields = ['company.name', 'provider.name', 'scheme.name'];

    const hasInnerJoinFilter = innerJoinFields.some(field => {
      const [table, column] = field.split('.');
      return filters[field] || (filters[table] && filters[table][column]);
    });

    if (hasInnerJoinFilter) {
      Object.keys(filters).forEach(key => {
        if (key.includes('.')) {
          const table = key.split('.')[0];
          filters[table] = {
            operator: 'not',
            innerOperator: 'is',
            value: null
          };
        }
      });
    }

    return super.getAll(filters, page, size, sortBy, sortType);
  }
}

export default Pension;
