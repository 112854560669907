import {
  Input,
  TextEditor,
  IconPicker,
  renderIcon,
  ColourPicker
} from '@core/components';
import { useState, useEffect } from 'react';

const Info = ({
  name,
  description,
  icon,
  colour,
  handleNameChange,
  handleDescriptionChange,
  handleIconChange,
  handleColourChange,
  setReady,
  showErrors
}) => {
  const [errors, setErrors] = useState({ name: '* Required' });

  const validateName = name => {
    const newErrors = {};
    if (!name) {
      newErrors.name = '* Required';
    }
    setErrors(newErrors);
    const isValid = Object.keys(newErrors).length === 0;
    setReady(isValid);
    return isValid;
  };

  const handleNameInputChange = e => {
    handleNameChange(e);
    validateName(e.target.value);
  };

  useEffect(() => {
    validateName(name);
  }, [name]);

  const colourMap = {
    'zinc-500': 'bg-zinc-500',
    'red-500': 'bg-red-500',
    'orange-500': 'bg-orange-500',
    'amber-500': 'bg-amber-500',
    'yellow-500': 'bg-yellow-500',
    'lime-500': 'bg-lime-500',
    'green-500': 'bg-green-500',
    'emerald-500': 'bg-emerald-500',
    'teal-500': 'bg-teal-500',
    'cyan-500': 'bg-cyan-500',
    'sky-500': 'bg-sky-500',
    'blue-500': 'bg-blue-500',
    'indigo-500': 'bg-indigo-500',
    'violet-500': 'bg-violet-500',
    'purple-500': 'bg-purple-500',
    'fuchsia-500': 'bg-fuchsia-500',
    'pink-500': 'bg-pink-500',
    'rose-500': 'bg-rose-500'
  };

  return (
    <div className='overflow-y-auto pr-4'>
      <div className='flex items-center justify-between mb-5'>
        <div className='flex items-center'>
          <div
            className={`w-12 h-12 flex items-center justify-center rounded-lg transition-colors duration-300 ${colourMap[colour]} text-white`}
          >
            {renderIcon(icon)}
          </div>
          <div>
            <span className='text-lg font-semibold ml-3'>{name}</span>
            <p
              className='text-sm ml-3 text-gray-600'
              dangerouslySetInnerHTML={{ __html: description }}
            ></p>
          </div>
        </div>
      </div>
      <div className='mb-4'>
        <div className='flex items-center gap-4'>
          <div className='flex-1'>
            <Input
              label='Trigger Name'
              type='text'
              value={name}
              onChange={handleNameInputChange}
              placeholder='Trigger Name'
              className={showErrors && errors.name ? 'border-red-500' : ''}
            />
            {showErrors && errors.name && (
              <div className='text-red-500 text-sm mt-1'>{errors.name}</div>
            )}
          </div>
          <div>
            <label className='block text-sm font-medium leading-6 text-gray-900 mb-1'>
              Select Icon
            </label>
            <IconPicker selectedIcon={icon} onChange={handleIconChange} />
          </div>
          <div>
            <label className='block text-sm font-medium leading-6 text-gray-900 mb-1'>
              Choose Color
            </label>
            <ColourPicker
              colour={colour}
              onChange={handleColourChange}
              showShades={false}
            />
          </div>
        </div>
      </div>
      <div className='mb-4'>
        <label className='block text-sm font-medium leading-6 text-gray-900'>
          Description
        </label>
        <TextEditor
          value={description}
          onChange={handleDescriptionChange}
          placeholder='Enter a description for the automation...'
        />
      </div>
    </div>
  );
};

export default Info;
