import { useState, useEffect } from 'react';
import {
  User as UserIcon,
  Mail,
  Briefcase,
  Calendar,
  CreditCard,
  Tags,
  Plus,
  X,
  MessageCircle,
  AtSign,
  Users,
  Signature,
  Phone
} from 'lucide-react';
import Entity from '../../../components/entity/Entity';
import UserProfile from '../../../models/UserProfile';
import CoreEntityType from '../../../models/CoreEntityType';
import Field from '../../../components/entity/Field';
import { EntityTypeEnum } from '../../../utilities/Enumerables';
import DateCard from '../../../components/entity/DateCard';
import { Input, SoftButton } from '@core/components';
import UserAddresses from './tabs/Addresses';
import UserPensions from './tabs/Pensions';
import UserTraces from './tabs/Traces';
import UserSearches from './tabs/Searches';
import { supabase } from '../../../utilities/supabase';

const UserDetails = ({ currentUser }) => {
  const [statusOptions, setStatusOptions] = useState([]);
  const [titleOptions, setTitleOptions] = useState([]);
  const [segmentationOptions, setSegmentationOptions] = useState([]);
  const [previousName, setPreviousName] = useState('');
  const [users, setUsers] = useState([]);
  const [entityExtraTagOptions, setEntityExtraTagOptions] = useState([]);

  const resolveAdvisors = async bookerIds => {
    if (!bookerIds?.length) return [];

    try {
      const users = await Promise.all(
        bookerIds.map(async id => {
          const user = await UserProfile.getByUniqueId(id);

          console.log(user);
          return {
            value: id, // Keep the original ID as the value
            label: `${user.firstName} ${user.lastName}`
          };
        })
      );
      return users;
    } catch (error) {
      console.error('Error resolving advisors:', error);
      return [];
    }
  };

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const adminUsers = await UserProfile.getAll({
          admin: { value: true }
        });
        setUsers(adminUsers.data);
      } catch (error) {
        console.error('Error fetching admin users:', error);
      }
    };

    fetchUsers();
  }, []);

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const [titleTypes, statusTypes, segmentationTypes, extraTags] =
          await Promise.all([
            CoreEntityType.getByEntityId(EntityTypeEnum.UserTitle),
            CoreEntityType.getByEntityId(EntityTypeEnum.UserStatus),
            CoreEntityType.getByEntityId(EntityTypeEnum.Segmentation),
            CoreEntityType.getByEntityId('1')
          ]);

        setTitleOptions(
          titleTypes.map(title => ({
            value: title.id,
            label: title.type
          }))
        );

        setStatusOptions(
          statusTypes.map(status => ({
            value: status.id,
            label: status.type
          }))
        );

        setSegmentationOptions(
          segmentationTypes.map(segmentation => ({
            value: segmentation.id,
            label: segmentation.type
          }))
        );

        setEntityExtraTagOptions(extraTags);
      } catch (error) {
        console.error('Error fetching options:', error);
      }
    };

    fetchOptions();
  }, []);

  const loadAdvisorOptions = async () => {
    try {
      const { data: profiles, error } = await supabase
        .from('user_profiles')
        .select('id, first_name, last_name, email')
        .eq('role', 14);

      if (error) throw error;

      return profiles.map(profile => ({
        value: profile.id,
        label: `${profile.first_name} ${profile.last_name} (${profile.email})`
      }));
    } catch (error) {
      console.error('Error loading booker options:', error);
      return [];
    }
  };

  const infoItems = [
    {
      icon: Briefcase,
      label: 'Title',
      displayField: 'title.type',
      editField: 'title.id',
      dataType: 'select',
      isEditable: true,
      options: titleOptions
    },
    {
      icon: UserIcon,
      label: 'First Name',
      field: 'firstName',
      isEditable: true
    },
    {
      icon: UserIcon,
      label: 'Last Name',
      field: 'lastName',
      isEditable: true
    },
    {
      icon: Mail,
      label: 'Email',
      field: 'email',
      isEditable: true
    },
    {
      icon: Phone,
      label: 'Phone Number',
      field: 'phoneNumber',
      isEditable: true
    },
    {
      icon: CreditCard,
      label: 'NI',
      field: 'nationalInsurance',
      isEditable: true
    },
    {
      icon: Calendar,
      label: 'Date of Birth',
      field: 'birthDate',
      isEditable: true,
      dataType: 'date'
    },
    // {
    //   icon: Shield,
    //   label: 'Status',
    //   displayField: 'tags',
    //   editField: 'tags',
    //   dataType: 'status',
    //   isEditable: false,
    //   options: statusOptions
    // },
    {
      icon: Signature,
      label: 'Signature',
      field: 'signature',
      isEditable: false,
      onlyExtension: true,
      extension: value => (
        <div className='flex items-center ml-2'>
          {value?.file_path ? (
            <img
              src={`https://storage.googleapis.com/pensiontracingservice/${value.file_path}/${value?.id}/${value?.file_name}`}
              className='h-12 object-contain'
            />
          ) : (
            <span className='text-sm'>N/A</span>
          )}
        </div>
      )
    },
    {
      icon: UserIcon,
      label: 'Owner',
      displayField: 'owner',
      field: 'owner',
      editField: 'owner',
      isEditable: true,
      dataType: 'user-select',
      options: users,
      inlineLabel: true,
      inlineEditor: false
    },
    // {
    //   icon: Users,
    //   label: 'Followers',
    //   displayField: 'followers',
    //   editField: 'followers',
    //   isEditable: false,
    //   dataType: 'followers',
    //   inlineLabel: true,
    //   inlineEditor: false
    // },
    {
      icon: Users,
      label: 'Advisors',
      field: 'advisors',
      isEditable: true,
      dataType: 'multiselect',
      loadOptions: loadAdvisorOptions,
      valueResolver: resolveAdvisors
    }
  ];

  const accordionItems = [
    {
      title: 'Key Dates',
      content: ({ data, onUpdate }) => (
        <div className='grid grid-cols-2 md:grid-cols-3 gap-2'>
          <DateCard date={data.createdDate} label='Created' />
          <DateCard date={data.updatedDate} label='Updated' />
        </div>
      )
    },
    {
      title: 'Previous Names',
      content: ({ data, onUpdate }) => (
        <div>
          <div className='flex items-center gap-2 mb-2'>
            <Input
              placeholder='Enter previous name'
              value={previousName}
              onChange={e => setPreviousName(e.target.value)}
            />
            <SoftButton
              onClick={() => {
                const updatedNames = [...data.previousNames, previousName];
                onUpdate('previousNames', updatedNames);
                setPreviousName('');
              }}
            >
              <Plus />
            </SoftButton>
          </div>
          {data.previousNames.map((name, index) => (
            <div key={index} className='flex items-center gap-2 group'>
              <div className='bg-gray-100 rounded w-full flex justify-between items-center mb-2 p-1'>
                <span>{name}</span>
                <div
                  className='opacity-0 group-hover:opacity-100 transition-opacity text-danger-500 cursor-pointer'
                  onClick={() => {
                    const updatedNames = data.previousNames.filter(
                      (_, i) => i !== index
                    );
                    onUpdate('previousNames', updatedNames);
                  }}
                >
                  <X className='h-5 w-5 mr-1'></X>
                </div>
              </div>
            </div>
          ))}
        </div>
      )
    },
    {
      title: 'Marketing',
      content: ({ data, onUpdate }) => (
        <div className='space-y-4'>
          <div>
            <Field
              Icon={Tags}
              label='Reason Applied'
              editField='segmentation.id'
              dataType='select'
              isEditable={true}
              value={data.segmentation.type}
              options={segmentationOptions}
              onUpdate={onUpdate}
            />
            <Field
              Icon={Mail}
              label='Post'
              value={data.marketing?.post}
              editField='marketing.post'
              dataType='checkbox'
              isEditable={true}
              onUpdate={(field, value) => {
                onUpdate('marketing', {
                  ...data.marketing,
                  post: value
                });
              }}
            />
            <Field
              Icon={MessageCircle}
              label='Text'
              value={data.marketing?.text}
              editField='marketing.text'
              dataType='checkbox'
              isEditable={true}
              onUpdate={(field, value) => {
                onUpdate('marketing', {
                  ...data.marketing,
                  text: value
                });
              }}
            />
            <Field
              Icon={AtSign}
              label='Email'
              value={data.marketing?.email}
              editField='marketing.email'
              dataType='checkbox'
              isEditable={true}
              onUpdate={(field, value) => {
                onUpdate('marketing', {
                  ...data.marketing,
                  email: value
                });
              }}
            />
          </div>
        </div>
      )
    }
  ];

  const additionalTabs = [
    {
      label: 'Addresses',
      path: 'addresses',
      content: ({ data }) => <UserAddresses userId={data.id} />
    },
    {
      label: 'Traces',
      path: 'traces',
      content: ({ data }) => <UserTraces user={data} />
    },
    {
      label: 'Searches',
      path: 'searches',
      content: ({ data }) => <UserSearches userId={data.id} />
    },
    {
      label: 'Pensions',
      path: 'pensions',
      content: ({ data }) => <UserPensions user={data} />
    }
  ];

  const progressItems = {
    title: 'User Progress',
    steps: statusOptions.map(status => {
      return {
        label: status.label
      };
    }),
    getValue: entity => {
      const result = statusOptions.map(status => {
        const matches = entity?.tags?.some(tag => {
          return tag === status.value;
        });

        return matches;
      });

      return result;
    },
    getExtraValue: (step, entity) => {
      if (step.label !== 'Lead') return null;

      const matchingTag = entityExtraTagOptions.find(extraTag =>
        entity?.tags?.includes(extraTag?.id)
      );

      return matchingTag?.type;
    },
    getColour: (step, entity) => {
      if (step.label === 'Lead') {
        const matchingTag = entityExtraTagOptions.find(extraTag =>
          entity?.tags?.includes(extraTag?.id)
        );

        if (
          matchingTag?.type === 'Portal Tour' ||
          matchingTag?.type === 'Portal'
        ) {
          return 'brand';
        }
        return 'danger';
      }
      return 'brand';
    }
  };

  return (
    <Entity
      currentUser={currentUser}
      entityType={EntityTypeEnum.User}
      model={UserProfile}
      progressItems={progressItems}
      progressType={'other'}
      infoItems={infoItems}
      accordionItems={accordionItems}
      additionalTabs={additionalTabs}
    />
  );
};

export default UserDetails;
