import { EntityTypeEnum } from './Enumerables';

// Permission mapping
export const PERMISSIONS = {
  [EntityTypeEnum.Pipelines]: {
    VIEW: 1,
    CREATE: 2,
    EDIT: 4,
    DELETE: 8
  },
  [EntityTypeEnum.Task]: {
    VIEW: 1,
    CREATE: 2,
    EDIT: 4,
    DELETE: 8
  },
  [EntityTypeEnum.User]: {
    VIEW: 1,
    CREATE: 2,
    EDIT: 4,
    DELETE: 8
  },
  [EntityTypeEnum.Trace]: {
    VIEW: 1,
    CREATE: 2,
    EDIT: 4,
    DELETE: 8
  },
  [EntityTypeEnum.Search]: {
    VIEW: 1,
    CREATE: 2,
    EDIT: 4,
    DELETE: 8
  },
  [EntityTypeEnum.Pension]: {
    VIEW: 1,
    CREATE: 2,
    EDIT: 4,
    DELETE: 8
  },
  [EntityTypeEnum.Company]: {
    VIEW: 1,
    CREATE: 2,
    EDIT: 4,
    DELETE: 8
  },
  [EntityTypeEnum.Provider]: {
    VIEW: 1,
    CREATE: 2,
    EDIT: 4,
    DELETE: 8
  },
  [EntityTypeEnum.Scheme]: {
    VIEW: 1,
    CREATE: 2,
    EDIT: 4,
    DELETE: 8
  },
  [EntityTypeEnum.Document]: {
    VIEW: 1,
    CREATE: 2,
    EDIT: 4,
    DELETE: 8
  },
  [EntityTypeEnum.Automations]: {
    VIEW: 1,
    CREATE: 2,
    EDIT: 4,
    DELETE: 8
  },
  [EntityTypeEnum.Guides]: {
    CREATE: 1,
    EDIT: 2,
    DELETE: 4
  },
  [EntityTypeEnum.GuideCategories]: {
    CREATE: 1,
    EDIT: 2,
    DELETE: 4
  },
  [EntityTypeEnum.Settings]: {
    VIEW_GLOBALS: 1,
    EDIT_GLOBALS: 2,
    VIEW_TEAMS: 4,
    CREATE_TEAMS: 8,
    EDIT_TEAMS: 16,
    DELETE_TEAMS: 32,
    VIEW_ROLES: 64,
    CREATE_ROLES: 128,
    EDIT_ROLES: 256,
    DELETE_ROLES: 512,
    VIEW_TAGS: 1024,
    CREATE_TAGS: 2048,
    EDIT_TAGS: 4096,
    DELETE_TAGS: 8192,
    VIEW_API: 16384,
    CREATE_API_KEYS: 32768,
    DELETE_API_KEYS: 65536
  }
};
