import { supabase } from '../utilities/supabase';
import BaseModel from './BaseModel';

/**
 * Represents a Provider in the system.
 * @extends BaseModel
 */
class Provider extends BaseModel {
  /** @type {string} The name of the database table for Providers */
  static table = 'providers';
  /** @type {string} The SQL query to select Provider data */
  static selectQuery = '*, status:core_entity_types!inner(*)';

  /**
   * Creates an instance of Provider.
   * @param {Object} data - The provider data.
   */
  constructor(data = {}) {
    super(data);
    this.name = data.name || null;
    this.previousNames = data.previous_names || [];
    this.emails = data.emails || [];
    this.phones = data.phones || [];
    this.portals = data.portals || [];
    this.signatures = data.signatures || {
      wet: true,
      digital: true,
      docusign: false
    };
    this.status = data.status || null;
    this.createdDate = data.created_date || null;
    this.updatedDate = data.updated_date || null;
    this.traceCount = data.trace_count || 0;
  }

  /**
   * Converts the Provider instance to a database-friendly format.
   * @returns {Object} The provider data ready for database operations.
   */
  toDatabase() {
    return {
      ...super.toDatabase(),
      name: this.name,
      previous_names: this.previousNames,
      emails: this.emails,
      phones: this.phones,
      portals: this.portals,
      signatures: this.signatures,
      status: typeof this.status === 'object' ? this.status.id : this.status,
      created_date: this.createdDate,
      updated_date: this.updatedDate
    };
  }

  /**
   * Get all providers with trace count using a specific function.
   * @param {Object} filters - The filters to apply.
   * @param {number} page - The page number.
   * @param {number} size - The page size.
   * @param {string} sortBy - The field to sort by.
   * @param {string} sortType - The sort type ('asc' or 'desc').
   * @returns {Promise<Object>} A promise that resolves to an object containing the data and pagination info.
   */
  static async getAllByFunction(
    filters = {},
    page = 1,
    size = 10,
    sortBy = 'id',
    sortType = 'asc'
  ) {
    try {
      let query = supabase.rpc(
        'get_provider_with_trace_count',
        {},
        { count: 'exact' }
      );

      query = this.filters(query, filters);
      query = this.sorting(query, sortBy, sortType);
      query = this.range(query, page, size);

      const { data, error, count } = await query;

      if (error) throw error;

      return {
        data: data.map(item => new this(item)),
        count: count,
        page: page,
        size: size,
        total: Math.ceil(count / size)
      };
    } catch (error) {
      console.error(
        `[Error] Fetching All Providers with Trace Count ${this.name}: `,
        error
      );
      throw error;
    }
  }
}

export default Provider;
