import React, { useState, useEffect } from 'react';
import { Calendar } from '@core/components';
import CreateMeeting from '../../popups/meetings/CreateMeeting';
import EditMeeting from '../../popups/meetings/EditMeeting';

const MeetingOverview = ({ meetings, entityType, entityId }) => {
  const [isCreateMeetingOpen, setIsCreateMeetingOpen] = useState(false);
  const [isEditMeetingOpen, setIsEditMeetingOpen] = useState(false);
  const [selectedMeeting, setSelectedMeeting] = useState(null);

  const handleEditEvent = meeting => {
    setSelectedMeeting(meeting);
    setIsEditMeetingOpen(true);
  };

  const handleAddEvent = date => {
    setIsCreateMeetingOpen(true);
  };

  const handleViewChange = view => {
    const userPreferences = JSON.parse(
      localStorage.getItem('user_preferences') || '{}'
    );
    localStorage.setItem(
      'user_preferences',
      JSON.stringify({
        ...userPreferences,
        calendarView: view
      })
    );
  };

  const defaultView =
    JSON.parse(localStorage.getItem('user_preferences') || '{}').calendarView ||
    'calendar';

  return (
    <div>
      <Calendar
        events={meetings.map(meeting => ({
          id: meeting.id,
          title: meeting.title,
          start: meeting.startDate,
          end: meeting.endDate,
          data: meeting
        }))}
        currentMonth={new Date()}
        onAddEvent={handleAddEvent}
        onEditEvent={handleEditEvent}
        defaultView={defaultView}
        onChange={handleViewChange}
      />
      {isCreateMeetingOpen && (
        <CreateMeeting
          isOpen={isCreateMeetingOpen}
          onClose={() => setIsCreateMeetingOpen(false)}
          entityType={entityType}
          entityId={entityId}
        />
      )}
      {isEditMeetingOpen && (
        <EditMeeting
          isOpen={isEditMeetingOpen}
          onClose={() => setIsEditMeetingOpen(false)}
          meeting={selectedMeeting.data}
        />
      )}
    </div>
  );
};

export default MeetingOverview;
