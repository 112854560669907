import {
  User as UserIcon,
  Briefcase,
  Shield,
  CheckCircle,
  Calendar,
  Banknote,
  Hash
} from 'lucide-react';
import Entity from '../../../components/entity/Entity';
import Trace from '../../../models/Trace';
import CoreEntityType from '../../../models/CoreEntityType';
import { EntityTypeEnum } from '../../../utilities/Enumerables';
import DateCard from '../../../components/entity/DateCard';
import { useEffect, useState } from 'react';
import TraceSearches from './tabs/Searches';
import TraceCompanies from './tabs/Companies';
import { SoftButton } from '@core/components'; // Assuming SoftButton is imported from this path
import AssistedTrace from '../../../components/popups/assistedtrace/AssistedTrace';
import PensionTrace from '../../../components/popups/assistedtrace/PensionTrace';

const TraceDetails = ({ currentUser }) => {
  const [statusOptions, setStatusOptions] = useState([]);
  const [typeOptions, setTypeOptions] = useState([]); // New state for type options
  const [isAssistedTraceOpen, setIsAssistedTraceOpen] = useState(false);
  const [isPensionTraceOpen, setIsPensionTraceOpen] = useState(false);
  const [selectedTrace, setSelectedTrace] = useState(null);

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const statusTypes = await CoreEntityType.getByEntityId(
          EntityTypeEnum.TraceStatus
        );

        setStatusOptions(
          statusTypes.map(status => ({
            value: status.id,
            label: status.description || status.type
          }))
        );

        const traceTypes = await CoreEntityType.getByEntityId(
          EntityTypeEnum.TraceType
        );

        setTypeOptions(
          traceTypes.map(type => ({
            value: type.id,
            label: type.description || type.type
          }))
        );
      } catch (error) {
        console.error('Error fetching options:', error);
      }
    };

    fetchOptions();
  }, []);

  const handleAssistClick = row => {
    setSelectedTrace(row);
    if (row.type.id === 134) {
      setIsPensionTraceOpen(true);
    } else {
      setIsAssistedTraceOpen(true);
    }
  };

  const infoItems = [
    {
      icon: Briefcase,
      label: 'Name',
      field: 'name',
      isEditable: true
    },
    {
      icon: UserIcon,
      label: 'Owner',
      displayField: 'owner',
      field: 'owner',
      dataType: 'owner',
      isEditable: false
    },
    {
      icon: CheckCircle,
      label: 'Status',
      displayField: 'status.type',
      editField: 'status.id',
      dataType: 'select',
      isEditable: true,
      options: statusOptions,
      valueResolver: (value, row) => (
        <div className='flex items-center gap-2'>
          <span>{value}</span>
          {value === 'New' && (
            <SoftButton
              onClick={e => {
                e.stopPropagation();
                handleAssistClick(row);
              }}
            >
              Assist
            </SoftButton>
          )}
        </div>
      )
    },
    {
      icon: Shield,
      label: 'Type',
      displayField: 'type.type',
      editField: 'type.id',
      dataType: 'select',
      isEditable: true,
      options: typeOptions
    },
    {
      icon: Hash,
      label: 'Policy Number',
      displayField: 'metadata.policy',
      editField: 'policy',
      isEditable: true,
      editObject: 'metadata'
    },
    {
      icon: Calendar,
      label: 'Start Year',
      displayField: 'metadata.start_year',
      editField: 'start_year',
      isEditable: true,
      editObject: 'metadata'
    },
    {
      icon: Calendar,
      label: 'End Year',
      displayField: 'metadata.end_year',
      editField: 'end_year',
      isEditable: true,
      editObject: 'metadata'
    },
    {
      icon: Banknote,
      label: 'Paying',
      displayField: 'metadata.paying',
      editField: 'paying',
      dataType: 'checkbox',
      isEditable: true,
      editObject: 'metadata'
    }
  ];

  const accordionItems = [
    {
      title: 'Key Dates',
      content: ({ data }) => (
        <div className='grid grid-cols-2 md:grid-cols-3 gap-2'>
          <DateCard date={data.createdDate} label='Created' />
          <DateCard date={data.updatedDate} label='Updated' />
        </div>
      )
    }
  ];

  const additionalTabs = [
    {
      label: 'Companies',
      path: 'companies',
      content: ({ data }) => <TraceCompanies trace={data} />
    },
    {
      label: 'Searches',
      path: 'searches',
      content: ({ data }) => <TraceSearches traceId={data.id} trace={data} />
    }
  ];

  const progressItems = {
    title: 'Trace Progress',
    steps: statusOptions.map(status => ({
      label: status.label
    })),
    getValue: entity => {
      const currentStatusIndex = statusOptions.findIndex(
        status => status.value === entity?.status.id
      );
      return currentStatusIndex + 1;
    }
  };

  return (
    <>
      <Entity
        currentUser={currentUser}
        entityType={EntityTypeEnum.Trace}
        progressItems={progressItems}
        progressType={'continuous'}
        model={Trace}
        infoItems={infoItems}
        accordionItems={accordionItems}
        additionalTabs={additionalTabs}
      />
      <AssistedTrace
        isOpen={isAssistedTraceOpen}
        trace={selectedTrace}
        user={currentUser}
        onClose={() => setIsAssistedTraceOpen(false)}
      />
      <PensionTrace
        isOpen={isPensionTraceOpen}
        trace={selectedTrace}
        onClose={() => setIsPensionTraceOpen(false)}
      />
    </>
  );
};

export default TraceDetails;
