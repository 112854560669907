import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { SquareArrowOutUpRight } from 'lucide-react';
import EntityTable from '../../../../components/tables/EntityTable';
import {
  EntityTypeEnum,
  FilterOperatorEnum
} from '../../../../utilities/Enumerables';
import { TextButton } from '@core/components';
import Search from '../../../../models/Search';
import CoreEntityType from '../../../../models/CoreEntityType';
import { formatDate } from '../../../../utilities/Formatting';

const ProviderSearches = ({ providerId }) => {
  const navigate = useNavigate();
  const [searchStatus, setSearchStatus] = useState([]);

  useEffect(() => {
    const fetchSearchStatus = async () => {
      try {
        const types = await CoreEntityType.getByEntityId(
          EntityTypeEnum.SearchStatus
        );

        const formattedTypes = types.map(type => ({
          value: type.type,
          key: type.id
        }));

        setSearchStatus(formattedTypes);
      } catch (error) {
        console.error('Error fetching search types:', error);
      }
    };

    fetchSearchStatus();
  }, []);

  const menuItems = [
    {
      label: 'Open in new tab',
      icon: <SquareArrowOutUpRight size={14} />,
      onClick: data => window.open(`/searches/${data.displayId}`, '_blank')
    }
  ];

  return (
    <EntityTable
      name='Searches'
      customId={`provider:${providerId}:searches`}
      filter={[
        {
          field: 'display_id',
          label: 'ID',
          type: 'text',
          operator: FilterOperatorEnum.EQUALS
        },
        {
          field: 'status',
          label: 'Status',
          type: 'select',
          options: searchStatus
        },
        {
          field: 'trace.name',
          label: 'Trace',
          type: 'text',
          operator: FilterOperatorEnum.ILIKE
        },
        {
          field: 'company.name',
          label: 'Company',
          type: 'text',
          operator: FilterOperatorEnum.ILIKE
        },
        {
          field: 'scheme.name',
          label: 'Scheme',
          type: 'text',
          operator: FilterOperatorEnum.ILIKE
        },
        {
          field: 'created_date',
          label: 'Created Date',
          type: 'daterange',
          operator: FilterOperatorEnum.BETWEEN
        },
        {
          field: 'updated_date',
          label: 'Updated Date',
          type: 'daterange',
          operator: FilterOperatorEnum.BETWEEN
        },
        {
          field: 'showArchived',
          label: 'Show Archived',
          hint: 'Show archived searches',
          type: 'conditional_toggle',
          operator: FilterOperatorEnum.IS,
          options: {
            value: 'true'
          }
        }
      ]}
      columns={[
        {
          label: 'ID',
          field: 'displayId'
        },
        {
          label: 'Status',
          field: 'status.type'
        },
        {
          label: 'Trace',
          field: 'trace',
          cell: value =>
            value ? (
              <Link
                to={`/traces/${value.displayId}`}
                target='_blank'
                onClick={e => e.stopPropagation()}
                className='hover:underline'
              >
                {value.name}
              </Link>
            ) : (
              'N/A'
            )
        },
        {
          label: 'Company',
          field: 'company',
          cell: value =>
            value ? (
              <Link
                to={`/companies/${value.id}`}
                target='_blank'
                onClick={e => e.stopPropagation()}
                className='hover:underline'
              >
                {value.name}
              </Link>
            ) : (
              'N/A'
            )
        },
        {
          label: 'Scheme',
          field: 'scheme',
          cell: value =>
            value ? (
              <Link
                to={`/schemes/${value.id}`}
                target='_blank'
                onClick={e => e.stopPropagation()}
                className='hover:underline'
              >
                {value.name}
              </Link>
            ) : (
              'N/A'
            )
        },
        {
          label: 'Created Date',
          field: 'createdDate',
          cell: value => formatDate(value)
        },
        {
          label: 'Updated Date',
          field: 'updatedDate',
          cell: value => formatDate(value)
        }
      ]}
      menuItems={menuItems}
      model={Search}
      entityType={EntityTypeEnum.Search}
      defaultFilters={{
        provider: {
          value: providerId
        },
        status: { value: '151', operator: FilterOperatorEnum.NOT_EQUALS }
      }}
      sortDefault={{ field: 'display_id', order: 'asc' }}
      onRowClick={search => {
        navigate(`/searches/${search.displayId}`);
      }}
    />
  );
};

export default ProviderSearches;
