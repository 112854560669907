import BaseModel from './BaseModel';

/**
 * Represents a CoreEntity in the system.
 * @extends BaseModel
 */
class CoreEntity extends BaseModel {
  /** @type {string} The name of the database table for CoreEntities */
  static table = 'core_entities';

  /** @type {string} The SQL query to select CoreEntity data */
  static selectQuery = '*';

  /**
   * Creates an instance of CoreEntity.
   * @param {Object} data - The @core/components entity data.
   */
  constructor(data = {}) {
    super(data);
    this.displayName = data.name || null;
    this.tableName = data.table || null;
    this.metadata = data.metadata || {};
  }

  /**
   * Converts the CoreEntity instance to a database-friendly format.
   * @returns {Object} The @core/components entity data ready for database operations.
   */
  toDatabase() {
    return {
      ...super.toDatabase(),
      name: this.displayName,
      table: this.tableName
    };
  }

  /**
   * Fetch a CoreEntity by its table name.
   * @param {string} tableName - The table name to fetch.
   * @returns {Promise<CoreEntity>} A promise that resolves to a new instance of CoreEntity.
   */
  static async getByTableName(tableName) {
    return this.fetchOne('table', tableName);
  }
}

export default CoreEntity;
