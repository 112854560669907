import React, { useState, useContext } from 'react';
import {
  getRelativeTimeString,
  formatDateOptions
} from '../../utilities/Formatting';
import {
  TextSkeleton,
  SoftButton,
  TextEditor,
  FilledButton,
  FlatButton,
  Tooltip,
  Popup
} from '@core/components';
import EmailPreview from '../popups/email/EmailPreview';
import { isEqual } from 'lodash';
import {
  Pin,
  PinOff,
  Edit2,
  History,
  ChevronDown,
  ChevronUp,
  Trash2,
  Eye
} from 'lucide-react';
import { UserProfileContext } from '../../App';

const FeedItem = ({ item, isLoading, logTypes, onPin, onUnpin }) => {
  const [showPopup, setShowPopup] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [isPinHovered, setIsPinHovered] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editedContent, setEditedContent] = useState('');
  const [showHistory, setShowHistory] = useState(false);
  const { userProfile } = useContext(UserProfileContext);

  if (isLoading) {
    return (
      <>
        <p className='flex-auto py-0.5 text-xs leading-5 text-neutral-500'>
          <TextSkeleton className='h-4 w-1/2' />
        </p>
        <TextSkeleton className='flex-none py-0.5 h-4 w-20' />
      </>
    );
  }

  const handleSaveContent = async () => {
    try {
      // Create history entry for the old content
      const historyEntry = {
        created_date: item.createdDate,
        content: item.content
      };

      // Get existing content history or initialize empty array
      const contentHistory = item.contentHistory || [];

      // Add new history entry
      contentHistory.push(historyEntry);

      // Update the activity log with new content and history
      item.content = editedContent;
      item.contentHistory = contentHistory;
      await item.update();

      setIsEditing(false);
    } catch (err) {
      console.error('Error saving content:', err);
    }
  };

  const handleDeleteNote = async () => {
    try {
      item.archived = true;
      await item.update();
    } catch (err) {
      console.error('Error deleting note:', err);
    }
  };

  /* NOTES */
  if (item.auditType?.id === 42) {
    return (
      <div
        className='rounded-md border border-neutral-100 p-2 w-full'
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <div className='flex items-center mb-2'>
          <span className='font-medium text-xs text-neutral-800'>
            {item.owner.firstName} {item.owner.lastName}{' '}
            <span className='text-xs font-normal text-neutral-500'>
              {item.archived
                ? 'deleted this note'
                : `added a note${
                    !item.archived && item.contentHistory?.length > 0
                      ? ' (edited)'
                      : ''
                  }`}
              .
            </span>
          </span>

          <div className='ml-auto flex items-center'>
            {isEditing && (
              <div className='flex gap-2 mr-2'>
                <FilledButton
                  onClick={() => setIsEditing(false)}
                  size='sm'
                  colour='base'
                >
                  Cancel
                </FilledButton>
                <FilledButton
                  onClick={handleSaveContent}
                  size='sm'
                  colour='primary'
                >
                  Save
                </FilledButton>
              </div>
            )}
            {item.pinned && !item.archived ? (
              <SoftButton
                onClick={() => onUnpin(item.id)}
                className='mr-2'
                size='sm'
                colour='primary'
                onMouseEnter={() => setIsPinHovered(true)}
                onMouseLeave={() => setIsPinHovered(false)}
              >
                {isPinHovered ? <PinOff size={16} /> : <Pin size={16} />}
              </SoftButton>
            ) : (
              isHovered &&
              !isEditing &&
              !item.archived && (
                <SoftButton
                  onClick={() => onPin(item.id)}
                  className='mr-2'
                  size='sm'
                  colour='primary'
                >
                  <Pin size={16} />
                </SoftButton>
              )
            )}
            {userProfile?.id === item.owner.id &&
              isHovered &&
              !isEditing &&
              !item.archived && (
                <>
                  <SoftButton
                    onClick={() => {
                      setEditedContent(item.content);
                      setIsEditing(true);
                    }}
                    className='mr-2'
                    size='sm'
                    colour='primary'
                  >
                    <Edit2 size={16} />
                  </SoftButton>
                  <SoftButton
                    onClick={handleDeleteNote}
                    className='mr-2'
                    size='sm'
                    colour='danger'
                  >
                    <Trash2 size={16} />
                  </SoftButton>
                </>
              )}
            <Tooltip
              content={
                <div className='text-xs'>
                  <p>
                    Created{' '}
                    <span className='font-medium'>
                      {formatDateOptions(item.createdDate, { dateTime: true })}
                    </span>
                  </p>
                  {!item.archived && item.contentHistory?.length > 0 && (
                    <p>
                      Last edited{' '}
                      <span className='font-medium'>
                        {formatDateOptions(item.updatedDate, {
                          dateTime: true
                        })}
                      </span>
                    </p>
                  )}
                </div>
              }
              size='sm'
              position='top'
            >
              <time
                dateTime={item.createdDate}
                className='text-xs text-neutral-500 mr-1 text-end py-1'
              >
                {getRelativeTimeString(item.createdDate)}
                {!item.archived && item.contentHistory?.length > 0 && (
                  <span className='ml-1'>
                    • edited {getRelativeTimeString(item.updatedDate)}
                  </span>
                )}
              </time>
            </Tooltip>
          </div>
        </div>
        {isEditing ? (
          <TextEditor
            value={editedContent}
            onChange={setEditedContent}
            className='w-full'
            type='standard'
          />
        ) : (
          <>
            <div
              className={`text-sm text-base-600 ${
                item.archived ? 'italics tracking-tight' : ''
              }`}
              dangerouslySetInnerHTML={{
                __html: item.archived ? '' : item.content
              }}
            />
            {!item.archived && item.contentHistory?.length > 0 && (
              <div className='mt-2'>
                <FlatButton
                  onClick={() => setShowHistory(!showHistory)}
                  size='xs'
                  colour='base'
                  className='flex items-center gap-1'
                >
                  <History size={14} />
                  {showHistory ? 'Hide' : 'Show'} edit history
                  {showHistory ? (
                    <ChevronUp size={14} />
                  ) : (
                    <ChevronDown size={14} />
                  )}
                </FlatButton>

                {showHistory && (
                  <div className='mt-2 space-y-2'>
                    {item.contentHistory.map((history, index) => (
                      <div
                        key={index}
                        className='pl-2 border-l-2 border-neutral-200'
                      >
                        <div className='text-xs text-neutral-500 mb-1'>
                          {getRelativeTimeString(history.created_date)}
                        </div>
                        <div
                          className='text-sm text-neutral-600'
                          dangerouslySetInnerHTML={{ __html: history.content }}
                        />
                      </div>
                    ))}
                  </div>
                )}
              </div>
            )}
          </>
        )}
      </div>
    );
  }

  /* EMAIL SENT */
  if (item.auditType?.id === 43) {
    const [showEmailPreview, setShowEmailPreview] = useState(false);
    let emailContent;
    try {
      emailContent = JSON.parse(item.content);
    } catch (error) {
      emailContent = { subject: 'No Subject', html: item.content };
    }

    return (
      <div
        className='rounded-md border border-neutral-100 p-2 w-full'
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <div className='flex items-center mb-2'>
          <span className='font-medium text-xs text-neutral-800'>
            {item.owner.firstName} {item.owner.lastName}{' '}
            <span className='text-xs font-normal text-neutral-500'>
              sent an email.
            </span>
          </span>
          <div className='ml-auto flex items-center'>
            <Tooltip
              content={formatDateOptions(item.createdDate, { dateTime: true })}
              position='top'
              size='sm'
            >
              <time
                dateTime={item.createdDate}
                className='text-xs text-neutral-500 text-end py-1'
              >
                {getRelativeTimeString(item.createdDate)}
              </time>
            </Tooltip>
          </div>
        </div>

        <div className='text-sm text-neutral-600 mb-2'>
          Subject: {emailContent.subject}
        </div>

        <FlatButton
          onClick={() => setShowEmailPreview(true)}
          size='xs'
          colour='base'
          className='flex items-center gap-1'
        >
          <Eye size={14} />
          View Email
        </FlatButton>

        <Popup
          isOpen={showEmailPreview}
          onClose={() => setShowEmailPreview(false)}
          title='Email Preview'
          size='lg'
        >
          <EmailPreview html={emailContent.html} />
        </Popup>
      </div>
    );
  }

  const renderChangePopup = () => {
    if (item.auditType?.id == 41 && showPopup) {
      return (
        <div className='absolute z-10 bg-white border border-neutral-200 rounded-md p-3 shadow-lg mt-5 left-1.5 w-72 break-words'>
          <h4 className='text-sm font-medium mb-2'>Changes:</h4>

          {Object.keys(item.newData || {}).length === 0 &&
          Object.keys(item.oldData || {}).length === 0 ? (
            <p className='text-xs mb-1'>{item.content}</p>
          ) : (
            Object.keys(item.newData || {}).map((key, index) => {
              const oldValue = item.oldData?.[key];
              const newValue = item.newData[key];
              if (
                key !== 'fts' &&
                key !== 'updated_date' &&
                key !== 'crm_preferences' &&
                !isEqual(oldValue, newValue)
              ) {
                return (
                  <p key={index} className='text-xs mb-1'>
                    <strong className='text-neutral-700'>{key}:</strong>{' '}
                    <span className='text-red-500'>
                      "{JSON.stringify(oldValue)}"
                    </span>{' '}
                    →{' '}
                    <span className='text-green-500'>
                      "{JSON.stringify(newValue)}"
                    </span>
                  </p>
                );
              }
              return null;
            })
          )}
        </div>
      );
    }
    return null;
  };

  return (
    <div
      className='relative flex-auto py-0.5 flex justify-between items-start'
      onMouseEnter={() => {
        setShowPopup(true);
        setIsHovered(true);
      }}
      onMouseLeave={() => {
        setShowPopup(false);
        setIsHovered(false);
      }}
    >
      <p className='text-xs leading-5 text-neutral-500 ml-2'>
        <span className='font-medium text-neutral-800'>
          {item.owner.firstName} {item.owner.lastName}
        </span>{' '}
        {logTypes[item.auditType?.id]?.title || 'Updated'} the{' '}
        {item.entityType?.displayName}.
      </p>
      <div className='flex items-center'>
        {item.pinned ? (
          <SoftButton
            onClick={() => onUnpin(item.id)}
            className='mr-2'
            size='sm'
            colour='primary'
            onMouseEnter={() => setIsPinHovered(true)}
            onMouseLeave={() => setIsPinHovered(false)}
          >
            {isPinHovered ? <PinOff size={16} /> : <Pin size={16} />}
          </SoftButton>
        ) : (
          isHovered && (
            <SoftButton
              onClick={() => onPin(item.id)}
              className='mr-2'
              size='sm'
              colour='primary'
            >
              <Pin size={16} />
            </SoftButton>
          )
        )}
        <Tooltip
          content={
            <div className='text-xs'>
              <p>
                <span className='font-medium'>
                  {formatDateOptions(item.createdDate, { dateTime: true })}
                </span>
              </p>
            </div>
          }
          size='sm'
          position='top'
        >
          <time
            dateTime={item.createdDate}
            className='text-xs leading-5 text-neutral-500 mr-3 py-1'
          >
            {getRelativeTimeString(item.createdDate)}
          </time>
        </Tooltip>
      </div>
      {renderChangePopup()}
    </div>
  );
};

export default FeedItem;
