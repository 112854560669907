import { supabase } from '../utilities/supabase';
import BaseModel from './BaseModel';

/**
 * Represents a Company in the system.
 * @extends BaseModel
 */
class Company extends BaseModel {
  /** @type {string} The name of the database table for Companies */
  static table = 'companies';

  /** @type {string} The SQL query to select Company data */
  static selectQuery = '*, status:core_entity_types!inner(*)';

  /**
   * Creates an instance of Company.
   * @param {Object} data - The company data.
   */
  constructor(data = {}) {
    super(data);
    this.name = data.name || null;
    this.status = data.status || null;
    this.emails = data.emails || [];
    this.phones = data.phones || [];
    this.number = data.number || null;
    this.createdDate = data.created_date || null;
    this.updatedDate = data.updated_date || null;
    this.previousNames = data.previous_names || [];
    this.companies = data.companies || [];
    this.loadCompanies();
  }

  /**
   * Loads the companies data from the database
   */
  async loadCompanies() {
    try {
      const { data, error } = await supabase
        .from(Company.table)
        .select('id, name')
        .in('id', this.companies);

      if (error) throw error;

      this.companies = data || [];
    } catch (error) {
      console.error('Error loading companies:', error);
    }
  }

  /**
   * Converts the Company instance to a database-friendly format.
   * @returns {Object} The company data ready for database operations.
   */
  toDatabase() {
    return {
      ...super.toDatabase(),
      name: this.name,
      status: typeof this.status === 'object' ? this.status.id : this.status,
      emails: this.emails,
      phones: this.phones,
      number: this.number || '',
      created_date: this.createdDate,
      updated_date: this.updatedDate,
      previous_names: this.previousNames,
      companies: this.companies.map(company =>
        typeof company === 'object' ? company.id : company
      )
    };
  }
}

export default Company;
