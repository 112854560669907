import { useState } from 'react';
import { Check, X } from 'lucide-react';

export const ProgressStages = ({
  stages,
  currentStage,
  onStageClick,
  disableNavigation
}) => {
  return (
    <div className='flex items-center w-full justify-center'>
      {stages.map((stage, index) => (
        <div key={index} className='flex items-center w-full'>
          {index > 0 ? (
            <div
              className={` min-w-10 flex flex-1 w-full h-1 rounded-r-full mr-4 ${
                index + 1 <= currentStage ? 'bg-primary-800' : 'bg-base-300'
              }`}
            />
          ) : (
            <div className='min-w-10 flex flex-1 w-full mr-4' />
          )}
          <div
            className='flex flex-col items-center'
            onClick={() => !disableNavigation && onStageClick?.(index + 1)}
            style={{ cursor: disableNavigation ? 'default' : 'pointer' }}
          >
            <div className='relative'>
              <div
                className={`flex items-center justify-center w-10 h-10 rounded-md border-2 ${
                  index + 1 <= currentStage
                    ? 'border-primary-800 bg-primary-800 text-white'
                    : 'border-primary-300 bg-primary-300 text-white'
                } ${!disableNavigation && 'hover:opacity-80'}`}
              >
                {typeof stage === 'object' ? stage.icon : index + 1}
              </div>
              {stage.status && (
                <div
                  className={`absolute -top-1 -right-1 w-4 h-4 rounded-full flex items-center justify-center ${
                    stage.status === 'complete'
                      ? 'bg-success-500'
                      : 'bg-danger-500'
                  }`}
                >
                  {stage.status === 'complete' ? (
                    <Check size={12} className='text-white' />
                  ) : (
                    <X size={12} className='text-white' />
                  )}
                </div>
              )}
            </div>
            {typeof stage === 'object' && stage.label && (
              <span
                className={`text-sm mt-3 ${
                  index + 1 <= currentStage
                    ? 'text-base-900 font-medium'
                    : 'text-base-500 font-base'
                }`}
              >
                {stage.label}
              </span>
            )}
          </div>

          {index < stages.length - 1 ? (
            <div
              className={` min-w-10 flex flex-1 w-full h-1 rounded-l-full ml-4 ${
                index + 1 < currentStage ? 'bg-primary-800' : 'bg-base-300'
              }`}
            />
          ) : (
            <div className='min-w-10 flex flex-1 w-full ml-4' />
          )}
        </div>
      ))}
    </div>
  );
};
