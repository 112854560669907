import React, { useEffect, useState, useContext } from 'react';
import { UserProfileContext } from '../../../App';
import TaskItem from '../../../components/entity/tasks/TaskItem';
import { Link, useNavigate } from 'react-router-dom';
import { supabase } from '../../../utilities/supabase';
import TaskModel from '../../../models/Task';
import EntityTable from '../../../components/tables/EntityTable';
import UserProfile from '../../../models/UserProfile';
import { SquareArrowOutUpRight } from 'lucide-react';
import {
  EntityTypeEnum,
  FilterOperatorEnum
} from '../../../utilities/Enumerables';
import CoreEntityType from '../../../models/CoreEntityType';
import { formatDate } from '../../../utilities/Formatting';
import { Badge } from '@core/components';
import { DropdownInput } from '../../../components/inputs/Forms';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
} from 'chart.js';
0;
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

const Home = () => {
  document.title = 'Dashboard | CRM | PTS';
  const navigate = useNavigate();
  const { userProfile: currentUser } = useContext(UserProfileContext);
  const [tasks, setTasks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [tagLoading, setTagLoading] = useState(true);
  const [titleOptions, setTitleOptions] = useState([]);
  const [segmentationOptions, setSegmentationOptions] = useState([]);
  const [tagStats, setTagStats] = useState(Array(13).fill(0));
  const [selectedPeriod, setSelectedPeriod] = useState('7');
  const [monthlyStats, setMonthlyStats] = useState([]);

  const tagNames = [
    'Information',
    'Address',
    'Segmentation',
    'Processing',
    'Failed',
    'First Trace',
    'Traces',
    'Known',
    'Pensions',
    'First Pension',
    'Pensions',
    'Authorisation',
    'Portal Tour',
    'Portal'
  ];

  useEffect(() => {
    const fetchMonthlyStats = async () => {
      try {
        const currentDate = new Date();
        const monthlyData = await Promise.all(
          Array.from({ length: 12 }, async (_, i) => {
            const startDate = new Date(
              currentDate.getFullYear(),
              currentDate.getMonth() - i,
              1
            );
            const endDate = new Date(
              currentDate.getFullYear(),
              currentDate.getMonth() - i + 1,
              0
            );

            const tagCounts = await Promise.all(
              Array.from({ length: 13 }, async (_, index) => {
                const { count } = await supabase
                  .from('user_profiles')
                  .select('*', { count: 'exact', head: true })
                  .contains('tags', [index + 1])
                  .gte('created_date', startDate.toISOString())
                  .lte('created_date', endDate.toISOString());
                return count || 0;
              })
            );

            return {
              month: startDate.toLocaleString('default', { month: 'short' }),
              tagCounts
            };
          })
        );
        setMonthlyStats(monthlyData.reverse());
      } catch (error) {
        console.error('Error fetching monthly stats:', error);
      }
    };

    fetchMonthlyStats();
  }, []);

  useEffect(() => {
    const fetchTasks = async () => {
      try {
        const filters = {
          assignees: {
            operator: FilterOperatorEnum.CONTAINS,
            value: `{${currentUser.id}}`
          },
          status: { operator: FilterOperatorEnum.NOT_EQUALS, value: 59 }
        };

        const { data } = await TaskModel.getAll(
          filters,
          1,
          1000,
          'order',
          'asc'
        );
        setTasks(data);
      } catch (error) {
        console.error('Error fetching tasks:', error);
      } finally {
        setLoading(false);
      }
    };

    let subscription;
    if (currentUser?.id) {
      fetchTasks();

      subscription = supabase
        .channel('tasks-changes')
        .on(
          'postgres_changes',
          {
            event: '*',
            schema: 'public',
            table: 'tasks'
          },
          fetchTasks
        )
        .subscribe();
    }

    return () => {
      subscription?.unsubscribe();
    };
  }, [currentUser]);

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const titles = await CoreEntityType.getByEntityId(EntityTypeEnum.Title);
        const segmentations = await CoreEntityType.getByEntityId(
          EntityTypeEnum.Segmentation
        );

        setTitleOptions(
          titles.map(title => ({
            value: title.type,
            key: title.id
          }))
        );

        setSegmentationOptions(
          segmentations.map(segmentation => ({
            value: segmentation.type,
            key: segmentation.id
          }))
        );
      } catch (error) {
        console.error('Error fetching options:', error);
      }
    };

    fetchOptions();
  }, []);

  useEffect(() => {
    const fetchTagStats = async () => {
      setTagLoading(true);
      try {
        const daysAgo = new Date();
        daysAgo.setDate(daysAgo.getDate() - Number(selectedPeriod));

        const tagCounts = await Promise.all(
          Array.from({ length: 13 }, async (_, index) => {
            const { count } = await supabase
              .from('user_profiles')
              .select('*', { count: 'exact', head: true })
              .contains('tags', [index + 1])
              .gte('created_date', daysAgo.toISOString());
            return count || 0;
          })
        );
        setTagStats(tagCounts);
      } catch (error) {
        console.error('Error fetching tag stats:', error);
      } finally {
        setTagLoading(false);
      }
    };

    fetchTagStats();
  }, [selectedPeriod]);

  const handleTaskClick = task => navigate(`/tasks/${task.id}`);

  const menuItems = [
    {
      label: 'Open in new tab',
      icon: <SquareArrowOutUpRight size={14} />,
      onClick: data => window.open(`/user-profiles/${data.displayId}`, '_blank')
    }
  ];

  const getGreeting = () => {
    const hour = new Date().getHours();
    if (hour < 12) return '🌄 Good Morning';
    if (hour < 18) return '🏜️ Good Afternoon';
    return '🌃 Good Evening';
  };

  const chartData = {
    labels: monthlyStats.map(stat => stat.month),
    datasets: tagNames.map((name, index) => ({
      label: name,
      data: monthlyStats.map(stat => stat.tagCounts[index]),
      borderColor: `hsl(${(index * 360) / tagNames.length}, 70%, 50%)`,
      backgroundColor: `hsla(${
        (index * 360) / tagNames.length
      }, 70%, 50%, 0.2)`,
      fill: true
    }))
  };

  return (
    <div className='p-4'>
      <div className='max-w-7xl mx-auto px-4 sm:px-6 lg:px-8'>
        <h1 className='text-4xl font-bold text-neutral-800 mb-6 mt-6'>
          {getGreeting()}, {currentUser?.firstName || 'User'}
        </h1>
        <p className='text-base text-neutral-600 mb-10'>
          Welcome to your personalised dashboard. Here you can manage your tasks
          and view your assigned users.
        </p>

        <div className='mb-6'>
          <h3 className='text-2xl font-bold text-neutral-800 mb-2'>
            Lead Stages
          </h3>
          <div className='bg-white p-4 rounded-lg shadow-sm'>
            <div className='relative w-48'>
              <select
                value={selectedPeriod}
                onChange={e => setSelectedPeriod(e.target.value)}
                className='block w-full px-3 py-2 text-sm bg-white border border-neutral-100 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-brand-700 focus:border-brand-700'
              >
                <option value='7'>Last 7 Days</option>
                <option value='14'>Last 14 Days</option>
                <option value='30'>Last 30 Days</option>
              </select>
            </div>
          </div>
          {tagLoading ? (
            <div className='animate-pulse'>
              <div className='h-4 w-full bg-base-200 rounded mb-2'></div>
              <div className='h-4 w-full bg-base-200 rounded mb-2'></div>
              <div className='h-4 w-full bg-base-200 rounded'></div>
            </div>
          ) : (
            <div className='grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-7 gap-4 mt-4'>
              {tagStats.map((count, index) => (
                <div
                  key={index}
                  className='bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow duration-200'
                >
                  <div className='p-4'>
                    <h4 className='text-sm font-semibold text-neutral-800 mb-2 truncate'>
                      {tagNames[index]}
                    </h4>
                    <span className='text-2xl font-bold text-primary-600 block'>
                      {count}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
        <div className='mb-6'>
          <h3 className='text-2xl font-bold text-neutral-800 mb-2'>
            Monthly Basis Chart
          </h3>
          <Line data={chartData} />
        </div>
        <div className='mt-10'>
          <h2 className='text-2xl font-bold text-neutral-800 flex items-center gap-2 mb-1'>
            My Tasks
            {!loading && tasks.length > 0 && (
              <Badge colour='base' size='md'>
                {tasks.length}
              </Badge>
            )}
          </h2>
          <p className='text-sm text-neutral-600 mb-5'>
            View and manage your assigned tasks. Click on any task to see more
            details or mark them as complete.
          </p>
          {loading ? (
            <div className='animate-pulse'>
              <div className='flex items-center justify-between py-4 gap-x-6'>
                <div className='flex items-center gap-x-3'>
                  <div className='h-4 w-4 bg-base-200 rounded'></div>
                  <div className='min-w-0'>
                    <div className='flex items-center gap-x-2'>
                      <div className='h-4 w-48 bg-base-200 rounded'></div>
                      <div className='h-4 w-16 bg-base-200 rounded'></div>
                    </div>
                    <div className='mt-1 flex items-center gap-x-2'>
                      <div className='h-3 w-32 bg-base-200 rounded'></div>
                      <div className='h-3 w-24 bg-base-200 rounded'></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            tasks.map((task, index) => (
              <TaskItem
                key={task.id}
                task={task}
                index={index}
                onClick={handleTaskClick}
                disableChildren
              />
            ))
          )}
        </div>

        <div className='mt-10'>
          <h2 className='text-2xl font-bold text-neutral-800 mb-1'>My Users</h2>
          <p className='text-sm text-neutral-600 mb-5'>
            Access and manage your assigned users. Use the filters to find
            specific users and click on any row for detailed information.
          </p>
          <EntityTable
            name='Users'
            customId='users'
            filter={[
              {
                field: 'display_id',
                label: 'ID',
                type: 'text',
                operator: 'eq'
              },
              {
                field: 'title',
                label: 'Title',
                type: 'select',
                options: titleOptions
              },
              {
                field: 'first_name',
                label: 'First Name',
                type: 'text',
                operator: FilterOperatorEnum.ILIKE
              },
              {
                field: 'last_name',
                label: 'Last Name',
                type: 'text',
                operator: FilterOperatorEnum.ILIKE
              },
              {
                field: 'email',
                label: 'Email',
                type: 'text',
                operator: FilterOperatorEnum.ILIKE
              },
              {
                field: 'national_insurance',
                label: 'National Insurance',
                type: 'text',
                operator: FilterOperatorEnum.ILIKE
              },
              {
                field: 'segmentation',
                label: 'Segmentation',
                type: 'select',
                options: segmentationOptions
              },
              {
                field: 'marketing->post',
                label: 'Marketing Post',
                type: 'select',
                options: [
                  { value: 'Yes', key: 'true' },
                  { value: 'No', key: 'false' }
                ]
              },
              {
                field: 'marketing->text',
                label: 'Marketing Text',
                type: 'select',
                options: [
                  { value: 'Yes', key: 'true' },
                  { value: 'No', key: 'false' }
                ]
              },
              {
                field: 'marketing->email',
                label: 'Marketing Email',
                type: 'select',
                options: [
                  { value: 'Yes', key: 'true' },
                  { value: 'No', key: 'false' }
                ]
              },
              {
                field: 'birth_date',
                label: 'Birth Date',
                type: 'daterange',
                operator: FilterOperatorEnum.BETWEEN
              },
              {
                field: 'created_date',
                label: 'Created Date',
                type: 'daterange',
                operator: FilterOperatorEnum.BETWEEN
              },
              {
                field: 'updated_date',
                label: 'Updated Date',
                type: 'daterange',
                operator: FilterOperatorEnum.BETWEEN
              }
            ]}
            sortDefault={{ field: 'display_id', order: 'asc' }}
            columns={[
              {
                label: 'ID',
                field: 'displayId',
                cell: value => (
                  <Link
                    to={`/user-profiles/${value}`}
                    className='text-brand-700 hover:text-brand-800'
                  >
                    {value}
                  </Link>
                )
              },
              { label: 'Title', field: 'title.type' },
              { label: 'First Name', field: 'firstName' },
              { label: 'Last Name', field: 'lastName' },
              { label: 'Email', field: 'email' },
              {
                label: 'Previous Names',
                field: 'previousNames',
                cell: value => (value ? value.join(', ') : '')
              },
              { label: 'National Insurance', field: 'nationalInsurance' },
              { label: 'Segmentation', field: 'segmentation.type' },
              {
                label: 'Post',
                field: 'marketing',
                sortable: false,
                cell: value => (value.post ? 'Yes' : 'No')
              },
              {
                label: 'Text',
                field: 'marketing',
                sortable: false,
                cell: value => (value.text ? 'Yes' : 'No')
              },
              {
                label: 'Email',
                field: 'marketing',
                sortable: false,
                cell: value => (value.email ? 'Yes' : 'No')
              },
              {
                label: 'Birth Date',
                field: 'birthDate',
                cell: value => formatDate(value)
              },
              {
                label: 'Created Date',
                field: 'createdDate',
                cell: value => formatDate(value)
              },
              {
                label: 'Updated Date',
                field: 'updatedDate',
                cell: value => formatDate(value)
              }
            ]}
            defaultFilters={{
              status: { value: '97', operator: FilterOperatorEnum.NOT_EQUALS },
              owner: {
                value: currentUser?.id,
                operator: FilterOperatorEnum.EQUALS
              }
            }}
            menuItems={menuItems}
            model={UserProfile}
            entityType={EntityTypeEnum.User}
            onRowClick={data => navigate(`/user-profiles/${data.displayId}`)}
          />
        </div>
      </div>
    </div>
  );
};

export default Home;
