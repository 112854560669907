import React, { useState, useEffect, Fragment } from 'react';
import { Input, PageHeading } from '@core/components';
import { supabase } from '../../../../utilities/supabase';
import { EntityTypeEnum } from '../../../../utilities/Enumerables';

const GlobalSettings = () => {
  const [settings, setSettings] = useState([]);
  const [categories, setCategories] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('all');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      // Fetch categories
      const { data: categoryData, error: categoryError } = await supabase
        .from('core_entity_types')
        .select('*')
        .eq('entity_id', EntityTypeEnum.SettingCategories)
        .order('type');

      if (categoryError) throw categoryError;

      // Fetch settings
      const { data: settingsData, error: settingsError } = await supabase
        .from('settings')
        .select('*')
        .order('name');

      if (settingsError) throw settingsError;

      setCategories(categoryData || []);
      setSettings(settingsData || []);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleValueChange = async (id, value) => {
    try {
      const { error } = await supabase
        .from('settings')
        .update({ value })
        .eq('id', id);

      if (error) throw error;

      setSettings(
        settings.map(setting =>
          setting.id === id ? { ...setting, value } : setting
        )
      );
    } catch (error) {
      console.error('Error updating setting:', error);
    }
  };

  const filteredSettings = settings.filter(
    setting =>
      setting.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
      (selectedCategory === 'all' ||
        setting.category === parseInt(selectedCategory))
  );

  // Group settings by category
  const groupedSettings = categories
    .map(category => ({
      ...category,
      settings: filteredSettings.filter(
        setting => setting.category === category.id
      )
    }))
    .filter(group => group.settings.length > 0);

  return (
    <div className='container mx-auto flex flex-col h-full'>
      <PageHeading
        title='Global Settings'
        pages={[
          { name: 'Settings', href: '/settings' },
          { name: 'Global Settings', href: '/settings/globals' }
        ]}
      />

      <div className='py-6'>
        <div className='mb-6 max-w-md'>
          <Input
            placeholder='Search settings...'
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value)}
            rightAddon={
              <select
                value={selectedCategory}
                onChange={e => setSelectedCategory(e.target.value)}
                className='border-0 bg-transparent focus:ring-0 py-0'
              >
                <option value='all'>All Categories</option>
                {categories.map(category => (
                  <option key={category.id} value={category.id}>
                    {category.type}
                  </option>
                ))}
              </select>
            }
          />
        </div>

        <div className='mt-8 flow-root'>
          <div className='-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8'>
            <div className='inline-block min-w-full py-2 align-middle'>
              <table className='min-w-full divide-y divide-gray-200 bg-white shadow rounded-lg'>
                <thead className='bg-white'>
                  <tr>
                    <th className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                      Setting
                    </th>
                    <th className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                      Value
                    </th>
                  </tr>
                </thead>
                <tbody className='bg-white divide-y divide-gray-200'>
                  {groupedSettings.map(category => (
                    <Fragment key={category.id}>
                      <tr className='border-t border-gray-200'>
                        <th
                          colSpan={2}
                          className='bg-gray-50 px-6 py-2 text-left text-sm font-semibold text-gray-900'
                        >
                          {category.type}
                        </th>
                      </tr>
                      {category.settings.map(setting => (
                        <tr key={setting.id}>
                          <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-900'>
                            {setting.name}
                          </td>
                          <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-900'>
                            <Input
                              type='number'
                              value={setting.value}
                              onChange={e =>
                                handleValueChange(setting.id, e.target.value)
                              }
                              className='w-32'
                              min={0}
                              max={100}
                              step={0.01}
                              suffix='%'
                            />
                          </td>
                        </tr>
                      ))}
                    </Fragment>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GlobalSettings;
