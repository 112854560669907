import React, { useState, useEffect, useCallback } from 'react';
import Resource from '../../../../models/Resources';
import CreateResource from '../../../../components/popups/resources/Create';
import View from './View';
import {
  Badge,
  SoftButton,
  ComboBox,
  Input,
  DropdownInput,
  FilledButton,
  PageHeading,
  Pagination
} from '@core/components';
import { Image, Search } from 'lucide-react';
import { supabase } from '../../../../utilities/supabase';
import Tag from '../../../../models/Tag';
import {
  FilterOperatorEnum,
  EntityTypeEnum
} from '../../../../utilities/Enumerables';
import CoreEntityType from '../../../../models/CoreEntityType';
import debounce from 'lodash/debounce';

const Resources = () => {
  document.title = 'Resources | CRM | PTS';

  const [resources, setResources] = useState([]);
  const [showCreate, setShowCreate] = useState(false);
  const [showView, setShowView] = useState(false);
  const [taggedEntities, setTaggedEntities] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [selectedResource, setSelectedResource] = useState(null);
  const [viewResourceId, setViewResourceId] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [tagsLoaded, setTagsLoaded] = useState(false);
  const [pageSize, setPageSize] = useState(9);

  const fetchResources = async (
    page = currentPage,
    itemsPerPage = pageSize
  ) => {
    try {
      setIsLoading(true);
      setTagsLoaded(false);
      const filters = {};

      // Add full text search filter
      if (searchTerm) {
        filters.fts = {
          value: searchTerm,
          operator: FilterOperatorEnum.TEXTSEARCH
        };
      }

      // Add tag filters
      if (selectedTags.length > 0) {
        // First get all tagged entities for the selected tags
        const { data: taggedResourceIds } = await supabase
          .from('tagged_entities')
          .select('entity_id')
          .in(
            'tag_id',
            selectedTags.map(tag => tag.id)
          )
          .eq('entity_type', 63) // Filter for resources only
          .order('entity_id');

        // Get unique resource IDs that have all selected tags
        const resourceIdCounts = taggedResourceIds.reduce(
          (acc, { entity_id }) => {
            acc[entity_id] = (acc[entity_id] || 0) + 1;
            return acc;
          },
          {}
        );

        const resourceIdsWithAllTags = Object.entries(resourceIdCounts)
          .filter(([_, count]) => count === selectedTags.length)
          .map(([id]) => id);

        if (resourceIdsWithAllTags.length > 0) {
          filters.id = {
            value: resourceIdsWithAllTags,
            operator: FilterOperatorEnum.IN
          };
        } else {
          // No resources match all tags
          setResources([]);
          setTotalPages(0);
          setIsLoading(false);
          setTagsLoaded(true);
          return;
        }
      }

      // Get resources with filters applied
      const { data, count } = await Resource.getAll(
        filters,
        page,
        itemsPerPage
      );

      setResources(data || []);
      setTotalPages(Math.ceil(count / itemsPerPage));

      // Fetch tagged entities for resources
      const taggedEntitiesData = {};
      await Promise.all(
        (data || []).map(async resource => {
          const { data: tagData } = await supabase
            .from('tagged_entities')
            .select(
              '*, tag:tags!inner (name, metadata, category:core_entity_types(*))'
            )
            .eq('entity_id', resource.id)
            .eq('entity_type', 63); // Filter for resources only

          taggedEntitiesData[resource.id] = tagData;
        })
      );

      setTaggedEntities(taggedEntitiesData);
      setTagsLoaded(true);
    } catch (error) {
      console.error('Error fetching resources:', error);
    } finally {
      setIsLoading(false);
    }
  };

  // Debounced search
  const debouncedFetch = useCallback(
    debounce(() => {
      fetchResources(1);
      setCurrentPage(1);
    }, 300),
    [searchTerm, selectedTags]
  );

  useEffect(() => {
    debouncedFetch();
    return () => debouncedFetch.cancel();
  }, [searchTerm, selectedTags, debouncedFetch]);

  useEffect(() => {
    fetchResources();
  }, [currentPage, pageSize]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const { data } = await CoreEntityType.getAll({
          entity_id: {
            value: EntityTypeEnum.TagCategory,
            operator: FilterOperatorEnum.EQUALS
          }
        });
        setCategories(data || []);
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    fetchCategories();
  }, []);

  const handlePageChange = page => {
    setCurrentPage(page);
  };

  const handleItemsPerPageChange = newPageSize => {
    setPageSize(newPageSize);
    setCurrentPage(1); // Reset to first page
    fetchResources(1, newPageSize); // Fetch with new page size
  };

  const handleEdit = resource => {
    setSelectedResource(resource);
    setShowCreate(true);
  };

  const handleCreateSuccess = async () => {
    setShowCreate(false);
    setSelectedResource(null);
    await fetchResources(currentPage);
  };

  const loadTagOptions = async query => {
    try {
      const filters = {
        name: { value: query, operator: FilterOperatorEnum.ILIKE }
      };

      if (selectedCategory) {
        filters.category_id = {
          value: selectedCategory,
          operator: FilterOperatorEnum.EQ
        };
      }

      const { data } = await Tag.getAll(filters);

      return data.map(tag => ({
        value: tag.id,
        label: tag.name,
        secondaryLabel: (
          <Badge
            size='xs'
            colour={
              tag.metadata?.colour ||
              tag.category?.metadata?.colour ||
              'primary'
            }
          >
            {tag.category?.type}
          </Badge>
        ),
        category: tag.category,
        metadata: tag.metadata
      }));
    } catch (error) {
      console.error('Error loading tag options:', error);
      return [];
    }
  };

  const handleTagSelect = selectedOption => {
    if (
      selectedOption &&
      !selectedTags.some(t => t.id === selectedOption.value)
    ) {
      setSelectedTags(prev => [
        ...prev,
        {
          id: selectedOption.value,
          name: selectedOption.label,
          metadata: selectedOption.metadata,
          category: selectedOption.category
        }
      ]);
    }
  };

  const handleRemoveTag = tagId => {
    setSelectedTags(prev => prev.filter(tag => tag.id !== tagId));
  };

  return (
    <div className='h-full mx-auto overflow-y-auto bg-base-100'>
      <div className='p-4 h-full w-full bg-white'>
        <div className='container mx-auto flex flex-col h-full'>
          <PageHeading
            title='Resources'
            pages={[
              { name: 'Help', href: '/help' },
              { name: 'Resources', href: '/help/resources' }
            ]}
            actions={
              <FilledButton
                onClick={() => {
                  setSelectedResource(null);
                  setShowCreate(true);
                }}
              >
                Create Resource
              </FilledButton>
            }
          />

          <div className='flex flex-col gap-4 mt-6'>
            <div className='flex flex-col sm:flex-row items-start sm:items-center gap-4'>
              <Input
                type='text'
                placeholder='Search resources...'
                value={searchTerm}
                onChange={e => setSearchTerm(e.target.value)}
                leftIcon={Search}
                className='w-full'
              />

              <div className='w-full sm:w-1/4'>
                <DropdownInput
                  placeholder='Select category'
                  options={[
                    { value: '', label: 'All Categories' },
                    ...categories.map(cat => ({
                      value: cat.id,
                      label: cat.type
                    }))
                  ]}
                  onChange={e => setSelectedCategory(e.target.value)}
                  value={selectedCategory}
                />
              </div>

              <div className='w-full sm:w-1/3'>
                <ComboBox
                  placeholder='Filter by tags...'
                  loadOptions={loadTagOptions}
                  onChange={handleTagSelect}
                  value={null}
                />
              </div>
            </div>

            {selectedTags.length > 0 && (
              <div className='flex flex-wrap gap-2'>
                {selectedTags.map(tag => (
                  <Badge
                    key={tag.id}
                    colour={
                      tag.metadata?.colour ||
                      tag.category?.metadata?.colour ||
                      'gray'
                    }
                    onRemove={() => handleRemoveTag(tag.id)}
                  >
                    {tag.name}
                  </Badge>
                ))}
              </div>
            )}
          </div>

          {isLoading || !tagsLoaded ? (
            <div className='text-center mt-16'>
              <p className='text-gray-500'>Loading resources...</p>
            </div>
          ) : (
            <div className='mx-auto mt-16 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4'>
              {resources.map(resource => (
                <article
                  key={resource.id}
                  className='flex flex-col bg-white border border-gray-200 rounded-lg shadow-sm hover:shadow-md transition-shadow duration-200'
                >
                  <div className='p-4 flex items-center gap-3 border-b border-gray-100'>
                    <div className='w-10 h-10 flex-shrink-0 rounded-lg bg-gray-100 flex items-center justify-center'>
                      {resource.image ? (
                        <img
                          src={resource.image}
                          alt={resource.name}
                          className='w-6 h-6 object-contain'
                        />
                      ) : (
                        <Image className='w-5 h-5 text-gray-400' />
                      )}
                    </div>
                    <div className='min-w-0 flex-1'>
                      <h3 className='text-sm font-medium text-gray-900 truncate'>
                        <button
                          onClick={() => {
                            setViewResourceId(resource.id);
                            setShowView(true);
                          }}
                          className='hover:underline'
                        >
                          {resource.name}
                        </button>
                      </h3>
                      <p className='text-xs text-gray-500 truncate'>
                        {resource.owner?.fullName}
                      </p>
                    </div>
                  </div>

                  <div className='p-4 flex-1'>
                    <p className='text-sm text-gray-600 line-clamp-2'>
                      {resource.description ||
                        'There is no description for this resource...'}
                    </p>

                    {taggedEntities[resource.id] &&
                      taggedEntities[resource.id].length > 0 && (
                        <div className='mt-3 flex flex-wrap gap-1'>
                          {taggedEntities[resource.id].map((entity, index) => (
                            <Badge
                              key={index}
                              colour={
                                entity.tag.metadata?.colour ||
                                entity.tag.category?.metadata?.colour ||
                                'gray'
                              }
                              size='xs'
                            >
                              {entity.tag.name}
                            </Badge>
                          ))}
                        </div>
                      )}
                  </div>

                  <div className='px-4 py-3 bg-gray-50 border-t border-gray-100 rounded-b-lg flex items-center justify-between'>
                    <time className='text-xs text-gray-500'>
                      {new Date(resource.createdDate).toLocaleDateString(
                        'en-GB',
                        { dateStyle: 'medium' }
                      )}
                    </time>
                    <div className='flex gap-2'>
                      <SoftButton
                        onClick={() => handleEdit(resource)}
                        className='text-xs'
                      >
                        Edit
                      </SoftButton>
                    </div>
                  </div>
                </article>
              ))}
            </div>
          )}

          {totalPages > 1 && (
            <div className='mt-8'>
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
                itemsPerPage={pageSize}
                onItemsPerPageChange={handleItemsPerPageChange}
              />
            </div>
          )}

          <CreateResource
            isOpen={showCreate}
            onClose={() => {
              setShowCreate(false);
              setSelectedResource(null);
            }}
            onSuccess={handleCreateSuccess}
            resource={selectedResource}
          />

          <View
            isOpen={showView}
            onClose={() => {
              setShowView(false);
              setViewResourceId(null);
            }}
            resourceId={viewResourceId}
          />
        </div>
      </div>
    </div>
  );
};

export default Resources;
