import React, { useState, useEffect, useContext } from 'react';
import Automation from '../../../models/Automations';
import Create from './Create';
import EntityTable from '../../../components/tables/EntityTable';
import RunsPopup from '../../../components/popups/automations/Runs';
import { formatDate, formatProfile } from '../../../utilities/Formatting';
import {
  EntityTypeEnum,
  FilterOperatorEnum
} from '../../../utilities/Enumerables';
import { useNavigate } from 'react-router-dom';
import { renderIcon } from '@core/components/inputs/pickers/IconPicker';
import { UserProfileContext } from '../../../App';
import { PERMISSIONS } from '../../../utilities/Permissions';
import { Alert } from '@core/components';

const bgColours = {
  'zinc-500': 'bg-zinc-500',
  'red-500': 'bg-red-500',
  'orange-500': 'bg-orange-500',
  'amber-500': 'bg-amber-500',
  'yellow-500': 'bg-yellow-500',
  'lime-500': 'bg-lime-500',
  'green-500': 'bg-green-500',
  'emerald-500': 'bg-emerald-500',
  'teal-500': 'bg-teal-500',
  'cyan-500': 'bg-cyan-500',
  'sky-500': 'bg-sky-500',
  'blue-500': 'bg-blue-500',
  'indigo-500': 'bg-indigo-500',
  'violet-500': 'bg-violet-500',
  'purple-500': 'bg-purple-500',
  'fuchsia-500': 'bg-fuchsia-500',
  'pink-500': 'bg-pink-500',
  'rose-500': 'bg-rose-500'
};

const AutomationsPage = ({ create }) => {
  document.title = 'Automations | CRM | PTS';
  const navigate = useNavigate();
  const { userProfile: currentUser } = useContext(UserProfileContext);

  const [automations, setAutomations] = useState([]);
  const [isCreating, setIsCreating] = useState(create ?? false);
  const [editAuto, setEditAuto] = useState(null);
  const [selectedAutomationId, setSelectedAutomationId] = useState(null);

  if (
    !currentUser.hasPermission(
      EntityTypeEnum.Automations,
      PERMISSIONS[EntityTypeEnum.Automations].VIEW
    )
  ) {
    return (
      <Alert
        title='Permission Denied'
        description='You do not have permission to view automations.'
        style='danger'
      />
    );
  }

  useEffect(() => {
    fetchEntityAutomations();
  }, []);

  const fetchEntityAutomations = async () => {
    try {
      const fetchedAutomations = await Automation.getAll();
      setAutomations(fetchedAutomations.data);
    } catch (error) {
      console.error('Error fetching entity automations:', error);
    }
  };

  const handleEditAutomation = automation => {
    setEditAuto(automation);
    setIsCreating(true);
  };

  const handleViewRuns = automation => {
    setSelectedAutomationId(automation);
  };

  if (isCreating)
    return (
      <Create
        entityId={null}
        entityType={null}
        edit={editAuto}
        setEditAuto={setEditAuto}
        setIsCreating={setIsCreating}
        automations={automations}
        setAutomations={setAutomations}
        isCreating={isCreating}
      />
    );

  return (
    <div className='p-4'>
      <EntityTable
        name='Automations'
        customId='Automations'
        filter={[
          { field: 'id', label: 'ID', type: 'text' },
          { field: 'name', label: 'Name', type: 'text' },
          { field: 'description', label: 'Description', type: 'text' },
          { field: 'entity.name', label: 'Entity', type: 'text' },
          { field: 'trigger.type', label: 'Action', type: 'text' },
          {
            field: 'user_profiles.first_name',
            label: 'Owner',
            type: 'text',
            operator: FilterOperatorEnum.ILIKE
          },
          { field: 'created_date_from', label: 'Created From', type: 'date' },
          { field: 'created_date_to', label: 'Created To', type: 'date' },
          { field: 'updated_date_from', label: 'Updated From', type: 'date' },
          { field: 'updated_date_to', label: 'Updated To', type: 'date' }
        ]}
        sortDefault={{ field: 'id', order: 'asc' }}
        columns={[
          {
            label: 'ID',
            field: 'id'
          },
          {
            label: 'Name',
            field: 'name',
            cell: (value, data) => (
              <div className='flex items-center text-white'>
                <div
                  className={`w-10 h-10 min-w-[2.5rem] min-h-[2.5rem] flex items-center justify-center rounded mr-3 ${
                    bgColours[data.metadata.colour]
                  }`}
                >
                  {renderIcon(data.metadata.icon)}
                </div>
                <span>{value}</span>
              </div>
            )
          },
          {
            label: 'Description',
            field: 'description',
            cell: value => <div dangerouslySetInnerHTML={{ __html: value }} />
          },
          {
            label: 'Entity',
            field: 'entity.name',
            foreignKey: { table: 'core_entities', column: 'id' }
          },
          {
            label: 'Action',
            field: 'trigger.type',
            foreignKey: { table: 'core_entity_types', column: 'id' }
          },
          {
            label: 'Owner',
            field: 'owner.firstName',
            foreignKey: { table: 'user_profiles', column: 'id' },
            cell: (value, data) => formatProfile(data.owner)
          },
          {
            label: 'Created Date',
            field: 'createdDate',
            cell: value => formatDate(value)
          },
          {
            label: 'Updated Date',
            field: 'updatedDate',
            cell: value => formatDate(value)
          }
        ]}
        model={Automation}
        entityType={EntityTypeEnum.Automations}
        canCreate={true}
        onNewClick={() => {
          setIsCreating(true);
          navigate('/automations/create');
        }}
        onRowClick={data => handleEditAutomation(data)}
        menuItems={[
          {
            label: 'View Runs',
            onClick: data => {
              handleViewRuns(data);
              console.log(data);
            }
          }
        ]}
      />
      <RunsPopup
        isOpen={selectedAutomationId}
        automation={selectedAutomationId}
        onClose={() => setSelectedAutomationId(null)}
      />
    </div>
  );
};

export default AutomationsPage;
