import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { SquareArrowOutUpRight, Trash } from 'lucide-react';
import EntityTable from '../../../../components/tables/EntityTable';
import { Modal, TextButton } from '@core/components';
import Pension from '../../../../models/Pension';
import CoreEntityType from '../../../../models/CoreEntityType';
import {
  EntityTypeEnum,
  FilterOperatorEnum
} from '../../../../utilities/Enumerables';
import CreatePension from '../../../../components/popups/pensions/CreatePension';
import { formatDate } from '../../../../utilities/Formatting';
import { supabase } from '../../../../utilities/supabase';

const UserPensions = ({ user }) => {
  const navigate = useNavigate();
  const [pensionStatus, setPensionStatus] = useState([]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [pensionToDelete, setPensionToDelete] = useState(null);
  const [isCreatePensionOpen, setIsCreatePensionOpen] = useState(false);

  useEffect(() => {
    const fetchPensionStatus = async () => {
      try {
        const types = await CoreEntityType.getByEntityId(
          EntityTypeEnum.PensionStatus
        );

        const formattedTypes = types.map(type => ({
          value: type.type,
          key: type.id
        }));

        setPensionStatus(formattedTypes);
      } catch (error) {
        console.error('Error fetching pension types:', error);
      }
    };

    fetchPensionStatus();
  }, []);
  const handleDeletePension = async () => {
    try {
      await supabase
        .from('pensions')
        .update({ status: 151 })
        .eq('id', pensionToDelete.id);

      setIsDeleteModalOpen(false);
      setPensionToDelete(null);
    } catch (error) {
      console.error('Error archiving pension:', error);
    }
  };

  const menuItems = [
    {
      label: 'Open in new tab',
      icon: <SquareArrowOutUpRight size={14} />,
      onClick: data => window.open(`/pensions/${data.displayId}`, '_blank')
    },
    {
      label: 'Delete',
      icon: <Trash size={14} className='text-danger-700' />,
      onClick: data => {
        setPensionToDelete(data);
        setIsDeleteModalOpen(true);
      }
    }
  ];

  return (
    <>
      <EntityTable
        name='Pensions'
        customId={`user:${user.id}:pensions`}
        filter={[
          {
            field: 'display_id',
            label: 'ID',
            type: 'text',
            operator: FilterOperatorEnum.EQUALS
          },
          {
            field: 'name',
            label: 'Name',
            type: 'text',
            operator: FilterOperatorEnum.ILIKE
          },
          {
            field: 'status',
            label: 'Status',
            type: 'select',
            options: pensionStatus
          },
          {
            field: 'company.name',
            label: 'Company',
            type: 'text',
            operator: FilterOperatorEnum.ILIKE
          },
          {
            field: 'provider.name',
            label: 'Provider',
            type: 'text',
            operator: FilterOperatorEnum.ILIKE
          },
          {
            field: 'scheme.name',
            label: 'Scheme',
            type: 'text',
            operator: FilterOperatorEnum.ILIKE
          },
          {
            field: 'created_date',
            label: 'Created Date',
            type: 'daterange',
            operator: FilterOperatorEnum.BETWEEN
          },
          {
            field: 'updated_date',
            label: 'Updated Date',
            type: 'daterange',
            operator: FilterOperatorEnum.BETWEEN
          },
          {
            field: 'showArchived',
            label: 'Show Archived',
            hint: 'Show archived pensions',
            type: 'conditional_toggle',
            operator: FilterOperatorEnum.IS,
            options: {
              value: 'true'
            }
          }
        ]}
        columns={[
          { label: 'ID', field: 'displayId' },
          { label: 'Name', field: 'name' },
          {
            label: 'Status',
            field: 'status.type'
          },
          {
            label: 'Company',
            field: 'company',
            cell: value =>
              value ? (
                <Link
                  to={`/companies/${value.id}`}
                  target='_blank'
                  onClick={e => e.stopPropagation()}
                  className='hover:underline'
                >
                  {value.name}
                </Link>
              ) : (
                'N/A'
              )
          },
          {
            label: 'Provider',
            field: 'provider',
            cell: value =>
              value ? (
                <Link
                  to={`/providers/${value.id}`}
                  target='_blank'
                  onClick={e => e.stopPropagation()}
                  className='hover:underline'
                >
                  {value.name}
                </Link>
              ) : (
                'N/A'
              )
          },
          {
            label: 'Scheme',
            field: 'scheme',
            cell: value =>
              value ? (
                <Link
                  to={`/schemes/${value.id}`}
                  target='_blank'
                  onClick={e => e.stopPropagation()}
                  className='hover:underline'
                >
                  {value.name}
                </Link>
              ) : (
                'N/A'
              )
          },
          {
            label: 'Created Date',
            field: 'createdDate',
            cell: value => formatDate(value)
          },
          {
            label: 'Updated Date',
            field: 'updatedDate',
            cell: value => formatDate(value)
          }
        ]}
        menuItems={menuItems}
        model={Pension}
        entityType={EntityTypeEnum.Pension}
        defaultFilters={{
          owner: { value: user.id, operator: FilterOperatorEnum.EQUALS },
          status: { value: '151', operator: FilterOperatorEnum.NOT_EQUALS }
        }}
        sortDefault={{ field: 'display_id', order: 'asc' }}
        onRowClick={pension => {
          navigate(`/pensions/${pension.displayId}`);
        }}
        canCreate={true}
        onNewClick={() => setIsCreatePensionOpen(true)}
      />
      <Modal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        title='Delete Pension'
        style='danger'
        actions={[
          {
            label: 'Cancel',
            onClick: () => setIsDeleteModalOpen(false),
            style: 'base'
          },
          {
            label: 'Delete',
            onClick: handleDeletePension,
            style: 'danger'
          }
        ]}
      >
        <p className='text-sm text-base-600'>
          Are you sure you want to delete this pension? This action cannot be
          undone.
        </p>
      </Modal>
      <CreatePension
        isOpen={isCreatePensionOpen}
        onClose={() => setIsCreatePensionOpen(false)}
        onPensionCreated={() => setIsCreatePensionOpen(false)}
        user={user}
      />
    </>
  );
};

export default UserPensions;
