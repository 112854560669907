import { SoftButton, Badge, Input, DropdownInput } from '@core/components';
import { Plus, X, LogOut, RotateCw, ChevronRight } from 'lucide-react';
import { Disclosure } from '@headlessui/react';
import { useState, useEffect, useCallback } from 'react';
import { ComboBox } from '@core/components';
import { supabase } from '../../../../utilities/supabase';

const Requests = ({
  colour,
  icon,
  renderIcon,
  sendRequests,
  handleRequestChange,
  handleHeaderChange,
  removeHeader,
  addHeader,
  sendResponses,
  handleTestConnection,
  removeRequest,
  addRequest,
  exitVariables,
  exitRequests,
  exitResponses,
  entity,
  setEntityId,
  entityId
}) => {
  const [entityOptions, setEntityOptions] = useState([]);
  const [showEntitySelector, setShowEntitySelector] = useState(false);
  const [selectedRequestType, setSelectedRequestType] = useState(null);
  const [selectedRequestIndex, setSelectedRequestIndex] = useState(null);
  const [selectedEntity, setSelectedEntity] = useState(null);

  const fetchEntities = useCallback(
    async (query, page) => {
      try {
        const { data: entityTypeData, error: entityTypeError } = await supabase
          .from('core_entities')
          .select('table')
          .eq('id', entity);

        if (entityTypeError || !entityTypeData.length) {
          console.error(
            'Invalid entity type:',
            entityTypeError || 'No data found'
          );
          return [];
        }

        const tableName = entityTypeData[0].table;

        const { data, error } = await supabase
          .from(tableName)
          .select('id, full_name')
          .textSearch('fts', query);

        if (error) {
          console.error(`Error fetching entities from ${tableName}:`, error);
          return [];
        }

        return data.map(entity => ({
          value: entity.id,
          label: entity.full_name
        }));
      } catch (error) {
        console.error('Error loading entities:', error);
        return [];
      }
    },
    [entity]
  );

  const handleTestWithEntity = (type, index) => {
    setSelectedRequestType(type);
    setSelectedRequestIndex(index);
    setShowEntitySelector(true);
  };

  const handleEntitySelect = selectedEntity => {
    setSelectedEntity(selectedEntity);
  };

  const handleTestSelectedEntity = () => {
    if (selectedEntity) {
      setEntityId(selectedEntity);
      handleTestConnection(
        selectedRequestType,
        selectedRequestIndex,
        selectedEntity
      );
      setShowEntitySelector(false);
    }
  };

  const bgColours = {
    'zinc-500': 'bg-zinc-500',
    'red-500': 'bg-red-500',
    'orange-500': 'bg-orange-500',
    'amber-500': 'bg-amber-500',
    'yellow-500': 'bg-yellow-500',
    'lime-500': 'bg-lime-500',
    'green-500': 'bg-green-500',
    'emerald-500': 'bg-emerald-500',
    'teal-500': 'bg-teal-500',
    'cyan-500': 'bg-cyan-500',
    'sky-500': 'bg-sky-500',
    'blue-500': 'bg-blue-500',
    'indigo-500': 'bg-indigo-500',
    'violet-500': 'bg-violet-500',
    'purple-500': 'bg-purple-500',
    'fuchsia-500': 'bg-fuchsia-500',
    'pink-500': 'bg-pink-500',
    'rose-500': 'bg-rose-500'
  };

  return (
    <div>
      {showEntitySelector && (
        <div className='fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50'>
          <div className='bg-white p-6 rounded-lg w-96'>
            <h3 className='text-lg font-semibold mb-4'>Send Test Request</h3>
            <p className='text-sm text-gray-600 mb-4'>
              Select an entity to use as test data for this request.
            </p>
            <ComboBox
              value={selectedEntity}
              onChange={handleEntitySelect}
              options={entityOptions}
              loadOptions={fetchEntities}
            />
            <div className='flex justify-end mt-4'>
              <SoftButton
                onClick={() => setShowEntitySelector(false)}
                className='mr-2'
              >
                Cancel
              </SoftButton>
              <SoftButton
                onClick={handleTestSelectedEntity}
                disabled={!selectedEntity}
              >
                Send
              </SoftButton>
            </div>
          </div>
        </div>
      )}
      <div className='grid grid-cols-2 gap-4 h-[56vh]'>
        <div className='overflow-y-auto pr-4'>
          <div className='flex items-center justify-between mb-5'>
            <div className='flex items-center'>
              <div
                className={`w-12 h-12 flex items-center justify-center rounded-lg transition-colors duration-300 ${bgColours[colour]} text-white`}
              >
                {renderIcon(colour, icon)}
              </div>
              <div>
                <span className='text-lg font-semibold ml-3'>Send</span>
                <p className='text-sm ml-3 text-gray-600'>
                  Triggered when the entity is updated
                </p>
              </div>
            </div>
          </div>
          <h3 className='text-lg font-semibold'>Requests</h3>
          {sendRequests.map((request, index) => {
            return (
              <Disclosure key={index}>
                {({ open }) => (
                  <>
                    <div className='flex items-center mb-2'>
                      <Disclosure.Button
                        className='cursor-pointer text-left mr-2'
                        onClick={() =>
                          handleRequestChange('send', index, 'open', !open)
                        }
                      >
                        <ChevronRight
                          className={open ? 'rotate-90' : ''}
                        ></ChevronRight>
                      </Disclosure.Button>
                      <Input
                        type='text'
                        value={request.name}
                        onChange={e =>
                          handleRequestChange(
                            'send',
                            index,
                            'name',
                            e.target.value
                          )
                        }
                        className='flex-grow'
                      />
                    </div>
                    <Disclosure.Panel>
                      <div className='mb-4'>
                        <DropdownInput
                          label='Method'
                          value={request.method}
                          onChange={e =>
                            handleRequestChange(
                              'send',
                              index,
                              'method',
                              e.target.value
                            )
                          }
                          options={[
                            { value: 'GET', label: 'GET' },
                            { value: 'POST', label: 'POST' },
                            { value: 'PATCH', label: 'PATCH' },
                            { value: 'DELETE', label: 'DELETE' },
                            { value: 'PUT', label: 'PUT' }
                          ]}
                        />
                      </div>
                      <div className='mb-4'>
                        <Input
                          label='URL'
                          type='text'
                          value={request.url}
                          onChange={e =>
                            handleRequestChange(
                              'send',
                              index,
                              'url',
                              e.target.value
                            )
                          }
                          placeholder='https://api.example.com/endpoint'
                        />
                      </div>
                      <div className='mb-4'>
                        <label className='block text-sm font-medium leading-6 text-gray-900 mb-2'>
                          Headers
                        </label>
                        {request.headers.map((header, headerIndex) => (
                          <div key={headerIndex} className='flex gap-2 mb-2'>
                            <Input
                              type='text'
                              value={header.key}
                              onChange={e =>
                                handleHeaderChange(
                                  'send',
                                  index,
                                  headerIndex,
                                  'key',
                                  e.target.value
                                )
                              }
                              placeholder='Key'
                            />
                            <Input
                              type='text'
                              value={header.value}
                              onChange={e =>
                                handleHeaderChange(
                                  'send',
                                  index,
                                  headerIndex,
                                  'value',
                                  e.target.value
                                )
                              }
                              placeholder='Value'
                              className='mr-2'
                            />
                            <SoftButton
                              colour='primary'
                              onClick={() =>
                                removeHeader('send', index, headerIndex)
                              }
                            >
                              <X size={16} />
                            </SoftButton>
                          </div>
                        ))}
                        <SoftButton
                          leftIcon={<Plus size={16} />}
                          onClick={() => addHeader('send', index)}
                          className='mt-2'
                        >
                          Add Header
                        </SoftButton>
                      </div>
                      {request.method !== 'GET' && (
                        <div className='mb-4'>
                          <label className='block text-sm font-medium leading-6 text-gray-900 mb-2'>
                            Body
                          </label>
                          <textarea
                            className='w-full p-2 border border-gray-300 rounded-md'
                            value={request.body}
                            onChange={e =>
                              handleRequestChange(
                                'send',
                                index,
                                'body',
                                e.target.value
                              )
                            }
                            placeholder='Enter request body...'
                            rows={4}
                          />
                        </div>
                      )}
                      <div className='mb-4'>
                        <div className='flex flex-col'>
                          <div className='flex items-center justify-between mb-2'>
                            <SoftButton
                              onClick={() =>
                                handleTestWithEntity('send', index)
                              }
                              leftIcon={<RotateCw></RotateCw>}
                              className='mr-2'
                            >
                              Test Request
                            </SoftButton>
                            {sendResponses[index] ? (
                              <div className='flex items-center'>
                                <span className='mr-2 text-sm'>Status:</span>
                                <div className='flex items-center'>
                                  {sendResponses[index].status >= 200 &&
                                  sendResponses[index].status < 300 ? (
                                    <div className='w-4 h-4 rounded-full bg-green-500 mr-2'></div>
                                  ) : (
                                    <div className='w-4 h-4 rounded-full bg-red-500 mr-2'></div>
                                  )}
                                  <span className='mr-1'>
                                    {sendResponses[index].status}
                                  </span>
                                  <span>
                                    ({sendResponses[index].statusText})
                                  </span>
                                </div>
                              </div>
                            ) : (
                              <span className='text-sm'>
                                Click to test your request
                              </span>
                            )}
                          </div>
                          {sendResponses[index] && (
                            <Disclosure>
                              {({ open }) => (
                                <>
                                  <div className='flex justify-end'>
                                    <Disclosure.Button className='cursor-pointer text-sm flex items-center'>
                                      <span className='mr-1'>
                                        <ChevronRight
                                          className={open ? 'rotate-90' : ''}
                                        />
                                      </span>
                                      Response
                                    </Disclosure.Button>
                                  </div>

                                  <Disclosure.Panel>
                                    <div className='mt-2'>
                                      <h5 className='text-sm mb-2'>Headers</h5>
                                      <div className='bg-gray-100 p-4 rounded-md overflow-auto max-h-40'>
                                        <pre className='text-sm mb-4'>
                                          {JSON.stringify(
                                            sendResponses[index].headers,
                                            null,
                                            2
                                          )}
                                        </pre>
                                      </div>
                                      <h5 className='text-sm mt-4 mb-2'>
                                        Body
                                      </h5>
                                      <div className='bg-gray-100 p-4 rounded-md overflow-auto max-h-40'>
                                        <pre className='text-sm'>
                                          {sendResponses[index].headers[
                                            'content-type'
                                          ].includes('application/json')
                                            ? (() => {
                                                try {
                                                  return JSON.stringify(
                                                    JSON.parse(
                                                      sendResponses[index]
                                                        .body || '{}'
                                                    ),
                                                    null,
                                                    2
                                                  );
                                                } catch (error) {
                                                  console.error(
                                                    'JSON parse error:',
                                                    error
                                                  );
                                                  return sendResponses[index]
                                                    .body;
                                                }
                                              })()
                                            : sendResponses[index].body}
                                        </pre>
                                      </div>
                                    </div>
                                  </Disclosure.Panel>
                                </>
                              )}
                            </Disclosure>
                          )}
                        </div>
                      </div>
                      <SoftButton
                        colour='danger'
                        onClick={() => removeRequest('send', index)}
                        className='mt-2'
                      >
                        Remove Request
                      </SoftButton>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            );
          })}
          <SoftButton
            leftIcon={<Plus size={16} />}
            onClick={() => addRequest('send')}
            className='mt-4'
          >
            Add Send Request
          </SoftButton>
        </div>
        <div className='overflow-y-auto pl-2'>
          <div className='flex items-center justify-between mb-5'>
            <div className='flex items-center'>
              <div
                className={`w-12 h-12 flex items-center justify-center rounded-lg transition-colors duration-300 ${bgColours[colour]} text-white`}
              >
                <LogOut className={`w-5 h-5`} />
              </div>
              <div>
                <span className='text-lg font-semibold ml-3'>Exit</span>
                <p className='text-sm ml-3 text-gray-600'>
                  Triggered when the flow is removed
                </p>
              </div>
            </div>
          </div>
          <div className='mb-8'>
            {exitVariables?.length > 0 && (
              <>
                <h3 className='text-lg font-semibold'>Variables</h3>
                {exitVariables?.map((variables, index) => (
                  <Disclosure key={index}>
                    {({ open }) => (
                      <>
                        <Disclosure.Button className='cursor-pointer mb-2 flex items-center'>
                          <span className='mr-1'>
                            <ChevronRight className={open ? 'rotate-90' : ''} />
                          </span>
                          {sendRequests[index]?.name ||
                            `Send Request ${index + 1}`}{' '}
                          Variables
                        </Disclosure.Button>
                        <Disclosure.Panel>
                          <div className='bg-gray-100 p-4 rounded-md space-y-2 cursor-pointer'>
                            {variables?.map(variable => (
                              <div className='space-x-2'>
                                <Badge
                                  key={variable.variable}
                                  colour='brand'
                                  size='sm'
                                  onClick={() =>
                                    navigator.clipboard.writeText(
                                      variable.variable
                                    )
                                  }
                                >
                                  {variable.variable}
                                </Badge>
                                <span className='text-sm'>
                                  ({variable.value})
                                </span>
                              </div>
                            ))}
                            {variables?.length === 0 && (
                              <span className='text-sm'>
                                No variables available
                              </span>
                            )}
                          </div>
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                ))}
              </>
            )}
          </div>
          <h3 className='text-lg font-semibold'>Requests</h3>
          {exitRequests.map((request, index) => {
            return (
              <Disclosure key={index}>
                {({ open }) => (
                  <>
                    <div className='flex items-center mb-2'>
                      <Disclosure.Button
                        className='cursor-pointer text-center mr-2 flex items-center'
                        onClick={() =>
                          handleRequestChange('exit', index, 'open', !open)
                        }
                      >
                        <span className='mr-1'>
                          <ChevronRight className={open ? 'rotate-90' : ''} />
                        </span>
                      </Disclosure.Button>
                      <Input
                        type='text'
                        value={request.name}
                        onChange={e =>
                          handleRequestChange(
                            'exit',
                            index,
                            'name',
                            e.target.value
                          )
                        }
                        placeholder={`Exit Request ${index + 1}`}
                        className='flex-grow'
                      />
                    </div>
                    <Disclosure.Panel>
                      <div className='mb-4'>
                        <DropdownInput
                          label='Method'
                          value={request.method}
                          onChange={e =>
                            handleRequestChange(
                              'exit',
                              index,
                              'method',
                              e.target.value
                            )
                          }
                          options={[
                            { value: 'GET', label: 'GET' },
                            { value: 'POST', label: 'POST' },
                            { value: 'PATCH', label: 'PATCH' },
                            { value: 'DELETE', label: 'DELETE' },
                            { value: 'PUT', label: 'PUT' }
                          ]}
                        />
                      </div>
                      <div className='mb-4'>
                        <Input
                          label='URL'
                          type='text'
                          value={request.url}
                          onChange={e =>
                            handleRequestChange(
                              'exit',
                              index,
                              'url',
                              e.target.value
                            )
                          }
                          placeholder='https://api.example.com/endpoint'
                        />
                      </div>
                      <div className='mb-4'>
                        <ComboBox
                          label='Select Entity'
                          value={entityId}
                          loadOptions={fetchEntities}
                          options={entityOptions}
                          onChange={setEntityId}
                        />
                      </div>
                      <div className='mb-4'>
                        <label className='block text-sm font-medium leading-6 text-gray-900 mb-2'>
                          Headers
                        </label>
                        {request.headers.map((header, headerIndex) => (
                          <div key={headerIndex} className='flex gap-2 mb-2'>
                            <Input
                              type='text'
                              value={header.key}
                              onChange={e =>
                                handleHeaderChange(
                                  'exit',
                                  index,
                                  headerIndex,
                                  'key',
                                  e.target.value
                                )
                              }
                              placeholder='Key'
                            />
                            <Input
                              type='text'
                              value={header.value}
                              onChange={e =>
                                handleHeaderChange(
                                  'exit',
                                  index,
                                  headerIndex,
                                  'value',
                                  e.target.value
                                )
                              }
                              placeholder='Value'
                              className='mr-2'
                            />
                            <SoftButton
                              colour='primary'
                              onClick={() =>
                                removeHeader('exit', index, headerIndex)
                              }
                            >
                              <X size={16} />
                            </SoftButton>
                          </div>
                        ))}
                        <SoftButton
                          leftIcon={<Plus size={16} />}
                          onClick={() => addHeader('exit', index)}
                          className='mt-2'
                        >
                          Add Header
                        </SoftButton>
                      </div>
                      {request.method !== 'GET' && (
                        <div className='mb-4'>
                          <label className='block text-sm font-medium leading-6 text-gray-900 mb-2'>
                            Body
                          </label>
                          <textarea
                            className='w-full p-2 border border-gray-300 rounded-md'
                            value={request.body}
                            onChange={e =>
                              handleRequestChange(
                                'exit',
                                index,
                                'body',
                                e.target.value
                              )
                            }
                            placeholder='Enter request body...'
                            rows={4}
                          />
                        </div>
                      )}
                      <div className='mb-4'>
                        <div className='flex flex-col'>
                          <div className='flex items-center justify-between mb-2'>
                            <SoftButton
                              onClick={() =>
                                handleTestConnection(
                                  'exit',
                                  index,
                                  request.entity
                                )
                              }
                              leftIcon={<RotateCw></RotateCw>}
                              className='mr-2'
                            >
                              Test Request
                            </SoftButton>
                            {exitResponses[index] ? (
                              <div className='flex items-center'>
                                <span className='mr-2 text-sm'>Status:</span>
                                <div className='flex items-center'>
                                  {exitResponses[index].status >= 200 &&
                                  exitResponses[index].status < 300 ? (
                                    <div className='w-4 h-4 rounded-full bg-green-500 mr-2'></div>
                                  ) : (
                                    <div className='w-4 h-4 rounded-full bg-red-500 mr-2'></div>
                                  )}
                                  <span className='mr-1'>
                                    {exitResponses[index].status}
                                  </span>
                                  <span>
                                    ({exitResponses[index].statusText})
                                  </span>
                                </div>
                              </div>
                            ) : (
                              <span className='text-sm'>
                                Click to test your request
                              </span>
                            )}
                          </div>
                          {exitResponses[index] && (
                            <Disclosure>
                              {({ open }) => (
                                <>
                                  <div className='flex justify-end'>
                                    <Disclosure.Button className='cursor-pointer text-sm text-right flex items-center'>
                                      <span className='mr-1'>
                                        <ChevronRight
                                          className={open ? 'rotate-90' : ''}
                                        />
                                      </span>
                                      Response
                                    </Disclosure.Button>
                                  </div>
                                  <Disclosure.Panel>
                                    <div className='mt-2'>
                                      <h5 className='text-sm mb-2'>Headers</h5>
                                      <div className='bg-gray-100 p-4 rounded-md overflow-auto max-h-40'>
                                        <pre className='text-sm mb-4'>
                                          {JSON.stringify(
                                            exitResponses[index].headers,
                                            null,
                                            2
                                          )}
                                        </pre>
                                      </div>
                                      <h5 className='text-sm mt-4 mb-2'>
                                        Body
                                      </h5>
                                      <div className='bg-gray-100 p-4 rounded-md overflow-auto max-h-40'>
                                        <pre className='text-sm'>
                                          {exitResponses[index].headers[
                                            'content-type'
                                          ] === 'application/json'
                                            ? (() => {
                                                try {
                                                  return JSON.stringify(
                                                    JSON.parse(
                                                      exitResponses[index]
                                                        .body || '{}'
                                                    ),
                                                    null,
                                                    2
                                                  );
                                                } catch (error) {
                                                  console.error(
                                                    'JSON parse error:',
                                                    error
                                                  );
                                                  return exitResponses[index]
                                                    .body;
                                                }
                                              })()
                                            : exitResponses[index].body}
                                        </pre>
                                      </div>
                                    </div>
                                  </Disclosure.Panel>
                                </>
                              )}
                            </Disclosure>
                          )}
                        </div>
                      </div>
                      <SoftButton
                        colour='danger'
                        onClick={() => removeRequest('exit', index)}
                        className='mt-2'
                      >
                        Remove Request
                      </SoftButton>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            );
          })}
          <SoftButton
            leftIcon={<Plus size={16} />}
            onClick={() => addRequest('exit')}
            className='mt-4'
          >
            Add Exit Request
          </SoftButton>
        </div>
      </div>
    </div>
  );
};

export default Requests;
