import React from 'react';
import { Input, SoftButton } from '@core/components';
import { Plus, Trash2 } from 'lucide-react';

const AdditionalDetails = ({ schemeData, handleInputChange }) => {
  const addEmail = () => {
    const emails = Array.isArray(schemeData.emails) ? schemeData.emails : [];
    handleInputChange('emails', [...emails, '']);
  };

  const removeEmail = index => {
    const emails = [...schemeData.emails];
    emails.splice(index, 1);
    handleInputChange('emails', emails);
  };

  const updateEmail = (index, value) => {
    const emails = [...schemeData.emails];
    emails[index] = value;
    handleInputChange('emails', emails);
  };

  const addPhone = () => {
    const phones = Array.isArray(schemeData.phones) ? schemeData.phones : [];
    handleInputChange('phones', [...phones, '']);
  };

  const removePhone = index => {
    const phones = [...schemeData.phones];
    phones.splice(index, 1);
    handleInputChange('phones', phones);
  };

  const updatePhone = (index, value) => {
    const phones = [...schemeData.phones];
    phones[index] = value;
    handleInputChange('phones', phones);
  };

  return (
    <div className='space-y-4'>
      <div className='space-y-2'>
        <label className='block text-sm font-medium'>Email Addresses</label>
        {Array.isArray(schemeData.emails) &&
          schemeData.emails.map((email, index) => (
            <div key={index} className='flex gap-2'>
              <Input
                value={email}
                onChange={e => updateEmail(index, e.target.value)}
                placeholder='email@example.com'
              />
              <button
                onClick={() => removeEmail(index)}
                className='p-2 text-red-600 hover:text-red-800'
              >
                <Trash2 size={16} />
              </button>
            </div>
          ))}
        <SoftButton onClick={addEmail} className='mb-2'>
          <Plus size={16} /> Add Email
        </SoftButton>
      </div>

      <div className='space-y-2'>
        <label className='block text-sm font-medium'>Phone Numbers</label>
        {Array.isArray(schemeData.phones) &&
          schemeData.phones.map((phone, index) => (
            <div key={index} className='flex gap-2'>
              <Input
                value={phone}
                onChange={e => updatePhone(index, e.target.value)}
                placeholder='+44 1234 567890'
              />
              <button
                onClick={() => removePhone(index)}
                className='p-2 text-red-600 hover:text-red-800'
              >
                <Trash2 size={16} />
              </button>
            </div>
          ))}
        <SoftButton onClick={addPhone} className='mb-2'>
          <Plus size={16} /> Add Phone
        </SoftButton>
      </div>
    </div>
  );
};

export default AdditionalDetails;
