import React, { useState, useRef, useEffect, useCallback } from 'react';
import { DropdownInput } from '../../components/inputs/Forms';
import {
  AlertTriangle,
  ArrowRight,
  Check,
  Loader,
  Plus,
  Settings,
  Search,
  Bell,
  Calendar,
  Download
} from 'lucide-react';
import Company from '../../models/Company';
import {
  FilledButton,
  SoftButton,
  OutlineButton,
  TextButton,
  Badge,
  MultiSelectBox
} from '@core/components';
import { Input } from '@core/components';

import colors from 'tailwindcss/colors';

const StylePages = () => {
  const [dropdownValue, setDropdownValue] = useState('option1');
  const [MultiSelectBoxValue, setMultiSelectBoxValue] = useState([]);
  const [companyMultiSelectBoxValue, setCompanyMultiSelectBoxValue] = useState(
    []
  );
  const [activeSection, setActiveSection] = useState('');
  const [searchTerm, setSearchTerm] = useState('');

  document.title = 'Styles Page | DEV | PTS';

  const palletRef = useRef(null);
  const coloursRef = useRef(null);
  const typographyRef = useRef(null);
  const formInputsRef = useRef(null);
  const buttonsRef = useRef(null);
  const badgesRef = useRef(null);

  const dropdownOptions = [
    { value: 'option1', label: 'Option 1' },
    { value: 'option2', label: 'Option 2' },
    { value: 'option3', label: 'Option 3' }
  ];

  const MultiSelectBoxOptions = [
    { value: 'red', label: 'Red' },
    { value: 'blue', label: 'Blue' },
    { value: 'green', label: 'Green' }
  ];

  const loadCompanyOptions = async inputValue => {
    try {
      const { data } = await Company.getAll({ name: inputValue });
      return data.map(company => ({ value: company.id, label: company.name }));
    } catch (error) {
      console.error('Error loading company options:', error);
      return [];
    }
  };

  const sections = [
    { id: 'colors1', label: 'Colour Pallet', ref: palletRef },
    { id: 'colors2', label: 'Tailwind Colours', ref: coloursRef },
    { id: 'typography', label: 'Typography', ref: typographyRef },
    { id: 'formInputs', label: 'Form Inputs', ref: formInputsRef },
    { id: 'buttons', label: 'Buttons', ref: buttonsRef },
    { id: 'badges', label: 'Badges', ref: badgesRef }
  ];

  const handleScroll = useCallback(() => {
    const scrollPosition = window.scrollY + 100; // Add offset for better accuracy

    for (const section of sections) {
      if (
        section.ref.current.offsetTop <= scrollPosition &&
        section.ref.current.offsetTop + section.ref.current.offsetHeight >
          scrollPosition
      ) {
        setActiveSection(section.id);
        break;
      }
    }
  }, [sections]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  const scrollToSection = useCallback(
    sectionId => {
      const section = sections.find(s => s.id === sectionId);
      if (section && section.ref.current) {
        section.ref.current.scrollIntoView({ behavior: 'smooth' });
      }
    },
    [sections]
  );

  const colorPalettes = [
    'Brand',
    'Base',
    'Primary',
    'Info',
    'Success',
    'Warning',
    'Danger'
  ];

  const shades = [
    '50',
    '100',
    '200',
    '300',
    '400',
    '500',
    '600',
    '700',
    '800',
    '900',
    '950'
  ];

  const tailwindColors = [
    'slate',
    'gray',
    'zinc',
    'neutral',
    'stone',
    'red',
    'orange',
    'amber',
    'yellow',
    'lime',
    'green',
    'emerald',
    'teal',
    'cyan',
    'sky',
    'blue',
    'indigo',
    'violet',
    'purple',
    'fuchsia',
    'pink',
    'rose'
  ];

  {
    /* Tailwind CSS color classes [DO NOT REMOVE]
    bg-slate-50 bg-slate-100 bg-slate-200 bg-slate-300 bg-slate-400 bg-slate-500 bg-slate-600 bg-slate-700 bg-slate-800 bg-slate-900 bg-slate-950
    bg-gray-50 bg-gray-100 bg-gray-200 bg-gray-300 bg-gray-400 bg-gray-500 bg-gray-600 bg-gray-700 bg-gray-800 bg-gray-900 bg-gray-950
    bg-zinc-50 bg-zinc-100 bg-zinc-200 bg-zinc-300 bg-zinc-400 bg-zinc-500 bg-zinc-600 bg-zinc-700 bg-zinc-800 bg-zinc-900 bg-zinc-950
    bg-neutral-50 bg-neutral-100 bg-neutral-200 bg-neutral-300 bg-neutral-400 bg-neutral-500 bg-neutral-600 bg-neutral-700 bg-neutral-800 bg-neutral-900 bg-neutral-950
    bg-stone-50 bg-stone-100 bg-stone-200 bg-stone-300 bg-stone-400 bg-stone-500 bg-stone-600 bg-stone-700 bg-stone-800 bg-stone-900 bg-stone-950
    bg-red-50 bg-red-100 bg-red-200 bg-red-300 bg-red-400 bg-red-500 bg-red-600 bg-red-700 bg-red-800 bg-red-900 bg-red-950
    bg-orange-50 bg-orange-100 bg-orange-200 bg-orange-300 bg-orange-400 bg-orange-500 bg-orange-600 bg-orange-700 bg-orange-800 bg-orange-900 bg-orange-950
    bg-amber-50 bg-amber-100 bg-amber-200 bg-amber-300 bg-amber-400 bg-amber-500 bg-amber-600 bg-amber-700 bg-amber-800 bg-amber-900 bg-amber-950
    bg-yellow-50 bg-yellow-100 bg-yellow-200 bg-yellow-300 bg-yellow-400 bg-yellow-500 bg-yellow-600 bg-yellow-700 bg-yellow-800 bg-yellow-900 bg-yellow-950
    bg-lime-50 bg-lime-100 bg-lime-200 bg-lime-300 bg-lime-400 bg-lime-500 bg-lime-600 bg-lime-700 bg-lime-800 bg-lime-900 bg-lime-950
    bg-green-50 bg-green-100 bg-green-200 bg-green-300 bg-green-400 bg-green-500 bg-green-600 bg-green-700 bg-green-800 bg-green-900 bg-green-950
    bg-emerald-50 bg-emerald-100 bg-emerald-200 bg-emerald-300 bg-emerald-400 bg-emerald-500 bg-emerald-600 bg-emerald-700 bg-emerald-800 bg-emerald-900 bg-emerald-950
    bg-teal-50 bg-teal-100 bg-teal-200 bg-teal-300 bg-teal-400 bg-teal-500 bg-teal-600 bg-teal-700 bg-teal-800 bg-teal-900 bg-teal-950
    bg-cyan-50 bg-cyan-100 bg-cyan-200 bg-cyan-300 bg-cyan-400 bg-cyan-500 bg-cyan-600 bg-cyan-700 bg-cyan-800 bg-cyan-900 bg-cyan-950
    bg-sky-50 bg-sky-100 bg-sky-200 bg-sky-300 bg-sky-400 bg-sky-500 bg-sky-600 bg-sky-700 bg-sky-800 bg-sky-900 bg-sky-950
    bg-blue-50 bg-blue-100 bg-blue-200 bg-blue-300 bg-blue-400 bg-blue-500 bg-blue-600 bg-blue-700 bg-blue-800 bg-blue-900 bg-blue-950
    bg-indigo-50 bg-indigo-100 bg-indigo-200 bg-indigo-300 bg-indigo-400 bg-indigo-500 bg-indigo-600 bg-indigo-700 bg-indigo-800 bg-indigo-900 bg-indigo-950
    bg-violet-50 bg-violet-100 bg-violet-200 bg-violet-300 bg-violet-400 bg-violet-500 bg-violet-600 bg-violet-700 bg-violet-800 bg-violet-900 bg-violet-950
    bg-purple-50 bg-purple-100 bg-purple-200 bg-purple-300 bg-purple-400 bg-purple-500 bg-purple-600 bg-purple-700 bg-purple-800 bg-purple-900 bg-purple-950
    bg-fuchsia-50 bg-fuchsia-100 bg-fuchsia-200 bg-fuchsia-300 bg-fuchsia-400 bg-fuchsia-500 bg-fuchsia-600 bg-fuchsia-700 bg-fuchsia-800 bg-fuchsia-900 bg-fuchsia-950
    bg-pink-50 bg-pink-100 bg-pink-200 bg-pink-300 bg-pink-400 bg-pink-500 bg-pink-600 bg-pink-700 bg-pink-800 bg-pink-900 bg-pink-950
    bg-rose-50 bg-rose-100 bg-rose-200 bg-rose-300 bg-rose-400 bg-rose-500 bg-rose-600 bg-rose-700 bg-rose-800 bg-rose-900 bg-rose-950
  */
  }

  {
    /* Custom color classes [DO NOT REMOVE]
    bg-brand-50 bg-brand-100 bg-brand-200 bg-brand-300 bg-brand-400 bg-brand-500 bg-brand-600 bg-brand-700 bg-brand-800 bg-brand-900 bg-brand-950
    bg-primary-50 bg-primary-100 bg-primary-200 bg-primary-300 bg-primary-400 bg-primary-500 bg-primary-600 bg-primary-700 bg-primary-800 bg-primary-900 bg-primary-950
    bg-secondary-50 bg-secondary-100 bg-secondary-200 bg-secondary-300 bg-secondary-400 bg-secondary-500 bg-secondary-600 bg-secondary-700 bg-secondary-800 bg-secondary-900 bg-secondary-950
    bg-success-50 bg-success-100 bg-success-200 bg-success-300 bg-success-400 bg-success-500 bg-success-600 bg-success-700 bg-success-800 bg-success-900 bg-success-950
    bg-warning-50 bg-warning-100 bg-warning-200 bg-warning-300 bg-warning-400 bg-warning-500 bg-warning-600 bg-warning-700 bg-warning-800 bg-warning-900 bg-warning-950
    bg-danger-50 bg-danger-100 bg-danger-200 bg-danger-300 bg-danger-400 bg-danger-500 bg-danger-600 bg-danger-700 bg-danger-800 bg-danger-900 bg-danger-950
    bg-info-50 bg-info-100 bg-info-200 bg-info-300 bg-info-400 bg-info-500 bg-info-600 bg-info-700 bg-info-800 bg-info-900 bg-info-950
    bg-base-50 bg-base-100 bg-base-200 bg-base-300 bg-base-400 bg-base-500 bg-base-600 bg-base-700 bg-base-800 bg-base-900 bg-base-950
  */
  }

  const getHexColour = (palette, shade) => {
    if (colors[palette] && colors[palette][shade]) {
      return colors[palette][shade];
    }
    const customColours = {
      brand: {
        DEFAULT: '#BC3D3F',
        50: '#FFFFFF',
        100: '#FBF3F3',
        200: '#F1D4D4',
        300: '#E7B5B6',
        400: '#DD9697',
        500: '#D37879',
        600: '#C9595B',
        700: '#BC3D3F',
        800: '#922F31',
        900: '#672104',
        950: '#521B1C'
      },
      base: colors.neutral,
      primary: colors.zinc,
      info: colors.blue,
      success: colors.green,
      warning: colors.amber,
      danger: colors.red
    };
    return customColours[palette]?.[shade] || 'n/a';
  };

  const renderColorRows = (palette, isCustom = false) => (
    <div className='grid grid-cols-12 gap-2 mb-2'>
      <div className='col-span-1 flex items-center'>{palette}</div>
      {shades.map(shade => (
        <div key={shade} className='flex flex-col items-center'>
          <div
            className={`w-full h-8 rounded shadow-inner ${
              isCustom
                ? `bg-${palette.toLowerCase()}-${shade}`
                : `bg-${palette}-${shade}`
            }`}
          ></div>
          <div className='text-xs'>
            {getHexColour(palette.toLowerCase(), shade)}
          </div>
        </div>
      ))}
    </div>
  );

  const filteredSections = sections.filter(section =>
    section.label.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className='min-h-screen bg-neutral-50 flex'>
      <nav className='w-64 bg-white shadow-lg p-6 fixed h-full overflow-auto'>
        <h2 className='text-2xl font-bold mb-8 text-neutral-800 border-b pb-4'>
          Contents
        </h2>
        <div className='mb-4 relative'>
          <input
            type='text'
            placeholder='Search sections...'
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value)}
            className='w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-brand-500'
          />
          <Search
            className='absolute right-3 top-2.5 text-gray-400'
            size={20}
          />
        </div>
        <ul className='space-y-2'>
          {filteredSections.map(section => (
            <ul key={section.id} className='p-0 m-0'>
              <button
                onClick={() => scrollToSection(section.id)}
                className={`w-full text-left py-2 px-4 rounded-md transition-all duration-200 ease-in-out ${
                  activeSection === section.id
                    ? 'bg-brand-100 text-brand-800 font-medium shadow-sm'
                    : 'text-neutral-600 hover:bg-neutral-100 hover:text-neutral-900'
                }`}
              >
                {section.label}
              </button>
            </ul>
          ))}
        </ul>
      </nav>
      <div className='flex-1 ml-64'>
        <div className='container mx-auto px-4 py-12'>
          <h1 className='text-4xl font-bold mb-12 text-neutral-800 border-b pb-4'>
            Style Guide
          </h1>

          <section ref={palletRef} className='mb-16'>
            <h2 className='text-3xl font-semibold mb-6 text-neutral-700'>
              Colour Pallet
            </h2>
            <div className='bg-white p-6 rounded-lg shadow-md'>
              <div className='grid grid-cols-12 gap-2 mb-4'>
                <div className='col-span-1 font-semibold'>Palette</div>
                {shades.map(shade => (
                  <div key={shade} className='text-center text-xs'>
                    {shade}
                  </div>
                ))}
              </div>
              {colorPalettes.map(palette => renderColorRows(palette, true))}
            </div>
          </section>

          <section ref={coloursRef} className='mb-16'>
            <h2 className='text-3xl font-semibold mb-6 text-neutral-700'>
              Tailwind Colours
            </h2>
            <div className='bg-white p-6 rounded-lg shadow-md'>
              <div className='grid grid-cols-12 gap-2 mb-4'>
                <div className='col-span-1 font-semibold'>Colours</div>
                {shades.map(shade => (
                  <div key={shade} className='text-center text-xs'>
                    {shade}
                  </div>
                ))}
              </div>
              {tailwindColors.map(color => renderColorRows(color))}
            </div>
          </section>

          <section ref={typographyRef} className='mb-16'>
            <h2 className='text-3xl font-semibold mb-6 text-neutral-700'>
              Typography
            </h2>
            <div className='bg-white p-6 rounded-lg shadow-md space-y-6'>
              <div>
                <h1 className='text-4xl font-bold mb-2'>Heading 1</h1>
                <p className='text-sm text-gray-500 mb-4'>text-4xl font-bold</p>
              </div>
              <div>
                <h2 className='text-3xl font-semibold mb-2'>Heading 2</h2>
                <p className='text-sm text-gray-500 mb-4'>
                  text-3xl font-semibold
                </p>
              </div>
              <div>
                <h3 className='text-2xl font-medium mb-2'>Heading 3</h3>
                <p className='text-sm text-gray-500 mb-4'>
                  text-2xl font-medium
                </p>
              </div>
              <div>
                <h4 className='text-xl font-medium mb-2'>Heading 4</h4>
                <p className='text-sm text-gray-500 mb-4'>
                  text-xl font-medium
                </p>
              </div>
              <div>
                <p className='text-base mb-2'>Regular paragraph text</p>
                <p className='text-sm text-gray-500 mb-4'>text-base</p>
              </div>
              <div>
                <p className='text-sm mb-2'>Small text</p>
                <p className='text-sm text-gray-500 mb-4'>text-sm</p>
              </div>
              <div>
                <p className='text-xs mb-2'>Extra small text</p>
                <p className='text-sm text-gray-500 mb-4'>text-xs</p>
              </div>
              <div>
                <p className='text-lg font-semibold mb-2'>Large text</p>
                <p className='text-sm text-gray-500 mb-4'>
                  text-lg font-semibold
                </p>
              </div>
              <div>
                <p className='italic mb-2'>Italic text</p>
                <p className='text-sm text-gray-500 mb-4'>italic</p>
              </div>
              <div>
                <p className='underline mb-2'>Underlined text</p>
                <p className='text-sm text-gray-500 mb-4'>underline</p>
              </div>
            </div>
          </section>

          <section ref={formInputsRef} className='mb-16'>
            <h2 className='text-3xl font-semibold mb-6 text-neutral-700'>
              Form Inputs
            </h2>
            <div className='bg-white p-6 rounded-lg shadow-md space-y-6'>
              <div>
                <h3 className='text-xl font-semibold mb-4'>Text Inputs</h3>
                <Input
                  label='Default Input'
                  placeholder='Enter text'
                  hint='This is a hint text'
                />
                <Input
                  label='Input with Left Icon'
                  placeholder='Search...'
                  leftIcon={() => (
                    <svg
                      className='h-5 w-5'
                      fill='none'
                      viewBox='0 0 24 24'
                      stroke='currentColor'
                    >
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        strokeWidth={2}
                        d='M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z'
                      />
                    </svg>
                  )}
                />
                <Input
                  label='Input with Right Icon'
                  placeholder='Enter email'
                  rightIcon={() => (
                    <svg
                      className='h-5 w-5'
                      fill='none'
                      viewBox='0 0 24 24'
                      stroke='currentColor'
                    >
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        strokeWidth={2}
                        d='M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207'
                      />
                    </svg>
                  )}
                />
                <Input
                  label='Input with Left Addon'
                  placeholder='Enter username'
                  leftAddon='@'
                />
                <Input
                  label='Input with Right Addon'
                  placeholder='Enter amount'
                  rightAddon='USD'
                />
                <Input
                  label='Input with Label Hint'
                  placeholder='Enter password'
                  labelHint='Optional'
                  type='password'
                />
                <Input
                  label='Input with Left Dropdown Addon'
                  placeholder='Enter phone number'
                  leftAddon={
                    <select className='h-full rounded-md border-0 bg-transparent py-0 pl-2 pr-7 text-gray-500 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm'>
                      <option>+1</option>
                      <option>+44</option>
                      <option>+91</option>
                    </select>
                  }
                />
                <Input
                  label='Input with Right Dropdown Addon'
                  placeholder='Enter weight'
                  rightAddon={
                    <select className='h-full rounded-md border-0 bg-transparent py-0 pl-2 pr-7 text-gray-500 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm'>
                      <option>kg</option>
                      <option>lbs</option>
                      <option>oz</option>
                    </select>
                  }
                />
              </div>
              <div>
                <h3 className='text-xl font-semibold mb-4'>Dropdown Inputs</h3>
                <DropdownInput
                  label='Small'
                  options={dropdownOptions}
                  value={dropdownValue}
                  onChange={e => setDropdownValue(e.target.value)}
                  size='small'
                />
                <DropdownInput
                  label='Medium'
                  options={dropdownOptions}
                  value={dropdownValue}
                  onChange={e => setDropdownValue(e.target.value)}
                  size='medium'
                />
                <DropdownInput
                  label='Large'
                  options={dropdownOptions}
                  value={dropdownValue}
                  onChange={e => setDropdownValue(e.target.value)}
                  size='large'
                />
              </div>
              <div className='w-full'>
                <h3 className='text-xl font-semibold mb-4'>
                  MultiSelectBox Input
                </h3>
                <MultiSelectBox
                  options={MultiSelectBoxOptions}
                  selectedOptions={MultiSelectBoxValue}
                  onChange={setMultiSelectBoxValue}
                  placeholder='Select options'
                  allowCreate={true}
                />
              </div>
              <div className='w-full'>
                <h3 className='text-xl font-semibold mb-4'>
                  Company MultiSelectBox Input
                </h3>
                <MultiSelectBox
                  loadOptions={loadCompanyOptions}
                  selectedOptions={companyMultiSelectBoxValue}
                  onChange={setCompanyMultiSelectBoxValue}
                  placeholder='Select or search for a company'
                  allowCreate={false}
                />
              </div>
            </div>
          </section>

          <section ref={buttonsRef} className='mb-16'>
            <h2 className='text-3xl font-semibold mb-6 text-neutral-700'>
              Buttons
            </h2>
            <div className='bg-white p-6 rounded-lg shadow-md'>
              <h3 className='text-xl font-semibold mb-4'>Button Sizes</h3>
              <div className='mb-8 space-x-4'>
                <FilledButton size='xs'>Button Text (xs)</FilledButton>
                <FilledButton size='sm'>Button Text (sm)</FilledButton>
                <FilledButton size='md'>Button Text (md)</FilledButton>
                <FilledButton size='lg'>Button Text (lg)</FilledButton>
                <FilledButton size='xl'>Button Text (xl)</FilledButton>
              </div>

              <div className='mb-8 space-x-4'>
                <FilledButton size='xs' leftIcon={<ArrowRight />}>
                  Button Text (xs)
                </FilledButton>
                <FilledButton size='sm' leftIcon={<Bell />}>
                  Button Text (sm)
                </FilledButton>
                <FilledButton size='md' leftIcon={<Calendar />}>
                  Button Text (md)
                </FilledButton>
                <FilledButton size='lg' leftIcon={<Check />}>
                  Button Text (lg)
                </FilledButton>
                <FilledButton size='xl' leftIcon={<Download />}>
                  Button Text (xl)
                </FilledButton>
              </div>

              <h3 className='text-xl font-semibold mb-4'>Button Colors</h3>
              <div className='flex gap-4 mb-8 items-center'>
                <p className='text-sm font-semibold w-10'>Filled:</p>
                <FilledButton colour='brand'>Brand</FilledButton>
                <FilledButton colour='primary'>Primary</FilledButton>
                <FilledButton colour='info'>Info</FilledButton>
                <FilledButton colour='success'>Success</FilledButton>
                <FilledButton colour='warning'>Warning</FilledButton>
                <FilledButton colour='danger'>Danger</FilledButton>
              </div>
              <div className='flex gap-4 mb-8 items-center'>
                <p className='text-sm font-semibold w-10'>Soft:</p>
                <SoftButton colour='brand'>Brand</SoftButton>
                <SoftButton colour='primary'>Primary</SoftButton>
                <SoftButton colour='info'>Info</SoftButton>
                <SoftButton colour='success'>Success</SoftButton>
                <SoftButton colour='warning'>Warning </SoftButton>
                <SoftButton colour='danger'>Danger</SoftButton>
              </div>
              <div className='flex gap-4 mb-8 items-center'>
                <p className='text-sm font-semibold w-10'>Outline:</p>
                <OutlineButton colour='brand'>Brand</OutlineButton>
                <OutlineButton colour='primary'>Primary</OutlineButton>
                <OutlineButton colour='info'>Info</OutlineButton>
                <OutlineButton colour='success'>Success</OutlineButton>
                <OutlineButton colour='warning'>Warning</OutlineButton>
                <OutlineButton colour='danger'>Danger</OutlineButton>
              </div>
              <div className='flex gap-4 mb-8 items-center'>
                <p className='text-sm font-semibold w-10'>Text:</p>
                <TextButton colour='brand'>Brand</TextButton>
                <TextButton colour='primary'>Primary</TextButton>
                <TextButton colour='info'>Info</TextButton>
                <TextButton colour='success'>Success</TextButton>
                <TextButton colour='warning'>Warning</TextButton>
                <TextButton colour='danger'>Danger</TextButton>
              </div>

              <h3 className='text-xl font-semibold mb-4'>Icon Placement</h3>
              <div className='flex gap-4 mb-8'>
                <FilledButton leftIcon={<Plus />}>Left Icon</FilledButton>
                <FilledButton rightIcon={<ArrowRight />}>
                  Right Icon
                </FilledButton>
                <FilledButton>
                  <Settings />
                </FilledButton>
              </div>

              <h3 className='text-xl font-semibold mb-4'>Animated Icons</h3>
              <div className='flex gap-4'>
                <FilledButton leftIcon={<Loader className='animate-spin' />}>
                  Spin
                </FilledButton>
                <FilledButton
                  leftIcon={<AlertTriangle className='animate-pulse' />}
                >
                  Pulse
                </FilledButton>
                <FilledButton
                  leftIcon={<AlertTriangle className='animate-ping' />}
                >
                  Ping
                </FilledButton>
                <FilledButton
                  leftIcon={<Download className='animate-bounce' />}
                >
                  Bounce
                </FilledButton>
              </div>
            </div>
          </section>

          <section ref={badgesRef}>
            <h2 className='text-3xl font-semibold mb-6 text-neutral-700'>
              Badges
            </h2>
            <div className='bg-white p-6 rounded-lg shadow-md'>
              <h3 className='text-xl font-semibold mb-4'>Standard Badges</h3>
              <div className='flex flex-wrap gap-4 mb-6'>
                {[
                  'brand',
                  'primary',
                  'info',
                  'success',
                  'warning',
                  'danger'
                ].map(colour => (
                  <Badge key={colour} colour={colour}>
                    {colour} badge
                  </Badge>
                ))}
              </div>

              <h3 className='text-xl font-semibold mb-4'>Pill Badges</h3>
              <div className='flex flex-wrap gap-4 mb-6'>
                {[
                  'brand',
                  'primary',
                  'info',
                  'success',
                  'warning',
                  'danger'
                ].map(colour => (
                  <Badge key={colour} colour={colour} shape='pill'>
                    {colour} pill
                  </Badge>
                ))}
              </div>

              <h3 className='text-xl font-semibold mb-4'>Sizes</h3>
              <div className='space-x-4 mb-6'>
                <Badge size='xs'>Extra Small</Badge>
                <Badge size='sm'>Small</Badge>
                <Badge size='md'>Medium</Badge>
                <Badge size='lg'>Large</Badge>
                <Badge size='xl'>Extra Large</Badge>
              </div>

              <h3 className='text-xl font-semibold mb-4'>With Icons</h3>
              <div className='flex flex-wrap gap-4 mb-6'>
                <Badge leftIcon={Check}>Left Icon</Badge>
                <Badge rightIcon={ArrowRight}>Right Icon</Badge>
                <Badge>
                  <Settings size={16} />
                </Badge>
              </div>

              <h3 className='text-xl font-semibold mb-4'>Removable</h3>
              <div className='flex flex-wrap gap-4'>
                <Badge onRemove={() => console.log('Badge removed')}>
                  Removable
                </Badge>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default StylePages;
