import React, { useState, useEffect } from 'react';
import { SquareArrowOutUpRight } from 'lucide-react';
import EntityTable from '../../../../components/tables/EntityTable';
import {
  EntityTypeEnum,
  FilterOperatorEnum
} from '../../../../utilities/Enumerables';
import { useNavigate } from 'react-router-dom';
import SearchCompany from '../../../../models/SearchCompany';
import CoreEntityType from '../../../../models/CoreEntityType';
import { formatDate } from '../../../../utilities/Formatting';
import Search from '../../../../models/Search';

const SchemeCompanies = ({ schemeId }) => {
  const navigate = useNavigate();
  const [statusOptions, setStatusOptions] = useState([]);

  useEffect(() => {
    const fetchStatusOptions = async () => {
      try {
        const statuses = await CoreEntityType.getByEntityId(
          EntityTypeEnum.CompanyStatus
        );
        setStatusOptions(
          statuses.map(status => ({ value: status.type, key: status.id }))
        );
      } catch (error) {
        console.error('Error fetching status options:', error);
      }
    };

    fetchStatusOptions();
  }, []);

  const menuItems = [
    {
      label: 'Open in new tab',
      icon: <SquareArrowOutUpRight size={14} />,
      onClick: data => window.open(`/companies/${data.company.id}`, '_blank')
    }
  ];

  const handleRowClick = search => {
    navigate(`/companies/${search.company.id}`);
  };

  return (
    <EntityTable
      name='Companies'
      customId={`scheme:${schemeId}:companies`}
      filter={[
        {
          field: 'company.id',
          label: 'ID',
          type: 'text',
          operator: FilterOperatorEnum.EQUALS
        },
        {
          field: 'company.name',
          label: 'Company Name',
          type: 'text',
          operator: FilterOperatorEnum.ILIKE
        },
        {
          field: 'company.number',
          label: 'Company Number',
          type: 'text',
          operator: FilterOperatorEnum.ILIKE
        },
        {
          field: 'created_date',
          label: 'Created Date',
          type: 'daterange',
          operator: FilterOperatorEnum.BETWEEN
        },
        {
          field: 'updated_date',
          label: 'Updated Date',
          type: 'daterange',
          operator: FilterOperatorEnum.BETWEEN
        }
      ]}
      columns={[
        { label: 'ID', field: 'company.id' },
        { label: 'Company', field: 'company.name' },
        { label: 'Company Number', field: 'company.number' },
        {
          label: 'Created Date',
          field: 'company',
          cell: value => {
            return formatDate(value?.createdDate);
          }
        },
        {
          label: 'Updated Date',
          field: 'company',
          cell: value => {
            return formatDate(value?.updatedDate);
          }
        }
      ]}
      menuItems={menuItems}
      model={Search}
      entityType={EntityTypeEnum.Company}
      defaultFilters={{
        scheme: { value: schemeId, operator: 'eq' },
        company: { operator: 'not', innerOperator: 'is', value: 'null' }
      }}
      sortDefault={{ field: 'id', order: 'asc' }}
      onRowClick={handleRowClick}
    />
  );
};

export default SchemeCompanies;
