import React, { useEffect, useState } from 'react';
import { Input, Checkbox, DropdownInput } from '@core/components';
import CoreEntityType from '../../../../models/CoreEntityType';
import { EntityTypeEnum } from '../../../../utilities/Enumerables';

const BasicDetails = ({ providerData, handleInputChange }) => {
  const [statusOptions, setStatusOptions] = useState([]);

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const statusTypes = await CoreEntityType.getByEntityId(
          EntityTypeEnum.ProviderStatus
        );

        setStatusOptions(
          statusTypes.map(status => ({
            value: status.id,
            label: status.type
          }))
        );
      } catch (error) {
        console.error('Error fetching status options:', error);
      }
    };

    fetchOptions();
  }, []);

  return (
    <div className='space-y-4'>
      <Input
        label='Provider Name'
        value={providerData.name}
        onChange={e => handleInputChange('name', e.target.value)}
        required
      />

      <DropdownInput
        label='Status'
        value={providerData.status}
        onChange={e => handleInputChange('status', e.target.value)}
        options={statusOptions}
        disablePlaceholder
      />

      <div className='space-y-2'>
        <label className='block text-sm font-medium'>Signatures</label>
        <div className='space-y-2'>
          <Checkbox
            label='Wet Signature'
            checked={providerData.signatures?.wet}
            onChange={e =>
              handleInputChange('signatures.wet', e.target.checked)
            }
          />
          <Checkbox
            label='Digital Signature'
            checked={providerData.signatures?.digital}
            onChange={e =>
              handleInputChange('signatures.digital', e.target.checked)
            }
          />
          <Checkbox
            label='DocuSign'
            checked={providerData.signatures?.docusign}
            onChange={e =>
              handleInputChange('signatures.docusign', e.target.checked)
            }
          />
        </div>
      </div>
    </div>
  );
};

export default BasicDetails;
