'use client';

import { useState, useEffect } from 'react';
import { format } from 'date-fns';
import { CalendarIcon } from 'lucide-react';

import { cn } from '@/lib/utils';
import { Button } from '@core/components/ui';
import { Calendar } from '@core/components/ui';
import { Popover, PopoverContent, PopoverTrigger } from '@core/components/ui';

export function DateRangePicker({
  initialDateRange = null,
  onChange,
  disabled = false,
  required = false,
  label,
  hint
}) {
  const [dateRange, setDateRange] = useState(initialDateRange);

  useEffect(() => {
    setDateRange(initialDateRange);
  }, [initialDateRange]);

  const handleSelect = selectedDateRange => {
    setDateRange(selectedDateRange);
    if (onChange) {
      onChange(selectedDateRange);
    }
  };

  return (
    <div className='w-full'>
      {label && (
        <div className='flex justify-between'>
          <label className='block text-sm font-medium leading-6 text-gray-900'>
            {label}
            {required && <span className='text-danger-700 ml-1'>*</span>}
          </label>
        </div>
      )}
      <Popover>
        <PopoverTrigger asChild>
          <Button
            variant={'outline'}
            className={cn(
              'w-full justify-start text-left font-normal px-2.5 py-1.5 text-sm',
              !dateRange && 'text-muted-foreground',
              required && !dateRange && 'border-red-500',
              disabled && 'cursor-not-allowed'
            )}
            disabled={disabled}
          >
            <CalendarIcon className='mr-2 h-4 w-4' />
            {dateRange?.from ? (
              dateRange.to ? (
                <>
                  {format(dateRange.from, 'do MMMM yyyy')} -{' '}
                  {format(dateRange.to, 'do MMMM yyyy')}
                </>
              ) : (
                format(dateRange.from, 'do MMMM yyyy')
              )
            ) : (
              <span>Pick a date range{required && '*'}</span>
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent className='w-auto p-0'>
          <Calendar
            mode='range'
            selected={dateRange}
            onSelect={handleSelect}
            initialFocus
            disabled={disabled}
            numberOfMonths={2}
          />
        </PopoverContent>
      </Popover>
      {hint && <p className='mt-1 text-sm text-gray-500'>{hint}</p>}
    </div>
  );
}
