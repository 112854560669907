import React, { useState, useEffect } from 'react';
import { Toggle } from '@core/components';
import { supabase } from '../../../../utilities/supabase';

const Quote_QuoteOptions = ({ quoteId, onChange, initialSelections }) => {
  const [traces, setTraces] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    console.log('test');
    const fetchTraces = async () => {
      if (!quoteId) return;

      try {
        const { data: tracesData, error: tracesError } = await supabase
          .from('traces')
          .select(
            '*,status:core_entity_types!traces_status_fkey(id,type),type:core_entity_types!traces_type_fkey(id,type)'
          )
          .eq('owner', quoteId)
          .order('display_id');

        console.log(tracesError);

        if (tracesError) throw tracesError;

        // Fetch searches for each trace
        const tracesWithSearches = await Promise.all(
          tracesData.map(async trace => {
            const { data: searchData } = await supabase
              .from('searches')
              .select('*')
              .eq('trace', trace.id);

            return {
              ...trace,
              searches: searchData || []
            };
          })
        );

        setTraces(tracesWithSearches || []);

        console.log(tracesWithSearches);

        // If no initialSelections provided, auto-select ready traces
        if (Object.keys(initialSelections || {}).length === 0) {
          const initialSelectedState = {};
          tracesWithSearches?.forEach(trace => {
            if (trace.status?.id === 206) {
              initialSelectedState[trace.id] = trace;
            }
          });
          onChange?.(initialSelectedState);
        }
      } catch (error) {
        console.error('Error fetching traces:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchTraces();
  }, []);

  const handleToggleTrace = traceId => {
    const trace = traces.find(t => t.id === traceId);
    const newSelectedTraces = { ...initialSelections };

    if (initialSelections[traceId]) {
      delete newSelectedTraces[traceId];
    } else {
      newSelectedTraces[traceId] = trace;
    }

    onChange?.(newSelectedTraces);
  };

  if (loading) {
    return <div className='text-sm text-base-500'>Loading traces...</div>;
  }

  if (!traces.length) {
    return <div className='text-sm text-base-500'>No traces found</div>;
  }

  const disabledStatuses = [203, 204, 205, 256, 257];

  return (
    <div>
      <div className='sm:flex sm:items-center'>
        <div className='sm:flex-auto'>
          <p className='mt-2 text-sm text-base-700'>
            Select which traces you would like to include from the table below.
          </p>
        </div>
      </div>
      <div className='mt-8 flow-root px-8'>
        <div className='-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8'>
          <div className='inline-block min-w-full py-2'>
            <table className='min-w-full divide-y divide-base-300'>
              <thead>
                <tr>
                  <th
                    scope='col'
                    className='py-3.5 pl-4 pr-3 text-left text-sm font-medium text-base-600 sm:pl-0 max-w-6'
                  >
                    Email
                  </th>
                  <th
                    scope='col'
                    className='px-3 py-3.5 text-left text-sm font-medium text-base-600'
                  >
                    ID
                  </th>
                  <th
                    scope='col'
                    className='px-3 py-3.5 text-left text-sm font-medium text-base-600'
                  >
                    Status
                  </th>
                  <th
                    scope='col'
                    className='px-3 py-3.5 text-left text-sm font-medium text-base-600'
                  >
                    Name
                  </th>
                  <th
                    scope='col'
                    className='px-3 py-3.5 text-left text-sm font-medium text-base-600'
                  >
                    Type
                  </th>
                </tr>
              </thead>
              <tbody className='divide-y divide-base-200'>
                {traces.map(trace => (
                  <tr
                    key={trace.id}
                    className={
                      initialSelections[trace.id] ? 'bg-white' : 'bg-base-50'
                    }
                  >
                    <td className='whitespace-nowrap p-4 text-sm leading-normal font-regular text-base-900 sm:pl-0 max-w-6'>
                      <Toggle
                        checked={!!initialSelections[trace.id]}
                        onChange={() => handleToggleTrace(trace.id)}
                        disabled={disabledStatuses.includes(trace.status?.id)}
                      />
                    </td>
                    <td className='whitespace-nowrap px-3 py-4 text-sm leading-normal font-regular text-base-900'>
                      {trace.display_id}
                    </td>
                    <td className='whitespace-nowrap px-3 py-4 text-sm leading-normal font-regular text-base-900'>
                      <span className='whitespace-nowrap text-sm leading-normal font-regular text-base-900'>
                        {trace.status?.type}
                      </span>
                    </td>
                    <td className='whitespace-nowrap px-3 py-4 text-sm leading-normal font-regular text-base-900'>
                      {trace.name || '-'}
                    </td>
                    <td className='whitespace-nowrap px-3 py-4 text-sm leading-normal font-regular text-base-900'>
                      {trace.type?.type || '-'}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Quote_QuoteOptions;
