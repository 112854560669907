import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeft, ArrowRight } from 'lucide-react';
import { FilledButton, SoftButton } from '@core/components';
import BasicDetails from './create/BasicDetails';
import AdditionalDetails from './create/AdditionalDetails';
import { supabase } from '../../../utilities/supabase';

const CreateScheme = () => {
  const navigate = useNavigate();

  const [stage, setStage] = useState(1);
  const [schemeData, setSchemeData] = useState({
    name: '',
    status: 87,
    emails: [],
    phones: [],
    signatures: {
      wet: true,
      digital: true,
      docusign: false
    }
  });

  const handleInputChange = (field, value) => {
    setSchemeData(prev => {
      if (field.includes('.')) {
        const [parent, child] = field.split('.');
        return {
          ...prev,
          [parent]: {
            ...prev[parent],
            [child]: value
          }
        };
      }
      return { ...prev, [field]: value };
    });
  };

  const handleSubmit = async (navigateToScheme = false) => {
    try {
      const { data, error } = await supabase
        .from('schemes')
        .insert([
          {
            name: schemeData.name,
            status: schemeData.status || 87,
            emails: schemeData.emails,
            phones: schemeData.phones,
            signatures: schemeData.signatures
          }
        ])
        .select('id');

      if (error) throw error;
      setTimeout(() => {
        const insertedScheme = data[0];
        navigate(
          navigateToScheme ? `/schemes/${insertedScheme.id}` : '/schemes'
        );
      }, 0);
    } catch (error) {
      console.error('Error creating scheme:', error);
    }
  };

  const handleNext = () => {
    setStage(prev => prev + 1);
  };

  const handleBack = () => {
    if (stage === 1) {
      navigate('/schemes');
    } else {
      setStage(prev => prev - 1);
    }
  };

  const handleExitToSchemes = () => {
    navigate('/schemes');
  };

  const stages = [
    {
      title: 'Basic Details',
      render: () => (
        <BasicDetails
          schemeData={schemeData}
          handleInputChange={handleInputChange}
        />
      )
    },
    {
      title: 'Additional Details',
      render: () => (
        <AdditionalDetails
          schemeData={schemeData}
          handleInputChange={handleInputChange}
        />
      )
    }
  ];

  return (
    <div className='bg-base-100 h-full overflow-y-auto'>
      <div className='max-w-4xl mx-auto px-4 py-8'>
        <SoftButton
          colour='primary'
          size='md'
          leftIcon={<ArrowLeft size={18} />}
          onClick={handleExitToSchemes}
          className='mb-8'
        >
          Back to Schemes
        </SoftButton>

        <div className='bg-white shadow-md rounded-lg overflow-visible'>
          <div className='px-6 py-4'>
            <h2 className='text-2xl font-bold'>Create New Scheme</h2>
          </div>

          <div className='p-6'>
            <div className='mb-8'>
              <div className='flex justify-between'>
                {stages.map((step, index) => (
                  <div
                    key={index}
                    className={`flex items-center ${
                      index < stage ? 'text-brand-700' : 'text-base-400'
                    }`}
                  >
                    <div
                      className={`w-8 h-8 rounded-full flex items-center justify-center ${
                        index < stage
                          ? 'bg-brand-700 text-white'
                          : 'bg-base-200'
                      }`}
                    >
                      {index + 1}
                    </div>
                    <span className='ml-2 text-sm font-medium'>
                      {step.title}
                    </span>
                  </div>
                ))}
              </div>
              <div className='mt-4 h-2 bg-base-200 rounded-full'>
                <div
                  className='h-full bg-brand-700 rounded-full transition-all duration-300 ease-in-out'
                  style={{
                    width: `${(stage / stages.length) * 100}%`
                  }}
                ></div>
              </div>
            </div>

            {stages[stage - 1].render()}

            <div className='mt-8 flex justify-between'>
              <SoftButton
                colour='primary'
                size='lg'
                leftIcon={<ArrowLeft size={18} />}
                onClick={handleBack}
                disabled={stage === 1}
              >
                Previous
              </SoftButton>
              {stage < stages.length ? (
                <FilledButton
                  colour='primary'
                  size='lg'
                  rightIcon={<ArrowRight size={18} />}
                  onClick={handleNext}
                  disabled={!schemeData.name.trim()}
                >
                  Next
                </FilledButton>
              ) : (
                <div className='flex flex-row space-x-4'>
                  <FilledButton
                    colour='base'
                    size='lg'
                    onClick={() => handleSubmit(false)}
                  >
                    Finish
                  </FilledButton>
                  <FilledButton
                    colour='primary'
                    size='lg'
                    onClick={() => handleSubmit(true)}
                  >
                    Finish and Open
                  </FilledButton>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateScheme;
