import React, { useState, useEffect, useCallback } from 'react';
import { supabase } from '../../../utilities/supabase';
import { LogoLoader, Popup } from '@core/components';
import StageOne from './StageOne';
import StageRuns from './StageTwo';
import StageMapping from './StageThree';

const DocflowPopup = ({ onClose, entity, entityType, setRefresh }) => {
  const [stage, setStage] = useState(1);
  const [templates, setTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [selectedRun, setSelectedRun] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [categories, setCategories] = useState([]);
  const [runs, setRuns] = useState([]);

  const fetchTemplates = useCallback(async () => {
    try {
      setIsLoading(true);
      const { data, error } = await supabase.functions.invoke(
        'audience/docflows/templates'
      );
      if (error) throw error;
      setTemplates(data);
      console.log(data);
    } catch (error) {
      console.error('Error fetching templates:', error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchTemplates();
  }, [fetchTemplates]);

  const handleTemplateSelect = async template => {
    try {
      setIsLoading(true);

      const { data, error } = await supabase.functions.invoke(
        `audience/docflows/runs`,
        { body: { templateId: template.id } }
      );

      if (error) throw error;

      setSelectedTemplate(template);
      setRuns(data);
      setStage(2);
    } catch (error) {
      console.error('Error fetching template html:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleRunSelect = run => {
    setSelectedRun(run);
    setStage(3);
  };

  const handleBack = () => {
    setStage(stage - 1);
    if (stage === 2) fetchTemplates();
  };

  const renderContent = () => (
    <>
      {isLoading ? (
        <div className='flex justify-center items-center h-96'>
          <LogoLoader
            logo={'/logo-star.png'}
            size='small'
            selfContained={false}
          />
        </div>
      ) : (
        <>
          {stage === 1 && (
            <StageOne
              templates={templates}
              categories={categories}
              onTemplateSelect={handleTemplateSelect}
            />
          )}

          {stage === 2 && selectedTemplate && (
            <StageRuns
              selectedTemplate={selectedTemplate}
              runs={runs}
              setRuns={setRuns}
              onRunSelect={handleRunSelect}
              onBack={handleBack}
            />
          )}

          {stage === 3 && selectedTemplate && selectedRun && (
            <StageMapping
              selectedTemplate={selectedTemplate}
              selectedRun={selectedRun}
              entity={entity}
              onBack={handleBack}
              onClose={onClose}
              entityType={entityType}
              setRefresh={setRefresh}
            />
          )}
        </>
      )}
    </>
  );

  return (
    <Popup
      isOpen={true}
      onClose={onClose}
      title={
        stage === 1
          ? 'Select Template'
          : stage === 2
          ? 'Select or Create Run'
          : 'Map Data'
      }
      size='lg'
    >
      {renderContent()}
    </Popup>
  );
};

export default DocflowPopup;
