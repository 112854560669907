import { createClient } from '@supabase/supabase-js';
import { v4 } from 'uuid';

const url = 'https://jikcvxkcnnyfblqbdmhr.supabase.co';
const anonKey =
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imppa2N2eGtjbm55ZmJscWJkbWhyIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjUwNDE4OTcsImV4cCI6MjA0MDYxNzg5N30.hCIXCgg5Lv6kXMAGkEr-vKpjrDam-zud3DoMTdNGH9s';

const supabase = createClient(url, anonKey, {
  auth: {
    autoRefreshToken: true,
    persistSession: true,
    detectSessionInUrl: false,
    flowType: 'pkce'
  }
});

const realtime = (event = '*', table, callback, filter, filters = {}) => {
  return supabase
    .channel(v4())
    .on(
      'postgres_changes',
      {
        event,
        schema: 'public',
        table,
        filter
      },
      payload => {
        const filtersCheck = Object.entries(filters).every(([key, value]) => {
          return payload.new[key] == value;
        });

        if (filtersCheck) callback(payload);
      }
    )
    .subscribe();
};

export { supabase, realtime };
