import { Building, CheckCircle, Plus, X, Link as LinkIcon } from 'lucide-react';
import Entity from '../../../components/entity/Entity';
import Company from '../../../models/Company';
import DateCard from '../../../components/entity/DateCard';
import CoreEntityTypes from '../../../models/CoreEntityType';
import { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { EntityTypeEnum } from '../../../utilities/Enumerables';
import Providers from './tabs/Providers';
import CompanySchemes from './tabs/Schemes';
import CompanyAddresses from './tabs/Addresses';
import CompanySearches from './tabs/Searches';
import CompanyTraces from './tabs/Traces';
import CompanyPensions from './tabs/Pensions';
import CompanyUsers from './tabs/Users';
import {
  Input,
  SoftButton,
  ComboBox,
  Modal,
  FilledButton
} from '@core/components';
import { supabase } from '../../../utilities/supabase';

const CompanyDetails = ({ currentUser }) => {
  const { id } = useParams();
  const [statusOptions, setStatusOptions] = useState([]);
  const [previousName, setPreviousName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [linkedCompany, setLinkedCompany] = useState(null);
  const [linkedCompanyNames, setLinkedCompanyNames] = useState({});
  const [isUnlinkModalOpen, setIsUnlinkModalOpen] = useState(false);
  const [companyToUnlink, setCompanyToUnlink] = useState(null);

  useEffect(() => {
    // Set the document title
    document.title = `Company #${id} | CRM | PTS`;
    const fetchOptions = async () => {
      try {
        const options = await CoreEntityTypes.getByEntityId(
          EntityTypeEnum.CompanyStatus
        );

        const formattedOptions = options.map(option => ({
          label: option.type,
          value: option.id
        }));

        setStatusOptions(formattedOptions);
      } catch (error) {
        console.error('Error fetching status options:', error);
      }
    };

    fetchOptions();
  }, []);

  const loadCompanyOptions = async query => {
    const { data, error } = await supabase
      .from('companies')
      .select('id, name')
      .ilike('name', `%${query}%`)
      .neq('id', id) // Exclude current company
      .limit(10);

    if (error) {
      console.error('Error loading companies:', error);
      return [];
    }

    return data.map(company => ({
      label: company.name,
      value: company.id
    }));
  };

  const handleLinkCompany = async (selectedCompany, onUpdate) => {
    try {
      console.log('Linking company:', selectedCompany);

      // Get all linked companies for both current and selected company
      const { data: linkedCompanies } = await supabase
        .from('companies')
        .select('id, companies')
        .in('id', [id, selectedCompany.value]);

      console.log('Fetched linked companies:', linkedCompanies);

      if (!linkedCompanies) return;

      // Get all unique company IDs that should be linked
      const allCompanyIds = new Set();
      linkedCompanies.forEach(company => {
        if (company.companies) {
          company.companies.forEach(id => allCompanyIds.add(id));
        }
      });
      allCompanyIds.add(parseInt(id));
      allCompanyIds.add(selectedCompany.value);

      console.log('All company IDs to link:', Array.from(allCompanyIds));

      // For each company, update their links to include all other companies
      const updates = Array.from(allCompanyIds).map(companyId => {
        const otherCompanyIds = Array.from(allCompanyIds).filter(
          otherId => otherId !== companyId
        );

        console.log(
          `Updating company ${companyId} with links:`,
          otherCompanyIds
        );

        return supabase
          .from('companies')
          .update({ companies: otherCompanyIds })
          .eq('id', companyId);
      });

      await Promise.all(updates);

      // Update current company's state to show new links
      const currentCompanyLinks = Array.from(allCompanyIds).filter(
        companyId => companyId !== parseInt(id)
      );
      console.log('Updated current company links:', currentCompanyLinks);
      onUpdate('companies', currentCompanyLinks);
      setLinkedCompany(null);
    } catch (error) {
      console.error('Error linking companies:', error);
    }
  };

  const handleUnlinkCompany = async (companyObject, index, onUpdate, data) => {
    setCompanyToUnlink({ companyObject, index, onUpdate, data });
    setIsUnlinkModalOpen(true);
  };

  const confirmUnlinkCompany = async () => {
    const { companyObject, index, onUpdate, data } = companyToUnlink;
    try {
      // Get all currently linked companies
      const { data: linkedCompanies } = await supabase
        .from('companies')
        .select('id, companies')
        .in('id', [id, companyObject.id]);

      if (!linkedCompanies) return;

      // Collect all company IDs that are linked to the current and unlinked company
      const allLinkedCompanyIds = new Set();
      linkedCompanies.forEach(company => {
        if (company.companies) {
          company.companies.forEach(linkId => allLinkedCompanyIds.add(linkId));
        }
      });

      // Remove the unlinked company from each company's links
      const updates = Array.from(allLinkedCompanyIds).map(linkedCompanyId => {
        const updatedLinks = Array.from(allLinkedCompanyIds).filter(
          linkId => linkId !== companyObject.id && linkId !== parseInt(id)
        );

        return supabase
          .from('companies')
          .update({ companies: updatedLinks })
          .eq('id', linkedCompanyId);
      });

      await Promise.all(updates);

      // Update local state with just company ids
      const updatedCompanyIds = data.companies
        .map(company => company.id)
        .filter((_, i) => i !== index);
      onUpdate('companies', updatedCompanyIds);
    } catch (error) {
      console.error('Error unlinking companies:', error);
    } finally {
      setIsUnlinkModalOpen(false);
      setCompanyToUnlink(null);
    }
  };

  const infoItems = [
    {
      icon: Building,
      label: 'Name',
      field: 'name',
      dataType: 'text',
      isEditable: true
    },
    {
      icon: CheckCircle,
      label: 'Status',
      displayField: 'status.type',
      editField: 'status.id',
      dataType: 'select',
      isEditable: true,
      options: statusOptions
    },
    {
      icon: Building,
      label: 'Company Number',
      field: 'number',
      dataType: 'text',
      isEditable: true
    }
  ];

  const accordionItems = [
    {
      title: 'Key Dates',
      content: ({ data }) => (
        <div className='grid grid-cols-1 md:grid-cols-3 gap-2'>
          <DateCard date={data.createdDate} label='Created' />
          <DateCard date={data.updatedDate} label='Updated' />
        </div>
      )
    },
    {
      title: 'Linked Companies',
      content: ({ data, onUpdate }) => (
        <div>
          <div className='flex items-center gap-2 mb-2'>
            <ComboBox
              loadOptions={loadCompanyOptions}
              value={linkedCompany}
              onChange={setLinkedCompany}
              placeholder='Search for a company to link'
              className='flex-1'
            />
            <SoftButton
              onClick={() => handleLinkCompany(linkedCompany, onUpdate)}
              disabled={!linkedCompany}
            >
              <LinkIcon className='w-4 h-4 mr-2' />
              Link
            </SoftButton>
          </div>
          {data.companies?.map((companyObject, index) => (
            <div key={index} className='flex items-center gap-2 group'>
              <div className='bg-gray-50 border border-gray-200 rounded w-full flex justify-between items-center mb-2 p-1 text-sm text-base-600'>
                <Link
                  to={`/companies/${companyObject?.id}`}
                  className='hover:underline'
                >
                  {companyObject?.name}
                </Link>
                <div
                  className='opacity-0 group-hover:opacity-100 transition-opacity text-danger-500 cursor-pointer'
                  onClick={() =>
                    handleUnlinkCompany(companyObject, index, onUpdate, data)
                  }
                >
                  <X className='h-5 w-5 mr-1'></X>
                </div>
              </div>
            </div>
          ))}
        </div>
      )
    },
    {
      title: 'Contact Details',
      content: ({ data, onUpdate }) => (
        <div className='space-y-4'>
          <div>
            <h3 className='font-medium mb-2'>Email Addresses</h3>
            <div className='flex items-center gap-2 mb-2'>
              <Input
                placeholder='Enter email address'
                value={email}
                onChange={e => setEmail(e.target.value)}
              />
              <SoftButton
                onClick={() => {
                  const updatedEmails = [...data.emails, email];
                  onUpdate('emails', updatedEmails);
                  setEmail('');
                }}
              >
                <Plus />
              </SoftButton>
            </div>
            {data.emails?.map((email, index) => (
              <div key={index} className='flex items-center gap-2 group'>
                <div className='bg-gray-100 rounded w-full flex justify-between items-center mb-2 p-1'>
                  <span>{email}</span>
                  <div
                    className='opacity-0 group-hover:opacity-100 transition-opacity text-danger-500 cursor-pointer'
                    onClick={() => {
                      const updatedEmails = data.emails.filter(
                        (_, i) => i !== index
                      );
                      onUpdate('emails', updatedEmails);
                    }}
                  >
                    <X className='h-5 w-5 mr-1'></X>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div>
            <h3 className='font-medium mb-2'>Phone Numbers</h3>
            <div className='flex items-center gap-2 mb-2'>
              <Input
                placeholder='Enter phone number'
                value={phone}
                onChange={e => setPhone(e.target.value)}
              />
              <SoftButton
                onClick={() => {
                  const updatedPhones = [...data.phones, phone];
                  onUpdate('phones', updatedPhones);
                  setPhone('');
                }}
              >
                <Plus />
              </SoftButton>
            </div>
            {data.phones?.map((phone, index) => (
              <div key={index} className='flex items-center gap-2 group'>
                <div className='bg-gray-100 rounded w-full flex justify-between items-center mb-2 p-1'>
                  <span>{phone}</span>
                  <div
                    className='opacity-0 group-hover:opacity-100 transition-opacity text-danger-500 cursor-pointer'
                    onClick={() => {
                      const updatedPhones = data.phones.filter(
                        (_, i) => i !== index
                      );
                      onUpdate('phones', updatedPhones);
                    }}
                  >
                    <X className='h-5 w-5 mr-1'></X>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )
    },
    {
      title: 'Previous Names',
      content: ({ data, onUpdate }) => (
        <div>
          <div className='flex items-center gap-2 mb-2'>
            <Input
              placeholder='Enter previous name'
              value={previousName}
              onChange={e => setPreviousName(e.target.value)}
            />
            <SoftButton
              onClick={() => {
                const updatedNames = [...data.previousNames, previousName];
                onUpdate('previousNames', updatedNames);
                setPreviousName('');
              }}
            >
              <Plus />
            </SoftButton>
          </div>
          {data.previousNames?.map((name, index) => (
            <div key={index} className='flex items-center gap-2 group'>
              <div className='bg-gray-100 rounded w-full flex justify-between items-center mb-2 p-1'>
                <span>{name}</span>
                <div
                  className='opacity-0 group-hover:opacity-100 transition-opacity text-danger-500 cursor-pointer'
                  onClick={() => {
                    const updatedNames = data.previousNames.filter(
                      (_, i) => i !== index
                    );
                    onUpdate('previousNames', updatedNames);
                  }}
                >
                  <X className='h-5 w-5 mr-1'></X>
                </div>
              </div>
            </div>
          ))}
        </div>
      )
    }
  ];

  const additionalTabs = [
    {
      label: 'Addresses',
      path: 'addresses',
      content: <CompanyAddresses companyId={id} />
    },
    {
      label: 'Providers',
      path: 'providers',
      content: <Providers companyId={id} />
    },
    {
      label: 'Schemes',
      path: 'schemes',
      content: <CompanySchemes companyId={id} />
    },
    {
      label: 'Traces',
      path: 'traces',
      content: <CompanyTraces companyId={id} />
    },
    {
      label: 'Searches',
      path: 'searches',
      content: <CompanySearches companyId={id} />
    },
    {
      label: 'Pensions',
      path: 'pensions',
      content: <CompanyPensions companyId={id} />
    },
    {
      label: 'Users',
      path: 'users',
      content: <CompanyUsers companyId={id} />
    }
  ];

  return (
    <>
      <Entity
        currentUser={currentUser}
        entityType={EntityTypeEnum.Company}
        model={Company}
        infoItems={infoItems}
        accordionItems={accordionItems}
        additionalTabs={additionalTabs}
      />
      <Modal
        isOpen={isUnlinkModalOpen}
        onClose={() => setIsUnlinkModalOpen(false)}
        title={<h2 className='text-2xl'>Unlink Company</h2>}
      >
        <p>
          This action will permanently unlink the company which will permantly
          effect the algorithm. Are you sure you want to proceed?
        </p>
        <div className='flex justify-end space-x-2 mt-4'>
          <SoftButton onClick={() => setIsUnlinkModalOpen(false)}>
            Cancel
          </SoftButton>
          <FilledButton onClick={confirmUnlinkCompany} colour='danger'>
            Confirm
          </FilledButton>
        </div>
      </Modal>
    </>
  );
};

export default CompanyDetails;
