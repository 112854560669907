import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeft, ArrowRight } from 'lucide-react';
import UserProfile from '../../../models/UserProfile';
import CoreEntityType from '../../../models/CoreEntityType';
import { EntityTypeEnum } from '../../../utilities/Enumerables';
import { FilledButton, SoftButton } from '@core/components';
import { UserProfileContext } from '../../../App';
import BasicDetails from './create/BasicDetails';
import AdditionalDetails from './create/AdditionalDetails';

const CreateUser = () => {
  const navigate = useNavigate();
  const { userProfile } = useContext(UserProfileContext);

  const [stage, setStage] = useState(1);
  const [userProfileData, setUserProfileData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    title: '',
    nationalInsurance: '',
    dateOfBirth: '',
    previousNames: [],
    segmentation: '',
    marketing: {
      post: false,
      text: false,
      email: false
    }
  });
  const [disabled, setDisabled] = useState(false);

  const handleInputChange = (field, value) => {
    setUserProfileData(prev => {
      // Handle nested fields like marketing.post
      if (field.includes('.')) {
        const [parent, child] = field.split('.');
        return {
          ...prev,
          [parent]: {
            ...prev[parent],
            [child]: value
          }
        };
      }
      return { ...prev, [field]: value };
    });
  };

  const handleSubmit = async (navigateToUserProfile = false) => {
    try {
      const newUserProfile = new UserProfile({
        first_name: userProfileData.firstName,
        last_name: userProfileData.lastName,
        email: userProfileData.email,
        title: { id: userProfileData.title || 92 },
        national_insurance: userProfileData.nationalInsurance,
        date_of_birth: userProfileData.dateOfBirth,
        previous_names: userProfileData.previousNames,
        segmentation: { id: userProfileData.segmentation || 86 },
        marketing: userProfileData.marketing,
        role: { id: 1 },
        status: 1,
        tags: [{ id: 21 }, { id: 7 }]
      });

      const insertedUserProfile = await newUserProfile.insert();

      if (navigateToUserProfile) {
        navigate(`/user-profiles/${insertedUserProfile.displayId}`);
      } else {
        navigate('/user-profiles');
      }
    } catch (error) {
      console.error('Error creating user profile:', error);
    }
  };

  const handleNext = () => {
    setStage(prev => prev + 1);
  };

  const handleBack = () => {
    if (stage === 1) {
      navigate('/user-profiles');
    } else {
      setStage(prev => prev - 1);
    }
  };

  const handleExitToUserProfiles = () => {
    navigate('/user-profiles');
  };

  const stages = [
    {
      title: 'Basic Details',
      render: () => (
        <BasicDetails
          userProfileData={userProfileData}
          handleInputChange={handleInputChange}
          setDisabled={setDisabled}
        />
      )
    },
    {
      title: 'Additional Details',
      render: () => (
        <AdditionalDetails
          userProfileData={userProfileData}
          handleInputChange={handleInputChange}
        />
      )
    }
  ];

  // Check if first page fields are valid
  const isFirstPageValid = () => {
    return (
      userProfileData.title &&
      userProfileData.firstName.trim() &&
      userProfileData.lastName.trim() &&
      userProfileData.email.trim() &&
      !disabled
    );
  };

  return (
    <div className='bg-base-100 h-full overflow-y-auto'>
      <div className='max-w-4xl mx-auto px-4 py-8'>
        <SoftButton
          colour='primary'
          size='md'
          leftIcon={<ArrowLeft size={18} />}
          onClick={handleExitToUserProfiles}
          className='mb-8'
        >
          Back to User Profiles
        </SoftButton>

        <div className='bg-white shadow-md rounded-lg overflow-visible'>
          <div className='px-6 py-4'>
            <h2 className='text-2xl font-bold'>Create New User Profile</h2>
          </div>

          <div className='p-6'>
            <div className='mb-8'>
              <div className='flex justify-between'>
                {stages.map((step, index) => (
                  <div
                    key={index}
                    className={`flex items-center ${
                      index < stage ? 'text-brand-700' : 'text-base-400'
                    }`}
                  >
                    <div
                      className={`w-8 h-8 rounded-full flex items-center justify-center ${
                        index < stage
                          ? 'bg-brand-700 text-white'
                          : 'bg-base-200'
                      }`}
                    >
                      {index + 1}
                    </div>
                    <span className='ml-2 text-sm font-medium'>
                      {step.title}
                    </span>
                  </div>
                ))}
              </div>
              <div className='mt-4 h-2 bg-base-200 rounded-full'>
                <div
                  className='h-full bg-brand-700 rounded-full transition-all duration-300 ease-in-out'
                  style={{
                    width: `${(stage / stages.length) * 100}%`
                  }}
                ></div>
              </div>
            </div>

            {stages[stage - 1].render()}

            <div className='mt-8 flex justify-between'>
              <SoftButton
                colour='primary'
                size='lg'
                leftIcon={<ArrowLeft size={18} />}
                onClick={handleBack}
                disabled={stage === 1}
              >
                Previous
              </SoftButton>
              {stage < stages.length ? (
                <FilledButton
                  colour='primary'
                  size='lg'
                  rightIcon={<ArrowRight size={18} />}
                  onClick={handleNext}
                  disabled={stage === 1 ? !isFirstPageValid() : disabled}
                >
                  Next
                </FilledButton>
              ) : (
                <div className='flex flex-row space-x-4'>
                  <FilledButton
                    colour='base'
                    size='lg'
                    onClick={() => handleSubmit(false)}
                  >
                    Finish
                  </FilledButton>
                  <FilledButton
                    colour='primary'
                    size='lg'
                    onClick={() => handleSubmit(true)}
                  >
                    Finish and Open
                  </FilledButton>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateUser;
