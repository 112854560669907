import BaseModel from './BaseModel';

/**
 * Represents a Document in the system.
 * @extends BaseModel
 */
class Document extends BaseModel {
  /** @type {string} The name of the database table for Documents */
  static table = 'documents';

  /** @type {string} The SQL query to select Document data with related information */
  static selectQuery = `
    *,
    type: core_entity_types(*),
    owner:owner!inner (
      id,
      first_name,
      last_name,
      full_name,
      profile_photo
    )
  `;

  /**
   * Creates an instance of Document.
   * @param {Object} data - The document data.
   */
  constructor(data = {}) {
    super(data);
    this.owner = data.owner
      ? {
          id: data.owner.id,
          firstName: data.owner?.first_name,
          lastName: data.owner?.last_name,
          profilePhoto: data.owner?.profile_photo
        }
      : null;
    this.fileName = data.file_name || null;
    this.filePath = data.file_path || null;
    this.fileType = data.file_type || null;
    this.connections = data.connections || [];
    this.displayId = data.display_id; // Added display ID
    this.type = data.type || null;
  }

  /**
   * Converts the Document instance to a database-friendly format.
   * @returns {Object} The document data ready for database operations.
   */
  toDatabase() {
    return {
      ...super.toDatabase(),
      owner: this.owner ? this.owner.id : null,
      file_name: this.fileName,
      file_path: this.filePath,
      file_type: this.fileType,
      connections: this.connections,
      display_id: this.displayId,
      type: this.type ? this.type.id : null
    };
  }
}

export default Document;
