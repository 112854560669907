import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeft, ExternalLink, Check, Upload } from 'lucide-react';
import { TextButton, Badge, Field, useNotification } from '@core/components';
import axios from 'axios';
import Accordion from './Accordion';
import CoreEntity from '../../models/CoreEntity';
import { UserProfileContext } from '../../App';
import { EntityTypeEnum } from '../../utilities/Enumerables';
import { supabase } from '../../utilities/supabase';

const EntityCard = ({
  entityId,
  entityType,
  infoItems,
  accordionItems,
  data,
  onUpdate
}) => {
  const navigate = useNavigate();
  const { addNotification } = useNotification();

  const [showCopySuccess, setShowCopySuccess] = useState(false);
  const [coreEntity, setCoreEntity] = useState({});
  const userProfile = React.useContext(UserProfileContext);
  const [profileImage, setProfileImage] = useState(data?.profilePhoto || null);

  useEffect(() => {
    const fetchEntityType = async () => {
      try {
        const coreEntity = await CoreEntity.getById(entityType);
        setCoreEntity(coreEntity);
      } catch (error) {
        console.error('Error fetching entity type:', error);
        setCoreEntity('Entity');
      }
    };

    fetchEntityType();
  }, [entityType]);

  const handleCopyId = () => {
    navigator.clipboard.writeText(entityId);
    setShowCopySuccess(true);
    setTimeout(() => {
      setShowCopySuccess(false);
    }, 1000);
  };

  const getNestedValue = (obj, path) => {
    if (!path) return null;

    return path.split('.').reduce((acc, part) => acc && acc[part], obj);
  };

  const handleImageUpload = async e => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > 1024 * 1024) {
        // 1MB in bytes
        addNotification({
          title: 'Error',
          description: 'Image file size must be less than 1MB',
          type: 'error'
        });
        return;
      }

      const uploadNotificationId = addNotification({
        title: 'Uploading profile photo...',
        description: '0%',
        type: 'info',
        duration: null
      });

      try {
        const { data: signedUrlData, error: signedUrlError } =
          await supabase.functions.invoke('storage/getSigned/upload', {
            body: {
              fileName: `${entityId}`,
              originalFileName: file.name,
              destinationFolder: 'profiles'
            }
          });

        if (signedUrlError) throw signedUrlError;

        await axios.put(signedUrlData.url, file, {
          headers: {
            'Content-Type': file.type
          },
          onUploadProgress: progressEvent => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            addNotification({
              id: uploadNotificationId,
              title: 'Uploading profile photo...',
              description: `${percentCompleted}%`,
              type: 'info',
              duration: null
            });
          }
        });

        const STORAGE_BASE_URL =
          'https://storage.googleapis.com/pensiontracingservice/';
        setProfileImage(STORAGE_BASE_URL + signedUrlData.file.key);
        await onUpdate(
          'profilePhoto',
          STORAGE_BASE_URL + signedUrlData.file.key
        );

        addNotification({
          id: uploadNotificationId,
          title: 'Success',
          description: 'Profile photo uploaded successfully',
          type: 'success'
        });
      } catch (error) {
        console.error('Error uploading profile photo:', error);
        addNotification({
          id: uploadNotificationId,
          title: 'Error',
          description: 'Failed to upload profile photo',
          type: 'error'
        });
      }
    }
  };

  const handleRemovePhoto = async () => {
    try {
      await onUpdate('profilePhoto', null);
      setProfileImage(null);
      addNotification({
        title: 'Success',
        description: 'Profile photo removed successfully',
        type: 'success'
      });
    } catch (error) {
      console.error('Error removing profile photo:', error);
      addNotification({
        title: 'Error',
        description: 'Failed to remove profile photo',
        type: 'error'
      });
    }
  };

  return (
    <div className='bg-white dark:bg-base-900 border-r border-neutral-200 h-full overflow-y-auto flex flex-col w-full'>
      <div className='p-2 flex justify-between items-center mb-2'>
        <div className='flex items-center'>
          <TextButton
            onClick={() => {
              let path = `/${coreEntity.tableName.replaceAll('_', '-')}`;
              if (path.includes('/import-')) {
                path = `${path.replace('-', 's/')}`;
              }
              navigate(path);
            }}
            className='mr-3'
            leftIcon={<ArrowLeft size={20} />}
            colour='base'
          />
          <h3 className='font-regular m-0'>{coreEntity.displayName}</h3>
          <div className='cursor-pointer ml-2'>
            <Badge
              colour={showCopySuccess ? 'success' : 'primary'}
              size='sm'
              rightIcon={showCopySuccess ? Check : null}
              onClick={handleCopyId}
            >
              {showCopySuccess ? 'Copied!' : `ID: ${entityId}`}
            </Badge>
          </div>
        </div>
        <div className='flex items-center'>
          <TextButton
            onClick={() => window.open(window.location.href, '_blank')}
            className='hover:text-brand-700'
            leftIcon={<ExternalLink size={20} />}
            colour='base'
          />
        </div>
      </div>
      <div className='px-4'>
        {infoItems.map((item, index) => (
          <Field
            key={index}
            Icon={item.icon}
            label={item.label}
            value={getNestedValue(data, item.displayField || item.field)}
            valueMetadata={
              item.valueMetadata ? item.valueMetadata(data) : undefined
            }
            displayField={item.displayField || item.field}
            editField={item.editField || item.field}
            dataType={item.dataType}
            options={item.options}
            loadOptions={item.loadOptions}
            isEditable={item.isEditable}
            onUpdate={onUpdate}
            inlineLabel={item.inlineLabel}
            inlineEditor={item.inlineEditor}
            extension={item.extension}
            userProfile={userProfile}
            valueResolver={item.valueResolver}
            onlyExtension={item.onlyExtension}
            editObject={getNestedValue(data, item.editObject)}
            editObjectName={item.editObject}
            displayLink={item.displayLink ? item.displayLink(data) : undefined}
            data={data}
            onChange={item.onChange}
          />
        ))}
      </div>
      <div className='mt-2'>
        <Accordion items={accordionItems} data={data} onUpdate={onUpdate} />
      </div>
    </div>
  );
};

export default EntityCard;
