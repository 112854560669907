import React, { useState, useEffect, useContext } from 'react';
import {
  Popup,
  FilledButton,
  TextButton,
  Input,
  DatePicker,
  TimePicker,
  DropdownInput,
  Badge,
  TextEditor,
  MultiSelectBox
} from '@core/components';
import {
  MapPin,
  Video,
  Calendar,
  Clock,
  ArrowRight,
  Users,
  Tag,
  Type
} from 'lucide-react';
import CoreEntityTypes from '../../../models/CoreEntityType';
import { EntityTypeEnum } from '../../../utilities/Enumerables';
import Meeting from '../../../models/Meeting';
import { UserProfileContext } from '../../../App';
import { supabase } from '../../../utilities/supabase';

const CreateMeeting = ({ isOpen, onClose, entityType, entityId }) => {
  const { userProfile: currentUser } = useContext(UserProfileContext);

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [startDate, setStartDate] = useState(() => {
    const now = new Date();
    now.setMinutes(Math.ceil(now.getMinutes() / 30) * 30);
    now.setSeconds(0);
    now.setMilliseconds(0);
    return now;
  });
  const [startTime, setStartTime] = useState(() => {
    const now = new Date();
    now.setMinutes(Math.ceil(now.getMinutes() / 30) * 30);
    return `${now.getHours().toString().padStart(2, '0')}:${now
      .getMinutes()
      .toString()
      .padStart(2, '0')}`;
  });
  const [endDate, setEndDate] = useState(() => {
    const end = new Date(startDate);
    end.setHours(end.getHours() + 1);
    return end;
  });
  const [endTime, setEndTime] = useState(() => {
    const end = new Date(startDate);
    end.setHours(end.getHours() + 1);
    return `${end.getHours().toString().padStart(2, '0')}:${end
      .getMinutes()
      .toString()
      .padStart(2, '0')}`;
  });
  const [category, setCategory] = useState(null);
  const [attendees, setAttendees] = useState([]);
  const [location, setLocation] = useState('');
  const [meetingLink, setMeetingLink] = useState('');
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    fetchCategories();
  }, []);

  const updateEndDateTime = (date, time) => {
    const dateObj = new Date(date);
    const [hours, minutes] = time.split(':');
    dateObj.setHours(parseInt(hours), parseInt(minutes));
    dateObj.setHours(dateObj.getHours() + 1);

    setEndDate(dateObj);
    setEndTime(
      `${dateObj.getHours().toString().padStart(2, '0')}:${dateObj
        .getMinutes()
        .toString()
        .padStart(2, '0')}`
    );
  };

  const updateStartDateTime = (date, time) => {
    const dateObj = new Date(date);
    const [hours, minutes] = time.split(':');
    dateObj.setHours(parseInt(hours), parseInt(minutes));
    dateObj.setHours(dateObj.getHours() - 1);

    setStartDate(dateObj);
    setStartTime(
      `${dateObj.getHours().toString().padStart(2, '0')}:${dateObj
        .getMinutes()
        .toString()
        .padStart(2, '0')}`
    );
  };

  const handleStartDateChange = newDate => {
    setStartDate(newDate);
    updateEndDateTime(newDate, startTime);
  };

  const handleStartTimeChange = newTime => {
    setStartTime(newTime);
    updateEndDateTime(startDate, newTime);
  };

  const handleEndDateChange = newDate => {
    setEndDate(newDate);
    updateStartDateTime(newDate, endTime);
  };

  const handleEndTimeChange = newTime => {
    setEndTime(newTime);
    updateStartDateTime(endDate, newTime);
  };

  const fetchCategories = async () => {
    try {
      const fetchedCategories = await CoreEntityTypes.getByEntityId(
        EntityTypeEnum.MeetingCategory
      );
      setCategories(
        fetchedCategories.map(cat => ({
          id: cat.id,
          name: cat.type,
          color: cat.metadata.color || 'gray',
          isVirtual: cat.metadata.isVirtual || false
        }))
      );
    } catch (error) {
      console.error('Error fetching meeting categories:', error);
    }
  };

  const fetchAdminUsers = async (search = '') => {
    try {
      const query = supabase.from('user_profiles').select('*').eq('role', 1);

      if (search) {
        query.textSearch('fts', search);
      }

      const { data: users, error } = await query;

      if (error) throw error;
      return users.map(user => ({
        value: user.id,
        label: `${user.first_name} ${user.last_name}`
      }));
    } catch (error) {
      console.error('Error fetching admin users:', error);
      return [];
    }
  };

  const handleSubmit = async e => {
    e.preventDefault();
    try {
      const combinedStartDate = new Date(startDate);
      const [startHours, startMinutes] = startTime.split(':');
      combinedStartDate.setHours(parseInt(startHours), parseInt(startMinutes));

      const combinedEndDate = new Date(endDate);
      const [endHours, endMinutes] = endTime.split(':');
      combinedEndDate.setHours(parseInt(endHours), parseInt(endMinutes));

      const meetingData = {
        title,
        description,
        start_date: combinedStartDate,
        end_date: combinedEndDate,
        category: { id: category?.id },
        attendees: attendees.map(a => a.value),
        location: category?.isVirtual ? meetingLink : location,
        entity_type: { id: entityType },
        entity_id: entityId,
        status: 106,
        owner: currentUser
      };

      console.log(meetingData);

      const newMeeting = new Meeting(meetingData);
      await newMeeting.insert();

      onClose();
    } catch (error) {
      console.error('Error creating meeting:', error);
    }
  };

  return (
    <Popup
      isOpen={isOpen}
      onClose={onClose}
      title='Create New Meeting'
      size='md'
    >
      <form onSubmit={handleSubmit} className='space-y-6'>
        <div className='grid grid-cols-1 gap-6'>
          <Input
            value={title}
            onChange={e => setTitle(e.target.value)}
            placeholder='Enter meeting title'
            leftIcon={Type}
          />
          <TextEditor
            value={description}
            onChange={setDescription}
            placeholder='Enter meeting description'
          />
          <div className='flex items-center space-x-2'>
            <DatePicker
              value={startDate}
              onChange={handleStartDateChange}
              placeholder='Start date'
              size='md'
            />
            <TimePicker
              value={startTime}
              onChange={handleStartTimeChange}
              placeholder='Start time'
              size='md'
            />
            <ArrowRight
              size={20}
              className='w-6 h-6 align-center text-base-400'
            />
            <DatePicker
              value={endDate}
              onChange={handleEndDateChange}
              placeholder='End date'
              size='md'
            />
            <TimePicker
              value={endTime}
              onChange={handleEndTimeChange}
              placeholder='End time'
              size='md'
            />
          </div>
          <DropdownInput
            options={categories.map(category => ({
              value: category.id,
              label: (
                <div className='flex items-center'>
                  <Badge colour={category.color} className='mr-2'>
                    {category.name}
                  </Badge>
                </div>
              )
            }))}
            value={category?.id}
            onChange={e => {
              const selectedCategory = categories.find(
                c => c.id == e.target.value
              );
              setCategory(selectedCategory);
            }}
            placeholder='Select a category'
          />
          <MultiSelectBox
            selectedOptions={attendees}
            loadOptions={fetchAdminUsers}
            onChange={setAttendees}
            placeholder='Select Attendees'
          />
          {category?.isVirtual ? (
            <Input
              value={meetingLink}
              onChange={e => setMeetingLink(e.target.value)}
              placeholder='Enter virtual meeting link'
              leftIcon={Video}
            />
          ) : (
            <Input
              value={location}
              onChange={e => setLocation(e.target.value)}
              placeholder='Enter meeting location'
              leftIcon={MapPin}
            />
          )}
        </div>
        <div className='flex justify-end space-x-2 pt-4'>
          <TextButton onClick={onClose} colour='base'>
            Cancel
          </TextButton>
          <FilledButton
            type='submit'
            colour='primary'
            disabled={!title?.trim()}
          >
            Create Meeting
          </FilledButton>
        </div>
      </form>
    </Popup>
  );
};

export default CreateMeeting;
