import React from 'react';
import { Link, useRouteError } from 'react-router-dom';
import { ChevronRight, Bookmark, LifeBuoy, ArrowLeft } from 'lucide-react';

const links = [
  {
    name: 'Guides',
    href: '#',
    description: 'Read our helpful guides and documentation',
    icon: Bookmark
  },
  {
    name: 'Support',
    href: '/support',
    description:
      'For persistent issues or further assistance, please contact our support team.',
    icon: LifeBuoy
  }
];

const Error = () => {
  const error = useRouteError();

  return (
    <div className='bg-white'>
      <main className='mx-auto w-full max-w-7xl px-6 pb-16 pt-10 sm:pb-24 lg:px-8'>
        <img
          alt='Your Company'
          src='/logo-star.png'
          className='mx-auto h-10 w-auto sm:h-12'
        />
        <div className='mx-auto mt-20 max-w-2xl text-center sm:mt-24'>
          <p className='text-base font-semibold leading-8 text-brand-600'>
            {error.code}
          </p>
          <h1 className='mt-4 text-3xl font-bold tracking-tight text-neutral-900 sm:text-5xl'>
            An unexpected error ocurred.
          </h1>
          <p className='mt-4 text-base leading-7 text-neutral-600 sm:mt-6 sm:text-lg sm:leading-8'>
            {error.statusText ||
              error.message ||
              'We apologize for the inconvenience. An unexpected error has occurred.'}
          </p>
        </div>
        <div className='mx-auto mt-16 flow-root max-w-lg sm:mt-20'>
          <h2 className='sr-only'>Popular pages</h2>
          <ul
            role='list'
            className='-mt-6 divide-y divide-neutral-900/5 border-b border-neutral-900/5'
          >
            {links.map((link, linkIdx) => (
              <li key={linkIdx} className='relative flex gap-x-6 py-6'>
                <div className='flex h-10 w-10 flex-none items-center justify-center rounded-lg shadow-sm ring-1 ring-neutral-900/10'>
                  <link.icon
                    aria-hidden='true'
                    className='h-6 w-6 text-brand-600'
                  />
                </div>
                <div className='flex-auto'>
                  <h3 className='text-sm font-semibold leading-6 text-neutral-900'>
                    <a href={link.href}>
                      <span aria-hidden='true' className='absolute inset-0' />
                      {link.name}
                    </a>
                  </h3>
                  <p className='mt-2 text-sm leading-6 text-neutral-600'>
                    {link.description}
                  </p>
                </div>
                <div className='flex-none self-center'>
                  <ChevronRight
                    aria-hidden='true'
                    className='h-5 w-5 text-neutral-400'
                  />
                </div>
              </li>
            ))}
          </ul>
          <div className='mt-10 flex justify-center'>
            <Link
              to={-1}
              className='text-sm font-semibold leading-6 text-brand-600'
            >
              <ArrowLeft className='inline-block mr-1 h-4 w-4' />
              Back to previous page
            </Link>
          </div>
          <div className='mt-10 flex justify-center'>
            <Link
              to='/'
              className='text-sm font-semibold leading-6 text-brand-600'
            >
              <ArrowLeft className='inline-block mr-1 h-4 w-4' />
              Back to home
            </Link>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Error;
