import { useState, useEffect, useRef } from 'react';
import Logo from '../../assets/logo.png';
import {
  Settings,
  Menu as MenuIcon,
  X,
  Bug,
  CircleHelp,
  GitBranch
} from 'lucide-react';
import { supabase } from '../../utilities/supabase';
import { useNavigate, useLocation } from 'react-router-dom';
import { FlatButton, UserProfilePicture } from '@core/components';
import { useContext } from 'react';
import { UserProfileContext } from '../../App';
import UserProfile from '../../models/UserProfile';
import { Sentry } from '../../utilities/sentry';
import GlobalSearch from '../globalsearch/GlobalSearch';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';

const Header = ({ isDarkMode, setIsDarkMode, sidebarItems }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const menuRef = useRef(null);
  const drawerRef = useRef(null);
  const { userProfile: currentUser, setUserProfile } =
    useContext(UserProfileContext) || {};

  const branches = [
    {
      name: 'Dev',

      url: 'http://localhost:5173',

      bgColor: 'bg-warning-100',

      textColor: 'text-warning-800'
    },

    {
      name: 'Beta',

      url: 'https://beta.crm.pensiontracingservice.com',

      bgColor: 'bg-info-100',

      textColor: 'text-info-800'
    },

    {
      name: 'Live',

      url: 'https://crm.pensiontracingservice.com',

      bgColor: 'bg-success-100',

      textColor: 'text-success-800'
    }
  ];

  const getCurrentBranch = () => {
    const hostname = window.location.hostname;

    if (hostname === 'localhost:5173') return branches[0];

    if (hostname === 'beta.crm.pensiontracingservice.com') return branches[1];

    if (hostname === 'crm.pensiontracingservice.com') return branches[2];

    return branches[0];
  };

  useEffect(() => {
    const handleKeyDown = e => {
      if (e.ctrlKey && e.key === 'k') {
        e.preventDefault();
        const searchInput = document.getElementById('globalsearch');
        if (searchInput) {
          searchInput.focus();
        }
      }
    };

    const handleClickOutside = event => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsMenuOpen(false);
      }
      if (
        drawerRef.current &&
        !drawerRef.current.contains(event.target) &&
        isDrawerOpen
      ) {
        setIsDrawerOpen(false);
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isDrawerOpen]);

  const handleLogout = async () => {
    await supabase.auth.signOut();
    navigate('/login');
  };

  const setTheme = theme => {
    setIsDarkMode(theme === 'dark');
    const userPreferences =
      JSON.parse(localStorage.getItem('user_preferences')) || {};
    userPreferences.theme = theme;
    localStorage.setItem('user_preferences', JSON.stringify(userPreferences));
  };

  return (
    <>
      <nav
        className={`bg-brand-700 lg:bg-white max-h-[48px] w-full px-4 shadow-sm border-b border-base-100 z-20 dark:bg-base-800 dark:text-white transition-colors duration-200`}
      >
        <div className='flex justify-between items-center h-12'>
          <div className='flex-shrink-0 flex items-center'>
            <button
              onClick={() => setIsDrawerOpen(!isDrawerOpen)}
              className='lg:hidden p-2 rounded-md text-white lg:text-base-400 hover:text-base-100 lg:hover:text-base-500 hover:bg-brand-600 lg:hover:bg-base-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-brand-500'
            >
              <MenuIcon className='h-6 w-6' />
            </button>
          </div>
          <div className='flex items-center'>
            <div className='relative flex space-x-0'>
              {currentUser?.role?.id == 1 && (
                <div className='flex items-center px-2'>
                  <Menu as='div' className='relative'>
                    <MenuButton
                      className={`flex gap-2 px-2 py-1 text-xs font-medium ${
                        getCurrentBranch().bgColor
                      } ${
                        getCurrentBranch().textColor
                      } rounded-md hover:opacity-90`}
                    >
                      <GitBranch size={16} /> {getCurrentBranch().name}
                    </MenuButton>

                    <MenuItems className='absolute left-0 mt-1 w-40 origin-top-left rounded-md bg-white dark:bg-base-700 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
                      {branches.map(branch => (
                        <MenuItem key={branch.name}>
                          {({ active }) => (
                            <a
                              href={`${branch.url}${location.pathname}`}
                              className={`${
                                active ? 'bg-base-100 dark:bg-base-600' : ''
                              } flex items-center px-4 py-2 text-sm text-base-700 dark:text-base-200`}
                            >
                              <GitBranch size={16} className='mr-2' />

                              {branch.name}
                            </a>
                          )}
                        </MenuItem>
                      ))}
                    </MenuItems>
                  </Menu>
                </div>
              )}
              <GlobalSearch />
              <FlatButton
                colour='primary'
                size='lg'
                className='lg:text-primary-600 text-white'
                onClick={() => navigate('/help')}
              >
                <CircleHelp size={20} />
              </FlatButton>
              {/*User profile menu */}
              <div className='relative' ref={menuRef}>
                <button
                  onClick={() => setIsMenuOpen(!isMenuOpen)}
                  className='flex items-center bg-brand-700 lg:bg-white dark:bg-base-700 px-2 py-1 rounded-lg text-white lg:text-base-400 hover:text-base-100 lg:hover:text-base-500 dark:text-base-300 dark:hover:text-base-200 focus:outline-none'
                >
                  <UserProfilePicture
                    firstName={currentUser?.firstName}
                    lastName={currentUser?.lastName}
                    profilePicture={currentUser?.profilePhoto}
                    size='lg'
                  />
                </button>
                {isMenuOpen && (
                  <div className='origin-top-right  absolute right-0 w-52 rounded-md shadow-lg bg-white dark:bg-base-700 ring-1 ring-black ring-opacity-5 focus:outline-none'>
                    <div className='px-4 py-3 border-b border-base-200 dark:border-base-600'>
                      <div className='flex justify-between items-start'>
                        <div>
                          <p className='text-sm font-medium text-base-900 dark:text-base-100'>{`${currentUser.firstName} ${currentUser.lastName}`}</p>
                          <p className='text-xs text-base-500 dark:text-base-400 truncate'>
                            {currentUser.email}
                          </p>
                        </div>
                        <button
                          className='p-1 text-base-500 hover:text-base-700 dark:text-base-400 dark:hover:text-base-200 focus:outline-none'
                          onClick={() => navigate('/account')}
                        >
                          <Settings size={18} />
                        </button>
                      </div>
                      {/* <div className='pt-3 pb-1'>
                        <ToggleGroup
                          options={[
                            { value: 'light', icon: Sun },
                            { value: 'dark', icon: Moon },
                            { value: 'system', icon: Monitor }
                          ]}
                          defaultOption={
                            JSON.parse(localStorage.getItem('user_preferences'))
                              ?.theme || 'system'
                          }
                          onChange={value => {
                            setTheme(value);
                          }}
                        />
                      </div> */}
                    </div>
                    <div className='py-1'>
                      <button
                        onClick={() => navigate('/settings')}
                        className='flex items-center px-4 py-2 text-sm text-base-700 dark:text-base-200 hover:bg-base-100 dark:hover:bg-base-600 w-full text-left'
                      >
                        <div className='flex items-center justify-between w-full'>
                          <span>Admin settings</span>
                        </div>
                      </button>
                      <button
                        id='sentry-feedback-action'
                        className='flex items-center px-4 py-2 text-sm text-base-700 dark:text-base-200 hover:bg-base-100 dark:hover:bg-base-600 w-full text-left'
                        onClick={async () => {
                          const form = await Sentry.getFeedback().createForm();
                          form.appendToDom();
                          form.open();
                        }}
                      >
                        <div className='flex items-center justify-between w-full'>
                          <span>Report a bug</span>
                          <Bug size={16} />
                        </div>
                      </button>
                      <button
                        onClick={() => {
                          handleLogout();
                          setIsMenuOpen(false);
                        }}
                        className='flex items-center px-4 py-2 text-sm text-base-700 dark:text-base-200 hover:bg-base-100 dark:hover:bg-base-600 w-full text-left'
                      >
                        Log out
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </nav>
      {/* Mobile Drawer */}
      <div
        ref={drawerRef}
        className={`fixed inset-y-0 left-0 z-20 w-64 bg-white dark:bg-base-800 shadow-lg transform ${
          isDrawerOpen ? 'translate-x-0' : '-translate-x-full'
        } transition-transform duration-300 ease-in-out lg:hidden`}
      >
        <div className='flex items-center justify-between p-4 border-b dark:border-base-700'>
          <img className='h-8 w-auto' src={Logo} alt='Logo' />
          <button
            onClick={() => setIsDrawerOpen(false)}
            className='text-base-500 hover:text-base-700 dark:text-base-400 dark:hover:text-base-200'
          >
            <X className='h-6 w-6' />
          </button>
        </div>
        <nav className='mt-5'>
          {sidebarItems.map(item => (
            <button
              key={item.name}
              onClick={() => {
                navigate(item.path);
                setIsDrawerOpen(false);
              }}
              className={`flex items-center w-full px-4 py-2 text-sm ${
                location.pathname === item.path
                  ? 'bg-brand-100 text-brand-700 dark:bg-brand-900 dark:text-brand-200'
                  : 'text-base-700 dark:text-base-200 hover:bg-base-100 dark:hover:bg-base-700'
              }`}
            >
              <item.icon
                className={`mr-3 h-5 w-5 ${
                  location.pathname === item.path
                    ? 'text-brand-600 dark:text-brand-300'
                    : 'text-base-400 dark:text-base-300'
                }`}
              />
              {item.name}
            </button>
          ))}
        </nav>
      </div>
    </>
  );
};

export default Header;
