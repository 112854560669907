import { SquareArrowOutUpRight } from 'lucide-react';
import EntityTable from '../../../../components/tables/EntityTable';
import {
  EntityTypeEnum,
  FilterOperatorEnum
} from '../../../../utilities/Enumerables';
import Company from '../../../../models/Company';
import { useNavigate } from 'react-router-dom';
import { formatDate } from '../../../../utilities/Formatting';
import { useEffect, useState } from 'react';
import CoreEntityType from '../../../../models/CoreEntityType';

const TraceCompanies = ({ trace }) => {
  const navigate = useNavigate();
  const [statusOptions, setStatusOptions] = useState([]);

  useEffect(() => {
    const fetchStatusOptions = async () => {
      try {
        const statuses = await CoreEntityType.getByEntityId(
          EntityTypeEnum.CompanyStatus
        );
        setStatusOptions(
          statuses.map(status => ({ value: status.type, key: status.id }))
        );
      } catch (error) {
        console.error('Error fetching status options:', error);
      }
    };

    fetchStatusOptions();
  }, []);

  const menuItems = [
    {
      label: 'Open in new tab',
      icon: <SquareArrowOutUpRight size={14} />,
      onClick: data => window.open(`/companies/${data.id}`, '_blank')
    }
  ];

  const handleRowClick = company => {
    navigate(`/companies/${company.id}`);
  };

  return (
    <>
      <EntityTable
        name='Companies'
        customId={`trace:${trace.id}:companies`}
        filter={[
          { field: 'id', label: 'ID', type: 'text', operator: 'eq' },
          { field: 'name', label: 'Name', type: 'text', operator: 'ilike' },
          {
            field: 'number',
            label: 'Company Number',
            type: 'text',
            operator: 'ilike'
          },
          {
            field: 'status',
            label: 'Status',
            type: 'select',
            options: statusOptions
          },
          {
            field: 'created_date',
            label: 'Created Date',
            type: 'daterange',
            operator: FilterOperatorEnum.BETWEEN
          },
          {
            field: 'updated_date',
            label: 'Updated Date',
            type: 'daterange',
            operator: FilterOperatorEnum.BETWEEN
          },
          {
            field: 'showArchived',
            label: 'Show Archived',
            hint: 'Show archived companies',
            type: 'conditional_toggle',
            operator: FilterOperatorEnum.IS,
            options: {
              value: 'true'
            }
          }
        ]}
        columns={[
          { label: 'ID', field: 'id' },
          { label: 'Name', field: 'name' },
          { label: 'Company Number', field: 'number' },
          { label: 'Status', field: 'status.type' },
          {
            label: 'Created Date',
            field: 'createdDate',
            cell: value => formatDate(value)
          },
          {
            label: 'Updated Date',
            field: 'updatedDate',
            cell: value => formatDate(value)
          }
        ]}
        menuItems={menuItems}
        model={Company}
        entityType={EntityTypeEnum.Company}
        defaultFilters={{
          id: {
            value: trace.companies,
            operator: 'in'
          },
          status: { value: '149', operator: FilterOperatorEnum.NOT_EQUALS }
        }}
        sortDefault={{ field: 'id', order: 'asc' }}
        onRowClick={handleRowClick}
      />
    </>
  );
};

export default TraceCompanies;
