import React from 'react';
import { supabase } from '../../../utilities/supabase';
import { Badge } from '@core/components';

const TagsTab = ({ tags, onUpdate }) => {
  const handleRemoveTag = async (e, tagId) => {
    e.stopPropagation();
    try {
      const { error } = await supabase
        .from('tagged_entities')
        .delete()
        .eq('tag_id', tagId);

      if (error) throw error;
      onUpdate();
    } catch (error) {
      console.error('Error removing tag:', error);
    }
  };

  return (
    <div className='flex flex-wrap gap-2'>
      {tags?.length > 0 ? (
        tags.map(tagEntity => (
          <Badge
            key={tagEntity.id}
            colour={
              tagEntity.tags?.metadata?.colour ||
              tagEntity.tags.category?.metadata?.colour ||
              'primary'
            }
            size='sm'
            onRemove={e => handleRemoveTag(e, tagEntity.tag_id)}
          >
            {tagEntity.tags.name}
          </Badge>
        ))
      ) : (
        <div className='w-full text-center text-neutral-500'>
          No tags added yet
        </div>
      )}
    </div>
  );
};

export default TagsTab;
