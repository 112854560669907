import { supabase } from '../utilities/supabase';

/**
 * BaseModel class for handling common database operations.
 */
class BaseModel {
  /**
   * Create a new BaseModel instance.
   * @param {Object} data - The data to initialize the model with.
   */
  constructor(data = {}) {
    this.id = data.id || null;
    this.displayId = data.display_id || null;
    this.createdDate = data.created_date || new Date();
    this.updatedDate = data.updated_date || new Date();
  }

  /** The table name in the database. Should be overridden by subclasses. */
  static table = '';

  /** The default select query. Can be overridden by subclasses for more specific queries. */
  static selectQuery = '*';

  /**
   * Convert the model instance to a database-friendly format.
   * @returns {Object} An object representation of the model for database operations.
   */
  toDatabase() {
    return {
      id: this.id,
      created_date: this.createdDate,
      updated_date: new Date().toISOString()
    };
  }

  /**
   * Fetch a single record from the database based on a field and value.
   * @param {string} field - The field to query on.
   * @param {*} value - The value to match.
   * @returns {Promise<BaseModel>} A promise that resolves to a new instance of the model.
   */
  static async fetchOne(field, value, query) {
    try {
      const { data, error } = await supabase
        .from(this.table)
        .select(query || this.selectQuery)
        .eq(field, value)
        .single();

      if (error) throw error;

      return new this(data);
    } catch (error) {
      console.error(`[Error] Fetching ${this.name}: `, error);
      throw error;
    }
  }

  /**
   * Get a record by its ID.
   * @param {string|number} id - The ID of the record to fetch.
   * @returns {Promise<BaseModel>} A promise that resolves to a new instance of the model.
   */
  static async getById(id) {
    return this.fetchOne('id', id);
  }

  /**
   * Insert the current instance into the database.
   * @returns {Promise<BaseModel>} A promise that resolves to a new instance of the model.
   */
  async insert() {
    try {
      const { data, error } = await supabase
        .from(this.constructor.table)
        .insert(
          [this.toDatabase()].map(
            ({ id, created_date, updated_date, ...rest }) => rest
          )
        )
        .select(this.constructor.selectQuery);

      if (error) throw error;

      return new this.constructor(data[0]);
    } catch (error) {
      console.error(`[Error] Inserting ${this.constructor.name}: `, error);
      throw error;
    }
  }

  /**
   * Update the current instance in the database.
   * @param {Object} updatedFields - The fields to update.
   * @returns {Promise<BaseModel>} A promise that resolves to a new instance of the model.
   */
  async update(updatedFields) {
    try {
      Object.assign(this, updatedFields);
      const payload = this.toDatabase();

      const { data, error } = await supabase
        .from(this.constructor.table)
        .update(payload)
        .eq('id', this.id)
        .select(this.constructor.selectQuery);

      if (error) throw error;

      if (data) {
        return new this.constructor(data[0]);
      }
    } catch (error) {
      console.error(`[Error] Updating ${this.constructor.name}: `, error);
      throw error;
    }
  }

  /**
   * Delete the current instance from the database.
   * @returns {Promise<void>}
   */
  async delete() {
    try {
      const { error } = await supabase
        .from(this.constructor.table)
        .delete()
        .eq('id', this.id);

      if (error) throw error;
    } catch (error) {
      console.error(`[Error] Deleting ${this.constructor.name}: `, error);
      throw error;
    }
  }

  /**
   * Apply filters to a query.
   * @param {Object} query - The Supabase query object.
   * @param {Object} filters - The filters to apply.
   * @returns {Object} The modified query object.
   */
  static filters(query, filters) {
    Object.entries(filters).forEach(([key, filter]) => {
      if (filter) {
        const { operator, innerOperator, value, isOr, isArray } = filter;
        if (isOr && Array.isArray(value) && value.length > 1) {
          const orConditions = value
            .map(val =>
              isArray
                ? `${key}.${operator}.{${val}}`
                : `${key}.${operator}.(${val})`
            )
            .join(',');
          query = query.or(orConditions);
        } else {
          switch (operator) {
            case 'eq':
              query = query.eq(key, value);
              break;
            case 'neq':
              query = query.neq(key, value);
              break;
            case 'gt':
              query = query.gt(key, value);
              break;
            case 'gte':
              query = query.gte(key, value);
              break;
            case 'lt':
              query = query.lt(key, value);
              break;
            case 'lte':
              query = query.lte(key, value);
              break;
            case 'like':
              query = query.like(key, value);
              break;
            case 'ilike':
              query = query.ilike(key, `%${value}%`);
              break;
            case 'is':
              query = query.is(key, value);
              break;
            case 'in':
              query = query.in(key, value);
              break;
            case 'not.in':
              query = query.not(key, 'in', value);
              break;
            case 'contains':
              query = query.contains(key, value);
              break;
            case 'cs':
              query = query.contains(key, value);
              break;
            case 'containedBy':
              query = query.containedBy(key, value);
              break;
            case 'rangeGt':
              query = query.rangeGt(key, value);
              break;
            case 'rangeGte':
              query = query.rangeGte(key, value);
              break;
            case 'rangeLt':
              query = query.rangeLt(key, value);
              break;
            case 'rangeLte':
              query = query.rangeLte(key, value);
              break;
            case 'textSearch':
              if (value) {
                query = query.textSearch(
                  key,
                  value.replaceAll(' ', '+').concat('', ':*')
                );
              }
              break;
            case 'match':
              query = query.match(key, value);
              break;
            case 'not':
              query = query.not(key, innerOperator, value);
              break;
            case 'or':
              query = query.or(value);
              break;
            case 'filter':
              query = query.filter(key, operator, value);
              break;
            case 'between':
              const v = JSON.parse(value);
              if (v.from && v.to) {
                query = query.gte(key, v.from).lt(key, v.to);
              } else if (v.from) {
                query = query.gte(key, v.from);
              } else if (v.to) {
                query = query.lt(key, v.to);
              }
              break;
            default:
              query = query.eq(key, value);
          }
        }
      }
    });

    return query;
  }

  /**
   * Apply sorting to a query.
   * @param {Object} query - The Supabase query object.
   * @param {string} by - The field to sort by.
   * @param {string} type - The sort type ('asc' or 'desc').
   * @returns {Object} The modified query object.
   */
  static sorting(query, by, type) {
    if (by) {
      const [table, column] = by.split('.');
      if (column) {
        // Foreign key sorting
        const snakeColumn = column.replace(
          /[A-Z]/g,
          letter => `_${letter.toLowerCase()}`
        );
        query = query.order(`${table}(${snakeColumn})`, {
          ascending: type === 'asc'
        });
      } else {
        // Regular column sorting
        const snakeBy = by.replace(
          /[A-Z]/g,
          letter => `_${letter.toLowerCase()}`
        );
        query = query.order(snakeBy, { ascending: type === 'asc' });
      }
    }
    return query;
  }

  /**
   * Apply pagination to a query.
   * @param {Object} query - The Supabase query object.
   * @param {number} page - The page number.
   * @param {number} size - The page size.
   * @returns {Object} The modified query object.
   */
  static range(query, page, size) {
    const from = (page - 1) * size;
    const to = from + size - 1;
    return query.range(from, to);
  }

  static async getCount(filters = {}) {
    let query = supabase
      .from(this.table)
      .select('id', { count: 'exact', head: true });

    query = this.filters(query, filters);

    const { count, error } = await query;

    if (error) throw error;

    return count;
  }

  /**
   * Get all records with filtering, sorting, and pagination.
   * @param {Object} filters - The filters to apply.
   * @param {number} page - The page number.
   * @param {number} size - The page size.
   * @param {string} sortBy - The field to sort by.
   * @param {string} sortType - The sort type ('asc' or 'desc').
   * @param {boolean} count - Whether to use core_counts table for count
   * @returns {Promise<Object>} A promise that resolves to an object containing the data and pagination info.
   */
  static async getAll(
    filters = {},
    page = 1,
    size = 10,
    sortBy = 'id',
    sortType = 'asc',
    count = true
  ) {
    try {
      let query;

      // if (count) {
      //   query = supabase.from(this.table).select(this.selectQuery);
      // } else {
      query = supabase
        .from(this.table)
        .select(this.selectQuery, { count: 'exact' });
      // }

      query = this.filters(query, filters);
      query = this.sorting(query, sortBy, sortType);
      query = this.range(query, page, size);

      const { data, error, count: queryCount } = await query;

      if (error) throw error;

      // let totalCount;
      // if (count) {

      //   totalCount = countData || 0;
      // } else {
      //   totalCount = countData;
      // }

      // console.log(totalCount, queryCount);

      return {
        data: data.map(item => new this(item)),
        count: queryCount,
        page: page,
        size: size,
        total: Math.ceil(queryCount / size)
      };
    } catch (error) {
      console.error(`[Error] Fetching All ${this.name}: `, error);
      throw error;
    }
  }
}

export default BaseModel;
