import React, { useState, useEffect } from 'react';
import {
  Popup,
  Input,
  SoftButton,
  FilledButton,
  Badge,
  Pagination,
  Modal,
  useContextMenu,
  ContextMenu
} from '@core/components';
import { Search, ListOrdered, ArrowRight } from 'lucide-react';
import { supabase } from '../../../utilities/supabase';
import AiLogo from '../../../assets/+Ai.png';
import Checkbox from '../../inputs/Checkbox';
import { Link } from 'react-router-dom';
import Address from '../../../models/Address';
import { EntityTypeEnum } from '../../../utilities/Enumerables';

const PensionTrace = ({ isOpen, onClose, trace }) => {
  const [selectedProviders, setSelectedProviders] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [providers, setProviders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [creatingSearches, setCreatingSearches] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [providersPerPage, setProvidersPerPage] = useState(5);
  const [isPreviousNamesModalOpen, setIsPreviousNamesModalOpen] =
    useState(false);
  const [previousNamesList, setPreviousNamesList] = useState([]);
  const [currentScreen, setCurrentScreen] = useState(1);
  const [progress, setProgress] = useState(0);

  const { contextMenu, handleContextMenu, closeContextMenu } = useContextMenu();

  const providerMenuItems = [
    {
      label: 'Convert to Active',
      onClick: async data => {
        try {
          const { error } = await supabase
            .from('providers')
            .update({ status: 78 })
            .eq('id', data.id);

          if (error) throw error;

          setProviders(prev =>
            prev.map(provider =>
              provider.id === data.id
                ? { ...provider, status: 'Active', status_id: 78 }
                : provider
            )
          );
        } catch (error) {
          console.error('[Error] Converting to Active: ', error);
        }
      },
      condition: data => data.status_id !== 78 && data.source === 'database'
    }
  ];

  useEffect(() => {
    setSelectedProviders([]);
    setProviders([]);
    setCurrentPage(1);
    setLoading(false);
    setCurrentScreen(1);
    setCreatingSearches(false);
    setProgress(0);
    setIsPreviousNamesModalOpen(false);
    setPreviousNamesList([]);

    if (trace) {
      setSearchTerm(trace.name || '');
      setTimeout(() => {
        fetchProviders(trace.name);
      }, 0);
    } else {
      setSearchTerm('');
    }
  }, [trace]);

  const fetchProviders = async searchTerm => {
    if (!searchTerm) return;

    setLoading(true);
    setSelectedProviders([]);
    setCurrentPage(1);

    try {
      const { data: matches, error } = await supabase.rpc('get_provider_name', {
        p_name: searchTerm
      });

      if (error) throw error;

      if (matches && matches.length > 0) {
        const { data: mainProviders } = await supabase
          .from('providers')
          .select(
            `
            *,
            status:core_entity_types(id, type)
          `
          )
          .in(
            'id',
            matches.map(c => c.id)
          )
          .neq('status', 149);

        if (mainProviders) {
          const providersWithAddresses = await Promise.all(
            mainProviders.map(async provider => {
              const { data: addressData, error } = await Address.getAll(
                {
                  entity_type: { value: EntityTypeEnum.Provider },
                  entity_id: { value: provider.id }
                },
                1,
                1
              );

              if (error) {
                console.error(
                  `[Error] Fetching Address For ${provider.id} (Provider): `,
                  error
                );

                return {
                  ...provider,
                  status: provider.status?.type || 'Unknown',
                  status_id: provider.status?.id || null,
                  address: 'N/A',
                  source: 'database',
                  score:
                    (matches.find(m => m.id === provider.id)?.score || 0) * 100
                };
              }

              const address = addressData[0];

              return {
                ...provider,
                status: provider.status?.type || 'Unknown',
                status_id: provider.status?.id || null,
                address: address
                  ? [
                      address.line1,
                      address.line2,
                      address.line3,
                      address.city,
                      address.postcode,
                      address.country
                    ]
                      .filter(Boolean)
                      .join(', ')
                  : 'N/A',
                source: 'database',
                score:
                  (matches.find(m => m.id === provider.id)?.score || 0) * 100
              };
            })
          );

          // Sort providers by score from highest to lowest
          providersWithAddresses.sort((a, b) => b.score - a.score);

          setProviders(providersWithAddresses);

          // Auto-select provider if name matches search term
          const exactMatchProvider = providersWithAddresses.find(
            provider => provider.name.toLowerCase() === searchTerm.toLowerCase()
          );
          if (exactMatchProvider) {
            setSelectedProviders(prev => {
              const isAlreadySelected = prev.find(
                c => c.id === exactMatchProvider.id
              );
              if (!isAlreadySelected) {
                return [...prev, exactMatchProvider];
              }
              return prev;
            });
          }
        }
      }
    } catch (error) {
      console.error(`[Error] Fetching Providers: `, error);
    } finally {
      setLoading(false);
    }
  };

  const markAsGovernmentTrace = async () => {
    try {
      const { error } = await supabase
        .from('traces')
        .update({ status: 94 })
        .eq('id', trace.id);

      if (error) throw error;

      onClose();
    } catch (error) {
      console.error('Error marking as government trace: ', error);
    }
  };

  const renderStageOne = () => {
    const indexOfLastProvider = currentPage * providersPerPage;
    const indexOfFirstProvider = indexOfLastProvider - providersPerPage;
    const currentProviders = providers.slice(
      indexOfFirstProvider,
      indexOfLastProvider
    );
    const totalPages = Math.ceil(providers.length / providersPerPage);

    return (
      <div className='space-y-4'>
        <div className='flex gap-2'>
          <Input
            placeholder='Provider Name'
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value)}
          />
          <SoftButton
            colour='primary'
            onClick={() => fetchProviders(searchTerm)}
          >
            <Search size={16} />
          </SoftButton>
        </div>
        <div className='overflow-x-auto bg-white rounded-md w-full'>
          <table className='w-full divide-y divide-base-200'>
            <thead>
              <tr>
                <th className='py-3 px-3 text-left text-sm font-semibold text-base-700'>
                  Provider
                </th>
                <th className='py-3 px-3 text-left text-sm font-semibold text-base-700'>
                  Previous Names
                </th>
                <th className='py-3 px-3 text-left text-sm font-semibold text-base-700'>
                  Status
                </th>
                <th className='py-3 px-3 text-left text-sm font-semibold text-base-700'>
                  Address
                </th>
                <th className='py-3 px-3 text-left text-sm font-semibold text-base-700'>
                  Score
                </th>
                <th className='px-4'></th>
              </tr>
            </thead>
            <tbody className='divide-y divide-base-50'>
              {loading ? (
                <tr>
                  <td colSpan='6' className='text-center py-10'>
                    <div className='flex flex-col items-center justify-center gap-2'>
                      <Search className='text-base-500 w-11 h-11 animate-bounce duration-2000' />
                      <span className='text-base-600'>
                        Finding providers from the database...
                      </span>
                      <span className='text-base-600 text-sm'>
                        This may take a moment or two, we are working hard in
                        the background...
                      </span>
                    </div>
                  </td>
                </tr>
              ) : (
                currentProviders.map(provider => {
                  const isSelected = selectedProviders.find(
                    c => c.id === provider.id
                  );
                  return (
                    <tr
                      key={provider.id}
                      onClick={() => {
                        setSelectedProviders(prev => {
                          const isAlreadySelected = prev.find(
                            c => c.id === provider.id
                          );
                          if (isAlreadySelected) {
                            return prev.filter(c => c.id !== provider.id);
                          } else {
                            return [...prev, provider];
                          }
                        });
                      }}
                      onContextMenu={e => {
                        handleContextMenu(e, provider);
                      }}
                      className={`cursor-pointer ${
                        isSelected
                          ? 'bg-brand-100 hover:bg-brand-200'
                          : 'hover:bg-base-50'
                      }`}
                    >
                      <td className='py-2 px-3 text-sm'>
                        <Link
                          to={`/providers/${provider.id}`}
                          target='_blank'
                          onClick={e => e.stopPropagation()}
                          className='hover:underline'
                        >
                          {provider.name}
                        </Link>
                      </td>
                      <td className='py-2 px-3 text-sm'>
                        {provider?.previous_names &&
                        provider?.previous_names.length > 0 ? (
                          <div className='flex items-center gap-2'>
                            <span>{provider.previous_names.length}</span>
                            <SoftButton
                              onClick={e => {
                                e.stopPropagation();
                                setPreviousNamesList(provider.previous_names);
                                setIsPreviousNamesModalOpen(true);
                              }}
                              size='xs'
                            >
                              <ListOrdered className='w-4 h-4' />
                            </SoftButton>
                          </div>
                        ) : (
                          'N/A'
                        )}
                      </td>
                      <td className='py-2 px-3 flex justify-between items-center'>
                        <Badge
                          size='xs'
                          colour={
                            provider.status
                              ? provider.status.toLowerCase() === 'active'
                                ? 'success'
                                : provider.status.toLowerCase() === 'dissolved'
                                ? 'danger'
                                : 'base'
                              : 'base'
                          }
                        >
                          {provider.status
                            ? provider.status
                                .split(' ')
                                .map(
                                  word =>
                                    word.charAt(0).toUpperCase() +
                                    word.slice(1).toLowerCase()
                                )
                                .join(' ')
                            : 'Unknown'}
                        </Badge>
                      </td>
                      <td className='py-2 px-3 text-sm truncate max-w-xs'>
                        {provider.address}
                      </td>
                      <td className='py-2 px-3 text-sm'>
                        {provider.score.toFixed(0)}%
                      </td>
                      <td>
                        <Checkbox
                          checked={isSelected}
                          onChange={() => {
                            setSelectedProviders(prev => {
                              const isAlreadySelected = prev.find(
                                c => c.id === provider.id
                              );
                              if (isAlreadySelected) {
                                return prev.filter(c => c.id !== provider.id);
                              } else {
                                return [...prev, provider];
                              }
                            });
                          }}
                        />
                      </td>
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={newPage => {
              setCurrentPage(newPage);
            }}
            itemsPerPage={providersPerPage}
            onItemsPerPageChange={newItemsPerPage => {
              setProvidersPerPage(newItemsPerPage);
              setCurrentPage(1);
            }}
          />
        </div>
      </div>
    );
  };

  const renderStageTwo = () => {
    return (
      <div className='space-y-4'>
        <div className='text-left text-gray-600'>
          Based on your selections, we can initiate{' '}
          <span className='font-bold'>{selectedProviders.length}</span>{' '}
          individual trace searches across your chosen companies and providers.
        </div>
        <div className='bg-brand-100 p-4 rounded-md'>
          <div className='text-xl font-semibold text-gray-900 mb-4 text-center'>
            Trace Map
          </div>
          <table className='w-full'>
            <tbody className='space-y-8'>
              {selectedProviders.map(provider => (
                <tr
                  key={provider.name}
                  className='flex w-full items-center justify-center'
                >
                  <td className='w-[30%] flex justify-center'>
                    <div className='space-y-2 flex flex-col items-center'>
                      <div className='text-sm bg-warning-100 p-2 rounded-md inline-block'>
                        {provider?.match?.label ?? provider.name}{' '}
                        <Badge>
                          ID: {provider.match?.value ?? provider.id}
                        </Badge>
                      </div>
                    </div>
                  </td>
                  <td className='w-[5%] text-center'>
                    <ArrowRight className='text-xl inline-block' />
                  </td>
                  <td className='w-[30%] flex justify-center'>
                    <div className='text-sm bg-brand-600 p-2 rounded-md inline-block text-white'>
                      Search
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className='text-left text-gray-600'>
          Would you like to proceed with creating these searches?
        </div>
      </div>
    );
  };

  const renderLoadingScreen = () => {
    return (
      <div className='flex flex-col items-center p-8'>
        <Search className='w-24 h-24 text-gray-500 mb-4 animate-bounce duration-1500' />
        <p className='text-lg mb-2'>
          Please wait, we are creating the searches...
        </p>
        <p className='text-sm mb-8'>
          This may take a moment or two, we are working hard in the
          background...
        </p>
        <div className='w-full bg-gray-200 rounded-full h-2.5'>
          <div
            className='bg-primary-600 h-2.5 rounded-full'
            style={{ width: `${progress}%` }}
          />
        </div>
      </div>
    );
  };

  const createSearchesForSelectedProviders = async () => {
    setCreatingSearches(true);
    setProgress(0);
    try {
      const totalProviders = selectedProviders.length;
      await Promise.all(
        selectedProviders.map(async (provider, index) => {
          const { error: searchError } = await supabase
            .from('searches')
            .insert([
              {
                trace: trace.id,
                status: 75,
                provider: provider.id,
                owner: trace.owner.id,
                metadata: { source: '+Ai Assisted Trace' }
              }
            ]);

          if (searchError) throw searchError;

          // Update progress
          setProgress(((index + 1) / totalProviders) * 100);
        })
      );

      // Update trace status
      const { error: traceError } = await supabase
        .from('traces')
        .update({ status: 70 }) // Assuming 95 is the new status for updated traces
        .eq('id', trace.id);

      if (traceError) throw traceError;

      // Close the popup
      onClose();
    } catch (error) {
      console.error(
        'Error creating searches or updating trace status: ',
        error
      );
    } finally {
      setCreatingSearches(false);
    }
  };

  return (
    <>
      <Popup
        isOpen={isOpen}
        onClose={() => {
          if (trace && trace.name) {
            onClose();
          }
        }}
        title={
          <div className='flex items-center'>
            <img src={AiLogo} className='w-9 h-9 mr-2' />
            <span className='font-semibold text-black pt-[3px]'>
              Assisted Trace
            </span>
          </div>
        }
        size='lg'
        footer={
          <div className='flex justify-between space-x-2 mt-1'>
            <div className='flex items-center'>
              <SoftButton colour='primary' onClick={markAsGovernmentTrace}>
                Mark as Government
              </SoftButton>
            </div>
            <div className='flex justify-end space-x-2'>
              {currentScreen === 1 ? (
                <SoftButton onClick={onClose}>Cancel</SoftButton>
              ) : (
                <SoftButton
                  colour='primary'
                  onClick={() => setCurrentScreen(1)}
                >
                  Back
                </SoftButton>
              )}
              <FilledButton
                colour='primary'
                onClick={() => {
                  if (selectedProviders.length > 0) {
                    if (currentScreen === 1) {
                      setCurrentScreen(2);
                    } else {
                      createSearchesForSelectedProviders();
                    }
                  }
                }}
                disabled={selectedProviders.length === 0}
              >
                {currentScreen === 1 ? 'Next' : 'Start Trace'}
              </FilledButton>
            </div>
          </div>
        }
      >
        {creatingSearches
          ? renderLoadingScreen()
          : currentScreen === 1
          ? renderStageOne()
          : renderStageTwo()}
      </Popup>
      <Modal
        isOpen={isPreviousNamesModalOpen}
        onClose={() => setIsPreviousNamesModalOpen(false)}
        title={<h2 className='text-2xl'>Previous Names</h2>}
      >
        <div>
          {previousNamesList.map((name, index) => (
            <div key={index} className='flex items-center justify-between py-2'>
              <span className='truncate mr-4 flex-1 text-left'>{name}</span>
            </div>
          ))}
        </div>
      </Modal>
      {contextMenu && (
        <ContextMenu
          x={contextMenu.x}
          y={contextMenu.y}
          onClose={closeContextMenu}
          menuItems={providerMenuItems.map(item => ({
            ...item,
            onClick: () => item.onClick(contextMenu.data),
            visible: item.condition ? item.condition(contextMenu.data) : true
          }))}
        />
      )}
    </>
  );
};

export default PensionTrace;
