import React from 'react';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@core/components/ui';
import { Smartphone, Laptop } from 'lucide-react';

const EmailPreview = ({ html }) => {
  return (
    <div className='border rounded-lg bg-white shadow-sm gap-y-8 justify-center items-center'>
      <Tabs
        defaultValue='laptop'
        className='flex flex-col justify-center items-center mx-auto border-b'
      >
        <TabsList className='mt-4'>
          <TabsTrigger value='mobile'>
            <Smartphone size={16} />
          </TabsTrigger>
          <TabsTrigger value='laptop'>
            <Laptop size={16} />
          </TabsTrigger>
        </TabsList>

        <TabsContent value='mobile' className='w-full border-t my-4'>
          <div className='flex justify-center items-center mx-auto p-4'>
            <div className='w-[390px] overflow-x-hidden rounded-lg shadow-md'>
              <iframe
                srcDoc={`
                  <!DOCTYPE html>
                  <html>
                    <head>
                      <meta name="viewport" content="width=device-width, initial-scale=1">
                      <style>
                        body { margin: 0; font-family: system-ui, sans-serif; }
                        img { max-width: 100%; height: auto; }
                      </style>
                    </head>
                    <body>${html}</body>
                  </html>
                `}
                className='w-full h-[600px] border-0'
                title='Email preview mobile'
              />
            </div>
          </div>
        </TabsContent>

        <TabsContent value='laptop' className='w-full border-t my-4'>
          <div className='flex justify-center items-center w-full p-4'>
            <div className='w-full max-w-[1024px] overflow-x-hidden rounded-lg shadow-md'>
              <iframe
                srcDoc={`
                  <!DOCTYPE html>
                  <html>
                    <head>
                      <style>
                        body { margin: 0; font-family: system-ui, sans-serif; }
                        img { max-width: 100%; height: auto; }
                      </style>
                    </head>
                    <body>${html}</body>
                  </html>
                `}
                className='w-full h-[600px] border-0'
                title='Email preview desktop'
              />
            </div>
          </div>
        </TabsContent>
      </Tabs>
    </div>
  );
};

export default EmailPreview;
