import {
  User as UserIcon,
  FileSearch,
  Building2,
  Shield,
  Binoculars,
  CheckCircle
} from 'lucide-react';
import Entity from '../../../components/entity/Entity';
import Search from '../../../models/Search';
import CoreEntityType from '../../../models/CoreEntityType';
import { EntityTypeEnum } from '../../../utilities/Enumerables';
import DateCard from '../../../components/entity/DateCard';
import { useEffect, useState } from 'react';
import { supabase } from '../../../utilities/supabase';
import {
  Modal,
  FilledButton,
  SoftButton,
  DropdownInput,
  Input
} from '@core/components';
import ConvertPension from '../../../components/popups/convertPension/Convert';

const SearchDetails = ({ currentUser }) => {
  const [statusOptions, setStatusOptions] = useState([]);
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [showSearchPopup, setShowSearchPopup] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedReason, setSelectedReason] = useState(null);
  const [entityData, setEntityData] = useState(null);
  const [statusReasons, setStatusReasons] = useState([]);
  const [pendingStatusChange, setPendingStatusChange] = useState(null);

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const statusTypes = await CoreEntityType.getByEntityId(
          EntityTypeEnum.SearchStatus
        );

        setStatusOptions(
          statusTypes.map(status => ({
            value: status.id,
            label: status.description || status.type
          }))
        );
      } catch (error) {
        console.error('Error fetching options:', error);
      }
    };

    fetchOptions();
  }, []);

  const loadSchemes = async query => {
    try {
      if (!query) return;

      const { data: schemes } = await supabase
        .from('schemes')
        .select('id, name')
        .ilike('name', `%${query}%`)
        .limit(10);

      return schemes.map(scheme => ({
        value: scheme.id,
        label: scheme.name
      }));
    } catch (error) {
      console.error('Error loading schemes:', error);
      return [];
    }
  };

  const loadProviders = async query => {
    try {
      if (!query) return;

      const { data: providers } = await supabase
        .from('providers')
        .select('id, name')
        .ilike('name', `%${query}%`)
        .limit(10);

      return providers.map(provider => ({
        value: provider.id,
        label: provider.name
      }));
    } catch (error) {
      console.error('Error loading providers:', error);
      return [];
    }
  };

  const getStatusReasons = async status => {
    try {
      if (status == 77) {
        const { data: tags } = await supabase
          .from('tags')
          .select('*')
          .eq('category_id', 175);

        setStatusReasons(
          (tags || []).map(tag => ({
            value: tag.id,
            label: tag.name
          }))
        );
      } else if (status == 176) {
        const { data: tags } = await supabase
          .from('tags')
          .select('*')
          .eq('category_id', 174);

        setStatusReasons(
          (tags || []).map(tag => ({
            value: tag.id,
            label: tag.name
          }))
        );
      } else {
        setStatusReasons([]);
      }
    } catch (error) {
      console.error('Error loading status reasons:', error);
      setStatusReasons([]);
    }
  };

  useEffect(() => {
    if (selectedStatus) {
      getStatusReasons(selectedStatus);
    }
  }, [selectedStatus]);

  const handleStatusConfirm = async () => {
    if (selectedStatus && entityData && pendingStatusChange && selectedReason) {
      try {
        // Add tag to tag_entities table
        await supabase.from('tagged_entities').insert({
          tag_id: selectedReason,
          entity_id: entityData.displayId,
          entity_type: 13
        });

        // Complete the status change
        pendingStatusChange.onConfirm();
      } catch (error) {
        console.error('Error adding tag:', error);
      }
    }
    setShowStatusModal(false);
    setSelectedStatus(null);
    setSelectedReason(null);
    setPendingStatusChange(null);
  };

  const handleStatusCancel = () => {
    if (pendingStatusChange) {
      pendingStatusChange.onCancel();
    }
    setShowStatusModal(false);
    setSelectedStatus(null);
    setSelectedReason(null);
    setPendingStatusChange(null);
  };

  const infoItems = [
    {
      icon: UserIcon,
      label: 'Owner',
      displayField: 'owner',
      dataType: 'owner',
      isEditable: false
    },
    {
      icon: Binoculars,
      label: 'Trace',
      displayField: 'trace.name',
      isEditable: false,
      dataType: 'trace',
      displayLink: data => `/traces/${data?.trace?.displayId}`
    },
    {
      icon: Building2,
      label: 'Provider',
      displayField: 'provider.name',
      editField: 'provider.id',
      dataType: 'search',
      isEditable: true,
      loadOptions: loadProviders,
      displayLink: data => `/providers/${data?.provider?.id}`
    },
    {
      icon: FileSearch,
      label: 'Scheme',
      displayField: 'scheme.name',
      editField: 'scheme.id',
      dataType: 'search',
      isEditable: true,
      loadOptions: loadSchemes,
      displayLink: data => `/schemes/${data?.scheme?.id}`
    },
    {
      icon: CheckCircle,
      label: 'Status',
      displayField: 'status.type',
      editField: 'status.id',
      dataType: 'select',
      isEditable: true,
      options: statusOptions,
      onChange: (value, data, onConfirm, onCancel) => {
        if (value.target.value == 176) {
          setEntityData(data);
          setSelectedStatus(value.target.value);
          setPendingStatusChange({ onConfirm, onCancel });
          setShowStatusModal(true);
        } else if (value.target.value == 77) {
          setEntityData(data);
          setShowSearchPopup(true);
        }
      }
    }
  ];

  const accordionItems = [
    {
      title: 'Key Dates',
      content: ({ data }) => (
        <div className='grid grid-cols-2 md:grid-cols-3 gap-2'>
          <DateCard date={data.createdDate} label='Created' />
          <DateCard date={data.updatedDate} label='Updated' />
        </div>
      )
    }
  ];

  const progressItems = {
    title: 'Trace Progress',
    steps: statusOptions.map(status => ({
      label: status.label
    })),
    getValue: entity => {
      const currentStatusIndex = statusOptions.findIndex(
        status => status.value === entity?.status.id
      );
      return currentStatusIndex + 1;
    }
  };

  return (
    <>
      <Entity
        currentUser={currentUser}
        entityType={EntityTypeEnum.Search}
        progressItems={progressItems}
        progressType='continuous'
        model={Search}
        infoItems={infoItems}
        accordionItems={accordionItems}
      />

      <Modal
        isOpen={showStatusModal}
        onClose={handleStatusCancel}
        title='Status Change Reasons'
      >
        <div className='p-6'>
          <div className='mb-6'>
            <p className='text-xl font-bold text-brand-600 mb-6'>
              {statusOptions.find(opt => opt.value === selectedStatus)?.label}
            </p>

            <DropdownInput
              value={selectedReason}
              onChange={e => setSelectedReason(e.target.value)}
              options={statusReasons}
              placeholder='Select a reason'
              label='Status Reason'
              required={true}
              className='w-full'
            />
          </div>

          <div className='flex justify-end gap-3 mt-8 pt-4 border-t'>
            <SoftButton onClick={handleStatusCancel} className='px-4'>
              Cancel
            </SoftButton>
            <FilledButton
              onClick={handleStatusConfirm}
              className='px-4'
              disabled={!selectedReason}
            >
              Update Status
            </FilledButton>
          </div>
        </div>
      </Modal>
      <ConvertPension
        onClose={() => setShowSearchPopup(false)}
        isOpen={showSearchPopup}
        search={entityData}
      />
    </>
  );
};

export default SearchDetails;
