import { supabase } from '../utilities/supabase';
import BaseModel from './BaseModel';

/**
 * Represents a Trace in the system.
 * @extends BaseModel
 */
class Trace extends BaseModel {
  /** @type {string} The name of the database table for Traces */
  static table = 'traces';
  /** @type {string} The SQL query to select Trace data */
  static selectQuery =
    '*, owner:user_profiles!inner(*), status:core_entity_types!traces_status_fkey(*), type:core_entity_types!traces_type_fkey(*)';

  /**
   * Creates an instance of Trace.
   * @param {Object} data - The trace data.
   */
  constructor(data = {}) {
    super(data);
    this.displayId = data.display_id || null;
    this.name = data.name || null;
    this.status = data.status ?? null;
    this.type = data.type ?? null;
    this.owner = data.owner
      ? {
          id: data.owner.id,
          firstName: data.owner.first_name,
          lastName: data.owner.last_name,
          email: data.owner.email,
          displayId: data.owner.display_id,
          createdDate: data.owner.created_date,
          updatedDate: data.owner.updated_date
        }
      : data.owner;
    this.metadata = data.metadata || {};
    this.createdDate = data.created_date || undefined;
    this.updatedDate = data.updated_date || undefined;
    this.companies = data.companies || [];
    this.searches = data.searches || [];
  }

  /**
   * Converts the Trace instance to a database-friendly format.
   * @returns {Object} The trace data ready for database operations.
   */
  toDatabase() {
    console.log(this);
    return {
      ...super.toDatabase(),
      name: this.name,
      status: this.status ? this.status.id : this.status,
      type: this.type ? this.type.id : this.type,
      owner: this.owner ? this.owner.id : this.owner,
      metadata: this.metadata,
      created_date: this.createdDate,
      updated_date: this.updatedDate
    };
  }

  /**
   * Get all records with related searches and companies.
   * @param {Object} filters - The filters to apply.
   * @param {number} page - The page number.
   * @param {number} size - The page size.
   * @param {string} sortBy - The field to sort by.
   * @param {string} sortType - The sort type ('asc' or 'desc').
   * @returns {Promise<Object>} A promise that resolves to an object containing the data and pagination info.
   */
  static async getAll(
    filters = {},
    page = 1,
    size = 10,
    sortBy = 'id',
    sortType = 'asc'
  ) {
    try {
      // Get traces with pagination, filtering and exact count
      let query = supabase
        .from(this.table)
        .select(this.selectQuery, { count: 'exact' });

      query = this.filters(query, filters);
      query = this.sorting(query, sortBy, sortType);
      query = this.range(query, page, size);

      const { data: traces, error: tracesError, count } = await query;

      if (tracesError) throw tracesError;

      // Get all trace IDs
      const traceIds = traces.map(trace => trace.id);

      // Get all searches for these traces in one query
      const { data: searches, error: searchesError } = await supabase
        .from('searches')
        .select('*, provider:providers(*)')
        .in('trace', traceIds);

      console.log(searches);

      if (searchesError) throw searchesError;

      // Get all companies for these traces in one query
      const { data: companies, error: companiesError } = await supabase
        .from('companies')
        .select('*')
        .in(
          'id',
          traces.flatMap(trace => trace.companies || [])
        );

      if (companiesError) throw companiesError;

      // Map searches and companies to their respective traces
      const tracesWithRelations = traces.map(trace => {
        const traceSearches = searches.filter(
          search => search.trace === trace.id
        );
        const traceCompanies = companies.filter(company =>
          trace.companies?.includes(company.id)
        );

        return new this({
          ...trace,
          searches: traceSearches,
          companies: traceCompanies
        });
      });

      return {
        data: tracesWithRelations,
        count,
        page: page,
        size: size,
        total: Math.ceil(count / size)
      };
    } catch (error) {
      console.error(`[Error] Fetching All ${this.name}: `, error);
      throw error;
    }
  }

  /**
   * Get all records using a specific function with filtering, sorting, and pagination.
   * @param {Object} filters - The filters to apply.
   * @param {number} page - The page number.
   * @param {number} size - The page size.
   * @param {string} sortBy - The field to sort by.
   * @param {string} sortType - The sort type ('asc' or 'desc').
   * @returns {Promise<Object>} A promise that resolves to an object containing the data and pagination info.
   */
  static async getAllByFunction(
    filters = {},
    page = 1,
    size = 10,
    sortBy = 'id',
    sortType = 'asc'
  ) {
    try {
      let query = supabase.rpc('get_traces_with_tags', {}, { count: 'exact' });

      query = this.filters(query, filters);
      query = this.sorting(query, sortBy, sortType);
      query = this.range(query, page, size);

      const { data, error, count } = await query;

      if (error) throw error;

      return {
        data: data.map(item => new this(item)),
        count: count,
        page: page,
        size: size,
        total: Math.ceil(count / size)
      };
    } catch (error) {
      console.error(`[Error] Fetching All by Function ${this.name}: `, error);
      throw error;
    }
  }

  /**
   * Get a record by its ID.
   * @param {string} id - The UUID of the record to fetch.
   * @returns {Promise<Trace>} A promise that resolves to a new instance of the model.
   */
  static async getById(id) {
    return this.fetchOne('display_id', id, this.selectQuery);
  }
}

export default Trace;
