import React from 'react';
import {
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType
} from 'react-router-dom';

import * as Sentry from '@sentry/react';

let sentryCreateBrowserRouter = createBrowserRouter;
let isSentryInitialized = false;

export const initializeSentry = ({
  enableTracing = false,
  enableReplays = false,
  enableFeedback = false,
  user
}) => {
  if (isSentryInitialized) return;

  const integrations = [];

  if (enableTracing) {
    sentryCreateBrowserRouter =
      Sentry.wrapCreateBrowserRouter(createBrowserRouter);
    integrations.push(
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      })
    );
  }

  if (enableReplays) {
    integrations.push(Sentry.replayIntegration());
  }

  let feedback;
  if (enableFeedback) {
    feedback = Sentry.feedbackIntegration({
      isEmailRequired: true,
      autoInject: false,
      showName: false,
      showEmail: false,
      useSentryUser: {
        name: 'fullName',
        email: 'email'
      }
    });
    integrations.push(feedback);
  }

  Sentry.setUser(user);

  Sentry.init({
    dsn: 'https://522a8b46ecae4c830a590bb0f4755d2a@o4508478590287872.ingest.de.sentry.io/4508478593826896',
    integrations,
    tracesSampleRate: 1.0,
    tracePropagationTargets: [
      'localhost',
      'https://crm.pensiontracingservice.com'
    ],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0
  });

  isSentryInitialized = true;
};

export { Sentry, sentryCreateBrowserRouter };
