import React, { useEffect, useState, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Task from '../../../models/Task';
import EntityTable from '../../../components/tables/EntityTable';
import { formatDate, formatProfile } from '../../../utilities/Formatting';
import { Link } from 'react-router-dom';
import { Pencil, Trash, SquareArrowOutUpRight } from 'lucide-react';
import {
  EntityTypeEnum,
  FilterOperatorEnum
} from '../../../utilities/Enumerables';
import CoreEntityType from '../../../models/CoreEntityType';
import TaskPopup from '../../../components/popups/tasks/TaskPopup';
import { UserProfileContext } from '../../../App';
import { Alert } from '@core/components';
import { PERMISSIONS } from '../../../utilities/Permissions';

const Tasks = () => {
  // Set the page title
  document.title = 'Tasks | CRM | PTS';
  const navigate = useNavigate();
  const { '*': wildcard, id } = useParams();
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [statusOptions, setStatusOptions] = useState([]);
  const [selectedTask, setSelectedTask] = useState(null);
  const { userProfile: currentUser } = useContext(UserProfileContext);

  if (
    !currentUser.hasPermission(
      EntityTypeEnum.Task,
      PERMISSIONS[EntityTypeEnum.Task].VIEW
    )
  ) {
    return (
      <Alert
        title='Permission Denied'
        description='You do not have permission to view tasks.'
        style='danger'
      />
    );
  }

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const categories = await CoreEntityType.getByEntityId(
          EntityTypeEnum.TaskCategory
        );
        const statuses = await CoreEntityType.getByEntityId(
          EntityTypeEnum.TaskStatus
        );

        setCategoryOptions(
          categories.map(category => ({
            key: category.id,
            value: category.type
          }))
        );
        setStatusOptions(
          statuses.map(status => ({ key: status.id, value: status.type }))
        );
      } catch (error) {
        console.error('Error fetching options:', error);
      }
    };

    fetchOptions();
  }, []);

  // Effect to handle both wildcard and direct ID routes
  useEffect(() => {
    const fetchTask = async () => {
      try {
        let taskId = id;
        // If no direct ID but wildcard exists, try to get ID from wildcard
        if (!taskId && wildcard) {
          taskId = wildcard.split('/')[0];
        }

        if (taskId) {
          const task = await Task.getById(taskId);
          if (task) {
            setSelectedTask(task);
          }
        }
      } catch (error) {
        console.error('Error fetching task:', error);
        navigate('/tasks'); // Redirect to tasks list if fetch fails
      }
    };

    fetchTask();
  }, [id, wildcard, navigate]);

  const menuItems = [
    // {
    //   label: 'Delete',
    //   icon: <Trash size={14} className='text-danger-700' />,
    //   onClick: data => console.log('Delete clicked', data)
    // }
  ];

  const handleRowClick = data => {
    setSelectedTask(data);
    navigate(`/tasks/${data.id}`);
  };

  const handleClosePopup = () => {
    setSelectedTask(null);
    navigate('/tasks');
  };

  return (
    <div className='p-4'>
      <EntityTable
        name='Tasks'
        customId='tasks'
        filter={[
          { field: 'id', label: 'ID', type: 'text' },
          {
            field: 'title',
            label: 'Title',
            type: 'text',
            operator: FilterOperatorEnum.ILIKE
          },
          {
            field: 'category',
            label: 'Category',
            type: 'select',
            options: categoryOptions
          },
          { field: 'priority', label: 'Priority', type: 'text' },
          {
            field: 'status',
            label: 'Status',
            type: 'select',
            options: statusOptions
          },
          {
            field: 'due_date',
            label: 'Due Date',
            type: 'daterange',
            operator: FilterOperatorEnum.BETWEEN
          },
          {
            field: 'created_date',
            label: 'Created Date',
            type: 'daterange',
            operator: FilterOperatorEnum.BETWEEN
          },
          {
            field: 'updated_date',
            label: 'Updated Date',
            type: 'daterange',
            operator: FilterOperatorEnum.BETWEEN
          }
        ]}
        columns={[
          {
            label: 'ID',
            field: 'id',
            cell: value => (
              <div className='text-brand-700 hover:text-brand-800'>{value}</div>
            )
          },
          { label: 'Title', field: 'title' },
          {
            label: 'Category',
            field: 'category',
            foreignKey: { table: 'category', column: 'type' },
            cell: value => value?.name
          },
          { label: 'Priority', field: 'priority' },
          {
            label: 'Status',
            field: 'status',
            foreignKey: { table: 'status', column: 'type' },
            cell: value => value?.name
          },
          {
            label: 'Due Date',
            field: 'dueDate',
            cell: value => formatDate(value)
          },
          {
            label: 'Created Date',
            field: 'createdDate',
            cell: value => formatDate(value)
          },
          {
            label: 'Updated Date',
            field: 'updatedDate',
            cell: value => formatDate(value)
          }
        ]}
        sortDefault={{ field: 'id', order: 'asc' }}
        menuItems={menuItems}
        model={Task}
        entityType={EntityTypeEnum.Task}
        onRowClick={handleRowClick}
      />
      {selectedTask && (
        <TaskPopup onClose={handleClosePopup} task={selectedTask} />
      )}
    </div>
  );
};

export default Tasks;
