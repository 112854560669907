import React from 'react';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { TextButton } from '@core/components';
import { Plus, Ellipsis } from 'lucide-react';
import { EntityTypeEnum } from '../../../utilities/Enumerables';
import { PERMISSIONS } from '../../../utilities/Permissions';

const PipelineBoard = ({
  columns,
  onDragEnd,
  currentUser,
  setShowEditPopup,
  setShowAddItemPopup,
  setSelectedGroupId
}) => {
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className='flex space-x-2 h-full'>
        {Object.entries(columns)
          .sort(([, a], [, b]) => a.order - b.order)
          .map(([columnId, column]) => (
            <div
              key={columnId}
              className={`w-72 flex-shrink-0 flex flex-col h-full rounded-md bg-${column.colour}-50 bg-opacity-50 p-2`}
            >
              <div className='flex justify-between group items-center mb-4'>
                <p
                  className={`font-medium text-xs bg-${column.colour}-600 text-white px-2 py-1 rounded-md flex items-center`}
                >
                  <span>{column.name}</span>
                  <span className='text-xs text-white ml-2 bg-opacity-50 bg-white px-1.5 py-0.5 rounded-full'>
                    {column.items.length}
                  </span>
                </p>
                <div className='flex items-center'>
                  {currentUser.hasPermission(
                    EntityTypeEnum.Pipelines,
                    PERMISSIONS[EntityTypeEnum.Pipelines].EDIT
                  ) && (
                    <TextButton
                      size='sm'
                      onClick={() => setShowEditPopup(true)}
                    >
                      <Ellipsis size={16} className='text-base-600' />
                    </TextButton>
                  )}
                  <TextButton
                    size='sm'
                    onClick={() => {
                      setSelectedGroupId(columnId);
                      setShowAddItemPopup(true);
                    }}
                  >
                    <Plus size={16} className='text-base-600' />
                  </TextButton>
                </div>
              </div>
              <div className='overflow-y-auto flex-grow'>
                <Droppable droppableId={columnId} type='item'>
                  {provided => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      className='min-h-[200px] space-y-2'
                    >
                      {column.items.map((item, index) => (
                        <Draggable
                          key={item.id || item.display_id}
                          draggableId={(item.id || item.display_id).toString()}
                          index={index}
                        >
                          {provided => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              className='bg-white dark:bg-base-700 p-1 rounded-lg text-sm border border-base-200 dark:border-base-600 hover:shadow-md transition-shadow duration-200'
                            >
                              {item.content}
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </div>
            </div>
          ))}
      </div>
    </DragDropContext>
  );
};

export default PipelineBoard;
