import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useContext
} from 'react';
import { supabase } from '../../../utilities/supabase';
import { useParams, useNavigate, NavLink } from 'react-router-dom';
import { SoftButton, Alert } from '@core/components';
import { EntityTypeEnum } from '../../../utilities/Enumerables';
import { Plus } from 'lucide-react';
import EditPipeline from '../../../components/popups/pipelines/EditPipeline';
import AddPipelineItem from '../../../components/popups/pipelines/AddPipelineItem';
import CreateBasicPipeline from '../../../components/popups/pipelines/CreateBasicPipeline';
import { UserProfileContext } from '../../../App';
import { PERMISSIONS } from '../../../utilities/Permissions';
import { useNotification } from '@core/components';
import PipelineBoard from './PipelineBoard';
import PipelineSidebar from './PipelineSidebar';
import PipelineItem from './PipelineItem';
import PipelineHeader from './PipelineHeader';

const Pipelines = () => {
  document.title = 'Pipelines | CRM | PTS';

  const [pipelines, setPipelines] = useState([]);
  const [selectedPipeline, setSelectedPipeline] = useState(null);
  const [columns, setColumns] = useState({});
  const [items, setItems] = useState([]);
  const [showBasicPopup, setShowBasicPopup] = useState(false);
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [showAddItemPopup, setShowAddItemPopup] = useState(false);
  const [selectedGroupId, setSelectedGroupId] = useState(null);
  const { id } = useParams();
  const navigate = useNavigate();
  const { userProfile: currentUser } = useContext(UserProfileContext);
  const { addNotification } = useNotification();

  useEffect(() => {
    let isMounted = true;
    const fetchPipelines = async () => {
      try {
        const userId = currentUser.id;

        const { data, error } = await supabase
          .from('pipelines')
          .select(
            '*, owner:user_profiles!owner(id,first_name,last_name,full_name,profile_photo)'
          )
          .or(`private.eq.false,and(private.eq.true,owner.eq.${userId})`)
          .order('title', { ascending: true });

        if (error) throw error;
        if (isMounted) {
          setPipelines(prevPipelines => {
            // Only update if the data has actually changed
            if (JSON.stringify(prevPipelines) !== JSON.stringify(data)) {
              return data;
            }
            return prevPipelines;
          });
        }
      } catch (error) {
        console.error('Error fetching pipelines:', error.message);
      }
    };

    if (currentUser.id) {
      fetchPipelines();
    }

    return () => {
      isMounted = false;
    };
  }, [currentUser.id]);

  useEffect(() => {
    if (pipelines.length > 0 && id) {
      const pipeline = pipelines.find(p => p.id == id);
      if (pipeline) {
        setSelectedPipeline(pipeline);
        console.log('Selected Pipeline:', pipeline);
      }
    }
  }, [pipelines, id]);

  const fetchItems = useCallback(async pipeline => {
    try {
      const { data: entityData } = await supabase
        .from('core_entities')
        .select('table')
        .eq('id', pipeline.entity)
        .single();

      const { data: pipelineEntities, error: pipelineError } = await supabase
        .from('pipeline_entities')
        .select('*')
        .eq('pipeline_id', pipeline.id);

      if (pipelineError) throw pipelineError;

      const { data: entityItems, error: entityError } = await supabase
        .from(entityData.table)
        .select(pipeline.columns)
        .in(
          pipeline.columns.split(',')[0], //will always be id or display_id
          pipelineEntities.map(pe => pe.entity_id)
        );

      if (entityError) throw entityError;

      const newItems = pipelineEntities.map(pe => {
        const entityItem = entityItems.find(
          ei => ei.id == pe.entity_id || ei.display_id == pe.entity_id
        );
        return {
          id: pe.id,
          item_id: entityItem.id,
          content: renderItemContent(
            entityItem,
            entityData.table,
            pipeline.config,
            pipeline.id
          ),
          group: pe.group,
          order: pe.order
        };
      });

      setItems(newItems);
      updateColumns(newItems, pipeline.groups);
    } catch (error) {
      console.error('Error fetching items:', error.message);
    }
  }, []);
  const updateColumns = useCallback((newItems, groups) => {
    setColumns(prevColumns => {
      const updatedColumns = {};
      groups.forEach(group => {
        updatedColumns[group.id] = {
          id: group.id,
          name: group.label,
          items: newItems
            .filter(item => item.group === group.id)
            .sort((a, b) => a.order - b.order),
          colour: group.colour,
          order: group.order
        };
      });
      return updatedColumns;
    });
  }, []);

  useEffect(() => {
    if (selectedPipeline) {
      fetchItems(selectedPipeline);
    }
  }, [selectedPipeline, fetchItems]);

  const onDragEnd = useCallback(
    async result => {
      if (!result.destination) return;
      const { source, destination, draggableId } = result;

      if (source.droppableId === destination.droppableId) {
        setColumns(prevColumns => {
          const newColumns = { ...prevColumns };
          const column = newColumns[source.droppableId];
          const newItems = Array.from(column.items);
          const [reorderedItem] = newItems.splice(source.index, 1);
          newItems.splice(destination.index, 0, reorderedItem);
          newColumns[source.droppableId] = {
            ...column,
            items: newItems
          };
          return newColumns;
        });
      } else {
        setColumns(prevColumns => {
          const newColumns = { ...prevColumns };
          const sourceColumn = newColumns[source.droppableId];
          const destColumn = newColumns[destination.droppableId];
          const sourceItems = [...sourceColumn.items];
          const destItems = [...destColumn.items];
          const [movedItem] = sourceItems.splice(source.index, 1);
          destItems.splice(destination.index, 0, movedItem);
          newColumns[source.droppableId] = {
            ...sourceColumn,
            items: sourceItems
          };
          newColumns[destination.droppableId] = {
            ...destColumn,
            items: destItems
          };
          return newColumns;
        });
      }

      updateDatabase(draggableId, destination);
    },
    [items, selectedPipeline]
  );

  const updateDatabase = async (draggableId, destination) => {
    try {
      const item = items.find(i => i.id.toString() === draggableId);
      if (!item) return;

      // Update the moved item
      const { error } = await supabase
        .from('pipeline_entities')
        .update({
          group: parseInt(destination.droppableId),
          order: destination.index
        })
        // .eq('pipeline_id', selectedPipeline.id)
        // .eq('entity_id', item.item_id)
        .eq('id', item.id);

      if (error) throw error;

      // Update other items in the same column
      const columnItems = columns[destination.droppableId].items;
      for (let i = 0; i < columnItems.length; i++) {
        if (columnItems[i].id !== item.id) {
          await supabase
            .from('pipeline_entities')
            .update({
              order: i >= destination.index ? i + 1 : i
            })
            .eq('pipeline_id', selectedPipeline.id)
            .eq('entity_id', columnItems[i].item_id);
        }
      }
    } catch (error) {
      console.error('Error updating items:', error.message);
    }
  };

  const removeItemFromPipeline = async (itemId, pipelineId) => {
    try {
      const { error } = await supabase
        .from('pipeline_entities')
        .delete()
        .eq('pipeline_id', pipelineId)
        .eq('entity_id', itemId);

      if (error) throw error;

      // Update local state
      setItems(prevItems => prevItems.filter(item => item.item_id !== itemId));
      setColumns(prevColumns => {
        const newColumns = { ...prevColumns };
        Object.keys(newColumns).forEach(columnId => {
          newColumns[columnId].items = newColumns[columnId].items.filter(
            item => item.item_id !== itemId
          );
        });
        return newColumns;
      });
    } catch (error) {
      console.error('Error removing item from pipeline:', error.message);
    }
  };

  const deletePipelineItem = async itemId => {
    try {
      await removeItemFromPipeline(itemId);
      console.log(`Item with ID ${itemId} has been deleted from the pipeline.`);
    } catch (error) {
      console.error('Error deleting pipeline item:', error.message);
    }
  };

  const renderBoardView = useMemo(
    () => (
      <PipelineBoard
        columns={columns}
        onDragEnd={onDragEnd}
        currentUser={currentUser}
        setShowEditPopup={setShowEditPopup}
        setShowAddItemPopup={setShowAddItemPopup}
        setSelectedGroupId={setSelectedGroupId}
      />
    ),
    [columns, onDragEnd]
  );

  const renderItemContent = (item, tableName, config, pipelineId) => (
    <PipelineItem
      pipelineId={pipelineId}
      item={item}
      tableName={tableName}
      config={config}
      removeItemFromPipeline={removeItemFromPipeline}
      deletePipelineItem={item => deletePipelineItem(item)}
    />
  );

  const handleBasicPipelineCreated = async newPipeline => {
    try {
      const { data, error } = await supabase
        .from('pipelines')
        .insert(newPipeline)
        .select(
          '*, owner:user_profiles!owner(id,first_name,last_name,full_name,profile_photo)'
        )
        .single();

      if (error) throw error;

      setPipelines([data, ...pipelines]);
      setSelectedPipeline(data);
      navigate(`/pipelines/${data.id}`);
    } catch (error) {
      console.error('Error creating basic pipeline:', error.message);
    }
  };

  const handlePipelineEdit = async editedPipeline => {
    try {
      const { owner, ...pipelineWithoutOwner } = editedPipeline;
      const { data, error } = await supabase
        .from('pipelines')
        .update({ ...pipelineWithoutOwner, owner: owner.id })
        .eq('id', editedPipeline.id)
        .select(
          '*, owner:user_profiles!owner(id,first_name,last_name,full_name,profile_photo)'
        );

      if (error) throw error;

      const updatedPipelines = pipelines.map(p =>
        p.id == editedPipeline.id ? data[0] : p
      );
      setPipelines(updatedPipelines);
      setSelectedPipeline(data[0]);
    } catch (error) {
      console.error('Error updating pipeline:', error.message);
      addNotification({
        type: 'error',
        title: 'Error updating the pipeline',
        description: error.message,
        duration: 4000
      });
    }
  };

  if (
    !currentUser.hasPermission(
      EntityTypeEnum.Pipelines,
      PERMISSIONS[EntityTypeEnum.Pipelines].VIEW
    )
  ) {
    return (
      <Alert
        title='Permission Denied'
        description='You do not have permission to view pipelines.'
        style='danger'
      />
    );
  }

  return (
    <div className='flex flex-col h-[calc(100vh-48px)] w-full bg-white dark:bg-base-900 overflow-hidden'>
      <div className='flex flex-1 flex-col md:flex-row overflow-hidden'>
        <div className='w-full sm:w-16 md:w-64 flex-shrink-0 bg-white border-r border-base-100 dark:bg-base-800 p-4'>
          <PipelineSidebar
            pipelines={pipelines}
            selectedPipeline={selectedPipeline}
            setSelectedPipeline={setSelectedPipeline}
            navigate={navigate}
            setShowBasicPopup={setShowBasicPopup}
          />
        </div>

        <div className='flex-1 w-full h-full p-4 overflow-hidden flex flex-col'>
          <PipelineHeader
            selectedPipeline={selectedPipeline}
            currentUser={currentUser}
            setShowEditPopup={setShowEditPopup}
            setShowAddItemPopup={setShowAddItemPopup}
          />
          <div className='overflow-x-auto w-full flex-1'>{renderBoardView}</div>
        </div>
        {showBasicPopup && (
          <CreateBasicPipeline
            onClose={() => setShowBasicPopup(false)}
            onSave={newPipeline => handleBasicPipelineCreated(newPipeline)}
          />
        )}
        {showAddItemPopup && (
          <AddPipelineItem
            pipelineId={id}
            pipelineGroup={selectedGroupId ?? 1}
            onClose={() => setShowAddItemPopup(false)}
            onItemAdded={() => {
              fetchItems(selectedPipeline);
            }}
          />
        )}
        {showEditPopup && (
          <EditPipeline
            pipeline={selectedPipeline}
            onClose={() => setShowEditPopup(false)}
            onSave={editedPipeline => {
              handlePipelineEdit(editedPipeline);
              setShowEditPopup(false);
            }}
          />
        )}
      </div>
    </div>
  );
};

export default Pipelines;
