import React, { useState, useEffect } from 'react';
import { Popup, Input, SoftButton } from '@core/components';
import { Clipboard } from 'lucide-react';
import { supabase } from '../../../utilities/supabase';
import { FilledButton } from '../../../../core/src/components/inputs/buttons/Button';

const PortalPopup = ({
  urls,
  user,
  isOpen,
  onClose,
  onSubmit,
  extraAttachements,
  autoSelectAttachements = []
}) => {
  const [copyStatus, setCopyStatus] = useState({});
  const [userInfo, setUserInfo] = useState({});
  const [address, setAddress] = useState('');
  const [traceName, setTraceName] = useState('');
  const [schemeName, setSchemeName] = useState('');
  const [reference, setReference] = useState('');
  const [attachments, setAttachments] = useState([]);

  useEffect(() => {
    const fetchUserInfo = async () => {
      if (user?.id) {
        const { data: userData, error: userError } = await supabase
          .from('user_profiles')
          .select('first_name, last_name, birth_date, national_insurance')
          .eq('id', user.owner.id)
          .single();

        if (userError) {
          console.error('Error fetching user info:', userError);
        } else {
          setUserInfo(userData);
        }

        const { data: addressData, error: addressError } = await supabase
          .from('addresses')
          .select('line1, postcode')
          .eq('entity_type', 20)
          .eq('entity_id', user.owner.id)
          .limit(1);

        if (addressError) {
          console.error('Error fetching address:', addressError);
        } else if (addressData && addressData.length > 0) {
          setAddress(
            `${addressData[0]?.line1 || ''} ${addressData[0]?.postcode || ''}`
          );
        }

        setTraceName(user.trace?.name || '');
        setSchemeName(user.scheme?.name || '');
        setReference(user.displayId || '');
      }
    };

    const fetchAttachments = async () => {
      try {
        if (!user) return;

        const entityAttachments = await supabase
          .from('documents')
          .select('*')
          .contains('connections', { 13: [user.id] });

        setAttachments(entityAttachments.data || []);
      } catch (error) {
        console.error('Error fetching attachments:', error);
      }
    };

    const fetchExtraAttachments = async () => {
      try {
        let allExtraAttachments = [];
        for (const attachment of extraAttachements) {
          const { data, error } = await supabase
            .from('documents')
            .select('*')
            .contains('connections', {
              [attachment.entityType]: [attachment.entityId]
            });

          if (error) throw error;

          allExtraAttachments = [...allExtraAttachments, ...(data || [])];
        }
        setAttachments(prevAttachments => [
          ...prevAttachments,
          ...allExtraAttachments
        ]);
      } catch (error) {
        console.error('Error fetching extra attachments:', error);
      }
    };

    fetchUserInfo();
    fetchAttachments();
    fetchExtraAttachments();
  }, [user, extraAttachements]);

  const handleCopy = (text, field) => {
    navigator.clipboard.writeText(text);
    setCopyStatus({ [field]: true });
    setTimeout(() => {
      setCopyStatus({ [field]: false });
    }, 2000);
  };

  const handleOpenUrl = url => {
    window.open(url, '_blank');
  };

  const handleComplete = () => {
    if (onSubmit) {
      onSubmit(user);
    }
    onClose();
  };

  const filteredAttachments = attachments.filter(attachment =>
    autoSelectAttachements.some(
      criteria =>
        attachment.type == criteria || attachment.file_name.includes(criteria)
    )
  );

  const handleDownloadAll = () => {
    filteredAttachments.forEach(attachment => {
      window.open(
        `https://storage.googleapis.com/pensiontracingservice/${attachment.file_path}/${attachment.id}/${attachment.file_name}`,
        '_blank'
      );
    });
  };

  return (
    <Popup
      isOpen={isOpen}
      onClose={onClose}
      title='Portal'
      size='lg'
      footer={
        <div className='flex justify-end'>
          <FilledButton onClick={handleComplete}>Complete</FilledButton>
        </div>
      }
    >
      <div className='w-full h-auto p-4'>
        <h3 className='text-lg font-semibold'>Website</h3>
        {urls?.map((url, index) => (
          <div key={index} className='flex items-center mb-4 space-x-2'>
            <Input
              label={`URL ${index + 1}`}
              value={url}
              readOnly
              className='flex-grow'
            />
            <SoftButton
              onClick={() => handleOpenUrl(url)}
              className='mt-5 h-full'
              style={{ height: '100%' }}
            >
              Go
            </SoftButton>
          </div>
        ))}
        <h3 className='text-lg font-semibold'>Details</h3>
        <div className='flex items-center mb-4 space-x-2'>
          <Input
            label='First Name'
            value={userInfo.first_name || ''}
            readOnly
            className='flex-grow'
          />
          <SoftButton
            onClick={() => handleCopy(userInfo.first_name || '', 'first_name')}
            className='mt-5 h-full'
            style={{ height: '100%' }}
            leftIcon={<Clipboard />}
          >
            {copyStatus.first_name ? 'Copied' : 'Copy'}
          </SoftButton>
          <Input
            label='Last Name'
            value={userInfo.last_name || ''}
            readOnly
            className='flex-grow'
          />
          <SoftButton
            onClick={() => handleCopy(userInfo.last_name || '', 'last_name')}
            className='mt-5 h-full'
            style={{ height: '100%' }}
            leftIcon={<Clipboard />}
          >
            {copyStatus.last_name ? 'Copied' : 'Copy'}
          </SoftButton>
          <Input
            label='Date of Birth'
            value={new Date(userInfo.birth_date).toLocaleDateString() || ''}
            readOnly
            className='flex-grow'
          />
          <SoftButton
            onClick={() =>
              handleCopy(new Date().toLocaleDateString() || '', 'birth_date')
            }
            className='mt-5 h-full'
            style={{ height: '100%' }}
            leftIcon={<Clipboard />}
          >
            {copyStatus.birth_date ? 'Copied' : 'Copy'}
          </SoftButton>
        </div>
        <div className='flex items-center mb-4 space-x-2'>
          <Input
            label='National Insurance Number'
            value={userInfo.national_insurance || ''}
            readOnly
            className='flex-grow'
          />
          <SoftButton
            onClick={() =>
              handleCopy(
                userInfo.national_insurance || '',
                'national_insurance'
              )
            }
            className='mt-5 h-full'
            style={{ height: '100%' }}
            leftIcon={<Clipboard />}
          >
            {copyStatus.national_insurance ? 'Copied' : 'Copy'}
          </SoftButton>
        </div>
        <div className='flex items-center mb-8 space-x-2'>
          <Input
            label='Address'
            value={address}
            readOnly
            className='flex-grow'
          />
          <SoftButton
            onClick={() => handleCopy(address, 'address')}
            className='mt-5 h-full'
            style={{ height: '100%' }}
            leftIcon={<Clipboard />}
          >
            {copyStatus.address ? 'Copied' : 'Copy'}
          </SoftButton>
        </div>
        <div className='flex items-center mb-4 space-x-2'>
          <Input
            label='Employer Name'
            value={traceName}
            readOnly
            className='flex-grow'
          />
          <SoftButton
            onClick={() => handleCopy(traceName, 'trace_name')}
            className='mt-5 h-full'
            style={{ height: '100%' }}
            leftIcon={<Clipboard />}
          >
            {copyStatus.trace_name ? 'Copied' : 'Copy'}
          </SoftButton>
          <Input
            label='Scheme Name'
            value={schemeName}
            readOnly
            className='flex-grow'
          />
          <SoftButton
            onClick={() => handleCopy(schemeName, 'scheme_name')}
            className='mt-5 h-full'
            style={{ height: '100%' }}
            leftIcon={<Clipboard />}
          >
            {copyStatus.scheme_name ? 'Copied' : 'Copy'}
          </SoftButton>
        </div>
        <div className='flex items-center mb-8 space-x-2'>
          <Input
            label='Reference'
            value={reference}
            readOnly
            className='flex-grow'
          />
          <SoftButton
            onClick={() => handleCopy(reference, 'reference')}
            className='mt-5 h-full'
            style={{ height: '100%' }}
            leftIcon={<Clipboard />}
          >
            {copyStatus.reference ? 'Copied' : 'Copy'}
          </SoftButton>
        </div>
        {filteredAttachments.length > 0 && (
          <div className='flex flex-col'>
            <div className='flex items-center justify-between mb-2'>
              <h3 className='text-lg font-semibold'>Attachments</h3>
              <SoftButton onClick={handleDownloadAll}>Download All</SoftButton>
            </div>
            {filteredAttachments.map((attachment, index) => (
              <div
                key={index}
                className='flex items-center border border-gray-200 p-2 rounded mb-2'
              >
                <span className='flex-grow text-sm'>
                  {attachment.file_name}
                </span>
                <SoftButton
                  onClick={() => {
                    window.open(
                      `https://storage.googleapis.com/pensiontracingservice/${attachment.file_path}/${attachment.id}/${attachment.file_name}`,
                      '_blank'
                    );
                  }}
                  className='h-full'
                  style={{ height: '100%' }}
                >
                  Download
                </SoftButton>
              </div>
            ))}
          </div>
        )}
      </div>
    </Popup>
  );
};

export default PortalPopup;
