import React, { useState, useEffect } from 'react';
import { FilledButton } from '@core/components';
import CommentModel from '../../../models/Comment';
import { TextEditor } from '@core/components';
import Comment from './Comment';
import { useLocation } from 'react-router-dom';
import { Send } from 'lucide-react';
import { EntityTypeEnum } from '../../../utilities/Enumerables';

const Comments = ({ taskId }) => {
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState('');
  const [highlightedCommentId, setHighlightedCommentId] = useState(null);
  const location = useLocation();

  useEffect(() => {
    fetchComments();
    const hash = location.hash.slice(1);
    if (hash) {
      setHighlightedCommentId(parseInt(hash));
    }
  }, [taskId, location]);

  const fetchComments = async () => {
    try {
      const { data } = await CommentModel.getAll(
        {
          entity_type: { value: EntityTypeEnum.Task },
          entity_id: { value: taskId }
        },
        1,
        100,
        'created_date',
        'desc'
      );
      setComments(data);
    } catch (error) {
      console.error('Error fetching comments:', error);
    }
  };

  const handleCommentChange = content => {
    setNewComment(content);
  };

  const handleAddComment = async () => {
    try {
      const newCommentData = {
        entity_type: 24,
        entity_id: taskId,
        comment: newComment
      };
      const comment = new CommentModel(newCommentData);
      const createdComment = await comment.insert();
      setComments([createdComment, ...comments]);
      setNewComment('');
    } catch (error) {
      console.error('Error adding comment:', error);
    }
  };

  return (
    <div className='space-y-4'>
      <div className='relative'>
        <TextEditor
          value={newComment}
          onChange={handleCommentChange}
          placeholder='Add a comment...'
          type='rich'
        />
        <div className='absolute bottom-2 right-2'>
          <FilledButton
            onClick={handleAddComment}
            disabled={!newComment?.trim()}
            colour='primary'
            className='p-2'
          >
            <Send size={16} />
          </FilledButton>
        </div>
      </div>
      <div className='space-y-4'>
        {comments.map(comment => (
          <Comment
            key={comment.id}
            comment={comment}
            isHighlighted={comment.id === highlightedCommentId}
          />
        ))}
      </div>
    </div>
  );
};

export default Comments;
