import React, { useState, useEffect } from 'react';
import {
  Combobox,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
  Dialog,
  DialogPanel,
  DialogBackdrop
} from '@headlessui/react';
import { Search, X, LifeBuoy, Clock, Trash2, ChevronDown } from 'lucide-react';
import { debounce } from 'lodash';
import { supabase } from '../../utilities/supabase';
import Table from '../tables/Table';
import { Badge, SoftButton, FlatButton } from '@core/components';
import { Link } from 'react-router-dom';

export default function GlobalSearch() {
  const [isOpen, setIsOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchFilter, setSearchFilter] = useState('all');
  const [recentSearches, setRecentSearches] = useState([]);

  const filterOptions = [
    { value: 'all', label: 'All' },
    { value: 'users', label: 'Users' },
    { value: 'traces', label: 'Traces' },
    { value: 'searches', label: 'Searches' },
    { value: 'pensions', label: 'Pensions' },
    { value: 'companies', label: 'Company' },
    { value: 'providers', label: 'Providers' },
    { value: 'schemes', label: 'Schemes' }
  ];

  useEffect(() => {
    // Load recent searches from localStorage on mount
    const savedSearches = localStorage.getItem('recentSearches');
    if (savedSearches) {
      setRecentSearches(JSON.parse(savedSearches));
    }
  }, []);

  useEffect(() => {
    const handleKeyDown = e => {
      if (e.ctrlKey && e.key === 'k') {
        e.preventDefault();
        setIsOpen(true);
      } else if (e.key === 'Escape') {
        setSearchQuery('');
        setIsOpen(false);
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, []);

  const addToRecentSearches = query => {
    if (!query.trim()) return;

    const updatedSearches = [
      query,
      ...recentSearches.filter(s => s !== query)
    ].slice(0, 5);

    setRecentSearches(updatedSearches);
    localStorage.setItem('recentSearches', JSON.stringify(updatedSearches));
  };

  const removeRecentSearch = searchToRemove => {
    const updatedSearches = recentSearches.filter(
      search => search !== searchToRemove
    );
    setRecentSearches(updatedSearches);
    localStorage.setItem('recentSearches', JSON.stringify(updatedSearches));
  };

  const clearAllRecentSearches = () => {
    setRecentSearches([]);
    localStorage.removeItem('recentSearches');
  };

  const handleFilterChange = value => {
    setSearchFilter(value);
    if (searchQuery) {
      performSearch(searchQuery, value);
    }
  };

  const performSearch = async (query, filter = searchFilter) => {
    if (!query.trim()) {
      setSearchResults([]);
      return;
    }
    setIsLoading(true);

    // Handle special search filters
    if (query.startsWith('?')) {
      setSearchFilter('help');
      setSearchResults([]);
      setIsLoading(false);
      return;
    }

    // Check for filter prefixes and update dropdown accordingly
    if (query.startsWith('user:')) {
      setSearchFilter('users');
      query = query.replace('user:', '').trim();
    } else if (query.startsWith('trace:')) {
      setSearchFilter('traces');
      query = query.replace('trace:', '').trim();
    } else if (query.startsWith('search:')) {
      setSearchFilter('searches');
      query = query.replace('search:', '').trim();
    } else if (query.startsWith('pension:')) {
      setSearchFilter('pensions');
      query = query.replace('pension:', '').trim();
    } else if (query.startsWith('company:')) {
      setSearchFilter('companies');
      query = query.replace('company:', '').trim();
    } else if (query.startsWith('provider:')) {
      setSearchFilter('providers');
      query = query.replace('provider:', '').trim();
    } else if (query.startsWith('scheme:')) {
      setSearchFilter('schemes');
      query = query.replace('scheme:', '').trim();
    }

    try {
      const { data, error } = await supabase.rpc('global_search', {
        search_query: query,
        filter: filter
      });
      if (error) throw error;
      setSearchResults(data);
      addToRecentSearches(query);
    } catch (error) {
      console.error('Error performing search: ', error);
    } finally {
      setIsLoading(false);
    }
  };

  const debouncedSearch = debounce(performSearch, 500);

  const handleSearchChange = e => {
    const value = e.target.value;
    setSearchQuery(value);
    debouncedSearch(value);
  };

  const handleRecentSearchClick = query => {
    setSearchQuery(query);
    performSearch(query);
  };

  const getTableHeaders = results => {
    const headers = {};
    results.forEach(result => {
      if (!headers[result.table_name]) {
        headers[result.table_name] = new Set(Object.keys(result.data));
      } else {
        Object.keys(result.data).forEach(key =>
          headers[result.table_name].add(key)
        );
      }
    });
    return headers;
  };

  const createColumns = (path, headers, linkableFields = []) => {
    return Array.from(headers)
      .filter(header => !header.endsWith('_path'))
      .map(header => ({
        label:
          header.charAt(0).toUpperCase() + header.slice(1).replace(/_/g, ' '),
        field: header,
        cell: (value, rowData) => {
          // Handle date fields
          if (header.includes('date') && value) {
            return new Date(value).toLocaleDateString();
          }

          // Handle linkable fields
          if (linkableFields.includes(header) && value) {
            const linkPath =
              header === 'id'
                ? path
                : rowData[`${header}_path`] || header.replace('_id', '');
            return (
              <Link
                to={`/${linkPath}/${value}`}
                className='text-brand-700 hover:text-brand-800'
                onClick={() => setIsOpen(false)}
              >
                {value}
              </Link>
            );
          }

          // Default case
          return value || 'N/A';
        }
      }));
  };

  return (
    <>
      <FlatButton
        onClick={() => setIsOpen(true)}
        colour='primary'
        size='lg'
        className='lg:text-primary-600 text-white'
      >
        <Search size={20} />
      </FlatButton>

      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        className='relative z-50'
      >
        <DialogBackdrop className='fixed inset-0 bg-black/30' />

        <div className='fixed inset-0 overflow-y-auto p-4 sm:p-6 md:p-20'>
          <DialogPanel className='mx-auto max-w-3xl transform divide-y divide-gray-100 overflow-visible rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5'>
            <Combobox onChange={() => {}}>
              <div className='relative flex'>
                <Search className='pointer-events-none absolute left-3 top-3.5 h-5 w-5 text-gray-400' />
                <ComboboxInput
                  className='h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-900 focus:ring-0'
                  placeholder='Search...'
                  onChange={handleSearchChange}
                  value={searchQuery}
                  onKeyDown={e => {
                    if (e.key === 'Enter') {
                      handleSearchChange(e);
                    }
                  }}
                />
                <div className='relative'>
                  <Combobox value={searchFilter} onChange={handleFilterChange}>
                    <div className='relative'>
                      <Combobox.Button className='relative w-32 cursor-default bg-white py-3 pl-3 pr-10 text-left border-r border-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-brand-500 focus-visible:ring-opacity-75 sm:text-sm rounded-tr-xl '>
                        <span className='block truncate font-medium text-right'>
                          {
                            filterOptions.find(
                              option => option.value === searchFilter
                            )?.label
                          }
                        </span>
                        <span className='pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2'>
                          <ChevronDown
                            className='h-4 w-4 text-gray-500'
                            aria-hidden='true'
                          />
                        </span>
                      </Combobox.Button>
                      <Combobox.Options className='absolute mt-1 max-h-60 w-48 overflow-auto rounded-md bg-white py-1 text-sm shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-[9999]'>
                        {filterOptions.map(option => (
                          <Combobox.Option
                            key={option.value}
                            value={option.value}
                            className={({ active }) =>
                              `relative cursor-pointer select-none py-2 px-4 ${
                                active
                                  ? 'bg-gray-100 text-gray-800'
                                  : 'text-gray-700 hover:bg-gray-50'
                              }`
                            }
                          >
                            {({ active, selected }) => (
                              <span
                                className={`block truncate ${
                                  selected ? 'font-medium' : 'font-normal'
                                }`}
                              >
                                {option.label}
                              </span>
                            )}
                          </Combobox.Option>
                        ))}
                      </Combobox.Options>
                    </div>
                  </Combobox>
                </div>
              </div>

              {!searchQuery && recentSearches.length > 0 && (
                <div className='p-2 overflow-hidden'>
                  <div className='flex justify-between items-center px-2 py-1'>
                    <h4 className='text-xs font-medium text-gray-500 flex items-center gap-1'>
                      <Clock size={12} /> Recent Searches
                    </h4>
                    <button
                      onClick={clearAllRecentSearches}
                      className='text-xs text-gray-500 hover:text-gray-700'
                    >
                      Clear all
                    </button>
                  </div>
                  {recentSearches.map((search, index) => (
                    <div
                      key={index}
                      className='flex items-center justify-between group'
                    >
                      <button
                        className='flex-grow text-left px-2 py-1.5 text-sm text-gray-700 hover:bg-gray-100 rounded'
                        onClick={() => handleRecentSearchClick(search)}
                      >
                        {search}
                      </button>
                      <button
                        onClick={e => {
                          e.stopPropagation();
                          removeRecentSearch(search);
                        }}
                        className='px-2 text-gray-400 hover:text-gray-600 opacity-0 group-hover:opacity-100'
                      >
                        <X size={14} />
                      </button>
                    </div>
                  ))}
                </div>
              )}

              {searchResults.length > 0 && (
                <div className='max-h-[32rem] overflow-y-auto p-4'>
                  {(() => {
                    const groupedResults = searchResults.reduce(
                      (acc, result) => {
                        const tableName = result.table_name;
                        if (!acc[tableName]) acc[tableName] = [];
                        acc[tableName].push(result);
                        return acc;
                      },
                      {}
                    );

                    const tableHeaders = getTableHeaders(searchResults);

                    return Object.entries(groupedResults).map(
                      ([tableName, results]) => (
                        <div key={tableName} className='mb-6'>
                          <h3 className='text-sm font-semibold text-base-900 mb-2'>
                            {tableName}
                          </h3>
                          <Table
                            size='xs'
                            columns={createColumns(
                              results[0].path,
                              tableHeaders[tableName],
                              results[0].linkable_fields
                            )}
                            data={results.map(result => result.data)}
                            isLoading={isLoading}
                            isError={false}
                            rowClassName='hover:bg-gray-100'
                          />
                        </div>
                      )
                    );
                  })()}
                </div>
              )}

              {searchQuery && !isLoading && searchResults.length === 0 && (
                <div className='p-6 text-center'>
                  {searchQuery === '?' ? (
                    <div className='space-y-4 items-center justify-center text-center'>
                      <div className='flex justify-center'>
                        <LifeBuoy size={20} className='text-base-400' />
                      </div>
                      <h3 className='text-base font-medium text-base-900'>
                        Help with searching
                      </h3>
                      <div className='text-sm text-base-600 space-y-2'>
                        <p>
                          The global search allows you to find any entity
                          matching your search query. You can enter a simple
                          query to retrieve results from all tables, or you can
                          specify a table with your query using prefixes.
                        </p>
                        <p className='mt-2'>
                          <span className='font-medium bg-base-100 px-2 py-0.5 rounded'>
                            company:pts
                          </span>
                          <span className='ml-2 text-base-500'>
                            Search for companies containing "pts"
                          </span>
                        </p>
                        <p className='mt-1'>
                          <span className='font-medium bg-base-100 px-2 py-0.5 rounded'>
                            user:john
                          </span>
                          <span className='ml-2 text-base-500'>
                            Search for users containing "john"
                          </span>
                        </p>
                        <p>
                          <span className='font-medium'>?</span> - Show this
                          help menu
                        </p>
                        <p className='text-xs text-base-400 italic mt-4'>
                          Tip: Press{' '}
                          <kbd className='px-2 py-1 text-xs font-semibold bg-base-100 rounded'>
                            Ctrl
                          </kbd>{' '}
                          +{' '}
                          <kbd className='px-2 py-1 text-xs font-semibold bg-base-100 rounded'>
                            K
                          </kbd>{' '}
                          to quickly open search from anywhere
                        </p>
                      </div>
                    </div>
                  ) : (
                    <p className='text-sm text-base-500'>No results found</p>
                  )}
                </div>
              )}

              <div className='flex items-center justify-between gap-x-4 p-3 border-t border-gray-50 bg-base-50 rounded-xl'>
                <div className='flex gap-x-2'>
                  {/* <span className='text-xs text-base-600'>
                    <Badge>#</Badge> for settings
                  </span> */}
                  <span className='text-xs text-base-600'>
                    <Badge>?</Badge> for help
                  </span>
                </div>
                <span className='text-xs text-base-600'>
                  Current filter: {searchFilter}
                </span>
              </div>
            </Combobox>
          </DialogPanel>
        </div>
      </Dialog>
    </>
  );
}
