import React from 'react';
import { useSortable } from '@dnd-kit/react/sortable';
import { DragDropProvider, useDroppable } from '@dnd-kit/react';
import { CollisionPriority } from '@dnd-kit/abstract';
import { arrayMove } from '@dnd-kit/helpers';
import { Popup, FilledButton, CircularIconButton } from '@core/components';
import { useState, useEffect, useCallback } from 'react';
import { useContext } from 'react';
import { UserProfileContext } from '../../../App';
import * as LucideIcons from 'lucide-react';
import EntityPopupHandler from '../EntityPopupHandler';

export const defaultActions = {
  active: [
    {
      icon: 'Edit',
      label: 'Note',
      handler: 'handleNoteClick',
      order: 1,
      disabled: false
    },
    {
      icon: 'Mail',
      label: 'Email',
      handler: 'handleEmailClick',
      order: 2,
      disabled: false
    },
    {
      icon: 'CheckSquare',
      label: 'Task',
      handler: 'handleTaskClick',
      order: 3,
      disabled: false
    },
    {
      icon: 'Calendar',
      label: 'Meeting',
      handler: 'handleMeetingClick',
      order: 4,
      disabled: false
    },
    {
      icon: 'KanbanSquare',
      label: 'Pipelines',
      handler: 'handlePipelinesClick',
      order: 5,
      disabled: false
    }
  ],
  available: [
    {
      icon: 'Tag',
      label: 'Tags',
      handler: 'handleTagsClick',
      order: 6,
      disabled: false
    },
    {
      icon: 'PhoneCall',
      label: 'Call',
      handler: 'handleCallClick',
      order: 7,
      disabled: true
    },
    {
      icon: 'MessageSquare',
      label: 'SMS',
      handler: 'handleSMSClick',
      order: 8,
      disabled: false
    },
    {
      icon: 'Headphones',
      label: 'Audio',
      handler: 'handleAudioClick',
      order: 9,
      disabled: true
    },
    {
      icon: 'Video',
      label: 'Video',
      handler: 'handleVideoClick',
      order: 10,
      disabled: true
    },
    {
      icon: 'MessageCircle',
      label: 'Ai Chat',
      handler: 'handleAiChatClick',
      order: 11,
      disabled: true
    },
    {
      icon: 'Phone',
      label: 'Ai Call',
      handler: 'handleAiCallClick',
      order: 12,
      disabled: true
    },
    {
      icon: 'Cpu',
      label: 'AiFlows',
      handler: 'handleAiFlowsClick',
      order: 13,
      disabled: false
    },
    {
      icon: 'FileText',
      label: 'DocFlows',
      handler: 'handleDocFlowsClick',
      order: 14,
      disabled: false
    },
    {
      icon: 'CreditCard',
      label: 'E-Card',
      handler: 'handleECardClick',
      order: 15,
      disabled: false
    },
    {
      icon: 'Users',
      label: 'Audience',
      handler: 'handleAudienceClick',
      order: 16,
      disabled: false
    },
    {
      icon: 'File',
      label: 'Page',
      handler: 'handlePageClick',
      order: 17,
      disabled: true
    },
    {
      icon: 'Link',
      label: 'Links',
      handler: 'handleLinksClick',
      order: 18,
      disabled: true
    },
    {
      icon: 'Gift',
      label: 'Gift',
      handler: 'handleGiftClick',
      order: 19,
      disabled: true
    }
  ]
};

const DraggableItem = ({
  id,
  index,
  group,
  itemData,
  isEditing,
  onHandlerClicked
}) => {
  const { ref, isDragging } = useSortable({
    id,
    index,
    type: 'item',
    accept: 'item',
    group: group,
    disabled: !isEditing
  });

  const handleActionClick = useCallback(() => {
    if (!isEditing && !itemData.disabled && itemData.handler) {
      onHandlerClicked(itemData.handler);
    }
  }, [isEditing, itemData, onHandlerClicked]);

  return (
    <div
      className={`relative flex justify-center ${
        isEditing ? 'hover:bg-base-50 cursor-grab rounded-xl' : ''
      }`}
      ref={ref}
      data-dragging={isDragging}
    >
      <div className='relative'>
        <CircularIconButton
          Icon={LucideIcons[itemData.icon]}
          label={itemData.label}
          onClick={handleActionClick}
          colour={itemData.disabled ? 'base' : 'brand'}
          className={isEditing ? 'pointer-events-none' : itemData.className}
        />
      </div>
    </div>
  );
};

const DroppableGroup = ({ children, id, isEditing }) => {
  const { isDropTarget, ref } = useDroppable({
    id,
    type: 'group',
    accept: 'item',
    collisionPriority: CollisionPriority.Low,
    disabled: !isEditing
  });
  return (
    <div className={`${isDropTarget ? 'bg-base-50' : ''}`} ref={ref}>
      {children}
    </div>
  );
};

const QuickActions = ({
  isOpen = false,
  onClose,
  onUpdate,
  entityType,
  entityId,
  entity
}) => {
  const { userProfile: currentUser, setUserProfile } =
    useContext(UserProfileContext);
  const [items, setItems] = useState({ active: [], available: [] });
  const [isEditing, setIsEditing] = useState(false);
  const [draftItems, setDraftItems] = useState({ active: [], available: [] });
  const [activePopup, setActivePopup] = useState(null);

  useEffect(() => {
    const loadActions = async () => {
      let actions =
        currentUser?.crmPreferences?.quick_actions || defaultActions;

      // Check if actions match expected format
      const hasValidFormat =
        actions.active &&
        Array.isArray(actions.active) &&
        actions.available &&
        Array.isArray(actions.available);

      if (!hasValidFormat) {
        actions = defaultActions;
        // Save default actions to user preferences
        if (currentUser?.crmPreferences) {
          currentUser.crmPreferences.quick_actions = defaultActions;
          currentUser.crmPreferences.quick_actions_last_updated =
            new Date().toISOString();
          await currentUser.update();
        }
      }

      const initialItems = {
        active: actions.active || [],
        available: actions.available || []
      };

      setItems(initialItems);
      setDraftItems(initialItems);
    };

    loadActions();
  }, [currentUser]);

  const handleSave = async () => {
    const updatedActions = draftItems;
    currentUser.crmPreferences.quick_actions = updatedActions;
    currentUser.crmPreferences.quick_actions_last_updated =
      new Date().toISOString();
    const newUser = await currentUser.update();
    setUserProfile(newUser);
    setItems(draftItems);
    setIsEditing(false);
  };

  const handleCancelEditing = () => {
    setDraftItems(items);
    setIsEditing(false);
  };

  const handleReset = () => {
    const resetItems = {
      active: defaultActions.active,
      available: defaultActions.available
    };
    setDraftItems(resetItems);
  };

  const handleActionClick = useCallback(
    handler => {
      if (!isEditing) {
        setActivePopup(handler);
      }
    },
    [isEditing]
  );

  const handleDragEnd = event => {
    const source = event.operation?.source?.sortable;

    // Guard against invalid or duplicate events
    if (!source || !source.initialGroup || !source.group) {
      console.warn('Invalid drag operation:', event);
      return;
    }

    const { initialIndex, index, initialGroup, group } = source;

    // Create new draft state to avoid direct mutations
    const newDraftItems = { ...draftItems };

    // Handle same group sorting
    if (initialGroup === group) {
      newDraftItems[group] = arrayMove(
        draftItems[group],
        initialIndex,
        index
      ).map((item, idx) => ({
        ...item,
        order: idx + 1
      }));
    } else {
      // Different groups
      const sourceArray = [...draftItems[initialGroup]];
      const targetArray = [...draftItems[group]];

      // Ensure arrays exist before proceeding
      if (!sourceArray || !targetArray) {
        console.warn('Invalid group arrays:', { sourceArray, targetArray });
        return;
      }

      const [movedItem] = sourceArray.splice(initialIndex, 1);
      targetArray.splice(index, 0, movedItem);

      newDraftItems[initialGroup] = sourceArray.map((item, idx) => ({
        ...item,
        order: idx + 1
      }));
      newDraftItems[group] = targetArray.map((item, idx) => ({
        ...item,
        order: idx + 1
      }));
    }

    setDraftItems(newDraftItems);

    //initialGroup = string
    //intialIndex = number
    //group = text
    //index = number
  };

  return (
    <>
      <Popup isOpen={isOpen} onClose={onClose} title='Quick Actions' size='sm'>
        <DragDropProvider
          onDragEnd={handleDragEnd}
          key={JSON.stringify(draftItems)}
        >
          <div className='mb-4'>
            <h3 className='text-lg font-semibold mb-2 flex items-center justify-between'>
              <div>
                Quick Actions{' '}
                <span className='text-sm font-normal'>
                  ({draftItems?.active.length}/11)
                </span>
              </div>
              <div className='flex flex-row space-x-2'>
                <FilledButton
                  onClick={() =>
                    isEditing ? handleCancelEditing() : setIsEditing(true)
                  }
                  colour='base'
                >
                  {isEditing ? <LucideIcons.X /> : <LucideIcons.Edit2 />}
                </FilledButton>
                {isEditing && (
                  <div className='flex flex-row space-x-2'>
                    <FilledButton onClick={handleReset} colour='base'>
                      <LucideIcons.RotateCcw />
                    </FilledButton>
                    <FilledButton onClick={handleSave} colour='primary'>
                      <LucideIcons.Save />
                    </FilledButton>
                  </div>
                )}
              </div>
            </h3>
            <DroppableGroup id='active' isEditing={isEditing}>
              <div className='grid grid-cols-6 gap-2 p-2 border border-base-100 rounded-xl'>
                {draftItems.active.map((item, index) => (
                  <DraggableItem
                    key={item.label}
                    id={item.label}
                    index={index}
                    group='active'
                    itemData={item}
                    isEditing={isEditing}
                    onHandlerClicked={handleActionClick}
                  />
                ))}
              </div>
            </DroppableGroup>
          </div>

          <div>
            <h3 className='text-lg font-semibold mb-2'>Available Actions</h3>
            <DroppableGroup id='available' isEditing={isEditing}>
              <div className='grid grid-cols-6 gap-2 p-2 border border-base-100 rounded-xl'>
                {draftItems.available.map((item, index) => (
                  <DraggableItem
                    key={item.label}
                    id={item.label}
                    index={index}
                    group='available'
                    itemData={item}
                    isEditing={isEditing}
                    onHandlerClicked={handleActionClick}
                  />
                ))}
              </div>
            </DroppableGroup>
          </div>
        </DragDropProvider>
      </Popup>
      <EntityPopupHandler
        entityType={entityType}
        entityId={entityId}
        entity={entity}
        onUpdate={onUpdate}
        handler={activePopup}
      />
    </>
  );
};

export default QuickActions;
