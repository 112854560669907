import React from 'react';
import FeedItem from './FeedItem';
import { UserProfilePicture } from '@core/components';

const Feed = ({ items, onPin, onUnpin, logTypes }) => {
  return (
    <>
      <ul role='list' className='space-y-6'>
        {items.map((item, index) => (
          <li key={item.id} className='relative flex gap-x-4'>
            <div
              className={`${
                index === items.length - 1 ? 'h-6' : '-bottom-6'
              } absolute left-0 top-0 flex w-6 justify-center`}
            >
              <div className='w-px bg-neutral-200'></div>
            </div>
            {item.auditType?.id === 42 ? (
              <div className='relative flex h-6 w-6 top-1.5 flex-none items-center justify-center bg-white'>
                <UserProfilePicture
                  firstName={item.owner.firstName}
                  lastName={item.owner.lastName}
                  profilePicture={item.owner.profilePicture}
                  size='md'
                />
              </div>
            ) : logTypes[item.auditType?.id]?.icon ? (
              <div className='relative flex h-6 w-6 top-1.5 flex-none items-center justify-center bg-white'>
                {logTypes[item.auditType?.id].icon}
              </div>
            ) : (
              <div className='relative flex h-6 w-6 flex-none items-center justify-center bg-white'>
                <div className='h-1.5 w-1.5 rounded-full bg-neutral-100 ring-1 ring-neutral-300' />
              </div>
            )}
            <FeedItem
              item={item}
              logTypes={logTypes}
              onPin={onPin}
              onUnpin={onUnpin}
            />
          </li>
        ))}
      </ul>
    </>
  );
};

export default Feed;
