import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import EntityTable from '../../../components/tables/EntityTable';
import { formatDate } from '../../../utilities/Formatting';
import { Link } from 'react-router-dom';
import { Pencil, Trash } from 'lucide-react';
import {
  EntityTypeEnum,
  FilterOperatorEnum
} from '../../../utilities/Enumerables';
import CoreEntityType from '../../../models/CoreEntityType';
import Provider from '../../../models/Provider';
import { Modal, Alert } from '@core/components';
import { UserProfileContext } from '../../../App';
import { PERMISSIONS } from '../../../utilities/Permissions';

const Providers = () => {
  document.title = 'Providers | CRM | PTS';
  const navigate = useNavigate();
  const [statusOptions, setStatusOptions] = useState([]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [providerToDelete, setProviderToDelete] = useState(null);
  const { userProfile: currentUser } = useContext(UserProfileContext);

  if (
    !currentUser.hasPermission(
      EntityTypeEnum.Provider,
      PERMISSIONS[EntityTypeEnum.Provider].VIEW
    )
  ) {
    return (
      <Alert
        title='Permission Denied'
        description='You do not have permission to view providers.'
        style='danger'
      />
    );
  }

  useEffect(() => {
    const fetchStatusOptions = async () => {
      try {
        const statuses = await CoreEntityType.getByEntityId(
          EntityTypeEnum.ProviderStatus
        );
        setStatusOptions(
          statuses.map(status => ({ value: status.type, key: status.id }))
        );
      } catch (error) {
        console.error('Error fetching status options:', error);
      }
    };

    fetchStatusOptions();
  }, []);

  const handleDeleteProvider = async () => {
    try {
      const updatedProvider = new Provider({
        ...(await providerToDelete.toDatabase()),
        status: 98
      });
      await updatedProvider.update();
      setIsDeleteModalOpen(false);
      setProviderToDelete(null);
    } catch (error) {
      console.error('Error archiving provider:', error);
    }
  };

  const menuItems = [
    {
      label: 'Edit',
      icon: <Pencil size={14} />,
      onClick: data => navigate(`/providers/${data.id}`)
    },
    {
      label: 'Delete',
      icon: <Trash size={14} className='text-danger-700' />,
      onClick: data => {
        setProviderToDelete(data);
        setIsDeleteModalOpen(true);
      }
    }
  ];

  const handleRowClick = data => {
    navigate(`/providers/${data.id}`);
  };

  const handleCreate = () => {
    navigate('/providers/create');
  };

  return (
    <div className='p-4'>
      <EntityTable
        fromFunction={true}
        name='Providers'
        customId='providers'
        filter={[
          {
            field: 'id',
            label: 'ID',
            type: 'text',
            operator: FilterOperatorEnum.EQUALS
          },
          {
            field: 'name',
            label: 'Provider Name',
            type: 'text',
            operator: FilterOperatorEnum.ILIKE
          },
          {
            field: 'status',
            label: 'Status',
            type: 'select',
            options: statusOptions
          },
          {
            field: 'created_date',
            label: 'Created Date',
            type: 'daterange',
            operator: FilterOperatorEnum.BETWEEN
          },
          {
            field: 'updated_date',
            label: 'Updated Date',
            type: 'daterange',
            operator: FilterOperatorEnum.BETWEEN
          },
          {
            field: 'showArchived',
            label: 'Show Archived',
            hint: 'Show archived traces',
            type: 'conditional_toggle',
            operator: FilterOperatorEnum.IS,
            options: {
              value: 'true'
            }
          },
          {
            field: 'phones',
            label: 'Phone Number',
            type: 'text',
            operator: FilterOperatorEnum.CONTAINS
          }
        ]}
        columns={[
          {
            label: 'ID',
            field: 'id',
            cell: value => (
              <Link
                to={`/providers/${value}`}
                className='text-brand-700 hover:text-brand-800'
              >
                {value}
              </Link>
            )
          },
          { label: 'Provider Name', field: 'name' },
          {
            label: 'Status',
            field: 'status',
            cell: value => value.type
          },
          { label: 'Traces', field: 'traceCount' },
          {
            label: 'Created Date',
            field: 'createdDate',
            cell: value => formatDate(value)
          },
          {
            label: 'Updated Date',
            field: 'updatedDate',
            cell: value => formatDate(value)
          }
        ]}
        menuItems={menuItems}
        model={Provider}
        defaultFilters={{
          status: { value: '98', operator: FilterOperatorEnum.NOT_EQUALS }
        }}
        entityType={EntityTypeEnum.Provider}
        onRowClick={handleRowClick}
        canCreate={true}
        onNewClick={handleCreate}
      />
      <Modal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        title='Delete Provider'
        style='danger'
        actions={[
          {
            label: 'Cancel',
            onClick: () => setIsDeleteModalOpen(false),
            style: 'base'
          },
          {
            label: 'Delete',
            onClick: handleDeleteProvider,
            style: 'danger'
          }
        ]}
      >
        <p className='text-sm text-base-600'>
          Are you sure you want to delete this provider? This action cannot be
          undone.
        </p>
      </Modal>
    </div>
  );
};

export default Providers;
