import React, { useEffect, useState, useRef } from 'react';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import { createPortal } from 'react-dom';

/**
 * Available Tailwind Colors:
 *
 * slate-50, slate-100, slate-200, slate-300, slate-400, slate-500, slate-600, slate-700, slate-800, slate-900, slate-950
 * base-50, base-100, base-200, base-300, base-400, base-500, base-600, base-700, base-800, base-900, base-950
 * zinc-50, zinc-100, zinc-200, zinc-300, zinc-400, zinc-500, zinc-600, zinc-700, zinc-800, zinc-900, zinc-950
 * neutral-50, neutral-100, neutral-200, neutral-300, neutral-400, neutral-500, neutral-600, neutral-700, neutral-800, neutral-900, neutral-950
 * stone-50, stone-100, stone-200, stone-300, stone-400, stone-500, stone-600, stone-700, stone-800, stone-900, stone-950
 * red-50, red-100, red-200, red-300, red-400, red-500, red-600, red-700, red-800, red-900, red-950
 * orange-50, orange-100, orange-200, orange-300, orange-400, orange-500, orange-600, orange-700, orange-800, orange-900, orange-950
 * amber-50, amber-100, amber-200, amber-300, amber-400, amber-500, amber-600, amber-700, amber-800, amber-900, amber-950
 * yellow-50, yellow-100, yellow-200, yellow-300, yellow-400, yellow-500, yellow-600, yellow-700, yellow-800, yellow-900, yellow-950
 * lime-50, lime-100, lime-200, lime-300, lime-400, lime-500, lime-600, lime-700, lime-800, lime-900, lime-950
 * green-50, green-100, green-200, green-300, green-400, green-500, green-600, green-700, green-800, green-900, green-950
 * emerald-50, emerald-100, emerald-200, emerald-300, emerald-400, emerald-500, emerald-600, emerald-700, emerald-800, emerald-900, emerald-950
 * teal-50, teal-100, teal-200, teal-300, teal-400, teal-500, teal-600, teal-700, teal-800, teal-900, teal-950
 * cyan-50, cyan-100, cyan-200, cyan-300, cyan-400, cyan-500, cyan-600, cyan-700, cyan-800, cyan-900, cyan-950
 * sky-50, sky-100, sky-200, sky-300, sky-400, sky-500, sky-600, sky-700, sky-800, sky-900, sky-950
 * blue-50, blue-100, blue-200, blue-300, blue-400, blue-500, blue-600, blue-700, blue-800, blue-900, blue-950
 * indigo-50, indigo-100, indigo-200, indigo-300, indigo-400, indigo-500, indigo-600, indigo-700, indigo-800, indigo-900, indigo-950
 * violet-50, violet-100, violet-200, violet-300, violet-400, violet-500, violet-600, violet-700, violet-800, violet-900, violet-950
 * purple-50, purple-100, purple-200, purple-300, purple-400, purple-500, purple-600, purple-700, purple-800, purple-900, purple-950
 * fuchsia-50, fuchsia-100, fuchsia-200, fuchsia-300, fuchsia-400, fuchsia-500, fuchsia-600, fuchsia-700, fuchsia-800, fuchsia-900, fuchsia-950
 * pink-50, pink-100, pink-200, pink-300, pink-400, pink-500, pink-600, pink-700, pink-800, pink-900, pink-950
 * rose-50, rose-100, rose-200, rose-300, rose-400, rose-500, rose-600, rose-700, rose-800, rose-900, rose-950
 */
const tailwindcolours = [
  'zinc',
  'red',
  'orange',
  'amber',
  'yellow',
  'lime',
  'green',
  'emerald',
  'teal',
  'cyan',
  'sky',
  'blue',
  'indigo',
  'violet',
  'purple',
  'fuchsia',
  'pink',
  'rose'
];

const shades = [50, 100, 200, 300, 400, 500, 600, 700, 800, 900, 950];

const ColourPicker = ({
  colour,
  onChange,
  showShades = true,
  rounded = 'lg'
}) => {
  const [selectedColour, setSelectedColour] = useState(
    colour?.split('-')[0] || 'base'
  );
  const [selectedShade, setSelectedShade] = useState(
    parseInt(colour?.split('-')[1]) || 500
  );
  const buttonRef = useRef(null);
  const [buttonPosition, setButtonPosition] = useState({ top: 0, left: 0 });

  useEffect(() => {
    if (colour) {
      setSelectedColour(colour?.split('-')[0]);
      setSelectedShade(parseInt(colour?.split('-')[1]) || 500);
    }
  }, [colour]);

  useEffect(() => {
    if (buttonRef.current) {
      const rect = buttonRef.current.getBoundingClientRect();
      setButtonPosition({
        top: rect.bottom + window.scrollY,
        left: rect.left + window.scrollX
      });
    }
  }, [buttonRef.current]);

  const handleColourChange = newColour => {
    event.preventDefault();
    event.stopPropagation();
    setSelectedColour(newColour);
    onChange(`${newColour}-${selectedShade}`);
  };

  const handleShadeChange = newShade => {
    event.preventDefault();
    event.stopPropagation();
    setSelectedShade(newShade);
    onChange(`${selectedColour}-${newShade}`);
  };

  return (
    <Menu as='div' className='relative inline-block text-left'>
      <div>
        <MenuButton
          ref={buttonRef}
          className={`w-8 h-8 rounded-${rounded} border-2 bg-${selectedColour}-${selectedShade} border-base-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500`}
        >
          <span className='sr-only'>Open colour picker</span>
        </MenuButton>

        {createPortal(
          <MenuItems
            className='absolute z-[9999] w-60 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'
            style={{
              position: 'absolute',
              top: buttonPosition.top + 8,
              left: buttonPosition.left
            }}
          >
            <div className='py-1'>
              <div className='px-4 py-2'>
                <p className='text-sm font-medium text-base-700 mb-1'>
                  Colours:
                </p>
                <div className='flex flex-wrap gap-2 justify-center'>
                  {tailwindcolours.map(colourOption => (
                    <button
                      key={colourOption}
                      className={`w-6 h-6 rounded-${rounded} border-2 bg-${colourOption}-600 ${
                        selectedColour === colourOption
                          ? 'border-primary-800'
                          : 'border-transparent'
                      }`}
                      onClick={() => handleColourChange(colourOption)}
                      title={colourOption}
                    />
                  ))}
                </div>
                <p className='text-sm text-center pt-2'>{selectedColour}</p>
              </div>
              {showShades && (
                <div className='px-4 py-2 border-t border-base-100'>
                  <p className='text-sm font-medium text-base-700 mb-1'>
                    Shades:
                  </p>
                  <div className='flex flex-wrap gap-2 justify-center'>
                    {shades.map(shade => (
                      <button
                        key={shade}
                        className={`w-6 h-6 rounded-${rounded} border-2 bg-${selectedColour}-${shade} ${
                          selectedShade === shade
                            ? 'border-base-800'
                            : 'border-transparent'
                        }`}
                        onClick={() => handleShadeChange(shade)}
                        title={shade}
                      />
                    ))}
                  </div>
                  <p className='text-sm text-center pt-2'>{selectedShade}</p>
                </div>
              )}
            </div>
          </MenuItems>,
          document.body
        )}
      </div>
    </Menu>
  );
};

export { ColourPicker };
