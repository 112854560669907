import React, { useState, useEffect } from 'react';
import { supabase } from '../../../utilities/supabase';
import UserProfile from '../../../models/UserProfile';
import Accordion from '../../entity/Accordion';
import {
  LogoLoader,
  FilledButton,
  OutlineButton,
  NotificationItem,
  Input,
  MultiSelectBox
} from '@core/components';
import { Send } from 'lucide-react';

const StageTwo = ({ selectedTemplate, onBack, onClose }) => {
  const [mergeTags, setMergeTags] = useState({});
  const [to, setTo] = useState([]);
  const [from, setFrom] = useState('Pension Tracing Service');
  const [message, setMessage] = useState('');
  const [preview, setPreview] = useState('');
  const [errors, setErrors] = useState({});
  const [showNotification, setShowNotification] = useState(false);
  const [notificationConfig, setNotificationConfig] = useState({});
  const [isSending, setIsSending] = useState(false);

  const extractMergeTags = text => {
    const regex = /\{\{(.*?)\}\}/g;
    const tags = [];
    let match;
    while ((match = regex.exec(text)) !== null) {
      tags.push(match[1].trim());
    }
    return tags;
  };

  useEffect(() => {
    const tags = extractMergeTags(selectedTemplate.content);
    const reducedTags = tags.reduce((acc, tag) => ({ ...acc, [tag]: '' }), {});
    setMergeTags(reducedTags);
    setMessage(selectedTemplate.content);
  }, [selectedTemplate]);

  const handleMergeTagChange = (tag, value) => {
    setMergeTags(prev => {
      const newTags = { ...prev, [tag]: value };
      updatePreview(selectedTemplate.content, newTags);
      return newTags;
    });
  };

  const updatePreview = (content, tags) => {
    let newPreview = content;
    Object.entries(tags).forEach(([tag, value]) => {
      newPreview = newPreview.replace(
        new RegExp(`\\{\\{\\s*${tag}\\s*\\}\\}`, 'g'),
        value
      );
    });
    setPreview(newPreview);
  };

  useEffect(() => {
    updatePreview(selectedTemplate.content, mergeTags);
  }, [selectedTemplate.content, mergeTags]);

  const loadUserOptions = async inputValue => {
    try {
      const { data } = await UserProfile.getAll(
        {
          fts: {
            operator: 'textSearch',
            value: inputValue
          }
        },
        1,
        10
      );
      return data.flatMap(user =>
        user.phones.map(phone => ({
          value: phone.number,
          label: `${user.firstName} ${user.lastName} (${phone.number})`
        }))
      );
    } catch (error) {
      console.error('Error loading user options:', error);
      return [];
    }
  };

  const validateField = (field, value) => {
    let error = '';
    switch (field) {
      case 'to':
        if (value.length === 0) {
          error = 'Please specify at least one recipient';
        }
        break;
      case 'from':
        if (!value.trim()) {
          error = 'From number is required';
        }
        break;
      case 'message':
        if (!value.trim()) {
          error = 'Message is required';
        }
        break;
      default:
        break;
    }
    setErrors(prev => ({ ...prev, [field]: error }));
    return !error;
  };

  const handleToChange = newTo => {
    console.log(newTo);
    setTo(newTo);
    validateField('to', newTo);
  };

  const handleFromChange = e => {
    const newFrom = e.target.value;
    setFrom(newFrom);
    validateField('from', newFrom);
  };

  const handleMessageChange = e => {
    const newMessage = e.target.value;
    setMessage(newMessage);
    validateField('message', newMessage);
  };

  const sendSMS = async () => {
    setIsSending(true);
    const isToValid = validateField('to', to);
    const isFromValid = validateField('from', from);
    const isMessageValid = validateField('message', message);

    if (!isToValid || !isFromValid || !isMessageValid) {
      setNotificationConfig({
        type: 'error',
        title: 'Form Validation Error',
        description: 'Please check and complete all required fields.'
      });
      setShowNotification(true);
      setIsSending(false);
      return;
    }

    try {
      const { error } = await supabase.functions.invoke('/audience/sms/send', {
        body: {
          to: to.map(recipient => recipient.value),
          from: from,
          data: Object.fromEntries(
            Object.entries(mergeTags).map(([key, value]) => [key, value])
          ),
          templateId: selectedTemplate.id
        }
      });

      if (error) throw error;

      setNotificationConfig({
        type: 'success',
        title: 'SMS Sent',
        description: 'Your message has been sent successfully.'
      });
      setShowNotification(true);

      setTimeout(() => {
        onClose();
      }, 2000);
    } catch (error) {
      console.error('Error sending SMS:', error);
      setErrors(prev => ({
        ...prev,
        general: 'Failed to send SMS. Please try again.'
      }));
    } finally {
      setIsSending(false);
    }
  };

  const accordionItems = [
    {
      title: 'SMS Details',
      content: (
        <>
          <div className='w-full mb-4'>
            <MultiSelectBox
              label='To'
              loadOptions={loadUserOptions}
              selectedOptions={to}
              onChange={handleToChange}
              placeholder='Enter phone number or search for user'
              allowCreate={false}
              error={errors.to}
            />
          </div>
          <Input
            label='From'
            value={from}
            onChange={handleFromChange}
            className='mb-4'
            error={errors.from}
            placeholder='+44...'
          />
        </>
      )
    },
    {
      title: 'Merge Tags',
      content: (
        <>
          {Object.entries(mergeTags).map(([tag, value]) => (
            <div key={tag} className='mb-4'>
              <Input
                label={tag}
                value={value}
                onChange={e => handleMergeTagChange(tag, e.target.value)}
              />
            </div>
          ))}
        </>
      )
    }
  ];

  return (
    <div className='flex flex-col'>
      {showNotification && (
        <NotificationItem
          type={notificationConfig.type}
          title={notificationConfig.title}
          description={notificationConfig.description}
          onClose={() => setShowNotification(false)}
          offset={{ top: 20, right: 20 }}
        />
      )}
      <div className='flex'>
        <div className='w-1/3 pr-1 flex flex-col'>
          <h3 className='font-semibold mb-4 text-lg'>Content</h3>
          <div>
            <Accordion
              items={accordionItems}
              showTopBorder={false}
              singleOpen={true}
            />
          </div>
        </div>
        <div className='w-2/3 pl-4 flex flex-col'>
          <h3 className='font-semibold mb-4 text-lg'>Preview</h3>
          <div className='border rounded-2xl bg-white shadow-inner w-full h-full flex flex-col'>
            <div className='bg-neutral-100 p-3 rounded-t-2xl border-b'>
              <div className='text-center text-sm text-neutral-600 font-medium'>
                Pension Tracing Service
              </div>
              <div className='text-center text-xs text-neutral-500'>{from}</div>
            </div>
            <div className='p-4 flex-1'>
              <div className='bg-brand-300 text-neutral-800 text-sm p-3 rounded-2xl rounded-tl-sm max-w-[85%] shadow-sm'>
                {preview}
              </div>
            </div>
            <div className='border-t p-3 flex items-center'>
              <input
                type='text'
                className='flex-1 rounded-lg px-4 py-2 mr-2 text-sm outline-none text-white placeholder-gray-400'
                placeholder='Type a message...'
                disabled
              />
              <button
                className='bg-brand-500 text-white rounded-full p-2 hover:bg-brand-600 disabled:opacity-50 flex items-center justify-center w-10 h-10'
                disabled
              >
                <Send className='w-5 h-5' />
              </button>
            </div>
          </div>
        </div>
      </div>
      {errors.general && (
        <p className='text-danger-600 mt-4'>{errors.general}</p>
      )}
      <div className='mt-8 flex justify-end'>
        <OutlineButton colour='base' onClick={onBack} className='mr-4'>
          Back
        </OutlineButton>
        <FilledButton colour='primary' onClick={sendSMS} disabled={isSending}>
          {isSending ? 'Sending...' : 'Send SMS'}
        </FilledButton>
      </div>
    </div>
  );
};

export default StageTwo;
