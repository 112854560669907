import React, { useState, useEffect, useCallback, useContext } from 'react';
import {
  Popup,
  Input,
  SoftButton,
  Badge,
  FilledButton,
  DropdownInput,
  ComboBox,
  Pagination,
  Modal,
  useContextMenu,
  ContextMenu,
  Alert,
  UserProfilePicture
} from '@core/components';
import {
  Search,
  ArrowRight,
  Eye,
  ListOrdered,
  Link as LinkIcon,
  Plus,
  Check,
  X,
  Building,
  Calendar,
  SearchIcon,
  ChevronDown,
  User,
  Phone,
  MapPin,
  Mail,
  History,
  Loader
} from 'lucide-react';
import { supabase } from '../../../utilities/supabase';
import AiLogo from '../../../assets/+Ai.png';
import Address from '../../../models/Address';
import { EntityTypeEnum } from '../../../utilities/Enumerables';
import Checkbox from '../../inputs/Checkbox';
import { Link } from 'react-router-dom';
import { debounce } from 'lodash';
import { createPortal } from 'react-dom';
import { UserProfileContext } from '../../../App';

const AssistedTrace = ({ isOpen, onClose, trace, user }) => {
  const { userProfile: currentUser } = useContext(UserProfileContext);
  const [stage, setStage] = useState(1);
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [selectedProviders, setSelectedProviders] = useState([]);
  const [localProviders, setLocalProviders] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const [companies, setCompanies] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingSource, setLoadingSource] = useState('');
  const [providersLoading, setProvidersLoading] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [providerPages, setProviderPages] = useState({});
  const [providerItemsPerPage, setProviderItemsPerPage] = useState({});
  const [creating, setCreating] = useState(false);
  const [creatingProgress, setCreatingProgress] = useState(0);
  const [providerMatches, setProviderMatches] = useState({});
  const [selectedMatches, setSelectedMatches] = useState({});
  const [manualSearch, setManualSearch] = useState({});
  const [companiesPerPage, setCompaniesPerPage] = useState(
    currentUser?.crmPreferences?.assistedTrace?.companiesPerPage ?? 10
  );
  const [isCompaniesModalOpen, setIsCompaniesModalOpen] = useState(false);
  const [companiesList, setCompaniesList] = useState([]);
  const [isPreviousNamesModalOpen, setIsPreviousNamesModalOpen] =
    useState(false);
  const [previousNamesList, setPreviousNamesList] = useState([]);
  const [isRemoveProviderModalOpen, setIsRemoveProviderModalOpen] =
    useState(false);
  const [providerToRemove, setProviderToRemove] = useState(null);
  const [isAddProviderModalOpen, setIsAddProviderModalOpen] = useState(false);
  const [newProvider, setNewProvider] = useState({});
  const [addProviderCompanyId, setAddProviderCompanyId] = useState(null);
  const [archivedWarning, setArchivedWarning] = useState(false);
  const [isCreateGovernmentModalOpen, setIsCreateGovernmentModalOpen] =
    useState(false);
  const [newGovernmentName, setNewGovernmentName] = useState('');
  const [isProviderCompaniesModalOpen, setIsProviderCompaniesModalOpen] =
    useState(false);
  const [providerCompaniesList, setProviderCompaniesList] = useState([]);
  const [activeHoverCard, setActiveHoverCard] = useState(null);

  const { contextMenu, handleContextMenu, closeContextMenu } = useContextMenu();

  const [isProvidersLoading, setIsProvidersLoading] = useState(false);

  const providerMenuItems = [
    {
      label: 'Delete',
      onClick: data => {
        setProviderToRemove(data);
        setIsRemoveProviderModalOpen(true);
      },
      condition: data => data.status.id !== 145 // Do not allow delete if provider is verified
    }
  ];

  const companyMenuItems = [
    {
      label: 'Convert to Government',
      onClick: async data => {
        try {
          const { error } = await supabase
            .from('companies')
            .update({ status: 90 }) // Assuming 90 is the ID for 'Government'
            .eq('id', data.id);

          if (error) throw error;

          setCompanies(prev =>
            prev.map(company =>
              company.id === data.id
                ? { ...company, status: 'Government', status_id: 90 }
                : company
            )
          );
        } catch (error) {
          console.error('[Error] Converting to Government: ', error);
        }
      },
      condition: data => data.status_id !== 90 && data.source === 'database' // Only show if the company is not a government and is from the database
    },
    {
      label: 'Convert to Active',
      onClick: async data => {
        try {
          const { error } = await supabase
            .from('companies')
            .update({ status: 78 }) // Assuming 83 is the ID for 'Active'
            .eq('id', data.id);

          if (error) throw error;

          setCompanies(prev =>
            prev.map(company =>
              company.id === data.id
                ? { ...company, status: 'Active', status_id: 78 }
                : company
            )
          );
        } catch (error) {
          console.error('[Error] Converting to Active: ', error);
        }
      },
      condition: data => data.status_id === 90 && data.source === 'database' // Only show if the company is a government and is from the database
    }
  ];

  const updateCrmPreferences = async (newItemsPerPage, user, type) => {
    try {
      const { data: userProfile, error: fetchError } = await supabase
        .from('user_profiles')
        .select('crm_preferences')
        .eq('id', user.id)
        .single();

      if (fetchError) {
        console.error('Error fetching user profile:', fetchError);
        return;
      }

      const updatedPreferences = {
        ...userProfile?.crm_preferences,
        assistedTrace: {
          ...userProfile.crm_preferences?.assistedTrace,
          [type === 'company' ? 'companiesPerPage' : 'providersPerPage']:
            newItemsPerPage
        }
      };

      const { error: updateError } = await supabase
        .from('user_profiles')
        .update({ crm_preferences: updatedPreferences })
        .eq('id', user.id);

      if (updateError) {
        console.error('Error updating user profile:', updateError);
      } else {
        console.log('User profile updated successfully');
      }
    } catch (err) {
      console.error('Unexpected error updating user profile:', err);
    }
  };

  useEffect(() => {
    if (trace) {
      setSearchTerm(trace.name || '');
      setStartDate(trace.metadata?.start_year || '');
      setEndDate(trace.metadata?.end_year || '');

      setTimeout(() => {
        fetchCompanies(trace.name);
      }, 0);
    }

    setStage(1);
    setSelectedCompanies([]);
    setSelectedProviders([]);
    setLocalProviders({});
    setCompanies([]);
    setCurrentPage(1);
    setProviderPages({});
    setProviderItemsPerPage({});
    setLoading(false);
    setLoadingSource('');
    setProvidersLoading({});
    setCreating(false);
    setCreatingProgress(0);
    setProviderMatches({});
    setSelectedMatches({});
    setManualSearch({});
  }, [trace]);

  const fetchCompanies = async searchTerm => {
    if (!searchTerm) return;

    setLoading(true);
    setLoadingSource('database');
    setSelectedCompanies([]);
    setSelectedProviders([]);
    setCurrentPage(1);

    try {
      const { data: matches, error } = await supabase.rpc(
        'get_company_similarity',
        {
          p_name: searchTerm
        }
      );

      if (error) throw error;

      if (matches && matches.length > 0) {
        const companiesWithLinks = matches.map(company => ({
          ...company,
          linkedCompanies: company.companies || []
        }));

        const { data: mainCompanies } = await supabase
          .from('companies')
          .select(
            `
            *,
            status:core_entity_types(id, type)
          `
          )
          .in(
            'id',
            matches.map(c => c.id)
          )
          .neq('status', 149); // Exclude companies with status 149

        const { data: linkedCompanies } = await supabase
          .from('companies')
          .select(
            `
            *,
            status:core_entity_types(id, type)
          `
          )
          .in(
            'id',
            companiesWithLinks.flatMap(c => c?.linkedCompanies)
          )
          .neq('status', 149); // Exclude linked companies with status 149

        if (mainCompanies) {
          const linkedCompaniesWithAddresses = await Promise.all(
            linkedCompanies?.map(async company => {
              const { data: addressData, error } = await Address.getAll(
                {
                  entity_type: { value: EntityTypeEnum.Company },
                  entity_id: { value: company.id }
                },
                1,
                1
              );

              if (error) {
                console.error(
                  `[Error] Fetching Address For ${company.id} (Linked Company): `,
                  error
                );

                return {
                  ...company,
                  status: company.status?.type || 'Unknown',
                  status_id: company.status?.id || null,
                  address: 'N/A',
                  source: 'database'
                };
              }

              const address = addressData[0];
              return {
                ...company,
                status: company.status?.type || 'Unknown',
                status_id: company.status?.id || null,
                address: address
                  ? [
                      address.line1,
                      address.line2,
                      address.line3,
                      address.city,
                      address.postcode,
                      address.country
                    ]
                      .filter(Boolean)
                      .join(', ')
                  : 'N/A',
                source: 'database'
              };
            }) || []
          );

          const companiesWithAddresses = await Promise.all(
            mainCompanies.map(async company => {
              const { data: addressData, error } = await Address.getAll(
                {
                  entity_type: { value: EntityTypeEnum.Company },
                  entity_id: { value: company.id }
                },
                1,
                1
              );

              if (error) {
                console.error(
                  `[Error] Fetching Address For ${company.id} (Company): `,
                  error
                );

                return {
                  ...company,
                  status: company.status?.type || 'Unknown',
                  status_id: company.status?.id || null,
                  address: 'N/A',
                  source: 'database',
                  score: matches.find(m => m.id === company.id)?.score || 0,
                  linkedCompanies:
                    linkedCompaniesWithAddresses.filter(lc =>
                      companiesWithLinks.find(
                        cwl =>
                          cwl.id === company.id &&
                          cwl?.linkedCompanies?.includes(lc.id)
                      )
                    ) || []
                };
              }

              const address = addressData[0];

              // Fetch trace count for the company based on type
              let traceCountData, traceCountError;

              if (company.source === 'edge') {
                // If from edge, find all companies by number and use their ids
                const { data: companyIds, error: companyIdsError } =
                  await supabase
                    .from('companies')
                    .select('id')
                    .eq('number', company.number);

                if (companyIdsError) {
                  console.error(
                    `[Error] Fetching Company IDs For ${company.number}: `,
                    companyIdsError
                  );
                }

                const ids = companyIds?.map(c => c.id) || [];

                ({ data: traceCountData, error: traceCountError } =
                  await supabase
                    .from('traces')
                    .select('id', { count: 'exact' })
                    .contains('companies', ids));
              } else {
                ({ data: traceCountData, error: traceCountError } =
                  await supabase
                    .from('traces')
                    .select('id', { count: 'exact' })
                    .contains('companies', [company.id]));
              }

              if (traceCountError) {
                console.error(
                  `[Error] Fetching Trace Count For ${company.id}: `,
                  traceCountError
                );
              }

              const traceCount = traceCountData?.length || 0;

              return {
                ...company,
                status: company.status?.type || 'Unknown',
                status_id: company.status?.id || null,
                address: address
                  ? [
                      address.line1,
                      address.line2,
                      address.line3,
                      address.city,
                      address.postcode,
                      address.country
                    ]
                      .filter(Boolean)
                      .join(', ')
                  : 'N/A',
                source: 'database',
                score: matches.find(m => m.id === company.id)?.score || 0,
                linkedCompanies:
                  linkedCompaniesWithAddresses.filter(lc =>
                    companiesWithLinks.find(
                      cwl =>
                        cwl.id === company.id &&
                        cwl?.linkedCompanies?.includes(lc.id)
                    )
                  ) || [],
                traceCount // Add trace count to the company object
              };
            })
          );

          setCompanies(companiesWithAddresses);
        }
      } else {
        setLoadingSource('edge');
        const { data: remoteCompanies, error: remoteError } =
          await supabase.functions.invoke('assistedtrace/company', {
            body: { company: searchTerm }
          });

        if (remoteError) throw remoteError;

        if (remoteCompanies.type === 'success') {
          const formattedCompanies = remoteCompanies.data.map(
            ({ id, name, address, status, created, ended, previous }) => ({
              id,
              name,
              number: id,
              address,
              status,
              source: 'edge',
              linkedCompanies: [],
              created,
              ended,
              previous_names: previous ? [previous] : [],
              traceCount: 0 // Default trace count for remote companies
            })
          );

          setCompanies(formattedCompanies);
        }
      }
    } catch (error) {
      console.error(`[Error] Fetching Companies: `, error);
    } finally {
      setLoading(false);
      setLoadingSource('');
    }
  };

  const isCompanyInDateRange = company => {
    console.log(company);
    const createdDate = company.created ? new Date(company.created) : null;
    const endedDate = company.ended ? new Date(company.ended) : null;
    const start = startDate ? new Date(startDate) : null;
    const end = endDate ? new Date(endDate) : null;

    return (
      (!createdDate && !endedDate) ||
      (!start && !end) ||
      ((!start ||
        (createdDate <= start && (!endedDate || start <= endedDate))) &&
        (!end ||
          !endedDate ||
          (start <= end && (!endedDate || end <= endedDate))))
    );
  };

  const fetchProvidersForCompany = async company => {
    setProvidersLoading(prev => ({ ...prev, [company.id]: true }));

    try {
      let found = true;
      let providers;

      if (company.source === 'edge') {
        const { data: dbCompany, error: dbError } = await supabase
          .from('companies')
          .select('id')
          .eq('number', company.number);

        if (dbError) throw dbError;

        if (!dbCompany) found = false;
        else {
          company.id = dbCompany[0].id;
        }
      }

      if (found) {
        const { data: providersData, error: providerError } = await supabase
          .from('algorithm')
          .select(
            `status:core_entity_types(
              id,
              type
            ),
            company:companies(
              id,
              name
            ),
            provider:providers(
              id,
              name,
              phones
            )
          `
          )
          .eq('company', company.id)
          .neq('status', 148)
          .neq('provider.status', 98);

        if (providerError) throw providerError;

        providers = providersData;

        if (providers && providers.length > 0) {
          const uniqueProviders = new Map();
          const formattedProviders = await Promise.all(
            providers.map(async ({ company, provider, status }) => {
              const { data: addressData, error: addressError } =
                await Address.getAll(
                  {
                    entity_type: { value: EntityTypeEnum.Provider },
                    entity_id: { value: provider.id }
                  },
                  1,
                  1
                );

              if (addressError)
                console.error(
                  `[Error] Fetching Address For ${provider.id} (Provider): `,
                  error
                );

              const address = addressData[0];
              const providerData = {
                ...provider,
                company,
                status,
                address: address
                  ? [
                      address.line1,
                      address.line2,
                      address.line3,
                      address.city,
                      address.postcode,
                      address.country
                    ]
                      .filter(Boolean)
                      .join(', ')
                  : 'N/A',
                source: 'database'
              };

              if (!uniqueProviders.has(provider.id)) {
                uniqueProviders.set(provider.id, providerData);
              }
            })
          );

          setLocalProviders(prev => ({
            ...prev,
            [company.id]: Array.from(uniqueProviders.values())
          }));

          const selectedProvidersToAdd = Array.from(
            uniqueProviders.values()
          ).filter(provider => provider.status.id === 145);
          if (selectedProvidersToAdd.length > 0) {
            setSelectedProviders(prev => [...prev, ...selectedProvidersToAdd]);
          }
        }
      }

      if (!found || !providers || providers.length === 0) {
        const { data: remoteProviders, error: remoteError } =
          await supabase.functions.invoke('assistedtrace/providers', {
            body: { company: company.name }
          });

        if (remoteError) throw remoteError;

        if (remoteProviders.type === 'success') {
          const providerMap = new Map();

          remoteProviders.data
            .filter(provider => provider.pension)
            .forEach(provider => {
              const address = provider.address
                ? [
                    provider.address.line1,
                    provider.address.line2,
                    provider.address.line3,
                    provider.address.postcode,
                    provider.address.country
                  ]
                    .filter(Boolean)
                    .join(', ')
                : 'N/A';

              const providerKey = provider.pension;
              if (!providerMap.has(providerKey)) {
                providerMap.set(providerKey, {
                  ...provider,
                  companyNames: [provider.name],
                  name: provider.pension,
                  address,
                  addressObject: provider.address,
                  source: 'edge'
                });
              } else {
                providerMap.get(providerKey).companyNames.push(provider.name);
              }
            });

          const formattedProviders = Array.from(providerMap.values());

          setLocalProviders(prev => ({
            ...prev,
            [company.id]: formattedProviders
          }));

          formattedProviders.forEach(provider => {
            setIsProvidersLoading(prev => ({
              ...prev,
              [provider.name]: true
            }));
          });

          const matchPromises = formattedProviders.map(async provider => {
            const { data: matches, error } = await supabase.rpc(
              'get_provider_similarity',
              {
                p_name: provider.name,
                p_address: provider?.addressObject?.line1 || '',
                p_postcode: provider?.addressObject?.postcode || '',
                p_number: provider?.telephone || ''
              }
            );

            if (!error && matches) {
              const formattedMatches = matches.map(match => ({
                id: match.id,
                name: match.name,
                score: match.score,
                metadata: match.metadata
              }));

              setProviderMatches(prev => ({
                ...prev,
                [provider.name]: formattedMatches
              }));

              if (formattedMatches.length > 0) {
                setSelectedMatches(prev => ({
                  ...prev,
                  [provider.name]: [
                    {
                      name: formattedMatches[0].name,
                      id: formattedMatches[0].id
                    }
                  ]
                }));
              }
            }

            setIsProvidersLoading(prev => ({
              ...prev,
              [provider.name]: false
            }));
          });

          setProvidersLoading(prev => ({ ...prev, [company.id]: false }));

          await Promise.all(matchPromises);
        }
      }
    } catch (error) {
      console.error('[Error] Fetching Providers: ', error);
    } finally {
      setProvidersLoading(prev => ({ ...prev, [company.id]: false }));
    }
  };

  useEffect(() => {
    if (stage === 2 && selectedCompanies.length > 0) {
      selectedCompanies.forEach(company => {
        if (!localProviders[company.id]) {
          fetchProvidersForCompany(company);
        }
      });
    }
  }, [stage, selectedCompanies]);

  const handleProviderMatchSelect = (providerName, match) => {
    setSelectedMatches(prev => ({
      ...prev,
      [providerName]: Array.isArray(prev[providerName])
        ? prev[providerName].some(m => m.id === match.id)
          ? prev[providerName].filter(m => m.id !== match.id)
          : [...prev[providerName], match]
        : [match]
    }));

    setLocalProviders(prev => {
      const updatedProviders = Object.keys(prev).reduce((acc, companyId) => {
        const companyProviders =
          prev[companyId]?.map(p =>
            p.name === providerName
              ? {
                  ...p,
                  match: Array.isArray(p.match)
                    ? p.match.some(m => m.id === match.id)
                      ? p.match.filter(m => m.id !== match.id)
                      : [...p.match, match]
                    : [match]
                }
              : p
          ) || [];

        return {
          ...acc,
          [companyId]: companyProviders
        };
      }, {});

      return {
        ...prev,
        ...updatedProviders
      };
    });

    setSelectedProviders(prev =>
      prev.map(p =>
        p.name === providerName
          ? {
              ...p,
              match: Array.isArray(p.match)
                ? p.match.some(m => m.id === match.id)
                  ? p.match.filter(m => m.id !== match.id)
                  : [...p.match, match]
                : [match]
            }
          : p
      )
    );
  };

  const loadProviderOptions = async (query, page, companyId) => {
    const existingProviders =
      localProviders[companyId]?.map(p => p?.id).filter(id => id) || [];

    const { data, error } = await supabase.rpc('get_provider_name_similarity', {
      p_name: query
    });

    if (error) {
      console.error('[Error] Loading Provider Options: ', error);
      return [];
    }

    const filteredData = data.filter(
      provider => !existingProviders.includes(provider.id)
    );

    return filteredData.map(provider => ({
      label: provider.name,
      id: provider.id
    }));
  };

  const removeProvider = async provider => {
    try {
      const companyIds = [
        provider.localCompany?.id,
        ...(provider.localCompany?.linkedCompanies?.map(c => c.id) || [])
      ].filter(id => id !== undefined);

      if (provider.source === 'database' || provider.source === 'manual') {
        const { error } = await supabase
          .from('algorithm')
          .update({ status: 148 })
          .in('company', companyIds)
          .eq('provider', provider.id);

        if (error) throw error;
      }

      setLocalProviders(prev => {
        const updatedProviders = { ...prev };
        companyIds.forEach(companyId => {
          if (updatedProviders[companyId]) {
            updatedProviders[companyId] = updatedProviders[companyId].filter(
              p =>
                p.id !== provider.id ||
                (provider.company && p.company.id !== provider.company.id)
            );
          }

          if (
            provider.localCompany?.linkedCompanies?.some(
              c => c.id === companyId
            )
          ) {
            delete updatedProviders[companyId];
          }
        });
        return updatedProviders;
      });

      setSelectedProviders(prev => {
        const updatedSelectedProviders = prev.filter(
          p =>
            p.id !== provider.id ||
            (provider.company && p.company.id !== provider.company.id)
        );

        companyIds.forEach(companyId => {
          if (
            provider.localCompany?.linkedCompanies?.some(
              c => c.id === companyId
            )
          ) {
            return updatedSelectedProviders.filter(
              p => p.company.id !== companyId
            );
          }
        });

        return updatedSelectedProviders;
      });
    } catch (error) {
      console.error('[Error] Removing Provider: ', error);
    }
  };

  const onSubmit = async () => {
    setCreating(true);
    setCreatingProgress(0);
    try {
      const companyIds = [];
      let updateStatus = false;

      const totalTasks = selectedCompanies.length + selectedProviders.length;
      let completedTasks = 0;

      await Promise.all(
        selectedCompanies.map(async company => {
          const providerCompanies = selectedProviders.filter(provider =>
            localProviders[company.id]?.some(p => p.name === provider.name)
          );

          if (providerCompanies.length > 0) {
            let companyId;

            if (company.source === 'edge') {
              const { data: existingCompany } = await supabase
                .from('companies')
                .select('id')
                .eq('number', company.number)
                .maybeSingle();

              if (!existingCompany?.id) {
                const { data: newCompany, error: companyError } = await supabase
                  .from('companies')
                  .insert([{ name: company.name, number: company.number }])
                  .select('*')
                  .single();

                if (companyError) throw companyError;
                companyId = newCompany.id;

                const { error: addressError } = await supabase
                  .from('addresses')
                  .insert([
                    {
                      line1: company.address,
                      entity_type: EntityTypeEnum.Company,
                      entity_id: companyId
                    }
                  ]);

                if (addressError) throw addressError;
              } else {
                companyId = existingCompany.id;
              }
            } else {
              companyId = company.id;
            }

            companyIds.push(companyId);

            const linkedCompanyIds = company?.linkedCompanies?.map(
              linkedCompany => linkedCompany.id
            );
            companyIds.push(...linkedCompanyIds);

            if (company.status_id === 90) {
              updateStatus = true;
            }
          }
          completedTasks++;
          setCreatingProgress((completedTasks / totalTasks) * 100);
        })
      );

      const processedProviders = new Set();

      const searchStatus = updateStatus ? 83 : 75;

      const uniqueProviders = Array.from(
        new Set(
          selectedProviders.flatMap(
            provider => provider.match?.map(match => match.id) || [provider.id]
          )
        )
      );

      await Promise.all(
        uniqueProviders.map(async providerId => {
          const provider = selectedProviders.find(
            p =>
              p.match?.some(match => match.id === providerId) ||
              p.id === providerId
          );

          if (!processedProviders.has(providerId)) {
            // Get schemeMetadata from company providers
            const providerMatch = providerMatches[provider.name]?.find(
              match => match.id == providerId
            );

            console.log(providerMatch);

            const schemeMetadata =
              providerMatch?.metadata?.found_via === 'scheme'
                ? providerMatch.metadata
                : null;

            const { error: searchError } = await supabase
              .from('searches')
              .insert([
                {
                  trace: trace.id,
                  status: searchStatus,
                  provider: providerId,
                  scheme: schemeMetadata ? schemeMetadata.scheme_id : null,
                  owner: trace.owner.id,
                  metadata: { source: '+Ai Assisted Trace' }
                }
              ]);

            if (searchError) throw searchError;

            if (
              provider.source !== 'database' &&
              provider.source !== 'manual'
            ) {
              try {
                const insertData = companyIds.map(companyId => {
                  const companySource = companies.find(
                    c => c.id === companyId
                  )?.source;
                  const status = companySource === 'edge' ? 146 : 147;
                  return {
                    status: status,
                    company: companyId,
                    provider: providerId
                  };
                });

                const { error: linkError } = await supabase
                  .from('algorithm')
                  .insert(insertData);

                if (linkError) throw linkError;
              } catch (error) {
                console.error('[Error] Inserting Links: ', error);
              }
            }

            processedProviders.add(providerId);
          }
          completedTasks++;
          setCreatingProgress((completedTasks / totalTasks) * 100);
        })
      );

      const traceStatus = updateStatus ? 94 : 70;
      const { error: traceUpdateError } = await supabase
        .from('traces')
        .update({ companies: companyIds, status: traceStatus })
        .eq('id', trace.id);

      if (traceUpdateError) throw traceUpdateError;

      onClose();
    } catch (error) {
      console.error(
        '[Error] Updating Trace With Companies And Searches: ',
        error
      );
    } finally {
      setCreating(false);
    }
  };

  const handleCreateGovernment = async () => {
    try {
      const { data: newCompany, error } = await supabase
        .from('companies')
        .insert([{ name: newGovernmentName, status: 90, number: '' }])
        .select('*')
        .single();

      if (error) throw error;

      setCompanies(prev => [
        {
          ...newCompany,
          status: 'Government',
          status_id: 90,
          address: 'N/A',
          source: 'database',
          linkedCompanies: []
        },
        ...prev
      ]);

      setSelectedCompanies(prev => [
        {
          ...newCompany,
          status: 'Government',
          status_id: 90,
          address: 'N/A',
          source: 'database',
          linkedCompanies: []
        },
        ...prev
      ]);
      setIsCreateGovernmentModalOpen(false);
    } catch (error) {
      console.error('[Error] Creating Government Company: ', error);
    }
  };

  const fetchMoreCompaniesFromEdge = async () => {
    setLoading(true);
    setLoadingSource('edge');
    try {
      const { data: remoteCompanies, error: remoteError } =
        await supabase.functions.invoke('assistedtrace/company', {
          body: { company: searchTerm }
        });

      if (remoteError) throw remoteError;

      if (remoteCompanies.type === 'success') {
        const formattedCompanies = remoteCompanies.data.map(
          ({ id, name, address, status, created, ended, previous }) => ({
            id,
            name,
            number: id,
            address,
            status,
            source: 'edge',
            linkedCompanies: [],
            created,
            ended,
            previous_names: previous ? [previous] : [],
            traceCount: 0 // Default trace count for remote companies
          })
        );

        setCompanies(prev => [...prev, ...formattedCompanies]);
      }
    } catch (error) {
      console.error(`[Error] Fetching More Companies: `, error);
    } finally {
      setLoading(false);
      setLoadingSource('');
    }
  };

  const fetchMoreProvidersFromEdge = async company => {
    setProvidersLoading(prev => ({ ...prev, [company.id]: true }));
    try {
      const { data: remoteProviders, error: remoteError } =
        await supabase.functions.invoke('assistedtrace/providers', {
          body: { company: company.name }
        });

      if (remoteError) throw remoteError;

      if (remoteProviders.type === 'success') {
        const providerMap = new Map();

        remoteProviders.data
          .filter(provider => provider.pension)
          .forEach(provider => {
            const address = provider.address
              ? [
                  provider.address.line1,
                  provider.address.line2,
                  provider.address.line3,
                  provider.address.postcode,
                  provider.address.country
                ]
                  .filter(Boolean)
                  .join(', ')
              : 'N/A';

            const providerKey = provider.pension;
            if (!providerMap.has(providerKey)) {
              providerMap.set(providerKey, {
                ...provider,
                companyNames: [provider.name],
                name: provider.pension,
                address,
                source: 'edge',
                addressObject: provider.address
              });
            } else {
              providerMap.get(providerKey).companyNames.push(provider.name);
            }
          });

        const formattedProviders = Array.from(providerMap.values());

        setLocalProviders(prev => ({
          ...prev,
          [company.id]: [...(prev[company.id] || []), ...formattedProviders]
        }));

        for (const provider of formattedProviders) {
          const { data: matches, error } = await supabase.rpc(
            'get_provider_similarity',
            {
              p_name: provider.name,
              p_address: provider?.addressObject?.line1 || '',
              p_postcode: provider?.addressObject?.postcode || '',
              p_number: provider?.telephone || ''
            }
          );

          if (!error && matches) {
            const formattedMatches = matches.map(match => ({
              id: match.id,
              name: match.name,
              score: match.score,
              metadata: match.metadata || {}
            }));

            setProviderMatches(prev => ({
              ...prev,
              [provider.name]: formattedMatches
            }));

            if (formattedMatches.length > 0) {
              setSelectedMatches(prev => ({
                ...prev,
                [provider.name]: [
                  {
                    name: formattedMatches[0].name,
                    id: formattedMatches[0].id
                  }
                ]
              }));
            }
          }
        }
      }
    } catch (error) {
      console.error(`[Error] Fetching More Providers: `, error);
    } finally {
      setProvidersLoading(prev => ({ ...prev, [company.id]: false }));
    }
  };

  const stages = [
    { title: 'Select Companies' },
    { title: 'Select Providers' },
    { title: 'Review Searches' }
  ];

  const progressBar = (
    <>
      <div className='flex justify-between'>
        {stages.map((step, index) => (
          <div
            key={index}
            className={`flex items-center ${
              index < stage ? 'text-brand-700' : 'text-base-400'
            }`}
          >
            <div
              className={`w-8 h-8 rounded-full flex items-center justify-center ${
                index < stage ? 'bg-brand-700 text-white' : 'bg-base-200'
              }`}
            >
              {index + 1}
            </div>
            <span className='ml-2 text-sm font-medium'>{step.title}</span>
          </div>
        ))}
      </div>
      <div className='mt-4 h-2 bg-base-200 rounded-full'>
        <div
          className='h-full bg-brand-700 rounded-full transition-all duration-300 ease-in-out'
          style={{
            width: `${(stage / 3) * 100}%`
          }}
        ></div>
      </div>
    </>
  );

  const renderStage = () => {
    if (creating)
      return (
        <div className='flex flex-col items-center p-8'>
          <Search className='w-24 h-24 text-gray-500 mb-4 animate-bounce duration-1500' />
          <p className='text-lg mb-2'>
            Please wait, we are creating the searches...
          </p>
          <p className='text-sm mb-8'>
            This may take a moment or two, we are working hard in the
            background...
          </p>
          <div className='w-full bg-gray-200 rounded-full h-2.5'>
            <div
              className='bg-primary-600 h-2.5 rounded-full'
              style={{ width: `${creatingProgress}%` }}
            />
          </div>
        </div>
      );

    switch (stage) {
      case 1:
        const indexOfLastCompany = currentPage * companiesPerPage;
        const indexOfFirstCompany = indexOfLastCompany - companiesPerPage;
        const currentCompanies = companies.slice(
          indexOfFirstCompany,
          indexOfLastCompany
        );
        const totalPages = Math.ceil(companies.length / companiesPerPage);

        return (
          <div className='space-y-4'>
            <div className='flex gap-2'>
              <Input
                placeholder='Company Name'
                value={searchTerm}
                onChange={e => setSearchTerm(e.target.value)}
              />
              <Input
                type='number'
                placeholder='Join Year'
                value={startDate}
                onChange={e => setStartDate(e.target.value)}
                min='1900'
                max={new Date().getFullYear()}
              />
              <Input
                type='number'
                placeholder='Leave Year'
                value={endDate}
                onChange={e => setEndDate(e.target.value)}
                min='1900'
                max={new Date().getFullYear()}
              />
              <SoftButton
                colour='primary'
                onClick={() => fetchCompanies(searchTerm)}
              >
                <Search size={16} />
              </SoftButton>
            </div>
            {(trace?.metadata?.employer_address ||
              trace?.metadata?.industry ||
              trace?.metadata?.title) && (
              <div className='bg-gray-100 p-4 rounded-lg'>
                <h3 className='text-base font-semibold text-gray-700 mb-2'>
                  More Information
                </h3>
                <div className='text-sm text-gray-600 space-y-1'>
                  {trace?.metadata?.employer_address && (
                    <div>
                      <span className='font-medium'>Employer Address:</span>{' '}
                      {trace.metadata.employer_address}
                    </div>
                  )}
                  {trace?.metadata?.industry && (
                    <div>
                      <span className='font-medium'>Industry:</span>{' '}
                      {trace.metadata.industry}
                    </div>
                  )}
                  {trace?.metadata?.title && (
                    <div>
                      <span className='font-medium'>Job Title:</span>{' '}
                      {trace.metadata.title}
                    </div>
                  )}
                </div>
              </div>
            )}
            <div className='overflow-x-auto bg-white rounded-md w-full'>
              <table className='w-full divide-y divide-base-200'>
                <thead>
                  <tr>
                    <th className='py-3 px-3 text-left text-sm font-semibold text-base-700'>
                      Company
                    </th>
                    <th className='py-3 px-3 text-left text-sm font-semibold text-base-700'>
                      Number
                    </th>
                    <th className='py-3 px-3 text-left text-sm font-semibold text-base-700'>
                      Previous Names
                    </th>
                    <th className='py-3 px-3 text-left text-sm font-semibold text-base-700'>
                      Status
                    </th>
                    <th className='py-3 px-3 text-left text-sm font-semibold text-base-700'>
                      Address
                    </th>
                    <th className='py-3 px-3 text-left text-sm font-semibold text-base-700'>
                      Linked
                    </th>
                    <th className='py-3 px-3 text-left text-sm font-semibold text-base-700'>
                      Traces
                    </th>
                    <th className='px-4'></th>
                  </tr>
                </thead>
                <tbody className='divide-y divide-base-50'>
                  {loading ? (
                    <tr>
                      <td colSpan='7' className='text-center py-10'>
                        <div className='flex flex-col items-center justify-center gap-2'>
                          {loadingSource === 'database' ? (
                            <>
                              <Search className='text-base-500 w-11 h-11 animate-bounce duration-2000' />
                              <span className='text-base-600'>
                                Finding companies from the database...
                              </span>
                            </>
                          ) : (
                            <>
                              <Search className='text-base-500 w-11 h-11 animate-bounce duration-2000' />
                              <span className='text-base-600 flex items-center'>
                                <img src={AiLogo} className='w-6 h-6 mr-1' />
                                AGi is researching possible companies...
                              </span>
                            </>
                          )}
                          <span className='text-base-600 text-sm'>
                            This may take a moment or two, we are working hard
                            in the background...
                          </span>
                        </div>
                      </td>
                    </tr>
                  ) : (
                    currentCompanies.map(company => {
                      const isSelected = selectedCompanies.find(
                        c => c.id === company.id
                      );
                      const isOutOfDateRange = !isCompanyInDateRange(company);
                      return (
                        <>
                          <tr
                            key={company.id}
                            onClick={() => {
                              setSelectedCompanies(prev => {
                                const isAlreadySelected = prev.find(
                                  c => c.id === company.id
                                );
                                if (isAlreadySelected) {
                                  return prev.filter(c => c.id !== company.id);
                                } else {
                                  const linkedCompanyIds =
                                    company?.linkedCompanies?.map(lc => lc.id);
                                  if (company.status_id === 90) {
                                    return [
                                      ...prev.filter(
                                        c =>
                                          c.status_id === 90 &&
                                          !linkedCompanyIds.includes(c.id)
                                      ),
                                      company
                                    ];
                                  } else {
                                    return [
                                      ...prev.filter(
                                        c =>
                                          c.status_id !== 90 &&
                                          !linkedCompanyIds.includes(c.id)
                                      ),
                                      company
                                    ];
                                  }
                                }
                              });
                            }}
                            onContextMenu={e => {
                              handleContextMenu(e, company);
                            }}
                            className={`cursor-pointer ${
                              isSelected
                                ? 'bg-brand-100 hover:bg-brand-200'
                                : 'hover:bg-base-50'
                            }`}
                          >
                            <td className='py-2 px-3 text-sm flex items-center'>
                              {isOutOfDateRange && (
                                <Calendar className='text-red-500 w-5 h-5 mr-2' />
                              )}
                              <Link
                                to={
                                  company.source === 'edge'
                                    ? `https://find-and-update.company-information.service.gov.uk/company/${company.number}`
                                    : `/companies/${company.id}`
                                }
                                target='_blank'
                                onClick={e => e.stopPropagation()}
                                className='hover:underline'
                              >
                                {company.name}
                              </Link>
                            </td>
                            <td className='py-2 px-3 text-sm'>
                              {company?.number || 'N/A'}
                            </td>
                            <td className='py-2 px-3 text-sm'>
                              {company?.previous_names &&
                              company?.previous_names.length > 0 ? (
                                <div className='flex items-center gap-2'>
                                  <span>{company.previous_names.length}</span>
                                  <SoftButton
                                    onClick={e => {
                                      e.stopPropagation();
                                      setPreviousNamesList(
                                        company.previous_names
                                      );
                                      setIsPreviousNamesModalOpen(true);
                                    }}
                                    size='xs'
                                  >
                                    <ListOrdered className='w-4 h-4' />
                                  </SoftButton>
                                </div>
                              ) : (
                                'N/A'
                              )}
                            </td>
                            <td className='py-2 px-3 flex justify-between items-center'>
                              <Badge
                                size='xs'
                                colour={
                                  company.status
                                    ? company.status.toLowerCase() === 'active'
                                      ? 'success'
                                      : company.status.toLowerCase() ===
                                        'dissolved'
                                      ? 'danger'
                                      : 'base'
                                    : 'base'
                                }
                              >
                                {company.status
                                  ? company.status
                                      .split(' ')
                                      .map(
                                        word =>
                                          word.charAt(0).toUpperCase() +
                                          word.slice(1).toLowerCase()
                                      )
                                      .join(' ')
                                  : 'Unknown'}
                              </Badge>
                            </td>
                            <td className='py-2 px-3 text-sm truncate max-w-xs'>
                              {company.address}
                            </td>
                            <td className='py-2 px-3 text-sm'>
                              {company?.linkedCompanies?.length > 0 ? (
                                <div className='flex items-center gap-2'>
                                  <span>
                                    {company?.linkedCompanies?.length}
                                  </span>
                                  <SoftButton
                                    onClick={e => {
                                      e.stopPropagation();
                                      setCompaniesList(
                                        company?.linkedCompanies
                                      );
                                      setIsCompaniesModalOpen(true);
                                    }}
                                    size='xs'
                                  >
                                    <LinkIcon className='w-4 h-4' />
                                  </SoftButton>
                                </div>
                              ) : (
                                'N/A'
                              )}
                            </td>
                            <td className='py-2 px-3 text-sm'>
                              {company.traceCount}
                            </td>
                            <td>
                              <Checkbox
                                checked={isSelected}
                                onChange={() => {
                                  setSelectedCompanies(prev => {
                                    const isAlreadySelected = prev.find(
                                      c => c.id === company.id
                                    );
                                    if (isAlreadySelected) {
                                      return prev.filter(
                                        c => c.id !== company.id
                                      );
                                    } else {
                                      const linkedCompanyIds =
                                        company?.linkedCompanies?.map(
                                          lc => lc.id
                                        );
                                      if (company.status_id === 90) {
                                        return [
                                          ...prev.filter(
                                            c =>
                                              c.status_id === 90 &&
                                              !linkedCompanyIds.includes(c.id)
                                          ),
                                          company
                                        ];
                                      } else {
                                        return [
                                          ...prev.filter(
                                            c =>
                                              c.status_id !== 90 &&
                                              !linkedCompanyIds.includes(c.id)
                                          ),
                                          company
                                        ];
                                      }
                                    }
                                  });
                                }}
                              />
                            </td>
                          </tr>
                        </>
                      );
                    })
                  )}
                  {!loading &&
                    companies.length > 0 &&
                    companies.every(c => c.source === 'database') && (
                      <tr>
                        <td colSpan='8'>
                          <div className='flex justify-center items-center py-2'>
                            <SoftButton
                              className='text-sm cursor-pointer text-center'
                              onClick={fetchMoreCompaniesFromEdge}
                            >
                              Load More With +Ai AGi...
                            </SoftButton>
                          </div>
                        </td>
                      </tr>
                    )}
                </tbody>
              </table>
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={newPage => {
                  setCurrentPage(newPage);
                }}
                itemsPerPage={companiesPerPage}
                onItemsPerPageChange={newItemsPerPage => {
                  setCompaniesPerPage(newItemsPerPage);
                  setCurrentPage(1);
                  updateCrmPreferences(newItemsPerPage, user, 'company');
                }}
              />
            </div>
          </div>
        );
      case 2:
        return (
          <div className='space-y-4'>
            {selectedCompanies.map(company => {
              const providers = localProviders[company.id] || [];
              const currentProviderPage = providerPages[company.id] || 1;
              const providersPerPage =
                providerItemsPerPage[company.id] ??
                currentUser?.crmPreferences?.assistedTrace?.providersPerPage ??
                10;
              const indexOfLastProvider =
                currentProviderPage * providersPerPage;
              const indexOfFirstProvider =
                indexOfLastProvider - providersPerPage;
              const currentProviders = providers.slice(
                indexOfFirstProvider,
                indexOfLastProvider
              );
              const totalProviderPages = Math.ceil(
                providers.length / providersPerPage
              );

              const providerSource = providers[0]?.source;

              return (
                <div key={company.id} className='space-y-2'>
                  <div className='flex items-center justify-between'>
                    <h3 className='font-medium flex-1 max-w-3xl truncate'>
                      {company.name}
                      {company?.linkedCompanies?.length > 0 && (
                        <span className='ml-2 text-sm text-gray-500'>
                          {company?.linkedCompanies
                            ?.map(lc => lc.name)
                            .join(', ')}
                        </span>
                      )}
                    </h3>
                    <SoftButton
                      onClick={() => {
                        setIsAddProviderModalOpen(true);
                        setAddProviderCompanyId(company.id);
                      }}
                      size='md'
                      disabled={providersLoading[company.id]}
                    >
                      <Plus className='w-4 h-4' />
                      Add Provider
                    </SoftButton>
                  </div>
                  <div className='overflow-x-auto bg-white rounded-md w-full'>
                    <table className='w-full divide-y divide-base-200'>
                      <thead>
                        <tr>
                          <th className='py-3 px-3 text-left text-sm font-semibold text-base-700'>
                            Company
                          </th>
                          <th className='py-3 px-3 text-left text-sm font-semibold text-base-700'>
                            Name
                          </th>
                          <th className='py-3 px-3 text-left text-sm font-semibold text-base-700'>
                            Status
                          </th>
                          <th className='py-3 px-3 text-left text-sm font-semibold text-base-700'>
                            Address
                          </th>
                          <th className='py-3 px-3 text-left text-sm font-semibold text-base-700'>
                            Phone
                          </th>
                          {providerSource === 'edge' && (
                            <th className='py-3 px-3 text-left text-sm font-semibold text-base-700'>
                              Provider Match
                            </th>
                          )}
                        </tr>
                      </thead>
                      <tbody className='divide-y divide-base-50'>
                        {providersLoading[company.id] ? (
                          <tr>
                            <td colSpan={6} className='text-center py-10'>
                              <div className='flex flex-col items-center justify-center gap-2'>
                                {loadingSource === 'database' ? (
                                  <>
                                    <Search className='text-base-500 w-11 h-11 animate-bounce duration-2000' />
                                    <span className='text-base-600'>
                                      Finding providers from the database...
                                    </span>
                                  </>
                                ) : (
                                  <>
                                    <Search className='text-base-500 w-11 h-11 animate-bounce duration-2000' />
                                    <span className='text-base-600 flex items-center'>
                                      <img
                                        src={AiLogo}
                                        className='w-6 h-6 mr-1'
                                      />
                                      AGi is researching possible providers...
                                    </span>
                                  </>
                                )}
                                <span className='text-base-600 text-sm'>
                                  This may take a moment or two, we are working
                                  hard in the background...
                                </span>
                              </div>
                            </td>
                          </tr>
                        ) : currentProviders.length === 0 ? (
                          <tr>
                            <td colSpan={6} className='text-center py-10'>
                              <div className='flex flex-col items-center justify-center'>
                                <span className='text-base-600 mb-2'>
                                  No data available. Consider conducting further
                                  research.
                                </span>
                                <span className='text-base-600 text-sm mb-4'>
                                  You can do this by adding a search to the
                                  trace.
                                </span>
                                <SoftButton
                                  onClick={() => {
                                    setIsAddProviderModalOpen(true);
                                    setAddProviderCompanyId(company.id);
                                  }}
                                >
                                  Add Provider
                                </SoftButton>
                              </div>
                            </td>
                          </tr>
                        ) : (
                          currentProviders.map(provider => {
                            const isSelected = selectedProviders?.some(
                              p => p.name == provider.name
                            );
                            const matches =
                              providerMatches[provider.name] || [];

                            if (matches.length > 0 && !provider.match) {
                              provider.match = matches[0].id;
                            }

                            return (
                              <tr
                                className={`${
                                  isSelected
                                    ? 'bg-brand-100 hover:bg-brand-200'
                                    : 'hover:bg-base-50'
                                }`}
                                onClick={() => {
                                  if (provider?.source !== 'edge') {
                                    setSelectedProviders(prev =>
                                      prev.some(p => p.name === provider.name)
                                        ? prev.filter(
                                            p => p.name !== provider.name
                                          )
                                        : [
                                            ...prev,
                                            {
                                              ...provider,
                                              match:
                                                selectedMatches[provider.name]
                                            }
                                          ]
                                    );
                                  }
                                }}
                                onContextMenu={e => {
                                  if (provider?.source !== 'edge') {
                                    handleContextMenu(e, {
                                      ...provider,
                                      localCompany: company
                                    });
                                  }
                                }}
                              >
                                <td className='py-2 px-3 text-sm flex items-center'>
                                  {provider?.status?.id === 145 && (
                                    <Check className='text-green-500 w-5 h-5 mr-1' />
                                  )}
                                  {provider?.companyNames?.length > 0 ? (
                                    <div className='flex items-center gap-2'>
                                      <span>{provider.companyNames[0]}</span>
                                      {provider.companyNames.length > 1 && (
                                        <SoftButton
                                          onClick={e => {
                                            e.stopPropagation();
                                            setProviderCompaniesList(
                                              provider.companyNames
                                            );
                                            setIsProviderCompaniesModalOpen(
                                              true
                                            );
                                          }}
                                          size='xs'
                                        >
                                          <Building className='w-4 h-4' />
                                        </SoftButton>
                                      )}
                                    </div>
                                  ) : (
                                    provider?.company?.name
                                  )}
                                </td>
                                <td className='py-2 px-3 text-sm'>
                                  {provider?.name}
                                </td>
                                <td className='py-2 px-3 flex justify-between items-center'>
                                  <Badge
                                    size='xs'
                                    colour={
                                      provider.status?.id === 145
                                        ? 'success'
                                        : provider.status?.id === 148
                                        ? 'danger'
                                        : 'base'
                                    }
                                  >
                                    {provider.status?.type ||
                                      (provider.status
                                        ? provider.status
                                            .split(' ')
                                            .map(
                                              word =>
                                                word.charAt(0).toUpperCase() +
                                                word.slice(1).toLowerCase()
                                            )
                                            .join(' ')
                                        : 'Unknown')}
                                  </Badge>
                                </td>
                                <td className='py-2 px-3 text-sm truncate max-w-xs'>
                                  {provider.address}
                                </td>
                                <td className='py-2 px-3 text-sm'>
                                  {provider?.phones?.[0] ||
                                    provider?.telephone ||
                                    'N/A'}
                                </td>
                                {isProvidersLoading[provider.name] ? (
                                  <div className='flex items-center justify-center text-sm'>
                                    <Loader className='animate-spin w-5 h-5 text-gray-500 mr-2' />
                                    <span>Loading...</span>
                                  </div>
                                ) : provider?.source === 'edge' ? (
                                  <td className='py-2 px-3'>
                                    <ProviderMatchHoverCard
                                      providerName={provider.name}
                                      matches={matches}
                                      selectedMatches={
                                        selectedMatches[provider.name] || []
                                      }
                                      onSelect={selected => {
                                        handleProviderMatchSelect(
                                          provider.name,
                                          selected
                                        );
                                      }}
                                      closeAllHovers={() =>
                                        setActiveHoverCard(null)
                                      }
                                      isActive={
                                        activeHoverCard === provider.name
                                      }
                                      setActiveHoverCard={setActiveHoverCard}
                                      provider={provider}
                                    />
                                  </td>
                                ) : (
                                  <td></td>
                                )}
                                <td className='px-4'>
                                  <Checkbox
                                    checked={isSelected}
                                    onChange={() =>
                                      setSelectedProviders(prev =>
                                        prev.some(p => p.name === provider.name)
                                          ? prev.filter(
                                              p => p.name !== provider.name
                                            )
                                          : [
                                              ...prev,
                                              {
                                                ...provider,
                                                match:
                                                  selectedMatches[provider.name]
                                              }
                                            ]
                                      )
                                    }
                                  />
                                </td>
                              </tr>
                            );
                          })
                        )}
                        {!providersLoading[company.id] &&
                          providers.length > 0 &&
                          providers.every(p => p.source === 'database') && (
                            <tr>
                              <td colSpan='7'>
                                <div className='flex justify-center items-center py-2'>
                                  <SoftButton
                                    className='text-sm cursor-pointer text-center'
                                    onClick={() =>
                                      fetchMoreProvidersFromEdge(company)
                                    }
                                  >
                                    Load More With +Ai AGi...
                                  </SoftButton>
                                </div>
                              </td>
                            </tr>
                          )}
                      </tbody>
                    </table>
                    <Pagination
                      currentPage={currentProviderPage}
                      totalPages={totalProviderPages}
                      onPageChange={newPage => {
                        setProviderPages(prev => ({
                          ...prev,
                          [company.id]: newPage
                        }));
                      }}
                      itemsPerPage={providersPerPage}
                      onItemsPerPageChange={newItemsPerPage => {
                        setProviderItemsPerPage(prev => ({
                          ...prev,
                          [company.id]: newItemsPerPage
                        }));
                        setProviderPages(prev => ({
                          ...prev,
                          [company.id]: 1
                        }));
                        updateCrmPreferences(newItemsPerPage, user, 'provider');
                      }}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        );
      case 3:
        const isGovernmentPension = selectedCompanies.some(
          company => company.status_id === 90
        );

        console.log(selectedProviders);

        return (
          <div className='space-y-8'>
            <div className='text-left text-gray-600'>
              {isGovernmentPension ? (
                <>
                  This is a government pension scheme. It will be set as a
                  government and complete. The providers you selected will help
                  the client to trace their government pension. You will
                  initiate{' '}
                  <span className='font-bold'>
                    {(() => {
                      const uniqueProviders = Array.from(
                        new Set(
                          selectedProviders.flatMap(
                            provider =>
                              provider.match?.map(match => match.id) || [
                                provider.id
                              ]
                          )
                        )
                      );

                      const matchedProviders = uniqueProviders.filter(
                        providerId =>
                          selectedCompanies.some(company =>
                            localProviders[company.id]?.some(
                              localProvider => localProvider.id === providerId
                            )
                          )
                      );

                      console.log('Matched Providers:', matchedProviders);

                      return uniqueProviders.length;
                    })()}
                  </span>{' '}
                  individual government searches across your chosen companies
                  and providers which will help the client.
                </>
              ) : (
                <>
                  Based on your selections, we can initiate{' '}
                  <span className='font-bold'>
                    {(() => {
                      const uniqueProviders = Array.from(
                        new Set(
                          selectedProviders.flatMap(
                            provider =>
                              provider.match?.map(match => match.id) || [
                                provider.id
                              ]
                          )
                        )
                      );

                      const matchedProviders = uniqueProviders.filter(
                        providerId =>
                          selectedCompanies.some(company =>
                            localProviders[company.id]?.some(
                              localProvider => localProvider.id === providerId
                            )
                          )
                      );

                      console.log('Matched Providers:', matchedProviders);

                      return uniqueProviders.length;
                    })()}
                  </span>{' '}
                  individual trace searches across your chosen companies and
                  providers.
                </>
              )}
            </div>
            <div className='bg-brand-100 p-4 rounded-md'>
              <div className='text-xl font-semibold text-gray-900 mb-4 text-center'>
                Trace Map
              </div>
              <table className='w-full'>
                <tbody className='space-y-8'>
                  {Array.from(
                    new Set(
                      selectedProviders.flatMap(
                        provider =>
                          provider.match?.map(match => match.id) || [
                            provider.id
                          ]
                      )
                    )
                  ).map(uniqueProviderId => {
                    const provider = selectedProviders.find(
                      p =>
                        p.match?.some(match => match.id === uniqueProviderId) ||
                        p.id === uniqueProviderId
                    );
                    const providerCompanies = selectedCompanies.filter(
                      company =>
                        localProviders[company.id]?.some(
                          p => p.name === provider.name
                        )
                    );

                    return providerCompanies.length > 0 ? (
                      <tr
                        key={provider.name}
                        className='flex w-full items-center justify-center'
                      >
                        <td className='w-[30%] flex justify-center'>
                          <div className='space-y-2 flex flex-col items-center'>
                            {providerCompanies.map(company => (
                              <div
                                key={company.id}
                                className='text-sm bg-success-100 p-2 rounded-md inline-block'
                              >
                                {company.name} <Badge>ID: {company.id}</Badge>
                              </div>
                            ))}
                          </div>
                        </td>
                        <td className='w-[5%] text-center'>
                          <ArrowRight className='text-xl inline-block' />
                        </td>
                        <td className='w-[30%] flex justify-center'>
                          <div className='space-y-2 flex flex-col items-center'>
                            <div className='text-sm bg-warning-100 p-2 rounded-md inline-block'>
                              {console.log(provider)}
                              {provider?.match?.find(
                                match => match.id === uniqueProviderId
                              )?.name ?? provider.name}{' '}
                              <Badge>ID: {uniqueProviderId}</Badge>
                            </div>
                          </div>
                        </td>
                        <td className='w-[5%] text-center'>
                          <ArrowRight className='text-xl inline-block' />
                        </td>
                        <td className='w-[30%] flex justify-center'>
                          <div className='text-sm bg-brand-600 p-2 rounded-md inline-block text-white'>
                            {isGovernmentPension
                              ? 'Search (Government)'
                              : 'Search'}
                          </div>
                        </td>
                      </tr>
                    ) : null;
                  })}
                </tbody>
              </table>
            </div>
            <div className='text-left text-gray-600'>
              {isGovernmentPension
                ? 'Would you like to complete this trace and give the information to the client?'
                : 'Would you like to proceed with creating these searches?'}
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  const handleTraceStatusChange = async status => {
    console.log(status);
    try {
      const statusId = status.target.value === 'Duplicate' ? 169 : 163; // Assuming 95 is for 'Duplicate' and 96 is for 'Can't Find Company'
      const { error } = await supabase
        .from('traces')
        .update({ status: statusId })
        .eq('id', trace.id);

      if (error) throw error;

      onClose();
    } catch (error) {
      console.error('[Error] Updating Trace Status: ', error);
    }
  };

  return (
    <>
      <Popup
        isOpen={isOpen}
        onClose={onClose}
        title={
          <div className='flex items-center'>
            <img src={AiLogo} className='w-9 h-9 mr-2' />
            <span className='font-semibold text-black pt-[3px]'>
              Assisted Trace
            </span>
          </div>
        }
        extra={progressBar}
        size='full'
        footer={
          <div
            className={`flex ${
              stage === 1 ? 'justify-between' : 'justify-end'
            } space-x-2 mt-1`}
          >
            {stage === 1 && (
              <div className='flex space-x-2 flex-shrink-0'>
                <DropdownInput
                  options={[
                    { label: 'Duplicate', value: 'Duplicate' },
                    {
                      label: 'Unable To Locate Company',
                      value: 'Unable To Locate Company'
                    }
                  ]}
                  onChange={option => handleTraceStatusChange(option)}
                  placeholder='Change Status'
                  className='flex-shrink-0'
                />
                <SoftButton
                  colour='primary'
                  onClick={() => {
                    setNewGovernmentName(searchTerm);
                    setIsCreateGovernmentModalOpen(true);
                  }}
                  disabled={
                    companies.filter(company => company.source === 'database')
                      .length !== 0 || loading
                  }
                  className='flex-shrink-0'
                >
                  Create Government
                </SoftButton>
              </div>
            )}
            <div className='flex space-x-2'>
              <SoftButton
                onClick={stage > 1 ? () => setStage(prev => prev - 1) : onClose}
              >
                {stage > 1 ? 'Back' : 'Cancel'}
              </SoftButton>
              <FilledButton
                onClick={
                  stage < 3 ? () => setStage(prev => prev + 1) : onSubmit
                }
                colour='primary'
                disabled={
                  (stage === 1 && selectedCompanies.length === 0) ||
                  (stage === 2 && selectedProviders.length === 0)
                }
              >
                {stage < 3 ? 'Next' : 'Start Trace'}
              </FilledButton>
            </div>
          </div>
        }
      >
        {renderStage()}
      </Popup>
      <Modal
        isOpen={isPreviousNamesModalOpen}
        onClose={() => setIsPreviousNamesModalOpen(false)}
        title={<h2 className='text-2xl'>Previous Names</h2>}
      >
        <div>
          {previousNamesList.map((name, index) => (
            <div key={index} className='flex items-center justify-between py-2'>
              <span className='truncate mr-4 flex-1 text-left'>{name}</span>
            </div>
          ))}
        </div>
      </Modal>
      <Modal
        isOpen={isCompaniesModalOpen}
        onClose={() => setIsCompaniesModalOpen(false)}
        title={<h2 className='text-2xl'>Companies</h2>}
      >
        <div>
          {companiesList.map((company, index) => (
            <div key={index} className='flex items-center justify-between py-2'>
              <span className='truncate mr-4 flex-1 text-left'>
                {company.name}
              </span>
              <SoftButton
                onClick={() =>
                  window.open(`/companies/${company.id}`, '_blank')
                }
              >
                <Eye />
              </SoftButton>
            </div>
          ))}
        </div>
      </Modal>
      <Modal
        isOpen={isProviderCompaniesModalOpen}
        onClose={() => setIsProviderCompaniesModalOpen(false)}
        title={<h2 className='text-2xl'>Companies</h2>}
      >
        <div>
          {providerCompaniesList.map((companyName, index) => (
            <div key={index} className='flex items-center justify-between py-2'>
              <span className='truncate mr-4 flex-1 text-left'>
                {companyName}
              </span>
            </div>
          ))}
        </div>
      </Modal>
      <Modal
        isOpen={isRemoveProviderModalOpen}
        onClose={() => setIsRemoveProviderModalOpen(false)}
        title={<h2 className='text-2xl'>Change Algorithm</h2>}
      >
        <table className='w-full'>
          <tbody>
            {providerToRemove && (
              <tr
                key={providerToRemove.name}
                className='flex w-full items-center justify-center'
              >
                <td className='w-[45%] flex justify-center'>
                  <div className='space-y-2 flex flex-col items-center'>
                    {selectedCompanies
                      .filter(company =>
                        localProviders[company.id]?.some(
                          p => p.name === providerToRemove.name
                        )
                      )
                      .map(company => (
                        <div
                          key={company.id}
                          className='text-sm bg-success-100 p-2 rounded-md inline-block'
                        >
                          {company.name} <Badge>ID: {company.id}</Badge>
                        </div>
                      ))}
                  </div>
                </td>
                <td className='w-[15%] text-center flex items-center justify-center'>
                  <div className='border-t-4 border-success-200 w-full'></div>
                  <X className='text-xl text-danger-500 h-20 w-20' />
                  <div className='border-t-4 border-warning-200 w-full'></div>
                </td>
                <td className='w-[45%] flex justify-center'>
                  <div className='space-y-2 flex flex-col items-center'>
                    <div className='text-sm bg-warning-100 p-2 rounded-md inline-block'>
                      {providerToRemove?.match?.label ?? providerToRemove.name}{' '}
                      <Badge>
                        ID:{' '}
                        {providerToRemove.match?.value ?? providerToRemove.id}
                      </Badge>
                    </div>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>

        <p className='mt-4'>
          This action will permanently disconnect the company from the provider
          in the algorithm. Are you absolutely sure you want to proceed?
        </p>
        <div className='flex justify-end space-x-2 mt-4'>
          <SoftButton onClick={() => setIsRemoveProviderModalOpen(false)}>
            Cancel
          </SoftButton>
          <FilledButton
            onClick={() => {
              if (providerToRemove) {
                removeProvider(providerToRemove);
              }
              setIsRemoveProviderModalOpen(false);
            }}
            colour='danger'
          >
            Confirm
          </FilledButton>
        </div>
      </Modal>
      <Modal
        isOpen={isAddProviderModalOpen}
        onClose={() => setIsAddProviderModalOpen(false)}
        title={<h2 className='text-2xl'>Add Provider</h2>}
      >
        <ComboBox
          loadOptions={(query, page) =>
            loadProviderOptions(query, page, addProviderCompanyId)
          }
          value={newProvider}
          onChange={async selectedProvider => {
            setNewProvider(selectedProvider);

            const { data, error } = await supabase
              .from('algorithm')
              .select(
                'provider, updated_by:user_profiles!algorithm_updated_by_fkey(id, first_name, last_name, full_name, profile_photo)'
              )
              .eq('company', addProviderCompanyId)
              .eq('provider', selectedProvider.id)
              .eq('status', 148);

            if (error) {
              console.error('[Error] Fetching Archived Connections: ', error);
              return;
            }

            const algo = {
              isArchived: data.length === 1,
              updatedBy: data.length === 1 ? data[0].updated_by : {}
            };

            setArchivedWarning(algo);
          }}
          placeholder='Search Providers...'
        />
        {archivedWarning?.isArchived && (
          <Alert
            title='Archived Algorithm'
            description={
              <div>
                <p>
                  This is an archived connection. Do you want to unarchive this
                  connection?
                </p>
                <div className='flex items-center justify-center mt-2'>
                  <span className='mr-2'>Archived By:</span>
                  <UserProfilePicture
                    firstName={archivedWarning.updatedBy?.first_name}
                    lastName={archivedWarning.updatedBy?.last_name}
                    profilePicture={archivedWarning.updatedBy?.profile_photo}
                    size='sm'
                  />
                  <span className='ml-2 text-sm'>
                    {archivedWarning.updatedBy?.full_name || 'Unknown'}
                  </span>
                </div>
              </div>
            }
            style='danger'
            className='mt-4'
          />
        )}
        <div className='flex justify-end space-x-2 mt-4'>
          <SoftButton onClick={() => setIsAddProviderModalOpen(false)}>
            Cancel
          </SoftButton>
          <FilledButton
            onClick={async () => {
              console.log('Starting local updates for provider:', newProvider);
              const handleLocalUpdates = async () => {
                console.log(
                  'Starting local updates for provider:',
                  newProvider
                );
                const { data: addressData, error: addressError } =
                  await Address.getAll(
                    {
                      entity_type: { value: EntityTypeEnum.Provider },
                      entity_id: { value: newProvider.id }
                    },
                    1,
                    1
                  );

                if (addressError) {
                  console.error(
                    `[Error] Fetching Address For ${newProvider.id} (Provider): `,
                    addressError
                  );
                  return;
                }

                const address = addressData[0]
                  ? [
                      addressData[0].line1,
                      addressData[0].line2,
                      addressData[0].line3,
                      addressData[0].city,
                      addressData[0].postcode,
                      addressData[0].country
                    ]
                      .filter(Boolean)
                      .join(', ')
                  : 'N/A';

                console.log('Fetched address:', address);

                const { data: phoneData, error: phoneError } = await supabase
                  .from('providers')
                  .select('phones')
                  .eq('id', newProvider.id)
                  .single();

                if (phoneError) {
                  console.error(
                    `[Error] Fetching Phones For ${newProvider.id} (Provider): `,
                    phoneError
                  );
                  return;
                }

                const phones = phoneData?.phones || ['N/A'];
                console.log('Fetched phones:', phones);

                const provider = {
                  id: newProvider.id,
                  name: newProvider.label,
                  address: address,
                  phones: phones,
                  source: 'manual',
                  status: { id: 147, type: 'Manual' },
                  company: {
                    id: addProviderCompanyId,
                    name:
                      selectedCompanies.find(
                        company => company.id == addProviderCompanyId
                      )?.name || ''
                  }
                };

                console.log('Constructed provider object:', provider);

                const companyIds = [
                  addProviderCompanyId,
                  ...(
                    selectedCompanies.find(
                      company => company.id === addProviderCompanyId
                    )?.linkedCompanies || []
                  ).map(lc => lc.id)
                ];

                console.log('Company IDs for update:', companyIds);

                setLocalProviders(prev => {
                  const updatedProviders = { ...prev };
                  companyIds.forEach(companyId => {
                    updatedProviders[companyId] = [
                      provider,
                      ...(updatedProviders[companyId] || [])
                    ];

                    console.log(
                      'Updated providers for company:',
                      companyId,
                      updatedProviders[companyId]
                    );

                    const linkedCompanies =
                      companies.find(
                        company => company.id === provider.company.id
                      )?.linkedCompanies || [];

                    if (linkedCompanies.some(lc => lc.id === companyId)) {
                      delete updatedProviders[companyId];

                      setSelectedProviders(prevSelected =>
                        prevSelected.filter(p => p.company.id !== companyId)
                      );
                      console.log(
                        'Removed provider from selected providers for company:',
                        companyId
                      );
                    }
                  });
                  return updatedProviders;
                });

                setSelectedProviders(prev => [
                  ...prev.filter(p => p.name !== provider.name),
                  provider
                ]);
                console.log('Updated selected providers:', provider);

                setIsAddProviderModalOpen(false);
                setNewProvider({});
                setAddProviderCompanyId(null);
                console.log('Reset new provider and company ID');
              };

              if (archivedWarning?.isArchived) {
                console.log('Provider is archived, attempting to unarchive');
                try {
                  const companyIds = [
                    addProviderCompanyId,
                    ...(
                      selectedCompanies.find(
                        company => company.id === addProviderCompanyId
                      )?.linkedCompanies || []
                    ).map(lc => lc.id)
                  ];

                  console.log('Company IDs for unarchiving:', companyIds);

                  const { error: updateError } = await supabase
                    .from('algorithm')
                    .update({ status: 147 })
                    .in('company', companyIds)
                    .eq('provider', newProvider.id);

                  if (updateError) {
                    console.error(
                      '[Error] Unarchiving Provider: ',
                      updateError
                    );
                  } else {
                    console.log('Successfully unarchived provider');
                    setArchivedWarning(false);
                    await handleLocalUpdates();
                  }
                } catch (error) {
                  console.error('[Error] Unarchiving Provider: ', error);
                }
              } else if (newProvider.id) {
                console.log('Adding new provider:', newProvider);
                try {
                  await handleLocalUpdates();

                  const companyIds = [
                    addProviderCompanyId,
                    ...(
                      selectedCompanies.find(
                        company => company.id === addProviderCompanyId
                      )?.linkedCompanies || []
                    ).map(lc => lc.id)
                  ];

                  console.log(
                    'Company IDs for inserting provider:',
                    companyIds
                  );

                  const insertData = companyIds.map(companyId => ({
                    provider: newProvider.id,
                    company: companyId,
                    status: 147
                  }));

                  const { error: insertError } = await supabase
                    .from('algorithm')
                    .insert(insertData);

                  if (insertError) {
                    console.error('[Error] Inserting Provider: ', insertError);
                  } else {
                    console.log('Successfully inserted provider');
                  }
                } catch (error) {
                  console.error('[Error] Adding Provider: ', error);
                }
              }
            }}
            colour={archivedWarning?.isArchived ? 'warning' : 'primary'}
          >
            {archivedWarning?.isArchived ? 'Unarchive' : 'Add'}
          </FilledButton>
        </div>
      </Modal>
      <Modal
        isOpen={isCreateGovernmentModalOpen}
        onClose={() => setIsCreateGovernmentModalOpen(false)}
        title={<h2 className='text-2xl'>Create Government</h2>}
      >
        <div className='space-y-4'>
          <Input
            placeholder='Government Name'
            value={newGovernmentName}
            onChange={e => setNewGovernmentName(e.target.value)}
          />
          <div className='flex justify-end space-x-2'>
            <SoftButton onClick={() => setIsCreateGovernmentModalOpen(false)}>
              Cancel
            </SoftButton>
            <FilledButton onClick={handleCreateGovernment} colour='primary'>
              Create
            </FilledButton>
          </div>
        </div>
      </Modal>
      {contextMenu && (
        <ContextMenu
          x={contextMenu.x}
          y={contextMenu.y}
          onClose={closeContextMenu}
          menuItems={
            Array.isArray(contextMenu.data?.linkedCompanies)
              ? companyMenuItems.map(item => ({
                  ...item,
                  onClick: () => item.onClick(contextMenu.data),
                  visible: item.condition
                    ? item.condition(contextMenu.data)
                    : true
                }))
              : providerMenuItems.map(item => ({
                  ...item,
                  onClick: () => item.onClick(contextMenu.data),
                  visible: item.condition
                    ? item.condition(contextMenu.data)
                    : true
                }))
          }
        />
      )}
    </>
  );
};

const ProviderMatchHoverCard = ({
  matches = [],
  selectedMatches = [],
  onSelect,
  provider,
  closeAllHovers = () => {}
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [hoverPosition, setHoverPosition] = useState({ top: 0, left: 0 });
  const [activeTab, setActiveTab] = useState('suggested');
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);

  // Add click handler to close hover card
  useEffect(() => {
    const handleClickOutside = e => {
      if (isHovered) {
        setIsHovered(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, [isHovered]);

  const handleHover = e => {
    e.stopPropagation(); // Prevent click from immediately closing
    closeAllHovers();
    const rect = e.currentTarget.getBoundingClientRect();
    const portalWidth = 330;
    const portalHeight = 400; // Approximate height of the portal
    const edgeThreshold = 20;

    // Calculate initial positions
    let left = rect.left + window.scrollX - 310;
    let top = rect.top + window.scrollY;

    // Check if portal would go off right edge
    if (left + portalWidth > window.innerWidth) {
      left = window.innerWidth - portalWidth - edgeThreshold;
    }

    // Check if portal would go off left edge
    if (left < edgeThreshold) {
      left = edgeThreshold;
    }

    // Determine if portal should appear above or below based on available space
    const spaceBelow = window.innerHeight - rect.bottom;
    const spaceAbove = rect.top;

    if (spaceBelow < portalHeight && spaceAbove > spaceBelow) {
      // Place above if more space above and not enough below
      top = rect.top - portalHeight - 10;
    } else {
      // Place below
      top = rect.bottom + 10;
    }

    // Ensure portal stays within vertical bounds
    if (top < edgeThreshold) {
      top = edgeThreshold;
    } else if (top + portalHeight > window.innerHeight - edgeThreshold) {
      top = window.innerHeight - portalHeight - edgeThreshold;
    }

    setHoverPosition({ top, left });
    setIsHovered(!isHovered);
  };

  const debouncedSearch = useCallback(
    debounce(async query => {
      if (!query) {
        setSearchResults([]);
        return;
      }
      try {
        const { data, error } = await supabase.rpc(
          'get_provider_match_similarity',
          {
            p_name: query
          }
        );

        if (error) throw error;

        setSearchResults(
          data.map(provider => ({
            id: provider.id,
            name: provider.name,
            previous_names: provider.previous_names,
            score: provider.score
          }))
        );
      } catch (error) {
        console.error('[Error] Searching Providers: ', error);
      }
    }, 300),
    []
  );

  useEffect(() => {
    debouncedSearch(searchQuery);
  }, [searchQuery, debouncedSearch]);

  const toggleSelectMatch = match => {
    onSelect(match);
  };

  const getDescription = (metadata, searchTerm) => {
    if (!metadata) return null;

    const getHighlightedName = name => {
      return name.split(' ').map((word, i, arr) => {
        if (
          searchTerm.toLowerCase().includes(word.toLowerCase()) ||
          word.toLowerCase().includes(searchTerm.toLowerCase())
        ) {
          return (
            <span key={i} className='text-brand-500 underline'>
              {word}
              {i !== arr.length - 1 ? ' ' : ''}
            </span>
          );
        }

        return (
          <span key={i}>
            {word}
            {i !== arr.length - 1 ? ' ' : ''}
          </span>
        );
      });
    };

    if (metadata.found_via == 'provider') {
      const providerName = metadata.provider_name;

      const highlightedName = getHighlightedName(providerName);

      return (
        <>
          <div>Potential Provider Name Match</div>
          <a
            href={`/providers/${metadata.provider_id}`}
            className='underline'
            target='_blank'
            rel='noopener noreferrer'
          >
            {highlightedName}
          </a>
        </>
      );
    }

    if (metadata.found_via == 'scheme') {
      return (
        <>
          <div>Potential Scheme Name Match</div>
          <a
            href={`/schemes/${metadata.scheme_id}`}
            className='underline'
            target='_blank'
            rel='noopener noreferrer'
          >
            {getHighlightedName(metadata.scheme_name)}
          </a>
        </>
      );
    }

    return null;
  };

  return (
    <div className='relative'>
      <div className='relative'>
        <div className='flex items-center gap-2 flex-wrap'>
          {selectedMatches && selectedMatches.length > 0
            ? selectedMatches.map(match => (
                <div
                  key={match.id}
                  className='inline-flex items-center gap-1 bg-gray-100 text-gray-700 px-2 py-1 rounded-md text-sm max-w-[200px]'
                >
                  <span className='truncate'>{match.name}</span>
                  <X
                    className='w-4 h-4 cursor-pointer hover:text-gray-900 flex-shrink-0'
                    onClick={() => toggleSelectMatch(match)}
                  />
                </div>
              ))
            : null}
          <Plus
            className='w-5 h-5 text-gray-500 cursor-pointer hover:text-gray-700 flex-shrink-0'
            onClick={handleHover}
          />
        </div>
      </div>
      {isHovered &&
        createPortal(
          <div
            className='absolute z-[9999] bg-white border border-gray-300 rounded-md shadow-lg p-3'
            style={{
              top: hoverPosition.top,
              left: hoverPosition.left,
              width: '330px',
              maxHeight: 'calc(100vh - 20px)', // Ensure the hover card doesn't exceed the viewport height
              minHeight: '100px', // Set a minimum height for the hover card
              overflow: 'auto' // Enable scrolling if content overflows
            }}
            onClick={e => e.stopPropagation()} // Prevent clicks inside from closing
          >
            <div className='relative h-full'>
              <X
                className='absolute right-0 top-0 w-4 h-4 cursor-pointer hover:text-gray-700 text-gray-500'
                onClick={() => setIsHovered(false)}
              />
              {activeTab === 'suggested' && (
                <div>
                  <span className='font-medium text-gray-900 text-md block border-b border-gray-300 w-full pb-2 mb-2'>
                    Suggested Providers
                  </span>
                  <div className='overflow-y-auto max-h-[300px] pr-2'>
                    {matches.map(match => {
                      const matchScores = match.metadata?.matches || {
                        name: 0,
                        previous_names: 0,
                        phone: 0,
                        address: 0
                      };

                      const totalScore = Math.round(
                        matchScores.name +
                          matchScores.previous_names +
                          matchScores.phone +
                          matchScores.address
                      );

                      const isSelected = selectedMatches.some(
                        selected =>
                          selected.id == match.id || selected.value == match.id
                      );

                      return (
                        <div
                          key={match.id}
                          className={`cursor-pointer p-2 mb-1 rounded ${
                            isSelected
                              ? 'bg-brand-100 border border-2 border-brand-200'
                              : 'hover:bg-gray-100'
                          }`}
                          onClick={() => toggleSelectMatch(match)}
                        >
                          <div className='flex flex-col'>
                            <span className='text-sm'>{match.name}</span>
                            <div className='text-xs text-gray-600 mb-2'>
                              {getDescription(match.metadata, provider.name)}
                            </div>
                            <div className='w-full bg-gray-200 rounded-full h-2.5 mb-2 relative'>
                              <div
                                className='bg-brand-500 h-2.5 rounded-full'
                                style={{ width: `${totalScore}%` }}
                              ></div>
                              {/* <span className='absolute right-0 -top-4 text-xs text-gray-600'>
                                {totalScore}%
                              </span> */}
                            </div>
                            <div className='flex gap-2'>
                              <div className='group relative'>
                                <div
                                  className={`rounded-md p-1 ${
                                    matchScores.name === 0
                                      ? 'bg-red-500'
                                      : matchScores.name < 30
                                      ? 'bg-yellow-500'
                                      : 'bg-green-500'
                                  }`}
                                >
                                  <User className='w-4 h-4 text-white' />
                                </div>
                                <span className='hidden group-hover:block absolute -top-8 left-0 bg-gray-800 text-white text-xs px-2 py-1 rounded whitespace-nowrap'>
                                  Name: {matchScores.name}%
                                </span>
                              </div>
                              <div className='group relative'>
                                <div
                                  className={`rounded-md p-1 ${
                                    matchScores.phone === 0
                                      ? 'bg-red-500'
                                      : matchScores.phone < 7.5
                                      ? 'bg-yellow-500'
                                      : 'bg-green-500'
                                  }`}
                                >
                                  <Phone className='w-4 h-4 text-white' />
                                </div>
                                <span className='hidden group-hover:block absolute -top-8 left-0 bg-gray-800 text-white text-xs px-2 py-1 rounded whitespace-nowrap'>
                                  Phone: {matchScores.phone}%
                                </span>
                              </div>
                              <div className='group relative'>
                                <div
                                  className={`rounded-md p-1 ${
                                    matchScores.address === 0
                                      ? 'bg-red-500'
                                      : matchScores.address < 7.5
                                      ? 'bg-yellow-500'
                                      : 'bg-green-500'
                                  }`}
                                >
                                  <MapPin className='w-4 h-4 text-white' />
                                </div>
                                <span className='hidden group-hover:block absolute -top-8 left-0 bg-gray-800 text-white text-xs px-2 py-1 rounded whitespace-nowrap'>
                                  Address: {matchScores.address}%
                                </span>
                              </div>
                              <div className='group relative'>
                                <div
                                  className={`rounded-md p-1 ${
                                    matchScores.previous_names === 0
                                      ? 'bg-red-500'
                                      : matchScores.previous_names < 7.5
                                      ? 'bg-yellow-500'
                                      : 'bg-green-500'
                                  }`}
                                >
                                  <History className='w-4 h-4 text-white' />
                                </div>
                                <span className='hidden group-hover:block absolute -top-8 left-0 bg-gray-800 text-white text-xs px-2 py-1 rounded whitespace-nowrap'>
                                  Other Names: {matchScores.previous_names}%
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
              {activeTab === 'search' && (
                <div>
                  <Input
                    placeholder='Search Providers'
                    value={searchQuery}
                    onChange={e => setSearchQuery(e.target.value)}
                    className='w-full pb-2'
                  />
                  <div className='mt-2 overflow-y-auto'>
                    {searchResults.length === 0 ? (
                      <div className='text-center text-gray-500 flex flex-col items-center p-4'>
                        <SearchIcon className='mb-2' />
                        <span>No Results</span>
                      </div>
                    ) : (
                      (() => {
                        const getHighlightedPreviousNames = (
                          previousNames,
                          query
                        ) => {
                          return previousNames.map((name, index) => {
                            return name
                              .split(' ')
                              .map((word, i, arr) => {
                                if (
                                  query
                                    .toLowerCase()
                                    .includes(word.toLowerCase()) ||
                                  word
                                    .toLowerCase()
                                    .includes(query.toLowerCase())
                                ) {
                                  return (
                                    <span
                                      key={i}
                                      className='text-[0.75rem] text-brand-500 underline'
                                    >
                                      {word}
                                      {i !== arr.length - 1 ? ' ' : ''}
                                    </span>
                                  );
                                }
                                return (
                                  <span key={i} className='text-[0.75rem]'>
                                    {word}
                                    {i !== arr.length - 1 ? ' ' : ''}
                                  </span>
                                );
                              })
                              .concat(
                                index !== previousNames.length - 1 ? ', ' : ''
                              );
                          });
                        };

                        return searchResults.map(result => {
                          const isSelected = selectedMatches.some(
                            selected => selected.id === result.id
                          );

                          return (
                            <div
                              key={result.id}
                              className={`cursor-pointer p-2 mb-1 rounded ${
                                isSelected
                                  ? 'bg-brand-100 border border-2 border-brand-200'
                                  : 'hover:bg-gray-100'
                              }`}
                              onClick={() => toggleSelectMatch(result)}
                            >
                              <Badge colour='primary' size='sm'>
                                {result.id}
                              </Badge>
                              <span className='ml-2 text-sm'>
                                {result.name}
                              </span>
                              {result.previous_names &&
                                result.previous_names.length > 0 && (
                                  <>
                                    <div className='text-[0.75rem] text-gray-500'>
                                      Previous Names
                                    </div>
                                    <div className='text-sm mt-1'>
                                      {getHighlightedPreviousNames(
                                        result.previous_names,
                                        searchQuery
                                      )}
                                    </div>
                                  </>
                                )}
                            </div>
                          );
                        });
                      })()
                    )}
                  </div>
                </div>
              )}
            </div>
            <div className='flex mt-2 p-1 bg-gray-200 rounded-sm font-medium'>
              <button
                className={`flex-1 px-4 py-2 rounded-sm text-sm ${
                  activeTab === 'suggested'
                    ? 'bg-white text-gray-900'
                    : 'text-gray-600'
                }`}
                onClick={() => setActiveTab('suggested')}
              >
                Suggested
              </button>
              <button
                className={`flex-1 px-4 py-2 rounded-sm text-sm ${
                  activeTab === 'search'
                    ? 'bg-white text-gray-900'
                    : 'text-gray-600'
                }`}
                onClick={() => setActiveTab('search')}
              >
                Search
              </button>
            </div>
          </div>,
          document.body
        )}
    </div>
  );
};

export default AssistedTrace;
