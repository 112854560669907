import BaseModel from './BaseModel';

/**
 * Represents an Algorithm in the system.
 * @extends BaseModel
 */
class Algorithm extends BaseModel {
  /** @type {string} The name of the database table for Algorithms */
  static table = 'algorithm';

  /** @type {string} The SQL query to select Algorithm data */
  static selectQuery =
    '*, company:companies(*), provider:providers(*), status:core_entity_types(id, type)';

  /**
   * Creates an instance of Algorithm.
   * @param {Object} data - The algorithm data.
   */
  constructor(data = {}) {
    super(data);
    this.company = data.company || null;
    this.provider = data.provider || null;
    this.status = data.status || null;
  }

  /**
   * Converts the Algorithm instance to a database-friendly format.
   * @returns {Object} The algorithm data ready for database operations.
   */
  toDatabase() {
    return {
      ...super.toDatabase(),
      company: this.company,
      provider: this.provider,
      status: this.status
    };
  }
}

export default Algorithm;
