import React, { useState } from 'react';
import {
  Input,
  DropdownInput,
  DatePickerV2,
  Popup,
  FilledButton,
  SoftButton
} from '@core/components';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../../../utilities/supabase';

const ConvertPension = ({ onClose, isOpen, search }) => {
  console.log(search);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    value: '',
    policyType: '',
    policyNumber: '',
    startDate: null,
    endDate: null
  });
  const policyTypeOptions = [
    { value: 'final_salary', label: 'Final Salary' },
    { value: 'personal_pension', label: 'Personal Pension Plan' },
    { value: 'group_pension', label: 'Group Pension Plan' },
    { value: 'stakeholder', label: 'Stakeholder' },
    { value: 'section_32', label: 'Section 32 Buy Out' },
    { value: 's226', label: 'S226 Retirement Annuity Contracts' },
    { value: 'sipp', label: 'SIPP' },
    { value: 'contracted_in', label: 'Contracted In (Money Purchase)' },
    { value: 'contracted_out', label: 'Contracted Out (Money Purchase)' },
    { value: 'non_transferable', label: 'Non-Transferable Pension' }
  ];

  const handleChange = (field, value) => {
    setFormData(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const handleSubmit = async () => {
    try {
      // Create pension
      const { data, error: pensionError } = await supabase
        .from('pensions')
        .insert({
          name: search.trace?.name,
          status: 127,
          owner: search.owner.id,
          company: search.company?.id,
          provider: search.provider?.id,
          scheme: search.scheme?.id,
          trace: search.trace?.id,
          search: search.id,
          metadata: {
            value: formData.value,
            policy_type: formData.policyType,
            policy_number: formData.policyNumber,
            start_date: formData.startDate,
            end_date: formData.endDate
          }
        })
        .select()
        .single();

      if (pensionError) throw pensionError;

      const { error: searchError } = await supabase
        .from('searches')
        .update({ status: 77 })
        .eq('id', search.id);

      if (searchError) throw searchError;

      onClose();
      navigate(`/pensions/${data.display_id}`);
    } catch (error) {
      console.error('Error creating pension:', error);
    }
  };

  return (
    <Popup
      title='Convert to Pension'
      onClose={onClose}
      isOpen={isOpen}
      size='sm'
    >
      <div className='space-y-4'>
        <Input
          label='Value'
          type='number'
          placeholder='Enter value'
          value={formData.value}
          onChange={e => handleChange('value', e.target.value)}
          className='w-full'
        />
        <DropdownInput
          label='Policy Type'
          placeholder='Select policy type'
          value={formData.policyType}
          onChange={e => handleChange('policyType', e.target.value)}
          options={policyTypeOptions}
          className='w-full'
        />
        <Input
          label='Policy Number'
          placeholder='Enter policy number'
          value={formData.policyNumber}
          onChange={e => handleChange('policyNumber', e.target.value)}
          className='w-full'
        />
        <DatePickerV2
          label='Start Date'
          initialDate={formData.startDate}
          onChange={date => handleChange('startDate', date)}
        />
        <DatePickerV2
          label='End Date'
          initialDate={formData.endDate}
          onChange={date => handleChange('endDate', date)}
        />
        <div className='flex justify-end gap-3 mt-8 pt-4 border-t'>
          <SoftButton onClick={onClose} className='px-4'>
            Cancel
          </SoftButton>
          <FilledButton onClick={handleSubmit} className='px-4'>
            Convert
          </FilledButton>
        </div>
      </div>
    </Popup>
  );
};

export default ConvertPension;
