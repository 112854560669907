import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Pencil, Download, Trash } from 'lucide-react';
import EntityTable from '../../tables/EntityTable';
import { formatDate, formatProfile } from '../../../utilities/Formatting';
import {
  EntityTypeEnum,
  FilterOperatorEnum
} from '../../../utilities/Enumerables';
import Document from '../../../models/Document';
import FileUploader from '../../popups/files/FileUploader';
import { supabase } from '../../../utilities/supabase';
import { DropdownInput } from '@core/components';
import CoreEntityType from '../../../models/CoreEntityType';

const FileOverview = ({ entityType, id, currentUser }) => {
  const [isUploadOpen, setIsUploadOpen] = useState(false);
  const [documentTypes, setDocumentTypes] = useState([]);

  const handleNewFileClick = () => {
    setIsUploadOpen(true);
  };

  const handleUploadSuccess = () => {
    // Logic to handle successful upload
    setIsUploadOpen(false);
  };

  const handleDownload = async document => {
    try {
      const baseUrl = 'https://storage.googleapis.com/pensiontracingservice';
      const filePath = `${document?.filePath}/${document?.id}/${document?.fileName}`;
      const link = window.document.createElement('a');
      link.href = `${baseUrl}/${filePath}`;
      link.download = `${document.fileName}` || `document.${document.fileType}`;
      link.target = '_blank'; // Open in new tab
      link.rel = 'noopener noreferrer'; // Security best practice for new tabs
      window.document.body.appendChild(link);
      link.click();
      window.document.body.removeChild(link);
    } catch (error) {
      console.error('Error downloading document:', error);
    }
  };

  useEffect(() => {
    const fetchDocumentTypes = async () => {
      try {
        const types = await CoreEntityType.getByEntityId(
          EntityTypeEnum.DocumentType
        );
        const formattedTypes = types.map(type => ({
          value: type.id,
          label: type.type
        }));
        setDocumentTypes(formattedTypes);
      } catch (error) {
        console.error('Error fetching document types:', error);
      }
    };

    fetchDocumentTypes();
  }, []);

  return (
    <div>
      <EntityTable
        name='Documents'
        customId='Documents'
        filter={[
          { field: 'display_id', label: 'ID', type: 'text' },
          {
            field: 'file_name',
            label: 'File Name',
            type: 'text',
            operator: FilterOperatorEnum.ILIKE
          },
          {
            field: 'owner.full_name',
            label: 'Uploaded By',
            type: 'text',
            operator: FilterOperatorEnum.ILIKE
          },
          {
            field: 'created_date',
            label: 'Created Date',
            type: 'daterange',
            operator: FilterOperatorEnum.BETWEEN
          },
          {
            field: 'updated_date',
            label: 'Updated Date',
            type: 'daterange',
            operator: FilterOperatorEnum.BETWEEN
          }
        ]}
        columns={[
          {
            label: 'ID',
            field: 'displayId',
            cell: value => (
              <div className='text-brand-700 hover:text-brand-800'>{value}</div>
            )
          },
          { label: 'File Name', field: 'fileName' },
          {
            label: 'Type',
            field: 'type',
            cell: (value, row) => (
              <DropdownInput
                value={row?.type?.id}
                options={documentTypes}
                onClick={e => e.stopPropagation()}
                onChange={async e => {
                  try {
                    await supabase
                      .from('documents')
                      .update({ type: e.target.value })
                      .eq('id', row?.id);
                  } catch (error) {
                    console.error('Error updating document type:', error);
                  }
                }}
                size='sm'
                className='min-w-[150px]'
              />
            )
          },
          {
            label: 'Uploaded By',
            field: 'owner',
            cell: value => formatProfile(value)
          },
          {
            label: 'Created Date',
            field: 'createdDate',
            cell: value => formatDate(value)
          },
          {
            label: 'Updated Date',
            field: 'updatedDate',
            cell: value => formatDate(value)
          }
        ]}
        model={Document}
        entityType={EntityTypeEnum.Document}
        onNewClick={handleNewFileClick}
        canCreate={true}
        onRowClick={handleDownload}
        menuItems={[
          {
            label: 'Download',
            icon: <Download size={14} />,
            onClick: handleDownload
          }
        ]}
        defaultFilters={{
          connections: { operator: 'contains', value: { [entityType]: [id] } }
        }}
      />
      {isUploadOpen && (
        <FileUploader
          isOpen={isUploadOpen}
          onClose={() => setIsUploadOpen(false)}
          onUploadSuccess={handleUploadSuccess}
          destinationFolder={'documents'}
          connections={{
            [entityType]: [id]
          }}
        />
      )}
    </div>
  );
};

export default FileOverview;
