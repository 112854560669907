import BaseModel from './BaseModel';

/**
 * Represents a Search in the system.
 * @extends BaseModel
 */
class Search extends BaseModel {
  /** @type {string} The name of the database table for Searches */
  static table = 'searches';
  /** @type {string} The SQL query to select Search data */
  static selectQuery =
    '*, owner:user_profiles(*), status:core_entity_types!inner(*), trace:traces!inner(*), company:companies(*), provider:providers(*), scheme:schemes(*)';

  /**
   * Creates an instance of Search.
   * @param {Object} data - The search data.
   */
  constructor(data = {}) {
    super(data);
    this.displayId = data.display_id || null;
    this.status = data.status || null;
    this.trace = data.trace
      ? {
          id: data.trace.id || null,
          name: data.trace.name || null,
          displayId: data.trace.display_id || null
        }
      : null;
    this.provider = data.provider
      ? {
          id: data.provider.id || null,
          name: data.provider.name || null,
          createdDate: data.provider.created_date || null,
          updatedDate: data.provider.updated_date || null,
          portals: data.provider.portals || []
        }
      : null;
    this.scheme = data.scheme
      ? {
          id: data.scheme.id || null,
          name: data.scheme.name || null,
          createdDate: data.scheme.created_date || null,
          updatedDate: data.scheme.updated_date || null
        }
      : null;
    this.company = data.company
      ? {
          id: data.company.id || null,
          name: data.company.name || null,
          number: data.company.number || null,
          createdDate: data.company.created_date || null,
          updatedDate: data.company.updated_date || null
        }
      : null;
    this.owner = data.owner
      ? {
          id: data.owner.id || null,
          firstName: data.owner.first_name || null,
          lastName: data.owner.last_name || null,
          email: data.owner.email || null,
          displayId: data.owner.display_id || null,
          nationalInsurance: data.owner.national_insurance || null,
          createdDate: data.owner.created_date || null,
          updatedDate: data.owner.updated_date || null
        }
      : null;
    this.metadata = data.metadata || {};
    this.createdDate = data.created_date || null;
    this.updatedDate = data.updated_date || null;
  }

  /**
   * Converts the Search instance to a database-friendly format.
   * @returns {Object} The search data ready for database operations.
   */
  toDatabase() {
    return {
      ...super.toDatabase(),
      status: typeof this.status === 'object' ? this.status?.id : undefined,
      trace: typeof this.trace === 'object' ? this.trace?.id : null,
      provider:
        typeof this.provider === 'object' ? this.provider?.id : undefined,
      scheme: typeof this.scheme === 'object' ? this.scheme?.id : undefined,
      company: typeof this.scheme === 'object' ? this.company?.id : undefined,
      owner: typeof this.owner === 'object' ? this.owner?.id : undefined,
      metadata: this.metadata,
      created_date: this.createdDate,
      updated_date: this.updatedDate
    };
  }

  /**
   * Get a record by its ID.
   * @param {string} id - The UUID of the record to fetch.
   * @returns {Promise<Search>} A promise that resolves to a new instance of the model.
   */
  static async getById(id) {
    return this.fetchOne('display_id', id, this.selectQuery);
  }

  /**
   * Override the getAll method from BaseModel to handle inner join filtering.
   * @param {Object} filters - The filters to apply.
   * @param {number} page - The page number.
   * @param {number} size - The page size.
   * @param {string} sortBy - The field to sort by.
   * @param {string} sortType - The sort type ('asc' or 'desc').
   * @returns {Promise<Object>} A promise that resolves to an object containing the data and pagination info.
   */
  static async getAll(
    filters = {},
    page = 1,
    size = 10,
    sortBy = 'id',
    sortType = 'asc'
  ) {
    const innerJoinFields = ['provider.name', 'scheme.name'];

    const hasInnerJoinFilter = innerJoinFields.some(field => {
      const [table, column] = field.split('.');
      return filters[field] || (filters[table] && filters[table][column]);
    });

    if (hasInnerJoinFilter) {
      Object.keys(filters).forEach(key => {
        if (key.includes('.')) {
          const table = key.split('.')[0];
          filters[table] = {
            operator: 'not',
            innerOperator: 'is',
            value: null
          };
        }
      });
    }

    return super.getAll(filters, page, size, sortBy, sortType);
  }
}

export default Search;
