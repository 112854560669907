import React, { useState, useEffect } from 'react';
import { Badge, TextButton } from '@core/components';

const ActiveFilters = ({ filters, removeFilter, removeAllFilters }) => {
  const [friendlyFilters, setFriendlyFilters] = useState({});

  useEffect(() => {
    const fetchFriendlyValues = async () => {
      const updatedFilters = {};
      for (const [key, value] of Object.entries(filters)) {
        if (key === 'distance' && value.metadata) {
          const { lat, lng } = value.metadata;
          const address = await reverseGeocode(lat, lng);
          updatedFilters[key] = {
            ...value,
            friendlyValue: `${value.value} miles of ${address}`
          };
        } else {
          updatedFilters[key] = {
            ...value,
            friendlyValue: getFriendlyValue(value.value, value.friendlyValue)
          };
        }
      }

      setFriendlyFilters(updatedFilters);
    };

    fetchFriendlyValues();
  }, [filters]);

  const reverseGeocode = async (lat, lng) => {
    if (!window.google?.maps) {
      console.error('Google Maps library not loaded');
      return 'Unknown location';
    }

    try {
      const { Geocoder } = await google.maps.importLibrary('geocoding');
      const geocoder = new Geocoder();

      return new Promise((resolve, reject) => {
        geocoder.geocode({ location: { lat, lng } }, (results, status) => {
          if (status === 'OK' && results[0]) {
            resolve(results[0].formatted_address);
          } else {
            console.error('Error reverse geocoding:', status);
            resolve('Unknown location');
          }
        });
      });
    } catch (error) {
      console.error('Error reverse geocoding:', error);
      return 'Unknown location';
    }
  };

  const getFriendlyValue = (value, friendlyValue) => {
    try {
      // If friendlyValue is provided and not null/undefined, use it
      if (friendlyValue) {
        if (Array.isArray(friendlyValue)) {
          return friendlyValue.join(', ');
        }
        return friendlyValue;
      }

      // Otherwise fall back to formatting the value
      if (Array.isArray(value)) {
        return value.join(', ');
      }

      let parsedValue = value;
      if (typeof value === 'string') {
        try {
          parsedValue = JSON.parse(value);
        } catch {
          parsedValue = value;
        }
      }

      if (typeof parsedValue === 'object' && parsedValue !== null) {
        return Object.entries(parsedValue)
          .map(([k, v]) => `${k} ${v}`)
          .join(' ');
      }

      return parsedValue;
    } catch (error) {
      console.error(error);
      return value; // Fallback to original value in case of error
    }
  };

  if (Object.keys(friendlyFilters).length === 0) {
    return null;
  }

  return (
    <div className='flex flex-wrap gap-2 mt-2 bg-base-50 items-center py-3 px-4 rounded-md'>
      <p className='text-base-500 text-sm leading-6 font-medium'>Filters</p>
      <div className='mx-2 h-5 w-px bg-base-300' />
      {Object.entries(friendlyFilters).map(
        ([key, value]) =>
          value && (
            <Badge
              key={key}
              colour='primary'
              shape='pill'
              onRemove={() => removeFilter(key)}
            >
              {`${value.label}: ${value.friendlyValue}`}
            </Badge>
          )
      )}
      <TextButton onClick={() => removeAllFilters()} colour='primary'>
        Clear all
      </TextButton>
    </div>
  );
};

export default ActiveFilters;
