import { supabase } from '../utilities/supabase';
import BaseModel from './BaseModel';

/**
 * Represents a Task in the system.
 * @extends BaseModel
 */
class Task extends BaseModel {
  /** @type {string} The name of the database table for Tasks */
  static table = 'tasks';

  /** @type {string} The SQL query to select Task data with related information */
  static selectQuery = `
    *,
    owner:user_profiles(id, first_name, last_name, profile_photo),
    category:core_entity_types!category(id, type, metadata),
    status:core_entity_types!status(id, type, metadata)
  `;

  /**
   * Creates an instance of Task.
   * @param {Object} data - The task data.
   */
  constructor(data = {}) {
    super(data);
    this.owner = data.owner
      ? {
          id: data.owner.id,
          firstName: data.owner.first_name,
          lastName: data.owner.last_name,
          profilePhoto: data.owner.profile_photo
        }
      : null;
    this.title = data.title || null;
    this.description = data.description || null;
    this.category = data.category
      ? {
          id: data.category.id,
          name: data.category.type,
          colour: data.category.metadata?.colour
        }
      : null;
    this.priority = data.priority || null;
    this.status = data.status
      ? {
          id: data.status.id,
          name: data.status.type,
          colour: data.status.metadata?.colour
        }
      : data.status;
    this.entityType = data.entity_type || null;
    this.entityId = data.entity_id || null;
    this.dueDate = data.due_date || null;
    this.assignees = data.assignees || [];
    this.followers = data.followers || [];
    this.parent = data.parent || null;
    this.order = data.order || null;
  }

  /**
   * Converts the Task instance to a database-friendly format.
   * @returns {Object} The task data ready for database operations.
   */
  toDatabase() {
    return {
      ...super.toDatabase(),
      owner: this.owner ? this.owner.id : null,
      title: this.title,
      description: this.description,
      category: this.category ? this.category.id : null,
      priority: this.priority,
      status: typeof this.status == 'object' ? this.status.id : this.status,
      entity_type: this.entityType,
      entity_id: this.entityId,
      due_date: this.dueDate,
      assignees: this.assignees || [],
      followers: this.followers,
      parent: this.parent,
      order: this.order
    };
  }

  /**
   * Update the order of the task.
   * @param {number} newOrder - The new order value.
   * @returns {Promise<Task>} A promise that resolves to the updated Task instance.
   */
  async updateOrder(newOrder) {
    try {
      const { data, error } = await supabase.rpc('update_task_order', {
        p_task_id: this.id,
        p_new_order: newOrder,
        p_entity_type: this.entityType,
        p_entity_id: this.entityId,
        p_parent_id: this.parent
      });

      if (error) throw error;

      this.order = newOrder;
      return this;
    } catch (error) {
      console.error('[Error] Updating Task Order: ', error);
      throw error;
    }
  }
}

export default Task;
