import React from 'react';
import { Check } from 'lucide-react';

const Checkbox = ({
  checked,
  onChange,
  className,
  label,
  disabled = false
}) => {
  const baseClasses = `
    relative inline-flex items-center justify-center
    w-5 h-5 rounded
    border-2 transition-colors duration-200 ease-in-out
    focus:outline-none focus:ring-2 focus:ring-offset-2
  `;

  const themedClasses = `
    ${checked ? 'bg-brand-600 border-brand-600' : 'bg-white border-neutral-300'}
    ${disabled ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'}
    ${checked && !disabled ? 'hover:bg-brand-700' : 'hover:border-brand-500'}
    focus:ring-brand-500
  `;

  return (
    <label
      className={`inline-flex items-center ${
        disabled ? 'cursor-not-allowed' : 'cursor-pointer'
      }`}
    >
      <input
        type='checkbox'
        checked={checked}
        onChange={onChange}
        disabled={disabled}
        className='absolute opacity-0 w-0 h-0'
      />
      <span className={`${baseClasses} ${themedClasses} ${className || ''}`}>
        {checked && <Check className='w-4 h-4 text-white' />}
      </span>
      {label && (
        <span
          className={`ml-2 text-sm ${
            disabled ? 'text-neutral-400' : 'text-neutral-700'
          }`}
        >
          {label}
        </span>
      )}
    </label>
  );
};

export default Checkbox;
