/**
 * Enumeration of entity types used throughout the application.
 * Each entity type is assigned a unique numeric identifier.
 *
 **/
export const EntityTypeEnum = {
  Title: 8,
  Company: 10,
  CompanyStatus: 29,
  Document: 37,
  DocumentType: 2,
  ActivityLog: 5,
  ActivityType: 21,
  Filter: 45,
  User: 20,
  UserTitle: 8,
  UserStatus: 4,
  UserTags: 4,
  UserSubTags: 1,
  Trace: 6,
  TraceStatus: 12,
  TraceType: 51,
  Search: 13,
  SearchStatus: 28,
  Pension: 7,
  PensionStatus: 27,
  Provider: 15,
  ProviderStatus: 30,
  Scheme: 16,
  SchemeStatus: 31,
  Task: 24,
  TaskCategory: 25,
  TaskStatus: 26,
  Meeting: 35,
  MeetingCategory: 44,
  MeetingStatus: 43,
  Pipelines: 33,
  Tags: 22,
  TagCategory: 23,
  Automations: 55,
  AutomationRun: 56,
  Settings: 57,
  SettingCategories: 58,
  TaggedEntities: 60,
  Guides: 47,
  GuideCategories: 48,
  Resources: 63,
  PipelineEntities: 64,
  SettingsTabs: 65,
  Segmentation: 17,
  Address: 18,
  AddressType: 46,
  AlgorithmStatus: 52
};

/**
 * Enum for filter operators used in query building.
 * These operators define how filter conditions are applied to fields.
 * @enum {string}
 */
export const FilterOperatorEnum = {
  /** Equality comparison */
  EQUALS: 'eq',
  /** Not equal comparison */
  NOT_EQUALS: 'neq',
  /** Greater than comparison */
  GREATER_THAN: 'gt',
  /** Greater than or equal to comparison */
  GREATER_THAN_OR_EQUAL: 'gte',
  /** Less than comparison */
  LESS_THAN: 'lt',
  /** Less than or equal to comparison */
  LESS_THAN_OR_EQUAL: 'lte',
  /** Case-insensitive pattern matching */
  ILIKE: 'ilike',
  /** Exact pattern matching */
  LIKE: 'like',
  /** Check if value is in a list */
  IN: 'in',
  /** Check if value is not in a list */
  NOT_IN: 'not.in',
  /** Check if value is  */
  IS: 'is',
  /**Check if a value is not */
  IS_NOT: 'not',
  /**Text search */
  TEXTSEARCH: 'textSearch',
  /**Allows convenient date filtering between values */
  BETWEEN: 'between',
  /**Check if array/json/range contains value */
  CONTAINS: 'contains'
};
