import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { SquareArrowOutUpRight } from 'lucide-react';
import EntityTable from '../../../../components/tables/EntityTable';
import { TextButton } from '@core/components';
import Pension from '../../../../models/Pension';
import CoreEntityType from '../../../../models/CoreEntityType';
import {
  EntityTypeEnum,
  FilterOperatorEnum
} from '../../../../utilities/Enumerables';
import { formatDate } from '../../../../utilities/Formatting';

const ProviderPensions = ({ providerId }) => {
  const navigate = useNavigate();
  const [pensionStatus, setPensionStatus] = useState([]);

  useEffect(() => {
    const fetchPensionStatus = async () => {
      try {
        const types = await CoreEntityType.getByEntityId(
          EntityTypeEnum.PensionStatus
        );

        const formattedTypes = types.map(type => ({
          value: type.type,
          key: type.id
        }));

        setPensionStatus(formattedTypes);
      } catch (error) {
        console.error('Error fetching pension types:', error);
      }
    };

    fetchPensionStatus();
  }, []);

  const menuItems = [
    {
      label: 'Open in new tab',
      icon: <SquareArrowOutUpRight size={14} />,
      onClick: data => window.open(`/pensions/${data.displayId}`, '_blank')
    }
  ];

  return (
    <EntityTable
      name='Pensions'
      customId={`provider:${providerId}:pensions`}
      filter={[
        {
          field: 'display_id',
          label: 'ID',
          type: 'text',
          operator: FilterOperatorEnum.EQUALS
        },
        {
          field: 'name',
          label: 'Name',
          type: 'text',
          operator: FilterOperatorEnum.ILIKE
        },
        {
          field: 'status',
          label: 'Status',
          type: 'select',
          options: pensionStatus
        },
        {
          field: 'company.name',
          label: 'Company',
          type: 'text',
          operator: FilterOperatorEnum.ILIKE
        },
        {
          field: 'scheme.name',
          label: 'Scheme',
          type: 'text',
          operator: FilterOperatorEnum.ILIKE
        },
        {
          field: 'created_date',
          label: 'Created Date',
          type: 'daterange',
          operator: FilterOperatorEnum.BETWEEN
        },
        {
          field: 'updated_date',
          label: 'Updated Date',
          type: 'daterange',
          operator: FilterOperatorEnum.BETWEEN
        },
        {
          field: 'showArchived',
          label: 'Show Archived',
          hint: 'Show archived pensions',
          type: 'conditional_toggle',
          operator: FilterOperatorEnum.IS,
          options: {
            value: 'true'
          }
        }
      ]}
      columns={[
        {
          label: 'ID',
          field: 'displayId'
        },
        { label: 'Name', field: 'name' },
        {
          label: 'Status',
          field: 'status.type'
        },

        {
          label: 'Company',
          field: 'company',
          cell: value =>
            value ? (
              <Link
                to={`/companies/${value.id}`}
                target='_blank'
                onClick={e => e.stopPropagation()}
                className='hover:underline'
              >
                {value.name}
              </Link>
            ) : (
              'N/A'
            )
        },
        {
          label: 'Scheme',
          field: 'scheme',
          cell: value =>
            value ? (
              <Link
                to={`/schemes/${value.id}`}
                target='_blank'
                onClick={e => e.stopPropagation()}
                className='hover:underline'
              >
                {value.name}
              </Link>
            ) : (
              'N/A'
            )
        },
        {
          label: 'Created Date',
          field: 'createdDate',
          cell: value => formatDate(value)
        },
        {
          label: 'Updated Date',
          field: 'updatedDate',
          cell: value => formatDate(value)
        }
      ]}
      menuItems={menuItems}
      model={Pension}
      entityType={EntityTypeEnum.Pension}
      defaultFilters={{
        provider: { value: providerId },
        status: { value: '151', operator: FilterOperatorEnum.NOT_EQUALS }
      }}
      sortDefault={{ field: 'display_id', order: 'asc' }}
      onRowClick={pension => {
        navigate(`/pensions/${pension.displayId}`);
      }}
    />
  );
};

export default ProviderPensions;
