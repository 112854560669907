import React, { useState, useEffect } from 'react';
import TaskTemplate from '../../../models/TaskTemplate';
import {
  FilledButton,
  TextButton,
  Input,
  MultiSelectBox,
  DropdownInput,
  Popup
} from '@core/components';
import { Plus, Trash2 } from 'lucide-react';
import CoreEntityTypeModel from '../../../models/CoreEntityType';
import { EntityTypeEnum } from '../../../utilities/Enumerables';

const CreateChecklist = ({ onClose, entityType, entityId, template }) => {
  const [parentTask, setParentTask] = useState({
    title: template ? template.template.title : '',
    daysAfterCreation: template ? template.template.daysAfterCreation : '',
    category: template ? template.template.category : null
  });
  const [childTasks, setChildTasks] = useState(
    template
      ? template.template.children
      : [{ title: '', daysAfterCreation: '', category: null }]
  );
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchCategories = async () => {
      setIsLoading(true);
      try {
        const { data } = await CoreEntityTypeModel.getAll({
          entity_id: { value: EntityTypeEnum.TaskCategory }
        });
        setCategoryOptions(
          data.map(option => ({
            value: option.id,
            label: option.type,
            type: option.type,
            colour: option.metadata?.colour
          }))
        );
      } catch (error) {
        console.error('Error fetching categories:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchCategories();
  }, []);

  const handleParentTaskChange = (field, value) => {
    setParentTask(prev => ({ ...prev, [field]: value }));
  };

  const handleChildTaskChange = (index, field, value) => {
    const updatedChildTasks = [...childTasks];
    updatedChildTasks[index][field] = value;
    setChildTasks(updatedChildTasks);
  };

  const addChildTask = () => {
    setChildTasks([
      ...childTasks,
      { title: '', daysAfterCreation: '', category: null }
    ]);
  };

  const removeChildTask = index => {
    const updatedChildTasks = childTasks.filter((_, i) => i !== index);
    setChildTasks(updatedChildTasks);
  };

  const isValid = () => {
    if (!parentTask.title?.trim()) return false;
    return childTasks.every(task => task.title?.trim());
  };

  const handleSubmit = async () => {
    try {
      const templateStructure = {
        title: parentTask.title,
        daysAfterCreation: parentTask.daysAfterCreation,
        category: parentTask.category,
        children: childTasks
      };

      const taskTemplate = new TaskTemplate({
        title: parentTask.title,
        template: templateStructure,
        entity_type: entityType
      });

      const newTaskTemplate = await taskTemplate.insert();
      onClose(newTaskTemplate);
    } catch (error) {
      console.error('Error creating checklist template:', error);
      // Handle error (e.g., show error message to user)
    }
  };

  if (isLoading) {
    return (
      <Popup
        isOpen={true}
        onClose={onClose}
        title='Create New Checklist Template'
      >
        <div className='flex justify-center items-center h-64'>
          <p>Loading categories...</p>
        </div>
      </Popup>
    );
  }

  return (
    <Popup
      isOpen={true}
      onClose={onClose}
      title='Create New Checklist Template'
    >
      <div className='space-y-4 w-full'>
        <div className='space-y-4'>
          <h3 className='text-lg font-medium'>Parent Task</h3>
          <div className='flex items-center w-full'>
            <Input
              label='Title'
              name='title'
              value={parentTask.title}
              onChange={e => handleParentTaskChange('title', e.target.value)}
              placeholder='Parent Task Title'
              className='flex-grow'
            />
          </div>
          <div className='flex items-center w-full'>
            <Input
              label='Days After Creation'
              type='number'
              name='daysAfterCreation'
              value={parentTask.daysAfterCreation}
              onChange={e =>
                handleParentTaskChange('daysAfterCreation', e.target.value)
              }
              className='flex-grow'
            />
          </div>
          <div className='flex items-center w-full'>
            <DropdownInput
              label='Category'
              options={categoryOptions}
              value={parentTask.category}
              onChange={e => handleParentTaskChange('category', e.target.value)}
              placeholder='Select category'
              className='flex-grow'
            />
          </div>
        </div>

        <div className='space-y-4 overflow-y-auto max-h-[40vh]'>
          <h3 className='text-lg font-medium'>Child Tasks</h3>
          {childTasks.map((childTask, index) => (
            <div
              key={index}
              className='max-sm:flex-col lg:flex items-center space-y-1 lg:space-y-0 lg:space-x-2 '
            >
              <div className='flex items-center w-full'>
                <Input
                  placeholder='Task title'
                  value={childTask.title}
                  onChange={e =>
                    handleChildTaskChange(index, 'title', e.target.value)
                  }
                  className='flex-grow'
                />
                <FilledButton
                  onClick={() => removeChildTask(index)}
                  colour='danger'
                  className='p-2 sm:hidden ml-2'
                >
                  <Trash2 size={16} />
                </FilledButton>
              </div>
              <Input
                type='number'
                placeholder='Days After Creation'
                value={childTask.daysAfterCreation}
                onChange={e =>
                  handleChildTaskChange(
                    index,
                    'daysAfterCreation',
                    e.target.value
                  )
                }
                className='w-full'
              />
              <DropdownInput
                options={categoryOptions}
                value={childTask.category}
                onChange={e =>
                  handleChildTaskChange(index, 'category', e.target.value)
                }
                placeholder='Select category'
                className='w-full'
              />
              <FilledButton
                onClick={() => removeChildTask(index)}
                colour='danger'
                className='p-2 hidden sm:block'
              >
                <Trash2 size={16} />
              </FilledButton>
            </div>
          ))}
          <TextButton
            onClick={addChildTask}
            colour='primary'
            leftIcon={<Plus size={16} />}
          >
            Add Child Task
          </TextButton>
        </div>

        <div className='flex justify-end space-x-2 mt-4'>
          <FilledButton
            onClick={handleSubmit}
            colour='primary'
            disabled={!isValid()}
          >
            Create Checklist Template
          </FilledButton>
        </div>
      </div>
    </Popup>
  );
};

export default CreateChecklist;
