import React, { useState, useEffect } from 'react';
import { DropdownInput, Input } from '@core/components';
import CoreEntityType from '../../../../models/CoreEntityType';
import { EntityTypeEnum } from '../../../../utilities/Enumerables';

const BasicDetails = ({ schemeData, handleInputChange }) => {
  const [statusOptions, setStatusOptions] = useState([]);

  useEffect(() => {
    const fetchStatusOptions = async () => {
      try {
        const statuses = await CoreEntityType.getByEntityId(
          EntityTypeEnum.SchemeStatus
        );
        setStatusOptions(
          statuses.map(status => ({
            label: status.type,
            value: status.id
          }))
        );
      } catch (error) {
        console.error('Error fetching status options:', error);
      }
    };

    fetchStatusOptions();
  }, []);

  return (
    <div className='space-y-4'>
      <Input
        label='Scheme Name'
        value={schemeData.name}
        onChange={e => handleInputChange('name', e.target.value)}
        required
      />
      <DropdownInput
        label='Status'
        value={schemeData.status}
        onChange={value => handleInputChange('status', value)}
        options={statusOptions}
        disablePlaceholder
      />
    </div>
  );
};

export default BasicDetails;
