import React, { useState, useEffect } from 'react';
import { supabase } from '../../../utilities/supabase';
import {
  LogoLoader,
  Popup,
  Input,
  FilledButton,
  TextButton,
  MultiSelectBox
} from '@core/components';

const ECardPopup = ({ onClose, entity }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [templates, setTemplates] = useState([]);
  const [searchTitle, setSearchTitle] = useState('');
  const [stage, setStage] = useState('browse'); // browse or preview
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [mergeFields, setMergeFields] = useState({});
  const [tags, setTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);

  useEffect(() => {
    fetchTemplates();
  }, [searchTitle, selectedTags]);

  useEffect(() => {
    fetchTags();
  }, []);

  const fetchTags = async () => {
    try {
      const { data, error } = await supabase.functions.invoke(
        'integrations/add-ai/get-ecard-tags',
        { body: {} }
      );
      if (error) throw error;
      const mappedTags = data.map(tag => ({ label: tag.name, value: tag.id }));
      setTags(mappedTags);
    } catch (error) {
      console.error('Error fetching tags:', error);
    }
  };

  const fetchTemplates = async () => {
    try {
      setIsLoading(true);
      const { data, error } = await supabase.functions.invoke(
        'integrations/add-ai/get-ecards',
        {
          body: {
            title: searchTitle,
            tags: selectedTags.map(tag => tag.value)
          }
        }
      );
      if (error) throw error;
      setTemplates(data);
    } catch (error) {
      console.error('Error fetching templates:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const extractMergeTags = config => {
    const tags = new Set();
    const findTags = obj => {
      for (const value of Object.values(obj)) {
        if (typeof value === 'string') {
          const matches = value.match(/\{\{(.*?)\}\}/g);
          if (matches) {
            matches.forEach(match => tags.add(match.replace(/[{}]/g, '')));
          }
        } else if (typeof value === 'object' && value !== null) {
          findTags(value);
        }
      }
    };
    findTags(config);
    return Array.from(tags);
  };

  const handleCardClick = template => {
    setSelectedTemplate(template);
    const mergeTags = extractMergeTags(template.pages);
    const initialMergeFields = {};
    mergeTags.forEach(tag => {
      initialMergeFields[tag] = '';
    });
    setMergeFields(initialMergeFields);
    setStage('preview');
  };

  const renderBrowseStage = () => (
    <>
      <div className='flex space-x-4 mb-4'>
        <Input
          label='Search'
          placeholder='Card title...'
          value={searchTitle}
          onChange={e => setSearchTitle(e.target.value)}
        />
        <MultiSelectBox
          label='Filter by tags'
          options={tags}
          selectedOptions={selectedTags}
          onChange={setSelectedTags}
        />
      </div>

      {isLoading ? (
        <div className='flex justify-center items-center h-96'>
          <LogoLoader
            logo={'/logo-star.png'}
            size='small'
            selfContained={false}
          />
        </div>
      ) : (
        <div className='grid grid-cols-4 gap-4'>
          {templates.map((template, index) => (
            <div
              key={index}
              className='rounded-lg overflow-hidden shadow-md w-[160px] cursor-pointer transform transition-transform duration-200 hover:scale-105'
              onClick={() => handleCardClick(template)}
            >
              <img
                src={template.thumbnail}
                alt={template.title}
                className='w-full h-[229px] object-contain'
              />
              <div className='p-2 bg-white'>
                <h3 className='text-sm font-medium'>{template.title}</h3>
                <div className='flex flex-wrap gap-1 mt-1'>
                  {template.tags?.map((tagId, tagIndex) => {
                    const tag = tags.find(t => t.value === tagId);
                    return (
                      tag && (
                        <span
                          key={tagIndex}
                          className='px-1.5 py-0.5 bg-neutral-100 text-neutral-700 rounded text-xs'
                        >
                          {tag.label}
                        </span>
                      )
                    );
                  })}
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  );

  const renderPreviewStage = () => (
    <div className='flex gap-4'>
      <div className='w-1/2'>
        <img
          src={selectedTemplate.thumbnail}
          alt={selectedTemplate.title}
          className='w-[320px] h-[458px] object-contain rounded-lg'
        />
      </div>
      <div className='w-1/2 space-y-4'>
        <h2 className='text-xl font-semibold mb-4'>{selectedTemplate.title}</h2>
        {Object.keys(mergeFields).map(tag => (
          <div key={tag}>
            <Input
              label={tag}
              value={mergeFields[tag]}
              onChange={e =>
                setMergeFields(prev => ({
                  ...prev,
                  [tag]: e.target.value
                }))
              }
            />
          </div>
        ))}
      </div>
    </div>
  );

  const renderFooter = () => {
    if (stage === 'preview') {
      return (
        <div className='flex justify-end gap-2'>
          <FilledButton colour='base' onClick={() => setStage('browse')}>
            Back
          </FilledButton>
          <FilledButton
            colour='primary'
            onClick={() => {
              const searchParams = new URLSearchParams(mergeFields);
              const url = `https://add.ai/ecard/${
                selectedTemplate.public_id
              }?${searchParams.toString()}`;
              navigator.clipboard.writeText(url);
            }}
          >
            Copy Card Link
          </FilledButton>
        </div>
      );
    }
    return null;
  };

  return (
    <Popup
      isOpen={true}
      onClose={onClose}
      title='Send ECard'
      size='md'
      footer={renderFooter()}
    >
      {stage === 'browse' ? renderBrowseStage() : renderPreviewStage()}
    </Popup>
  );
};

export default ECardPopup;
