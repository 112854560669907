import React, { useState, useEffect, useContext, useCallback } from 'react';
import { supabase } from '../../../utilities/supabase';
import UserProfile from '../../../models/UserProfile';
import Accordion from '../../entity/Accordion';
import {
  FilledButton,
  OutlineButton,
  NotificationItem,
  Input,
  MultiSelectBox,
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem
} from '@core/components';
import { FilterOperatorEnum } from '../../../utilities/Enumerables';
import { LoaderCircle, Send, ArrowRight, Zap } from 'lucide-react';
import Quote_QuoteOptions from './loops/User_Traces';
import { handleLoops, buildLoopPayload } from './loops/loopFunctions';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@core/components/ui';
import EmailPreview from './EmailPreview';
import { UserProfileContext } from '../../../App';
import Automation from '../../../models/Automations';

const StageTwo = ({
  selectedTemplate,
  onBack,
  onClose,
  entity,
  entityType,
  to: initialTo = [],
  extraAttachements,
  autoSelectAttachements = [], // New prop for auto-selecting attachments
  autoRunAutomationId = null, // New prop for auto-running automation
  onSubmit // New prop for handling submission
}) => {
  const [mergeTags, setMergeTags] = useState({});
  const [loops, setLoops] = useState([]);
  const [to, setTo] = useState(initialTo);
  const [from, setFrom] = useState('support@pensiontracingservice.com');
  const [subject, setSubject] = useState(null);
  const [errors, setErrors] = useState({});
  const [showNotification, setShowNotification] = useState(false);
  const [notificationConfig, setNotificationConfig] = useState({});
  const [isSending, setIsSending] = useState(false);
  const [databaseEntity, setDatabaseEntity] = useState({});
  const [selectedQuoteOptions, setSelectedQuoteOptions] = useState({});
  const [activeTab, setActiveTab] = useState('content');
  const [availableTriggers, setAvailableTriggers] = useState([]);
  const [isLoadingTriggers, setIsLoadingTriggers] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const [selectedAttachments, setSelectedAttachments] = useState([]);

  const { userProfile: currentUser } = useContext(UserProfileContext);

  useEffect(() => {
    fetchEntityAutomations();
    fetchAttachments();
  }, [entityType, entity]);

  useEffect(() => {
    fetchExtraAttachments();
  }, [extraAttachements]);

  useEffect(() => {
    setTo(initialTo);
  }, [initialTo]);

  useEffect(() => {
    autoSelectAttachmentsByTypeOrName();
  }, [attachments, autoSelectAttachements]);

  useEffect(() => {
    autoSelectAttachmentsByTypeOrName();
  }, [attachments]);

  const fetchEntityAutomations = async () => {
    setIsLoadingTriggers(true);
    try {
      const fetchedAutomations = await Automation.getAll({
        entity: { operator: 'eq', value: entityType },
        trigger: { operator: 'eq', value: 100 }
      });

      setAvailableTriggers(fetchedAutomations.data);
    } catch (error) {
      console.error('Error fetching entity automations:', error);
    } finally {
      setIsLoadingTriggers(false);
    }
  };

  const fetchAttachments = async () => {
    try {
      if (!entity) return;

      const entityAttachments = await supabase
        .from('documents')
        .select('*')
        .contains('connections', { [entityType]: [entity.id] });

      setAttachments(prevAttachments => [
        ...prevAttachments,
        ...(entityAttachments.data || [])
      ]);
    } catch (error) {
      console.error('Error fetching attachments:', error);
    }
  };

  const fetchExtraAttachments = async () => {
    try {
      for (const attachment of extraAttachements) {
        const { data, error } = await supabase
          .from('documents')
          .select('*')
          .contains('connections', {
            [attachment.entityType]: [attachment.entityId]
          });

        if (error) throw error;

        setAttachments(prevAttachments => {
          const updatedAttachments = [...prevAttachments, ...data];
          console.log('Attachments after update:', updatedAttachments);
          return updatedAttachments;
        });
      }
    } catch (error) {
      console.error('Error fetching extra attachments:', error);
    }
  };

  const autoSelectAttachmentsByTypeOrName = () => {
    const autoSelected = attachments.filter(attachment =>
      autoSelectAttachements.some(
        criteria =>
          attachment.type == criteria || attachment.file_name.includes(criteria)
      )
    );

    const formattedAutoSelected = autoSelected.map(attachment => ({
      value: `https://storage.googleapis.com/pensiontracingservice/${attachment.file_path}/${attachment.id}/${attachment.file_name}`,
      label: attachment.file_name
    }));

    setSelectedAttachments(formattedAutoSelected);
  };

  const extractMergeTags = html => {
    const regex = /\{\{\s*(.*?)\s*\}\}/g;
    const matches = [...html.matchAll(regex)];
    const tags = matches.map(match => match[1].trim());
    return tags;
  };

  const extractLoopHtml = html => {
    const loops = [];
    let currentPosition = 0;

    while (currentPosition < html.length) {
      const openTagStart = html.indexOf('<loop id="', currentPosition);
      if (openTagStart === -1) break;

      const openTagEnd = html.indexOf('>', openTagStart);
      if (openTagEnd === -1) break;

      let depth = 1;
      let searchPosition = openTagEnd + 1;

      while (depth > 0 && searchPosition < html.length) {
        const nextOpen = html.indexOf('<loop id="', searchPosition);
        const nextClose = html.indexOf('</loop>', searchPosition);

        if (nextClose === -1) break;

        if (nextOpen !== -1 && nextOpen < nextClose) {
          depth++;
          searchPosition = nextOpen + 1;
        } else {
          depth--;
          if (depth === 0) {
            const loopContent = html.substring(openTagStart, nextClose + 7);
            loops.push(loopContent);
          }
          searchPosition = nextClose + 1;
        }
      }

      currentPosition = openTagEnd + 1;
    }

    return loops;
  };

  const extractLoopIds = html => {
    const loopIds = new Set();
    let currentPosition = 0;

    while (currentPosition < html.length) {
      const openTagStart = html.indexOf('<loop id="', currentPosition);
      if (openTagStart === -1) break;

      const openTagEnd = html.indexOf('>', openTagStart);
      if (openTagEnd === -1) break;

      const loopId = html
        .substring(openTagStart + 10, openTagEnd)
        .replace('"', '');
      loopIds.add(loopId);

      currentPosition = openTagEnd + 1;
    }

    return Array.from(loopIds);
  };

  const formatDateValue = value => {
    if (!value) return '';
    if (typeof value === 'string' && value.includes('-')) {
      const date = new Date(value);
      return date.toLocaleDateString('en-GB', {
        day: '2-digit',
        month: 'short',
        year: 'numeric'
      });
    }
    return value;
  };

  useEffect(() => {
    const loopsHtml = extractLoopHtml(selectedTemplate.html);
    const loops = extractLoopIds(selectedTemplate.html);
    setLoops(loops);

    const loopTags = new Set(
      loopsHtml.flatMap(content => extractMergeTags(content))
    );

    const tags = extractMergeTags(selectedTemplate.html);
    const subjectTags = extractMergeTags(selectedTemplate.subject);

    const filteredTags = tags.filter(tag => {
      return !loopTags.has(tag);
    });

    const reducedTags = [...filteredTags, ...subjectTags].reduce((acc, tag) => {
      let value = '';
      if (entity) {
        value = tag.split('.').reduce((obj, key) => obj?.[key], entity) || '';
        if (typeof entity.toDatabase === 'function') {
          value =
            value ||
            tag
              .split('.')
              .reduce((obj, key) => obj?.[key], entity.toDatabase()) ||
            '';
        }
        if (tag.toLowerCase().includes('date')) {
          value = formatDateValue(value);
        }
      }
      console.log(entity);
      console.log(`Tag: ${tag}, Value: ${value}`); // Added console log
      return { ...acc, [tag]: value };
    }, {});

    setMergeTags(reducedTags);

    setSubject(selectedTemplate.subject);

    if (entity && typeof entity.toDatabase === 'function') {
      setDatabaseEntity(entity.toDatabase());
    }
  }, [selectedTemplate, entity]);

  const handleMergeTagChange = (tag, value) => {
    setMergeTags(prev => ({ ...prev, [tag]: value }));
  };

  const handleQuoteOptionsChange = selections => {
    setSelectedQuoteOptions(selections);
  };

  const handleAttachmentChange = selected => {
    setSelectedAttachments(selected);
  };

  const renderPreview = () => {
    let preview = selectedTemplate.html;

    preview = handleLoops(preview, loops, selectedQuoteOptions);

    Object.entries(mergeTags).forEach(([tag, value]) => {
      preview = preview.replace(
        new RegExp(`\\{\\{\\s*${tag}\\s*\\}\\}`, 'g'),
        value
      );
    });

    return preview;
  };

  const renderSubject = () => {
    let renderedSubject = subject;

    Object.entries(mergeTags).forEach(([tag, value]) => {
      renderedSubject = renderedSubject.replace(
        new RegExp(`\\{\\{\\s*${tag}\\s*\\}\\}`, 'g'),
        value
      );
    });

    return renderedSubject;
  };

  const loadUserOptions = useCallback(async inputValue => {
    try {
      if (inputValue.length > 2) {
        const { data } = await UserProfile.getAll(
          { email: { value: inputValue, operator: FilterOperatorEnum.ILIKE } },
          1,
          10
        );
        console.log('User options loaded:', data);
        return data.map(user => ({
          value: user.email,
          label: `${user.firstName} ${user.lastName} (${user.email})`
        }));
      }
      return [];
    } catch (error) {
      console.error('Error loading user options:', error);
      return [];
    }
  }, []);

  const validateField = (field, value) => {
    let error = '';
    switch (field) {
      case 'to':
        if (value.length === 0) {
          error = 'Please specify at least one recipient';
        } else {
          for (const recipient of value) {
            if (!/\S+@\S+\.\S+/.test(recipient.value)) {
              error = 'Invalid email format for one or more recipients';
              break;
            }
          }
        }
        break;
      case 'from':
        if (!value.trim()) {
          error = 'From email is required';
        } else if (!/\S+@pensiontracingservice\.com$/.test(value)) {
          error = 'Email must end in @pensiontracingservice.com';
        }
        break;
      case 'subject':
        if (!value.trim()) {
          error = 'Subject is required';
        }
        break;
      default:
        break;
    }
    setErrors(prev => ({ ...prev, [field]: error }));
    return !error;
  };

  const handleToChange = newTo => {
    setTo(newTo);
    validateField('to', newTo);
  };

  const handleFromChange = e => {
    const newFrom = e.target.value;
    setFrom(newFrom);
    validateField('from', newFrom);
  };

  const handleSubjectChange = e => {
    const newSubject = e.target.value;
    setSubject(newSubject);
    validateField('subject', newSubject);
  };

  const handleNext = () => {
    setActiveTab('preview');
  };

  const handleBack = () => {
    if (activeTab === 'preview') {
      setActiveTab('content');
    } else {
      onBack();
    }
  };

  const sendEmail = async (auto = true) => {
    if (autoRunAutomationId && auto)
      return handleSendAndRun(autoRunAutomationId);

    setIsSending(true);
    const isToValid = validateField('to', to);
    const isFromValid = validateField('from', from);
    const isSubjectValid = validateField('subject', subject);

    if (!isToValid || !isFromValid || !isSubjectValid) {
      setNotificationConfig({
        type: 'error',
        title: 'Form Validation Error',
        description: 'Please check and complete all required fields.'
      });
      setShowNotification(true);
      setIsSending(false);
      return;
    }

    try {
      const loopData = {};
      if (loops.length > 0) {
        loops.forEach(loopId => {
          if (loopId === 'user_traces' && selectedQuoteOptions.length > 0) {
            loopData[loopId] = buildLoopPayload(loopId, selectedQuoteOptions);
          }
        });
      }
      const { error } = await supabase.functions.invoke(
        'integrations/add-ai/send-email',
        {
          body: {
            to: to.map(recipient => recipient.value).join(', '),
            from: {
              email: from
            },
            data: {
              ...Object.fromEntries(
                Object.entries(mergeTags).map(([key, value]) => [key, value])
              ),
              ...loopData
            },
            templateId: selectedTemplate.id,
            metadata: {
              owner: currentUser.id,
              entity_type: entityType,
              entity_id: entity.displayId ?? entity.id
            },
            attachments: selectedAttachments.map(doc => ({
              name: doc.label,
              url: doc.value
            }))
          }
        }
      );

      if (error) throw error;

      setNotificationConfig({
        type: 'success',
        title: 'Email Sent',
        description: 'Your email has been sent successfully.'
      });
      setShowNotification(true);

      if (onSubmit) {
        onSubmit(); // Call the onSubmit prop when email is added to the database
      }

      setTimeout(() => {
        onClose();
      }, 2000);
    } catch (error) {
      console.error('Error sending email:', error);
      setErrors(prev => ({
        ...prev,
        general: 'Failed to send email. Please try again.'
      }));
    } finally {
      setIsSending(false);
    }
  };

  const handleSendAndRun = async triggerId => {
    setIsSending(true);
    try {
      await sendEmail(false);

      const { error } = await supabase.from('automation_runs').insert({
        automation: triggerId,
        entity_id: entity.id,
        entity_type: entityType,
        status: 104
      });

      if (error) throw error;

      setNotificationConfig({
        type: 'success',
        title: 'Email Sent & Flow Started',
        description: 'Your email has been sent and the flow has been triggered.'
      });
      setShowNotification(true);

      setTimeout(() => {
        onClose();
      }, 2000);
    } catch (error) {
      console.error('Error in send and run:', error);
      setErrors(prev => ({
        ...prev,
        general: 'Failed to complete operation. Please try again.'
      }));
    } finally {
      setIsSending(false);
    }
  };

  const accordionItems = [
    {
      title: 'Email Details',
      content: (
        <>
          <div className='w-full mb-4'>
            <MultiSelectBox
              label='To'
              loadOptions={loadUserOptions} // Use useCallback to prevent re-creation
              selectedOptions={to}
              onChange={handleToChange}
              placeholder='Enter email or search for user'
              canCreate={true}
              onCreate={email => {
                if (!/\S+@\S+\.\S+/.test(email)) {
                  return null;
                }
                return {
                  value: email,
                  label: email
                };
              }}
              required
              hint={errors.to}
              colour='primary'
            />
          </div>
          <Input
            label='From'
            value={from}
            onChange={handleFromChange}
            className='mb-4'
            error={errors.from}
            required
          />
          <Input
            label='Subject'
            value={renderSubject()}
            onChange={handleSubjectChange}
            error={errors.subject}
            required
            inlineRightAddon={
              <select
                className='border-0 bg-transparent text-sm text-gray-900 focus:ring-0'
                defaultValue='normal'
              >
                <option value='high'>High Priority</option>
                <option value='normal'>Normal Priority</option>
                <option value='low'>Low Priority</option>
              </select>
            }
          />
        </>
      )
    },
    {
      title: 'Merge Tags',
      content: (
        <>
          {Object.entries(mergeTags).map(([tag, value]) => (
            <div key={tag} className='mb-4'>
              <Input
                label={tag}
                id={tag}
                value={value}
                onChange={e => handleMergeTagChange(tag, e.target.value)}
              />
            </div>
          ))}
        </>
      )
    },
    ...(loops.length > 0
      ? [
          {
            title: 'Tables',
            content: (
              <div className='p-0 text-sm'>
                {loops.includes('user_traces') && (
                  <Quote_QuoteOptions
                    quoteId={entity?.id}
                    onChange={handleQuoteOptionsChange}
                    initialSelections={selectedQuoteOptions}
                  />
                )}
              </div>
            )
          }
        ]
      : []),
    {
      title: 'Attachments',
      content: (
        <div className='p-0 text-sm'>
          <MultiSelectBox
            label='Select Attachments'
            options={attachments.map(doc => ({
              value: `https://storage.googleapis.com/pensiontracingservice/${doc.file_path}/${doc.id}/${doc.file_name}`,
              label: doc.file_name
            }))}
            selectedOptions={selectedAttachments}
            onChange={handleAttachmentChange}
            placeholder='Select attachments to send'
            isMulti
            key={attachments.length}
          />
        </div>
      )
    }
  ];

  return (
    <div className='flex flex-col'>
      {showNotification && (
        <NotificationItem
          type={notificationConfig.type}
          title={notificationConfig.title}
          description={notificationConfig.description}
          onClose={() => setShowNotification(false)}
          offset={{ top: 20, right: 20 }}
        />
      )}
      <Tabs
        value={activeTab}
        onValueChange={setActiveTab}
        className='justify-center'
      >
        <TabsList className='grid w-[400px] grid-cols-2 mx-4'>
          <TabsTrigger value='content'>Content</TabsTrigger>
          <TabsTrigger value='preview'>Preview Email</TabsTrigger>
        </TabsList>
        <TabsContent value='content'>
          <Accordion
            items={accordionItems}
            showTopBorder={false}
            singleOpen={true}
          />
        </TabsContent>
        <TabsContent value='preview'>
          <EmailPreview html={renderPreview()} />
        </TabsContent>
      </Tabs>
      {errors.general && (
        <p className='text-danger-600 mt-4'>{errors.general}</p>
      )}
      <div className='mt-8 flex justify-between'>
        <OutlineButton colour='base' onClick={handleBack} className='mr-4'>
          Back
        </OutlineButton>
        <div className='flex gap-2'>
          <FilledButton
            colour='primary'
            onClick={activeTab === 'content' ? handleNext : sendEmail}
            disabled={
              activeTab === 'preview' &&
              (isSending || to.length == 0 || !from || errors.from || !subject)
            }
            leftIcon={
              isSending ? <LoaderCircle className='animate-spin' /> : null
            }
            rightIcon={
              activeTab === 'content' ? (
                <ArrowRight size={16} />
              ) : (
                <Send size={16} />
              )
            }
          >
            {isSending
              ? 'Sending...'
              : activeTab === 'content'
              ? 'Next'
              : 'Send'}
          </FilledButton>
          {activeTab === 'preview' && !autoRunAutomationId && (
            <DropdownMenu>
              <DropdownMenuTrigger
                disabled={
                  isSending ||
                  to.length == 0 ||
                  !from ||
                  errors.from ||
                  !subject ||
                  isLoadingTriggers
                }
              >
                <FilledButton
                  colour='primary'
                  className='h-[34px]'
                  disabled={
                    isSending ||
                    to.length == 0 ||
                    !from ||
                    !subject ||
                    isLoadingTriggers
                  }
                  rightIcon={
                    isLoadingTriggers ? (
                      <LoaderCircle className='animate-spin' />
                    ) : (
                      <Zap size={16} />
                    )
                  }
                >
                  Send & Run
                </FilledButton>
              </DropdownMenuTrigger>
              <DropdownMenuContent align='end'>
                {availableTriggers.length === 0 ? (
                  <DropdownMenuItem disabled>
                    No automations available
                  </DropdownMenuItem>
                ) : (
                  availableTriggers.map(trigger => (
                    <DropdownMenuItem
                      key={trigger.id}
                      onClick={() => handleSendAndRun(trigger.id)}
                    >
                      {trigger.name}
                    </DropdownMenuItem>
                  ))
                )}
              </DropdownMenuContent>
            </DropdownMenu>
          )}
        </div>
      </div>
    </div>
  );
};

export default StageTwo;
