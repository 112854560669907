import React, { useState, useEffect, useCallback } from 'react';
import {
  FilledButton,
  TextButton,
  Input,
  DropdownInput,
  ComboBox
} from '@core/components';
import { Popup, Map } from '@core/components';
import { searchPlaces } from '@core/utilities';
import Address from '../../../models/Address';
import CoreEntityType from '../../../models/CoreEntityType';
import { EntityTypeEnum } from '../../../utilities/Enumerables';

const EditAddress = ({
  isOpen,
  onClose,
  location,
  onLocationUpdated,
  dates = false
}) => {
  const initialFormData = {
    line1: '',
    line2: '',
    line3: '',
    city: '',
    postcode: '',
    country: '',
    type: '122', // Default address type to id 122
    status: '80',
    ...(dates && {
      metadata: {
        start_date: '',
        end_date: ''
      }
    })
  };

  const [formData, setFormData] = useState(initialFormData);
  const [addressTypes, setAddressTypes] = useState([]);

  useEffect(() => {
    if (!location) return;

    const loadLocation = async () => {
      const data = {
        line1: location.line1,
        line2: location.line2,
        line3: location.line3,
        city: location.city,
        postcode: location.postcode,
        country: location.country,
        type: location.type || '122',
        status: location.status || '80'
      };

      if (dates) {
        data.metadata = {
          start_date: location.metadata?.start_date || '',
          end_date: location.metadata?.end_date || ''
        };
      }

      setFormData(data);
    };

    loadLocation();
  }, [location]);

  useEffect(() => {
    const fetchAddressTypes = async () => {
      try {
        const { data: types } = await CoreEntityType.getAll({
          entity_id: { value: EntityTypeEnum.AddressType }
        });
        setAddressTypes(
          types.map(type => ({ label: type.type, value: type.id }))
        );
      } catch (error) {
        console.error('Error fetching address types:', error);
      }
    };

    fetchAddressTypes();
  }, []);

  const loadPlaces = useCallback(async query => {
    if (!query) return [];
    try {
      const results = await searchPlaces(query);

      return results.map(place => ({
        key: place.place_id,
        value: place.place_id,
        label: place.name,
        secondaryLabel: place.formatted_address,
        details: {
          place_id: place.place_id,
          name: place.name,
          formatted_address: place.formatted_address,
          location: place.location,
          address_components: place.address_components,
          phone_number: place.phone_number
        }
      }));
    } catch (error) {
      console.error('Error loading places:', error);

      return [];
    }
  }, []);

  const handlePlaceSelect = place => {
    if (!place) return;

    const addressComponents = place.details.address_components;
    const streetNumber =
      addressComponents.find(c => c.types.includes('street_number'))
        ?.longText || '';
    const street =
      addressComponents.find(c => c.types.includes('route'))?.longText || '';
    const line2 =
      addressComponents.find(c => c.types.includes('sublocality_level_1'))
        ?.longText || '';
    const city =
      addressComponents.find(c => c.types.includes('postal_town'))?.longText ||
      '';
    const country =
      addressComponents.find(c => c.types.includes('country'))?.longText || '';
    const postcode =
      addressComponents.find(c => c.types.includes('postal_code'))?.longText ||
      '';

    setFormData(prev => ({
      ...prev,
      line1: place.details.name,
      line2: streetNumber ? `${streetNumber} ${street}` : street,
      line3: line2,
      city,
      postcode,
      country
    }));
  };

  const isValidAddress = () => {
    return (
      formData.line1?.trim() &&
      formData.city?.trim() &&
      formData.postcode?.trim() &&
      formData.country?.trim()
    );
  };

  const handleSubmit = async () => {
    if (!isValidAddress()) {
      return;
    }

    try {
      const updatedLocation = new Address({
        ...(await location.toDatabase()),
        ...formData
      });

      await updatedLocation.update();

      onLocationUpdated(updatedLocation);
      onClose();
    } catch (error) {
      console.error('Error updating location:', error);
    }
  };

  const handleClose = () => {
    setFormData(initialFormData);
    onClose();
  };

  return (
    <Popup
      isOpen={isOpen}
      onClose={handleClose}
      title='Edit Address'
      footer={
        <div className='flex justify-end space-x-2 mt-1'>
          <FilledButton type='button' colour='base' onClick={handleClose}>
            Cancel
          </FilledButton>
          <FilledButton onClick={handleSubmit} disabled={!isValidAddress()}>
            Update Address
          </FilledButton>
        </div>
      }
    >
      <div className='space-y-6 overflow-y-auto'>
        <div className='space-y-4'>
          <div>
            <ComboBox
              label='Search Places'
              loadOptions={loadPlaces}
              onChange={handlePlaceSelect}
              placeholder='Search for a location...'
              hint='Search for a place to automatically fill the address details'
            />
          </div>
        </div>

        <div className='h-96 w-full rounded-lg overflow-hidden shadow-sm border border-gray-200'>
          <Map
            locations={[
              {
                address: `${formData.line1}, ${formData.city}, ${formData.postcode}`
              }
            ]}
            zoom={15}
            width='100%'
            height='100%'
          />
        </div>

        <div className='space-y-6'>
          <div className='grid grid-cols-1 md:grid-cols-3 gap-4'>
            <div className='col-span-3'>
              <Input
                label='Address Line 1'
                value={formData.line1}
                onChange={e =>
                  setFormData(prev => ({ ...prev, line1: e.target.value }))
                }
                required
              />
            </div>
            <div className='col-span-3'>
              <Input
                label='Address Line 2'
                value={formData.line2}
                onChange={e =>
                  setFormData(prev => ({ ...prev, line2: e.target.value }))
                }
              />
            </div>
            <div className='col-span-3'>
              <Input
                label='Address Line 3'
                value={formData.line3}
                onChange={e =>
                  setFormData(prev => ({ ...prev, line3: e.target.value }))
                }
              />
            </div>
            <div className='col-span-3 md:col-span-1'>
              <Input
                label='City'
                value={formData.city}
                onChange={e =>
                  setFormData(prev => ({ ...prev, city: e.target.value }))
                }
                required
              />
            </div>
            <div className='col-span-3 md:col-span-1'>
              <Input
                label='Postcode'
                value={formData.postcode}
                onChange={e =>
                  setFormData(prev => ({ ...prev, postcode: e.target.value }))
                }
                required
              />
            </div>
            <div className='col-span-3 md:col-span-1'>
              <Input
                label='Country'
                value={formData.country}
                onChange={e =>
                  setFormData(prev => ({ ...prev, country: e.target.value }))
                }
                required
              />
            </div>
            {dates && (
              <>
                <div className='col-span-3 md:col-span-1'>
                  <DropdownInput
                    label='Address Type'
                    options={addressTypes}
                    value={formData.type}
                    onChange={e =>
                      setFormData(prev => ({
                        ...prev,
                        type: e.target.value
                      }))
                    }
                    disablePlaceholder
                  />
                </div>
                <div className='col-span-3 md:col-span-1'>
                  <Input
                    label='Moved In Date'
                    type='date'
                    value={formData.metadata.start_date}
                    onChange={e =>
                      setFormData(prev => ({
                        ...prev,
                        metadata: {
                          ...prev.metadata,
                          start_date: e.target.value
                        }
                      }))
                    }
                  />
                </div>
                {formData.type != '121' && (
                  <div className='col-span-3 md:col-span-1'>
                    <Input
                      label='Moved Out Date'
                      type='date'
                      value={formData.metadata.end_date}
                      onChange={e =>
                        setFormData(prev => ({
                          ...prev,
                          metadata: {
                            ...prev.metadata,
                            end_date: e.target.value
                          }
                        }))
                      }
                    />
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </Popup>
  );
};

export default EditAddress;
