import React, { useState, useEffect } from 'react';
import {
  FilledButton,
  Input,
  DropdownInput,
  Checkbox,
  ComboBox
} from '@core/components';
import { Popup } from '@core/components';
import Trace from '../../../models/Trace';
import CoreEntityType from '../../../models/CoreEntityType';
import { EntityTypeEnum } from '../../../utilities/Enumerables';
import { formatProfile } from '../../../utilities/Formatting';
import { supabase } from '../../../utilities/supabase';

const CreateTrace = ({ isOpen, onClose, onTraceCreated, user, trace }) => {
  const [formData, setFormData] = useState({
    name: '',
    owner: '',
    status: 69,
    type: 133,
    metadata: {
      paying: false,
      policy: null,
      address: null,
      end_year: '',
      provider: null,
      start_year: ''
    }
  });

  const [typeOptions, setTypeOptions] = useState([]);

  useEffect(() => {
    setFormData({
      name: trace?.name || '',
      owner: trace?.owner || user || '',
      status: trace?.status?.id || 69,
      type: trace?.type?.id || 133,
      metadata: {
        paying: trace?.metadata?.paying || false,
        policy: trace?.metadata?.policy || null,
        address: trace?.metadata?.address || null,
        end_year: trace?.metadata?.end_year || '',
        provider: trace?.metadata?.provider || null,
        start_year: trace?.metadata?.start_year || ''
      }
    });
  }, [trace, user]);

  useEffect(() => {
    const loadTypeOptions = async () => {
      try {
        const options = await CoreEntityType.getByEntityId(
          EntityTypeEnum.TraceType
        );
        const formattedOptions = options.map(option => ({
          label: option.type,
          value: option.id
        }));
        setTypeOptions(formattedOptions);
      } catch (error) {
        console.error('Error loading type options:', error);
      }
    };

    loadTypeOptions();
  }, []);

  const handleSubmit = async () => {
    try {
      const traceData = new Trace({
        ...formData,
        owner: { id: formData.owner.id || formData.owner.value },
        status: { id: formData.status },
        type: { id: formData.type }
      });
      let savedTrace;
      if (trace) {
        traceData.id = trace.id;
        savedTrace = await traceData.update();
      } else {
        savedTrace = await traceData.insert();
      }
      onTraceCreated(savedTrace);
      handleClose();
    } catch (error) {
      console.error('Error saving trace:', error);
    }
  };

  const handleClose = () => {
    setFormData({
      name: '',
      owner: user || '',
      status: 69,
      type: 133,
      metadata: {
        paying: false,
        policy: null,
        address: null,
        end_year: '',
        provider: null,
        start_year: ''
      }
    });
    onClose();
  };

  const loadUserOptions = async query => {
    if (!query) return;

    try {
      const { data } = await supabase
        .from('user_profiles')
        .select('id, full_name, email')
        .textSearch('fts', query.replaceAll(' ', '+').concat('', ':*'))
        .limit(10);

      return data.map(user => ({
        label: user.full_name,
        value: user.id,
        secondaryLabel: user.email
      }));
    } catch (error) {
      console.error('Error loading user options:', error);
      return [];
    }
  };

  const handleInputChange = (field, value) => {
    if (field.includes('.')) {
      const [parent, child] = field.split('.');
      setFormData(prev => ({
        ...prev,
        [parent]: {
          ...prev[parent],
          [child]: value
        }
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        [field]: value
      }));
    }
  };

  return (
    <Popup
      isOpen={isOpen}
      onClose={handleClose}
      title={trace ? 'Edit Trace' : 'New Trace'}
      footer={
        <div className='flex justify-end space-x-2 mt-1'>
          <FilledButton type='button' colour='base' onClick={handleClose}>
            Cancel
          </FilledButton>
          <FilledButton
            onClick={handleSubmit}
            disabled={!formData.name?.trim() || !(user || formData.owner)}
          >
            {trace ? 'Update Trace' : 'Create Trace'}
          </FilledButton>
        </div>
      }
    >
      <div className='space-y-6'>
        <div className='grid grid-cols-1 gap-4'>
          <Input
            label='Name'
            value={formData.name}
            onChange={e => handleInputChange('name', e.target.value)}
          />

          {user ? (
            <div>
              <label className='block text-sm font-medium text-black mb-1'>
                User
              </label>
              <div>{formatProfile(user)}</div>
            </div>
          ) : (
            <ComboBox
              label='User'
              value={formData.owner}
              loadOptions={loadUserOptions}
              onChange={selected => handleInputChange('owner', selected)}
              placeholder='Search Users...'
            />
          )}

          <DropdownInput
            label='Type'
            value={formData.type}
            options={typeOptions}
            onChange={e => handleInputChange('type', e.target.value)}
            disablePlaceholder
          />

          <Input
            label='Policy Number'
            value={formData.metadata.policy || ''}
            onChange={e => handleInputChange('metadata.policy', e.target.value)}
          />

          <div className='grid grid-cols-2 gap-4'>
            <Input
              label='Start Year'
              type='number'
              value={formData.metadata.start_year}
              onChange={e =>
                handleInputChange('metadata.start_year', e.target.value)
              }
            />

            <Input
              label='End Year'
              type='number'
              value={formData.metadata.end_year}
              onChange={e =>
                handleInputChange('metadata.end_year', e.target.value)
              }
            />
          </div>

          <Checkbox
            label='Paying Into The Trace'
            checked={formData.metadata.paying}
            onChange={e =>
              handleInputChange('metadata.paying', e.target.checked)
            }
          />
        </div>
      </div>
    </Popup>
  );
};

export default CreateTrace;
