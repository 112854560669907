import React, { useState } from 'react';
import { Calendar, Pencil } from 'lucide-react';
import { Tooltip, Modal, DatePicker } from '@core/components';

const DateCard = ({
  date,
  label,
  icon: Icon = Calendar,
  className = '',
  isEditable = false,
  onUpdate,
  editField
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(date);

  const isValidDate = selectedDate && !isNaN(new Date(selectedDate));

  const formattedDate = isValidDate
    ? new Date(selectedDate).toLocaleDateString('en-GB', {
        day: 'numeric',
        month: 'short',
        year: 'numeric'
      })
    : '--';

  const formattedTime = isValidDate
    ? new Date(selectedDate).toLocaleTimeString('en-GB', {
        hour: '2-digit',
        minute: '2-digit'
      })
    : null;

  const tooltipContent = isValidDate ? `${formattedTime}` : null;

  const handleCardClick = () => {
    if (isEditable) {
      setIsModalOpen(true);
    }
  };

  const handleDateChange = newDate => {
    setSelectedDate(newDate);
  };

  const handleSave = () => {
    onUpdate(editField, selectedDate);
    setIsModalOpen(false);
  };

  const CardContent = (
    <div
      className={`flex items-center px-1.5 py-2 bg-white border border-neutral-200 rounded-md shadow-sm ${className} ${
        isEditable ? 'cursor-pointer' : ''
      }`}
      onClick={handleCardClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className='mr-1'>
        {isEditable && isHovered ? (
          <Pencil
            size={16}
            strokeWidth={1.2}
            className='text-neutral-600 mr-1'
          />
        ) : (
          <Icon size={16} strokeWidth={1.2} className='text-neutral-900 mr-1' />
        )}
      </div>
      <div>
        <p className='text-sm font-regular text-neutral-900'>{formattedDate}</p>
        <p className='text-xs font-medium text-neutral-600'>{label}</p>
      </div>
    </div>
  );

  return (
    <>
      {isValidDate ? (
        <Tooltip content={tooltipContent}>{CardContent}</Tooltip>
      ) : (
        CardContent
      )}
      <Modal
        style='primary'
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title={`Edit ${label} Date`}
        actions={[
          {
            label: 'Save',
            onClick: handleSave,
            style: 'primary'
          },
          {
            label: 'Cancel',
            onClick: () => setIsModalOpen(false),
            style: 'base'
          }
        ]}
      >
        <DatePicker
          value={selectedDate}
          onChange={handleDateChange}
          placeholder='Select date and time'
        />
      </Modal>
    </>
  );
};

export default DateCard;
