import React, { useState, useEffect, useContext } from 'react';
import {
  SquareArrowOutUpRight,
  Trash,
  Eye,
  Building,
  SearchIcon,
  Copy
} from 'lucide-react';
import EntityTable from '../../../components/tables/EntityTable';
import {
  EntityTypeEnum,
  FilterOperatorEnum
} from '../../../utilities/Enumerables';
import { Modal, SoftButton, Alert } from '@core/components';
import Trace from '../../../models/Trace';
import AssistedTrace from '../../../components/popups/assistedtrace/AssistedTrace';
import PensionTrace from '../../../components/popups/assistedtrace/PensionTrace';
import CoreEntityType from '../../../models/CoreEntityType';
import { formatDate, formatProfile } from '../../../utilities/Formatting';
import { Link, useNavigate } from 'react-router-dom';
import CreateTrace from '../../../components/popups/traces/CreateTrace';
import { UserProfileContext } from '../../../App';
import { PERMISSIONS } from '../../../utilities/Permissions';
import { supabase } from '../../../utilities/supabase';

const Traces = () => {
  document.title = 'Traces | CRM | PTS';

  const navigate = useNavigate();
  const [isAddTraceOpen, setIsAddTraceOpen] = useState(false);
  const [selectedTrace, setSelectedTrace] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [traceToDelete, setTraceToDelete] = useState(null);
  const [isAssistedTraceOpen, setIsAssistedTraceOpen] = useState(false);
  const [isPensionTraceOpen, setIsPensionTraceOpen] = useState(false);
  const [statusOptions, setStatusOptions] = useState([]);
  const [isCompaniesModalOpen, setIsCompaniesModalOpen] = useState(false);
  const [isSearchesModalOpen, setIsSearchesModalOpen] = useState(false);
  const [companiesList, setCompaniesList] = useState([]);
  const [searchesList, setSearchesList] = useState([]);
  const { userProfile: currentUser } = useContext(UserProfileContext);
  const [tagsOptions, setTagsOptions] = useState([]);

  if (
    !currentUser.hasPermission(
      EntityTypeEnum.Trace,
      PERMISSIONS[EntityTypeEnum.Trace].VIEW
    )
  ) {
    return (
      <Alert
        title='Permission Denied'
        description='You do not have permission to view traces.'
        style='danger'
      />
    );
  }

  useEffect(() => {
    const fetchStatusOptions = async () => {
      try {
        const statuses = await CoreEntityType.getByEntityId(
          EntityTypeEnum.TraceStatus
        );
        const tags = await CoreEntityType.getByEntityId(
          EntityTypeEnum.UserTags
        );
        setStatusOptions(
          statuses.map(status => ({ value: status.type, key: status.id }))
        );
        setTagsOptions(
          tags.map(tag => ({
            value: tag.type,
            key: tag.id
          }))
        );
      } catch (error) {
        console.error('Error fetching status options:', error);
      }
    };

    fetchStatusOptions();
  }, []);

  const handleDeleteTrace = async () => {
    try {
      await supabase
        .from('traces')
        .update({ status: 150 })
        .eq('id', traceToDelete.id);

      setIsDeleteModalOpen(false);
      setTraceToDelete(null);
    } catch (error) {
      console.error('Error archiving trace:', error);
    }
  };

  const handleDuplicateTrace = async trace => {
    try {
      const { data, error } = await supabase.from('traces').insert({
        type: 133,
        status: 69,
        name: trace.name,
        owner: trace.owner.id,
        metadata: trace.metadata || {}
      });

      if (error) throw error;
      console.log('Trace duplicated:', data);
    } catch (error) {
      console.error('Error duplicating trace:', error);
    }
  };

  const menuItems = [
    {
      label: 'Open in new tab',
      icon: <SquareArrowOutUpRight size={14} />,
      onClick: data => window.open(`/traces/${data.displayId}`, '_blank')
    },
    {
      label: 'Delete',
      icon: <Trash size={14} className='text-danger-700' />,
      onClick: data => {
        setTraceToDelete(data);
        setIsDeleteModalOpen(true);
      }
    },
    {
      label: 'Duplicate',
      icon: <Copy size={14} />,
      onClick: data => handleDuplicateTrace(data)
    }
  ];

  return (
    <div className='p-4'>
      <EntityTable
        name='Traces'
        customId='traces'
        fromFunction={true}
        filter={[
          { field: 'display_id', label: 'ID', type: 'text' },
          {
            field: 'name',
            label: 'Name',
            type: 'text',
            operator: FilterOperatorEnum.ILIKE
          },
          {
            field: 'owner.full_name',
            label: 'Owner',
            type: 'text',
            operator: FilterOperatorEnum.ILIKE
          },
          {
            field: 'owner_tags',
            label: 'Owner Tags',
            type: 'multiselect',
            options: tagsOptions,
            operator: 'cs',
            isOr: true,
            isArray: true
          },
          {
            field: 'status',
            label: 'Status',
            type: 'select',
            options: statusOptions
          },
          {
            field: 'metadata->>number',
            label: 'Policy Number',
            type: 'text',
            operator: FilterOperatorEnum.ILIKE
          },
          {
            field: 'metadata->>start_year',
            label: 'Start Year',
            type: 'number'
          },
          {
            field: 'metadata->>end_year',
            label: 'End Year',
            type: 'number'
          },
          {
            field: 'created_date',
            label: 'Created Date',
            type: 'daterange',
            operator: FilterOperatorEnum.BETWEEN
          },
          {
            field: 'updated_date',
            label: 'Updated Date',
            type: 'daterange',
            operator: FilterOperatorEnum.BETWEEN
          },
          {
            field: 'showArchived',
            label: 'Show Archived',
            hint: 'Show archived traces',
            type: 'conditional_toggle',
            operator: FilterOperatorEnum.IS,
            options: {
              value: 'true'
            }
          }
        ]}
        columns={[
          {
            label: 'ID',
            field: 'displayId',
            sortField: { column: 'display_id' },
            cell: value => (
              <Link
                to={`/traces/${value}`}
                className='text-brand-700 hover:text-brand-800'
              >
                {value}
              </Link>
            )
          },
          { label: 'Name', field: 'name' },
          {
            label: 'Owner',
            field: 'owner',
            sortField: { table: 'owner', column: 'full_name' },
            cell: value => formatProfile(value, 'sm')
          },
          {
            label: 'Type',
            field: 'type',
            sortField: { table: 'type', column: 'type' },
            cell: value => value.type
          },
          {
            label: 'Status',
            field: 'status',
            sortField: { table: 'status', column: 'type' },
            cell: (value, row) => (
              <div className='flex items-center gap-2'>
                <span>{value.type}</span>
                {value.id === 69 && (
                  <SoftButton
                    onClick={e => {
                      e.stopPropagation();
                      setSelectedTrace(row);
                      if (row.type.id === 134) {
                        setIsPensionTraceOpen(true);
                      } else {
                        setIsAssistedTraceOpen(true);
                      }
                    }}
                  >
                    Assist
                  </SoftButton>
                )}
              </div>
            )
          },
          {
            label: 'Policy Number',
            field: 'metadata',
            sortable: false,
            cell: value => (value.policy ? value.policy : 'N/A')
          },
          {
            label: 'Start Year',
            field: 'metadata',
            sortable: false,
            cell: value => (value.start_year ? value.start_year : 'N/A')
          },
          {
            label: 'End Year',
            field: 'metadata',
            sortable: false,
            cell: value => (value.end_year ? value.end_year : 'N/A')
          },
          {
            label: 'Companies',
            field: 'companies',
            sortable: false,
            cell: companies => (
              <div className='flex items-center gap-2'>
                <span>{companies ? companies.length : 0}</span>
                {companies && companies.length > 0 && (
                  <SoftButton
                    onClick={e => {
                      e.stopPropagation();
                      setCompaniesList(companies);
                      setIsCompaniesModalOpen(true);
                    }}
                  >
                    <Building size={16} />
                  </SoftButton>
                )}
              </div>
            )
          },
          {
            label: 'Searches',
            field: 'searches',
            sortable: false,
            cell: searches => (
              <div className='flex items-center gap-2'>
                <span>{searches ? searches.length : 0}</span>
                {searches && searches.length > 0 && (
                  <SoftButton
                    onClick={e => {
                      e.stopPropagation();
                      setSearchesList(searches);
                      setIsSearchesModalOpen(true);
                    }}
                  >
                    <SearchIcon size={16} />
                  </SoftButton>
                )}
              </div>
            )
          },
          {
            label: 'Created Date',
            field: 'createdDate',
            cell: value => formatDate(value)
          },
          {
            label: 'Updated Date',
            field: 'updatedDate',
            cell: value => formatDate(value)
          }
        ]}
        menuItems={menuItems}
        model={Trace}
        entityType={EntityTypeEnum.Trace}
        defaultFilters={{
          status: { value: '150', operator: FilterOperatorEnum.NOT_EQUALS }
        }}
        sortDefault={{ field: 'display_id', order: 'asc' }}
        onRowClick={data => navigate(`/traces/${data.displayId}`)}
        canCreate={true}
        onNewClick={() => {
          setIsAddTraceOpen(true);
        }}
      />
      <CreateTrace
        isOpen={isAddTraceOpen}
        onClose={() => setIsAddTraceOpen(false)}
        onTraceCreated={trace => {
          setIsAddTraceOpen(false);

          navigate(`/traces/${trace.displayId}`);
        }}
      />
      <Modal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        title='Delete Trace'
        style='danger'
        actions={[
          {
            label: 'Cancel',
            onClick: () => setIsDeleteModalOpen(false),
            style: 'base'
          },
          {
            label: 'Delete',
            onClick: handleDeleteTrace,
            style: 'danger'
          }
        ]}
      >
        <p className='text-sm text-base-600'>
          Are you sure you want to delete this trace? This action cannot be
          undone.
        </p>
      </Modal>
      <AssistedTrace
        isOpen={isAssistedTraceOpen}
        trace={selectedTrace}
        user={currentUser}
        onClose={() => setIsAssistedTraceOpen(false)}
      />
      <PensionTrace
        isOpen={isPensionTraceOpen}
        trace={selectedTrace}
        onClose={() => setIsPensionTraceOpen(false)}
      />
      <Modal
        isOpen={isCompaniesModalOpen}
        onClose={() => setIsCompaniesModalOpen(false)}
        title={<h2 className='text-2xl'>Companies</h2>}
      >
        <div>
          {companiesList.map((company, index) => (
            <div key={index} className='flex items-center justify-between py-2'>
              <span className='truncate mr-4 flex-1 text-left'>
                {company.name}
              </span>
              <SoftButton
                onClick={() =>
                  window.open(`/companies/${company.id}`, '_blank')
                }
              >
                <Eye />
              </SoftButton>
            </div>
          ))}
        </div>
      </Modal>
      <Modal
        isOpen={isSearchesModalOpen}
        onClose={() => setIsSearchesModalOpen(false)}
        title={<h2 className='text-2xl'>Searches</h2>}
      >
        <div>
          {searchesList.map((search, index) => (
            <div key={index} className='flex items-center justify-between py-2'>
              <span className='truncate mr-4 flex-1 text-left'>
                {search.provider?.name || 'N/A'}
              </span>
              <SoftButton
                onClick={() =>
                  window.open(`/searches/${search.display_id}`, '_blank')
                }
              >
                <Eye />
              </SoftButton>
            </div>
          ))}
        </div>
      </Modal>
    </div>
  );
};

export default Traces;
