import React, { useState, useEffect } from 'react';
import {
  Popup,
  FilledButton,
  TextButton,
  Input,
  DatePicker,
  TimePicker,
  DropdownInput,
  Badge,
  TextEditor,
  MultiSelectBox
} from '@core/components';
import { MapPin, Video, ArrowRight, Type } from 'lucide-react';
import CoreEntityTypes from '../../../models/CoreEntityType';
import { EntityTypeEnum } from '../../../utilities/Enumerables';
import { supabase } from '../../../utilities/supabase';

const EditMeeting = ({ isOpen, onClose, meeting }) => {
  const [title, setTitle] = useState(meeting.title);
  const [description, setDescription] = useState(meeting.description);
  const [startDate, setStartDate] = useState(
    new Date(meeting.startDate ?? meeting.start)
  );
  const [startTime, setStartTime] = useState(() => {
    const start = new Date(meeting.startDate || meeting.start);
    return `${start.getHours().toString().padStart(2, '0')}:${start
      .getMinutes()
      .toString()
      .padStart(2, '0')}`;
  });
  const [endDate, setEndDate] = useState(
    new Date(meeting.endDate || meeting.end)
  );
  const [endTime, setEndTime] = useState(() => {
    const end = new Date(meeting.endDate || meeting.end);
    return `${end.getHours().toString().padStart(2, '0')}:${end
      .getMinutes()
      .toString()
      .padStart(2, '0')}`;
  });
  const [category, setCategory] = useState(meeting.category);
  const [attendees, setAttendees] = useState([]);
  const [location, setLocation] = useState(meeting.location);
  const [meetingLink, setMeetingLink] = useState(meeting.location);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    fetchCategories();
    fetchInitialAttendees();
  }, []);

  const fetchInitialAttendees = async () => {
    try {
      const { data: users, error } = await supabase
        .from('user_profiles')
        .select('*')
        .in('id', meeting.attendees || []);

      if (error) throw error;

      const formattedAttendees = users.map(user => ({
        value: user.id,
        label: `${user.first_name} ${user.last_name}`
      }));

      setAttendees(formattedAttendees);
    } catch (error) {
      console.error('Error fetching initial attendees:', error);
    }
  };

  const updateEndDateTime = (date, time) => {
    const newEndDate = new Date(date);
    const [hours, minutes] = time.split(':');
    newEndDate.setHours(parseInt(hours), parseInt(minutes));
    newEndDate.setHours(newEndDate.getHours() + 1);

    setEndDate(newEndDate);
    setEndTime(
      `${newEndDate.getHours().toString().padStart(2, '0')}:${newEndDate
        .getMinutes()
        .toString()
        .padStart(2, '0')}`
    );
  };

  const updateStartDateTime = (date, time) => {
    const newStartDate = new Date(date);
    const [hours, minutes] = time.split(':');
    newStartDate.setHours(parseInt(hours), parseInt(minutes));
    newStartDate.setHours(newStartDate.getHours() - 1);

    setStartDate(newStartDate);
    setStartTime(
      `${newStartDate.getHours().toString().padStart(2, '0')}:${newStartDate
        .getMinutes()
        .toString()
        .padStart(2, '0')}`
    );
  };

  const handleStartDateChange = newDate => {
    setStartDate(newDate);
    updateEndDateTime(newDate, startTime);
  };

  const handleStartTimeChange = newTime => {
    setStartTime(newTime);
    updateEndDateTime(startDate, newTime);
  };

  const handleEndDateChange = newDate => {
    setEndDate(newDate);
    updateStartDateTime(newDate, endTime);
  };

  const handleEndTimeChange = newTime => {
    setEndTime(newTime);
    updateStartDateTime(endDate, newTime);
  };

  const fetchCategories = async () => {
    try {
      const fetchedCategories = await CoreEntityTypes.getByEntityId(
        EntityTypeEnum.MeetingCategory
      );
      const mappedCategories = fetchedCategories.map(cat => ({
        id: cat.id,
        name: cat.type,
        color: cat.metadata.color || 'gray',
        isVirtual: cat.metadata.isVirtual || false
      }));
      setCategories(mappedCategories);

      // Set the category based on the meeting we're editing
      const matchingCategory = mappedCategories.find(
        cat => cat?.id == meeting?.category?.id
      );
      if (matchingCategory) {
        setCategory(matchingCategory);
      }
    } catch (error) {
      console.error('Error fetching meeting categories:', error);
    }
  };

  const handleSubmit = async e => {
    e.preventDefault();
    const combinedStartDate = new Date(startDate);
    const [startHours, startMinutes] = startTime.split(':');
    combinedStartDate.setHours(parseInt(startHours), parseInt(startMinutes));

    const combinedEndDate = new Date(endDate);
    const [endHours, endMinutes] = endTime.split(':');
    combinedEndDate.setHours(parseInt(endHours), parseInt(endMinutes));

    const { error } = await supabase
      .from('meetings')
      .update({
        title,
        description,
        start_date: combinedStartDate,
        end_date: combinedEndDate,
        category: category?.id,
        attendees: attendees.map(a => a.value),
        location: category?.isVirtual ? meetingLink : location
      })
      .eq('id', meeting.id);

    if (error) {
      console.error('Error updating meeting:', error);
      throw error;
    }

    onClose();
  };

  const fetchAdminUsers = async (search = '') => {
    try {
      const query = supabase.from('user_profiles').select('*').eq('role', 1);

      if (search) {
        query.textSearch('fts', search);
      }

      const { data: users, error } = await query;

      if (error) throw error;
      return users.map(user => ({
        value: user.id,
        label: `${user.first_name} ${user.last_name}`
      }));
    } catch (error) {
      console.error('Error fetching admin users:', error);
      return [];
    }
  };

  const isValid = title?.trim();

  return (
    <Popup isOpen={isOpen} onClose={onClose} title='Edit Meeting' size='md'>
      <form onSubmit={handleSubmit} className='space-y-6'>
        <div className='grid grid-cols-1 gap-6'>
          <Input
            value={title}
            onChange={e => setTitle(e.target.value)}
            placeholder='Enter meeting title'
            leftIcon={Type}
          />
          <TextEditor
            value={description}
            onChange={setDescription}
            placeholder='Enter meeting description'
          />
          <div className='flex items-center space-x-2'>
            <DatePicker
              value={startDate}
              onChange={handleStartDateChange}
              placeholder='Start date'
              size='md'
            />
            <TimePicker
              value={startTime}
              onChange={handleStartTimeChange}
              placeholder='Start time'
              size='md'
            />
            <ArrowRight className='text-gray-400' />
            <DatePicker
              value={endDate}
              onChange={handleEndDateChange}
              placeholder='End date'
              size='md'
            />
            <TimePicker
              value={endTime}
              onChange={handleEndTimeChange}
              placeholder='End time'
              size='md'
            />
          </div>
          <DropdownInput
            options={categories.map(category => ({
              value: category.id,
              label: (
                <div className='flex items-center'>
                  <Badge colour={category.color} className='mr-2'>
                    {category.name}
                  </Badge>
                </div>
              )
            }))}
            value={category?.id}
            onChange={e => {
              const selectedCategory = categories.find(
                c => c.id == e.target.value
              );

              setCategory(selectedCategory);
            }}
            placeholder='Select a category'
          />
          <MultiSelectBox
            selectedOptions={attendees}
            loadOptions={fetchAdminUsers}
            onChange={setAttendees}
            placeholder='Select Attendees'
          />
          {category?.isVirtual ? (
            <Input
              value={meetingLink}
              onChange={e => setMeetingLink(e.target.value)}
              placeholder='Enter virtual meeting link'
              leftIcon={Video}
            />
          ) : (
            <Input
              value={location}
              onChange={e => setLocation(e.target.value)}
              placeholder='Enter meeting location'
              leftIcon={MapPin}
            />
          )}
        </div>
        <div className='flex justify-end space-x-2 pt-4'>
          <TextButton onClick={onClose} colour='base'>
            Cancel
          </TextButton>
          <FilledButton type='submit' colour='primary' disabled={!isValid}>
            Save Changes
          </FilledButton>
        </div>
      </form>
    </Popup>
  );
};

export default EditMeeting;
