import { useState, useEffect, useContext } from 'react';
import { Routes, Route } from 'react-router-dom';
import Header from '../../components/navigation/Header';
import Sidebar from '../../components/navigation/Sidebar';
import {
  ListTodo,
  Users as UsersIcon,
  Building,
  Folders,
  SquareKanban,
  HousePlus,
  UserPlus,
  Zap,
  Briefcase,
  Search as SearchIcon,
  Binoculars,
  FileSearch,
  Building2,
  AlertTriangle
} from 'lucide-react';
import Home from './home/Home';
import Users from './users/Users';
import UserDetails from './users/User';
import Documents from './documents/Documents';
import Tasks from './tasks/Tasks';
import Pipelines from './pipelines/Pipelines';
import Automations from './automations/Automations';
import { supabase } from '../../utilities/supabase';
import { Badge, useNotification } from '@core/components';
import { UserProfileContext } from '../../App';
import Settings from './settings/Settings';
import Account from '../account/Account';
import Help from './help/Help';
import Docs from './help/docs/Docs';
import Doc from './help/docs/Doc';
import Category from './help/docs/Category';
import CreateDoc from './help/docs/CreateDoc';
import Resources from './help/resources/Resources';
import View from './help/resources/View';

import Companies from './companies/Companies';
import Company from './companies/Company';
import CreateCompany from './companies/CreateCompany';
import Pensions from './pensions/Pensions';
import PensionDetails from './pensions/Pension';
import Traces from './traces/Traces';
import TraceDetails from './traces/Trace';
import Searches from './searches/Searches';
import SearchDetails from './searches/Search';

import Providers from './providers/Providers';
import ProviderDetails from './providers/Provider';
import Schemes from './schemes/Schemes';
import SchemeDetails from './schemes/Scheme';
import CreateProvider from './providers/CreateProvider';
import CreateUser from './users/CreateUser';
import CreateScheme from './schemes/CreateScheme';
import { EntityTypeEnum } from '../../utilities/Enumerables';

const Dashboard = () => {
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [showDemoMessage, setShowDemoMessage] = useState(false);
  const { addNotification } = useNotification();
  const { userProfile } = useContext(UserProfileContext);

  //POC: Server notifications using realtime broadcast
  /**
   * {
    "broadcast": false,
    "duration": 5000,
    "message": "Hello Luke!, From the server!",
    "recipientId": "a228e330-90a1-4dd0-93a8-33bf9660eaca",
    "title": "New Message",
    "type": "info"
  }
   */
  useEffect(() => {
    const channel = supabase
      .channel('notifications')
      .on('broadcast', { event: 'notification' }, ({ payload }) => {
        if (
          payload.recipientId == userProfile?.id ||
          payload.broadcast === true
        ) {
          addNotification({
            title: payload.title,
            description: payload.message,
            type: payload.type || 'info',
            duration: payload.duration || 5000
          });
        }
      })
      .subscribe();

    return () => {
      channel.unsubscribe();
    };
  }, [userProfile, addNotification]);

  useEffect(() => {
    // Load dark mode preference from local storage
    const userPreferences =
      JSON.parse(localStorage.getItem('user_preferences')) || {};
    setIsDarkMode(userPreferences.darkMode || false);
  }, []);

  const sidebarItems = [
    {
      icon: SquareKanban,
      name: 'Pipelines',
      path: '/pipelines',
      entityType: EntityTypeEnum.Pipelines
    },
    {
      icon: ListTodo,
      name: 'Tasks',
      path: '/tasks',
      entityType: EntityTypeEnum.Task
    },
    {
      icon: UsersIcon,
      name: 'Users',
      path: '/user-profiles',
      entityType: EntityTypeEnum.User
    },
    {
      icon: Binoculars,
      name: 'Traces',
      path: '/traces',
      entityType: EntityTypeEnum.Trace
    },
    {
      icon: SearchIcon,
      name: 'Searches',
      path: '/searches',
      entityType: EntityTypeEnum.Search
    },
    {
      icon: Briefcase,
      name: 'Pensions',
      path: '/pensions',
      entityType: EntityTypeEnum.Pension
    },
    {
      icon: Building,
      name: 'Companies',
      path: '/companies',
      entityType: EntityTypeEnum.Company
    },
    {
      icon: Building2,
      name: 'Providers',
      path: '/providers',
      entityType: EntityTypeEnum.Provider
    },
    {
      icon: FileSearch,
      name: 'Schemes',
      path: '/schemes',
      entityType: EntityTypeEnum.Scheme
    },
    {
      icon: Folders,
      name: 'Documents',
      path: '/documents',
      entityType: EntityTypeEnum.Document
    },
    {
      icon: Zap,
      name: 'Automations',
      path: '/automations',
      entityType: EntityTypeEnum.Automations
    }
  ];

  return (
    <div
      className={`flex flex-col md:flex-row h-screen w-screen ${
        isDarkMode ? 'dark' : ''
      }`}
    >
      <div className='hidden lg:block'>
        <Sidebar items={sidebarItems} />
      </div>
      <div className='flex flex-col flex-1 bg-white dark:bg-neutral-900 w-full overflow-hidden'>
        <Header
          isDarkMode={isDarkMode}
          setIsDarkMode={setIsDarkMode}
          sidebarItems={sidebarItems}
        />
        <main className='flex-auto text-neutral-600 dark:text-neutral-300 overflow-auto'>
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/user-profiles' element={<Users />} />
            <Route path='/user-profiles/:id/*' element={<UserDetails />} />
            <Route path='/user-profiles/create' element={<CreateUser />} />
            <Route path='/companies' element={<Companies />} />
            <Route path='/companies/create' element={<CreateCompany />} />
            <Route path='/companies/:id/*' element={<Company />} />
            <Route path='/pensions' element={<Pensions />} />
            <Route path='/pensions/:id/*' element={<PensionDetails />} />
            <Route path='/traces' element={<Traces />} />
            <Route path='/traces/:id/*' element={<TraceDetails />} />
            <Route path='/searches' element={<Searches />} />
            <Route path='/searches/:id/*' element={<SearchDetails />} />
            <Route path='/documents' element={<Documents />} />
            <Route path='/tasks/*' element={<Tasks />} />
            <Route path='/pipelines/:id?' element={<Pipelines />} />
            <Route path='/settings/*' element={<Settings />} />
            <Route path='/account/*' element={<Account />} />
            <Route path='/automations' element={<Automations />} />
            <Route
              path='/automations/create'
              element={<Automations create={true} />}
            />
            <Route path='/help' element={<Help />} />
            <Route path='/help/docs' element={<Docs />} />
            <Route path='/help/docs/create' element={<CreateDoc />} />
            <Route path='/help/docs/:category' element={<Category />} />
            <Route path='/help/docs/:category/:slug' element={<Doc />} />
            <Route path='/help/resources' element={<Resources />} />
            <Route path='/help/resources/:id' element={<View />} />
            <Route path='/providers' element={<Providers />} />
            <Route path='/providers/:id/*' element={<ProviderDetails />} />
            <Route path='/providers/create' element={<CreateProvider />} />
            <Route path='/schemes' element={<Schemes />} />
            <Route path='/schemes/:id/*' element={<SchemeDetails />} />
            <Route path='/schemes/create' element={<CreateScheme />} />
          </Routes>
        </main>
      </div>
    </div>
  );
};

export default Dashboard;
