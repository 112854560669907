// Main handler function for processing loops
export const handleLoops = (preview, loops, selectedQuoteOptions) => {
  loops.forEach(loopId => {
    if (loopId === 'quote_quoteoptions') {
      preview = handleQuoteOptionsLoop(preview, selectedQuoteOptions);
    }
    // Add additional loop handlers here as needed
  });
  return preview;
};

// Handler for quote options loop
const handleQuoteOptionsLoop = (preview, selectedQuoteOptions) => {
  if (!selectedQuoteOptions) return preview;

  // Process nested loops recursively
  const processNestedContent = (content, option) => {
    let processedContent = content;
    let currentPosition = 0;
    let stack = [];

    while (currentPosition < content.length) {
      const openTagStart = content.indexOf('<loop id="', currentPosition);
      if (openTagStart === -1) break;

      const openTagEnd = content.indexOf('>', openTagStart);
      if (openTagEnd === -1) break;

      const loopId = content
        .substring(openTagStart + 10, openTagEnd)
        .replace('"', '');
      stack.push({ id: loopId, start: openTagStart });
      currentPosition = openTagEnd + 1;

      // Find matching closing tag
      let depth = 1;
      while (depth > 0 && currentPosition < content.length) {
        const nextOpen = content.indexOf('<loop id="', currentPosition);
        const nextClose = content.indexOf('</loop>', currentPosition);

        if (nextClose === -1) break;
        if (nextOpen !== -1 && nextOpen < nextClose) {
          depth++;
          currentPosition = nextOpen + 1;
        } else {
          depth--;
          if (depth === 0) {
            const loopInfo = stack.pop();
            const innerContent = content.substring(openTagEnd + 1, nextClose);

            // Handle different types of nested loops
            if (loopInfo.id === 'quote_quoteoptions_dates') {
              let datesContent = '';
              if (option.dates && Array.isArray(option.dates)) {
                option.dates.forEach(date => {
                  let dateItem = innerContent;
                  dateItem = dateItem.replace(
                    /\{\{start_date\}\}/g,
                    date.start_date
                  );
                  dateItem = dateItem.replace(
                    /\{\{end_date\}\}/g,
                    date.end_date
                  );
                  datesContent += dateItem;
                });
              }
              processedContent = processedContent.replace(
                content.substring(loopInfo.start, nextClose + 7),
                datesContent
              );
            }
          }
          currentPosition = nextClose + 7; // Length of </loop>
        }
      }
    }

    return processedContent;
  };

  // Handle the main quote_quoteoptions loop
  let result = preview;
  let currentPosition = 0;

  while (currentPosition < preview.length) {
    const openTagStart = preview.indexOf(
      '<loop id="quote_quoteoptions">',
      currentPosition
    );
    if (openTagStart === -1) break;

    const openTagEnd = openTagStart + 30; // Length of opening tag
    let depth = 1;
    currentPosition = openTagEnd;

    // Find matching closing tag
    while (depth > 0 && currentPosition < preview.length) {
      const nextOpen = preview.indexOf('<loop id="', currentPosition);
      const nextClose = preview.indexOf('</loop>', currentPosition);

      if (nextClose === -1) break;
      if (nextOpen !== -1 && nextOpen < nextClose) {
        depth++;
        currentPosition = nextOpen + 1;
      } else {
        depth--;
        if (depth === 0) {
          const innerContent = preview.substring(openTagEnd, nextClose);
          let replacementContent = '';

          Object.entries(selectedQuoteOptions).forEach(([optionId, option]) => {
            let itemContent = innerContent;

            // Process any nested loops first
            itemContent = processNestedContent(itemContent, option);

            // Replace standard merge tags
            const replacements = {
              proposal_id: option.proposal_id || '',
              slug: option.courses?.slug || '',
              course_title: option.courses?.name || '',
              delivery: option.delivery?.type || '',
              location:
                option.obfuscated_location ||
                option.locations?.full_address ||
                '',
              price: option.quote_value
                ? `£${Number(option.quote_value).toFixed(2)}`
                : '',
              full_day: option.delivery?.id === 62 ? 'Per Day' : 'Per Person',
              dates: option.dates.map(date => ({
                start_date: date.startDate,
                end_date: date.endDate
              }))
            };

            Object.entries(replacements).forEach(([tag, value]) => {
              itemContent = itemContent.replace(
                new RegExp(`\\{\\{${tag}\\}\\}`, 'g'),
                value || ''
              );
            });

            replacementContent += itemContent;
          });

          result = result.replace(
            preview.substring(openTagStart, nextClose + 7),
            replacementContent
          );
        }
        currentPosition = nextClose + 7; // Length of </loop>
      }
    }
  }

  return result;
};

// Handler for building the final payload to send in the email
export const buildLoopPayload = (loopId, data) => {
  switch (loopId) {
    case 'quote_quoteoptions':
      return data.map(option => ({
        proposal_id: option.quotes.access_token,
        slug: option.courses.slug,
        course_title: option.courses.name,
        delivery: option.delivery.type,
        location: option.obfuscated_location
          ? option.obfuscated_location
          : option.locations?.full_address,
        price: option.quote_value
          ? `£${Number(option.quote_value).toFixed(2)}`
          : '',
        full_day: option.course_delivery == 62 ? 'Per Day' : 'Per Person',
        quote_quoteoptions_dates: option.dates.map(date => ({
          start_date: date.startDate,
          end_date: date.endDate
        }))
      }));
    // Add more cases for other loop types here
    default:
      return data;
  }
};
