import React from 'react';
import { SquareArrowOutUpRight } from 'lucide-react';
import EntityTable from '../../../../components/tables/EntityTable';
import {
  EntityTypeEnum,
  FilterOperatorEnum
} from '../../../../utilities/Enumerables';
import { useNavigate } from 'react-router-dom';
import Search from '../../../../models/Search';
import { formatDate, formatProfile } from '../../../../utilities/Formatting';
import Trace from '../../../../models/Trace';

const CompanyUsers = ({ companyId }) => {
  const navigate = useNavigate();

  const menuItems = [
    {
      label: 'Open in new tab',
      icon: <SquareArrowOutUpRight size={14} />,
      onClick: data =>
        window.open(`/user-profiles/${data.owner.displayId}`, '_blank')
    }
  ];

  const handleRowClick = search => {
    navigate(`/user-profiles/${search.owner.displayId}`);
  };

  return (
    <EntityTable
      name='Users'
      customId={`company:${companyId}:users`}
      filter={[
        {
          field: 'owner.display_id',
          label: 'ID',
          type: 'text',
          operator: 'eq'
        },
        {
          field: 'owner.first_name',
          label: 'First Name',
          type: 'text',
          operator: FilterOperatorEnum.ILIKE
        },
        {
          field: 'owner.last_name',
          label: 'Last Name',
          type: 'text',
          operator: FilterOperatorEnum.ILIKE
        },
        {
          field: 'owner.email',
          label: 'Email',
          type: 'text',
          operator: FilterOperatorEnum.ILIKE
        },
        {
          field: 'created_date',
          label: 'Created Date',
          type: 'daterange',
          operator: FilterOperatorEnum.BETWEEN
        },
        {
          field: 'updated_date',
          label: 'Updated Date',
          type: 'daterange',
          operator: FilterOperatorEnum.BETWEEN
        },
        {
          field: 'showArchived',
          label: 'Show Archived',
          hint: 'Show archived users',
          type: 'conditional_toggle',
          operator: FilterOperatorEnum.IS,
          options: {
            value: 'true'
          }
        }
      ]}
      columns={[
        {
          label: 'ID',
          field: 'owner.displayId'
        },
        { label: 'First Name', field: 'owner.firstName' },
        { label: 'Last Name', field: 'owner.lastName' },
        {
          label: 'Email',
          field: 'owner.email'
        },
        {
          label: 'Created Date',
          field: 'owner',
          cell: value => {
            return formatDate(value?.createdDate);
          }
        },
        {
          label: 'Updated Date',
          field: 'owner',
          cell: value => formatDate(value?.updatedDate)
        }
      ]}
      menuItems={menuItems}
      model={Trace}
      entityType={EntityTypeEnum.Trace}
      defaultFilters={{
        companies: {
          value: [companyId],
          operator: FilterOperatorEnum.CONTAINS
        },
        'owner.status': { value: '97', operator: FilterOperatorEnum.NOT_EQUALS }
      }}
      onRowClick={handleRowClick}
    />
  );
};

export default CompanyUsers;
