import React, { useState, useEffect, useCallback } from 'react';
import { Check, ChevronsUpDown, X } from 'lucide-react';
import { Label, Field } from '@headlessui/react';
import { debounce } from 'lodash';

const MultiSelectBox = ({
  loadOptions,
  options,
  label,
  placeholder,
  onChange,
  selectedOptions,
  className = '',
  colour = 'primary',
  hint,
  canCreate = false,
  onCreate,
  disabled,
  required
}) => {
  const [availableOptions, setAvailableOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [query, setQuery] = useState('');
  const [selectedItems, setSelectedItems] = useState(selectedOptions || []);
  const [isOpen, setIsOpen] = useState(false);

  const pageSize = 10;

  const handleLoadOptions = useCallback(
    async (searchQuery, pageNumber) => {
      if (loadOptions) {
        setLoading(true);
        try {
          const results = (await loadOptions(searchQuery, pageNumber)) || [];
          setAvailableOptions(prevOptions =>
            pageNumber === 1 ? results : [...prevOptions, ...results]
          );
          setHasMore(results.length === pageSize);
          setPage(pageNumber);
        } catch (error) {
          console.error('Error loading options:', error);
          setHasMore(false);
        } finally {
          setLoading(false);
        }
      }
    },
    [loadOptions]
  );

  const handleFilterOptions = useCallback(
    searchQuery => {
      if (options) {
        const filtered = options.filter(opt => {
          const optValue = opt.value.toLowerCase();
          const queryValue = searchQuery.toLowerCase();
          return optValue
            .split('')
            .some((_, i) => optValue.slice(i).startsWith(queryValue));
        });
        setAvailableOptions(filtered);
      }
    },
    [options]
  );

  const debouncedLoadOptions = useCallback(
    debounce(searchQuery => {
      if (loadOptions) {
        handleLoadOptions(searchQuery, 1);
      } else if (options) {
        handleFilterOptions(searchQuery);
      }
    }, 300),
    [handleLoadOptions, handleFilterOptions, loadOptions, options]
  );

  // Load initial options when the dropdown is opened
  useEffect(() => {
    if (isOpen && availableOptions.length === 0) {
      if (loadOptions) {
        handleLoadOptions('', 1);
      } else if (options) {
        setAvailableOptions(options);
      }
    }
  }, [isOpen, handleLoadOptions, options, availableOptions.length]);

  useEffect(() => {
    if (isOpen && query) {
      setAvailableOptions([]);
      debouncedLoadOptions(query);
    }
  }, [query, debouncedLoadOptions, isOpen]);

  useEffect(() => {
    setSelectedItems(selectedOptions || []);
  }, [selectedOptions]);

  const handleLoadMore = () => {
    if (loadOptions && hasMore) {
      handleLoadOptions(query, page + 1);
    }
  };

  const filteredOptions = canCreate
    ? [
        ...availableOptions.filter(
          option => !selectedItems.some(item => item.value === option.value)
        ),
        ...(query.length > 0
          ? [{ label: `Add "${query}"`, value: 'create', type: 'create' }]
          : [])
      ]
    : availableOptions.filter(
        option => !selectedItems.some(item => item.value === option.value)
      );

  const colourClasses = {
    brand: 'ring-brand-300 focus:ring-brand-600',
    primary: 'ring-primary-300 focus:ring-primary-600',
    info: 'ring-info-300 focus:ring-info-600',
    success: 'ring-success-300 focus:ring-success-600',
    warning: 'ring-warning-300 focus:ring-warning-600',
    danger: 'ring-danger-300 focus:ring-danger-600',
    base: 'ring-base-300 focus:ring-base-600'
  };

  const focusClasses = {
    brand: 'data-[focus]:bg-brand-100 data-[focus]:text-brand-700',
    primary: 'data-[focus]:bg-primary-100 data-[focus]:text-primary-700',
    info: 'data-[focus]:bg-info-100 data-[focus]:text-info-700',
    success: 'data-[focus]:bg-success-100 data-[focus]:text-success-700',
    warning: 'data-[focus]:bg-warning-100 data-[focus]:text-warning-700',
    danger: 'data-[focus]:bg-danger-100 data-[focus]:text-danger-700',
    base: 'data-[focus]:bg-base-100 data-[focus]:text-base-700'
  };

  const handleSelect = selected => {
    if (selected) {
      if (selected.type === 'create' && onCreate) {
        // Call the creation handler with the current query
        const newItem = onCreate(query);
        if (newItem) {
          const updatedItems = [...selectedItems, newItem];
          setSelectedItems(updatedItems);
          onChange(updatedItems);
        }
      } else {
        const updatedItems = [...selectedItems, selected];
        setSelectedItems(updatedItems);
        onChange(updatedItems);
      }
    }
    setQuery('');
  };

  const handleRemove = itemToRemove => {
    const updatedItems = selectedItems.filter(
      item => item.value !== itemToRemove.value
    );
    setSelectedItems(updatedItems);
    onChange(updatedItems);
  };

  return (
    <div className={`w-full relative ${className}`}>
      <Field>
        {label && (
          <Label className='block text-sm font-medium leading-6 text-primary-900'>
            {label}
            {required && <span className='text-red-500 ml-1'>*</span>}
          </Label>
        )}
        <div className='relative'>
          <div
            className={`w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-primary-900 shadow-sm ring-1 ring-inset 
              ${
                disabled
                  ? 'bg-base-100 text-base-500 cursor-not-allowed'
                  : 'bg-white'
              }
              ${
                required && selectedItems.length === 0
                  ? 'ring-danger-300 focus-within:ring-danger-600'
                  : colourClasses[colour]
              }
              focus-within:ring-2 focus-within:ring-inset sm:text-sm sm:leading-6`}
          >
            <div className='flex flex-wrap gap-1'>
              {selectedItems.map(item => (
                <span
                  key={item.value}
                  className={`inline-flex items-center px-1.5 py-0.5 rounded-md text-sm font-medium bg-${colour}-100 text-${colour}-800`}
                >
                  {item.label}
                  <button
                    type='button'
                    onClick={() => handleRemove(item)}
                    className={`ml-1 inline-flex items-center rounded-sm hover:bg-${colour}-200 focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-${colour}-600`}
                    disabled={disabled}
                  >
                    <X size={14} />
                  </button>
                </span>
              ))}
              <input
                className='flex-grow border-none px-1 py-0.5 text-primary-900 focus:outline-none text-sm'
                placeholder={placeholder}
                value={query}
                onChange={e => {
                  const newQuery = e.target.value;
                  setQuery(newQuery);
                  debouncedLoadOptions(newQuery);
                }}
                onFocus={() => !disabled && setIsOpen(true)}
                onBlur={() => {
                  setTimeout(() => setIsOpen(false), 200);
                }}
                style={{ minWidth: '100px' }}
                disabled={disabled}
              />
            </div>
            <div className='absolute inset-y-0 right-0 flex items-center rounded-r-md px-2'>
              <ChevronsUpDown
                className='h-5 w-5 text-primary-400'
                aria-hidden='true'
              />
            </div>
          </div>

          {isOpen && !disabled && filteredOptions.length > 0 && (
            <div className='absolute z-50 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-primary-900 ring-opacity-5 focus:outline-none sm:text-sm'>
              {filteredOptions.map(option => (
                <div
                  key={option.value}
                  className={`relative cursor-pointer select-none py-2 pl-3 pr-9 text-primary-900 ${focusClasses[colour]}`}
                  onClick={() => handleSelect(option)}
                >
                  <div className='flex flex-col md:flex-row md:items-center md:justify-start'>
                    {option.icon && (
                      <span
                        className={`mr-2 bg-${option.icon.colour} bg-opacity-50 rounded-md p-1`}
                      >
                        {option.icon.render()}
                      </span>
                    )}
                    <span className='block truncate'>{option.label}</span>
                    {option.secondaryLabel && (
                      <span
                        className={`mt-1 truncate md:mt-0 md:ml-2 text-primary-500 group-${focusClasses[colour]} text-sm`}
                      >
                        {option.secondaryLabel}
                      </span>
                    )}
                  </div>
                </div>
              ))}
              {loadOptions && hasMore && (
                <div
                  className='py-2 px-3 text-sm text-primary-700 cursor-pointer hover:bg-primary-100'
                  onClick={handleLoadMore}
                >
                  {loading ? 'Loading...' : 'Load more'}
                </div>
              )}
            </div>
          )}
        </div>
        {hint && <p className='mt-2 text-sm text-primary-500'>{hint}</p>}
      </Field>
    </div>
  );
};

export { MultiSelectBox };
