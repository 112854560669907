import React, { useState, useEffect } from 'react';
import {
  FilledButton,
  SoftButton,
  Popup,
  Input,
  TextButton
} from '@core/components';
import {
  Zap,
  LogOut,
  Home,
  User,
  Settings,
  Bell,
  Heart,
  Star,
  ArrowRight,
  ChevronRight,
  Cloud,
  Sun,
  Moon,
  Coffee,
  Book,
  Camera,
  Car,
  Clock,
  Code,
  Compass,
  DollarSign,
  Feather,
  Film,
  Gift
} from 'lucide-react';
import Automation from '../../../models/Automations';
import { supabase } from '../../../utilities/supabase';
import Create from './Create';
import { useNavigate } from 'react-router-dom';

const SelectAutomation = ({ isOpen, onClose, entityId, entityType }) => {
  const navigate = useNavigate();

  const [automations, setAutomations] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isCreating, setIsCreating] = useState(false);
  const [editAuto, setEditAuto] = useState(null);

  useEffect(() => {
    fetchEntityAutomations();
  }, []);

  const fetchEntityAutomations = async () => {
    try {
      const fetchedAutomations = await Automation.getAll({
        entity: { operator: 'eq', value: entityType },
        trigger: { operator: 'eq', value: 100 }
      });

      setAutomations(fetchedAutomations.data);
    } catch (error) {
      console.error('Error fetching entity automations:', error);
    }
  };

  const handleEditAutomation = automation => {
    setEditAuto(automation);
    setIsCreating(true);
  };

  const handleRunAutomation = async automation => {
    try {
      const { error: runError } = await supabase
        .from('automation_runs')
        .insert({
          automation: automation.id,
          entity_id: entityId,
          entity_type: entityType,
          status: 104
        });

      if (runError) throw runError;

      onClose();
    } catch (error) {
      console.error('Error running automation:', error);
    }
  };

  const filteredAutomations = automations.filter(automation =>
    automation.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const textColours = {
    red: 'text-red-800',
    green: 'text-green-800',
    blue: 'text-blue-800',
    yellow: 'text-yellow-800',
    purple: 'text-purple-800',
    pink: 'text-pink-800',
    indigo: 'text-indigo-800',
    teal: 'text-teal-800',
    orange: 'text-orange-800',
    cyan: 'text-cyan-800',
    lime: 'text-lime-800',
    amber: 'text-amber-800',
    violet: 'text-violet-800',
    fuchsia: 'text-fuchsia-800',
    rose: 'text-rose-800',
    emerald: 'text-emerald-800',
    slate: 'text-slate-800',
    stone: 'text-stone-800',
    zinc: 'text-zinc-800',
    neutral: 'text-neutral-800'
  };

  const bgColours = {
    red: 'bg-red-400',
    green: 'bg-green-400',
    blue: 'bg-blue-400',
    yellow: 'bg-yellow-400',
    purple: 'bg-purple-400',
    pink: 'bg-pink-400',
    indigo: 'bg-indigo-400',
    teal: 'bg-teal-400',
    orange: 'bg-orange-400',
    cyan: 'bg-cyan-400',
    lime: 'bg-lime-400',
    amber: 'bg-amber-400',
    violet: 'bg-violet-400',
    fuchsia: 'bg-fuchsia-400',
    rose: 'bg-rose-400',
    emerald: 'bg-emerald-400',
    slate: 'bg-slate-400',
    stone: 'bg-stone-400',
    zinc: 'bg-zinc-400',
    neutral: 'bg-neutral-400'
  };

  const renderIcon = (iconName, iconColour, useColour = true) => {
    const textColourClass = useColour
      ? textColours[iconColour] || 'text-gray-800'
      : '';
    const strokeWidth = useColour ? 2.5 : 2;
    switch (iconName) {
      case 'Zap':
        return (
          <Zap
            strokeWidth={strokeWidth}
            className={`w-6 h-6 ${textColourClass}`}
          />
        );
      case 'LogOut':
        return (
          <LogOut
            strokeWidth={strokeWidth}
            className={`w-6 h-6 ${textColourClass}`}
          />
        );
      case 'Home':
        return (
          <Home
            strokeWidth={strokeWidth}
            className={`w-6 h-6 ${textColourClass}`}
          />
        );
      case 'User':
        return (
          <User
            strokeWidth={strokeWidth}
            className={`w-6 h-6 ${textColourClass}`}
          />
        );
      case 'Settings':
        return (
          <Settings
            strokeWidth={strokeWidth}
            className={`w-6 h-6 ${textColourClass}`}
          />
        );
      case 'Bell':
        return (
          <Bell
            strokeWidth={strokeWidth}
            className={`w-6 h-6 ${textColourClass}`}
          />
        );
      case 'Heart':
        return (
          <Heart
            strokeWidth={strokeWidth}
            className={`w-6 h-6 ${textColourClass}`}
          />
        );
      case 'Star':
        return (
          <Star
            strokeWidth={strokeWidth}
            className={`w-6 h-6 ${textColourClass}`}
          />
        );
      case 'ArrowRight':
        return (
          <ArrowRight
            strokeWidth={strokeWidth}
            className={`w-6 h-6 ${textColourClass}`}
          />
        );
      case 'ChevronRight':
        return (
          <ChevronRight
            strokeWidth={strokeWidth}
            className={`w-6 h-6 ${textColourClass}`}
          />
        );
      case 'Cloud':
        return (
          <Cloud
            strokeWidth={strokeWidth}
            className={`w-6 h-6 ${textColourClass}`}
          />
        );
      case 'Sun':
        return (
          <Sun
            strokeWidth={strokeWidth}
            className={`w-6 h-6 ${textColourClass}`}
          />
        );
      case 'Moon':
        return (
          <Moon
            strokeWidth={strokeWidth}
            className={`w-6 h-6 ${textColourClass}`}
          />
        );
      case 'Coffee':
        return (
          <Coffee
            strokeWidth={strokeWidth}
            className={`w-6 h-6 ${textColourClass}`}
          />
        );
      case 'Book':
        return (
          <Book
            strokeWidth={strokeWidth}
            className={`w-6 h-6 ${textColourClass}`}
          />
        );
      case 'Camera':
        return (
          <Camera
            strokeWidth={strokeWidth}
            className={`w-6 h-6 ${textColourClass}`}
          />
        );
      case 'Car':
        return (
          <Car
            strokeWidth={strokeWidth}
            className={`w-6 h-6 ${textColourClass}`}
          />
        );
      case 'Clock':
        return (
          <Clock
            strokeWidth={strokeWidth}
            className={`w-6 h-6 ${textColourClass}`}
          />
        );
      case 'Code':
        return (
          <Code
            strokeWidth={strokeWidth}
            className={`w-6 h-6 ${textColourClass}`}
          />
        );
      case 'Compass':
        return (
          <Compass
            strokeWidth={strokeWidth}
            className={`w-6 h-6 ${textColourClass}`}
          />
        );
      case 'DollarSign':
        return (
          <DollarSign
            strokeWidth={strokeWidth}
            className={`w-6 h-6 ${textColourClass}`}
          />
        );
      case 'Feather':
        return (
          <Feather
            strokeWidth={strokeWidth}
            className={`w-6 h-6 ${textColourClass}`}
          />
        );
      case 'Film':
        return (
          <Film
            strokeWidth={strokeWidth}
            className={`w-6 h-6 ${textColourClass}`}
          />
        );
      case 'Gift':
        return (
          <Gift
            strokeWidth={strokeWidth}
            className={`w-6 h-6 ${textColourClass}`}
          />
        );
      default:
        return (
          <Zap
            strokeWidth={strokeWidth}
            className={`w-6 h-6 ${textColourClass}`}
          />
        );
    }
  };

  return (
    <Popup
      isOpen={isOpen}
      onClose={onClose}
      title={isCreating ? 'Create Automation' : 'Select Automation'}
      size='lg'
    >
      {!isCreating ? (
        <>
          <div className='mb-4'>
            <Input
              type='text'
              placeholder='Search automations...'
              value={searchTerm}
              onChange={e => setSearchTerm(e.target.value)}
            />
          </div>
          <div className='grid grid-cols-2 gap-4 mh-[56vh] overflow-y-auto'>
            {filteredAutomations.map(automation => (
              <div
                key={automation.id}
                className='p-4 border rounded-lg transition-colors duration-300 bg-white border-gray-200 hover:bg-gray-50'
              >
                <div className='flex items-center justify-between'>
                  <div className='flex items-center'>
                    <div
                      className={`w-12 h-12 flex items-center justify-center rounded-lg flex-shrink-0 shrink-0 ${
                        bgColours[automation.metadata?.colour]
                      } ${textColours[automation.metadata?.colour]} mr-3`}
                    >
                      {renderIcon(
                        automation.metadata?.icon,
                        automation.metadata?.colour
                      )}
                    </div>
                    <div className='flex flex-col'>
                      <span className='font-medium text-lg'>
                        {automation.name}
                      </span>
                      <span className='text-sm text-gray-500'>
                        {automation.description}
                      </span>
                    </div>
                  </div>
                  <SoftButton
                    onClick={() => handleRunAutomation(automation)}
                    className='ml-2'
                  >
                    Run
                  </SoftButton>
                </div>
              </div>
            ))}
          </div>
          <div className='flex justify-end mt-4'>
            <FilledButton onClick={() => navigate('/automations/create')}>
              Create New
            </FilledButton>
          </div>
        </>
      ) : (
        <Create
          onClose={onClose}
          entityId={entityId}
          entityType={entityType}
          edit={editAuto}
          setEditAuto={setEditAuto}
          setIsCreating={setIsCreating}
          automations={automations}
          setAutomations={setAutomations}
        />
      )}
    </Popup>
  );
};

export default SelectAutomation;
