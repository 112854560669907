import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeft, ArrowRight } from 'lucide-react';
import Provider from '../../../models/Provider';
import UserProfile from '../../../models/UserProfile'; // Import UserProfile model
import CoreEntityType from '../../../models/CoreEntityType';
import { EntityTypeEnum } from '../../../utilities/Enumerables';
import { FilledButton, SoftButton, Input, ComboBox } from '@core/components';
import { UserProfileContext } from '../../../App';
import BasicDetails from './create/BasicDetails';
import AdditionalDetails from './create/AdditionalDetails';

const CreateProvider = () => {
  const navigate = useNavigate();
  const { userProfile } = useContext(UserProfileContext);

  const [stage, setStage] = useState(1);
  const [providerData, setProviderData] = useState({
    name: '',
    status: 84,
    emails: [],
    phones: [],
    addresses: [],
    signatures: {
      wet: true,
      digital: true,
      docusign: false
    }
  });

  const handleInputChange = (field, value) => {
    setProviderData(prev => {
      // Handle nested fields like signatures.wet
      if (field.includes('.')) {
        const [parent, child] = field.split('.');
        return {
          ...prev,
          [parent]: {
            ...prev[parent],
            [child]: value
          }
        };
      }
      return { ...prev, [field]: value };
    });
  };

  const handleSubmit = async (navigateToProvider = false) => {
    try {
      const newProvider = new Provider({
        name: providerData.name,
        status: providerData.status || 84,
        emails: providerData.emails,
        phones: providerData.phones,
        addresses: providerData.addresses,
        signatures: providerData.signatures
      });

      const insertedProvider = await newProvider.insert();

      if (navigateToProvider) {
        navigate(`/providers/${insertedProvider.id}`);
      } else {
        navigate('/providers');
      }
    } catch (error) {
      console.error('Error creating provider or user profile:', error);
    }
  };

  const handleNext = () => {
    setStage(prev => prev + 1);
  };

  const handleBack = () => {
    if (stage === 1) {
      navigate('/providers');
    } else {
      setStage(prev => prev - 1);
    }
  };

  const handleExitToProviders = () => {
    navigate('/providers');
  };

  const stages = [
    {
      title: 'Basic Details',
      render: () => (
        <BasicDetails
          providerData={providerData}
          handleInputChange={handleInputChange}
        />
      )
    },
    {
      title: 'Additional Details',
      render: () => (
        <AdditionalDetails
          providerData={providerData}
          handleInputChange={handleInputChange}
        />
      )
    }
  ];

  return (
    <div className='bg-base-100 h-full overflow-y-auto'>
      <div className='max-w-4xl mx-auto px-4 py-8'>
        <SoftButton
          colour='primary'
          size='md'
          leftIcon={<ArrowLeft size={18} />}
          onClick={handleExitToProviders}
          className='mb-8'
        >
          Back to Providers
        </SoftButton>

        <div className='bg-white shadow-md rounded-lg overflow-visible'>
          <div className='px-6 py-4'>
            <h2 className='text-2xl font-bold'>Create New Provider</h2>
          </div>

          <div className='p-6'>
            <div className='mb-8'>
              <div className='flex justify-between'>
                {stages.map((step, index) => (
                  <div
                    key={index}
                    className={`flex items-center ${
                      index < stage ? 'text-brand-700' : 'text-base-400'
                    }`}
                  >
                    <div
                      className={`w-8 h-8 rounded-full flex items-center justify-center ${
                        index < stage
                          ? 'bg-brand-700 text-white'
                          : 'bg-base-200'
                      }`}
                    >
                      {index + 1}
                    </div>
                    <span className='ml-2 text-sm font-medium'>
                      {step.title}
                    </span>
                  </div>
                ))}
              </div>
              <div className='mt-4 h-2 bg-base-200 rounded-full'>
                <div
                  className='h-full bg-brand-700 rounded-full transition-all duration-300 ease-in-out'
                  style={{
                    width: `${(stage / stages.length) * 100}%`
                  }}
                ></div>
              </div>
            </div>

            {stages[stage - 1].render()}

            <div className='mt-8 flex justify-between'>
              <SoftButton
                colour='primary'
                size='lg'
                leftIcon={<ArrowLeft size={18} />}
                onClick={handleBack}
                disabled={stage === 1}
              >
                Previous
              </SoftButton>
              {stage < stages.length ? (
                <FilledButton
                  colour='primary'
                  size='lg'
                  rightIcon={<ArrowRight size={18} />}
                  onClick={handleNext}
                  disabled={!providerData?.name?.trim()}
                >
                  Next
                </FilledButton>
              ) : (
                <div className='flex flex-row space-x-4'>
                  <FilledButton
                    colour='base'
                    size='lg'
                    onClick={() => handleSubmit(false)}
                  >
                    Finish
                  </FilledButton>
                  <FilledButton
                    colour='primary'
                    size='lg'
                    onClick={() => handleSubmit(true)}
                  >
                    Finish and Open
                  </FilledButton>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateProvider;
