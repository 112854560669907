import React, { useState } from 'react';
import { Modal, TextButton, FilledButton } from '@core/components';

const MoveTagModal = ({
  isOpen,
  onClose,
  onMove,
  categories,
  selectedCategory,
  tag
}) => {
  const [targetCategoryId, setTargetCategoryId] = useState('');

  const handleMove = () => {
    onMove(targetCategoryId);
    setTargetCategoryId('');
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} title='Move Tag'>
      <div className='space-y-4'>
        <div>
          <label className='block text-sm font-medium text-gray-700 mb-1'>
            Select Category
          </label>
          <select
            className='mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm rounded-md'
            value={targetCategoryId}
            onChange={e => setTargetCategoryId(e.target.value)}
          >
            <option value=''>Select a category</option>
            {categories
              .filter(cat => cat.id !== selectedCategory?.id)
              .map(category => (
                <option key={category.id} value={category.id}>
                  {category.type}
                </option>
              ))}
          </select>
        </div>
        <div className='flex justify-end space-x-2'>
          <TextButton onClick={onClose}>Cancel</TextButton>
          <FilledButton onClick={handleMove} disabled={!targetCategoryId}>
            Move Tag
          </FilledButton>
        </div>
      </div>
    </Modal>
  );
};

export default MoveTagModal;
