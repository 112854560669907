import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useContext
} from 'react';
import { Check, ChevronsUpDown, X, Pencil } from 'lucide-react';
import {
  FilledButton,
  SoftButton,
  UserSelector,
  UserProfilePicture,
  Input,
  DropdownInput,
  ComboBox,
  DatePicker,
  Badge
} from '@core/components';
import { UserProfileContext } from '../../App';
import { formatProfile } from '../../utilities/Formatting';
import { Link } from 'react-router-dom';
import { supabase } from '../../utilities/supabase';
import { debounce } from 'lodash';

const Field = ({
  Icon,
  label,
  value,
  valueMetadata,
  editField,
  isEditable,
  onUpdate,
  dataType = 'text', // text, select, date, search, user-select, link, checkbox, dob, status, owner, entity, number
  options,
  loadOptions,
  extension,
  onlyExtension,
  inlineLabel = true,
  inlineEditor = true,
  editObject,
  editObjectName, // New prop to handle edit object
  entityType,
  entityId,
  valueResolver,
  placeholder,
  colour = 'primary',
  hint,
  error,
  size = 'md',
  allowCreate,
  debounceMs = 300
}) => {
  const [editing, setEditing] = useState(false);
  const [editValue, setEditValue] = useState(value);
  const [tags, setTags] = useState([]);
  const [entityData, setEntityData] = useState(null);
  const { userProfile } = useContext(UserProfileContext);
  const [query, setQuery] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [availableOptions, setAvailableOptions] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const inputRef = useRef(null);
  const dropdownRef = useRef(null);

  const pageSize = 10;

  const sizeClasses = {
    xs: 'text-xs py-0.5 px-1 rounded',
    sm: 'text-sm py-1 px-2 rounded-md',
    md: 'text-sm py-1.5 px-2.5 rounded-md',
    lg: 'text-base py-2 px-3 rounded-lg',
    xl: 'text-lg py-3 px-4 rounded-lg'
  };

  const colourClasses = {
    brand: 'ring-brand-300 focus:ring-brand-600',
    primary: 'ring-primary-300 focus:ring-primary-600',
    info: 'ring-info-300 focus:ring-info-600',
    success: 'ring-success-300 focus:ring-success-600',
    warning: 'ring-warning-300 focus:ring-warning-600',
    danger: 'ring-danger-300 focus:ring-danger-600',
    base: 'ring-base-300 focus:ring-base-600'
  };

  const focusClasses = {
    brand: 'data-[focus]:bg-brand-100 data-[focus]:text-brand-700',
    primary: 'data-[focus]:bg-primary-100 data-[focus]:text-primary-700',
    info: 'data-[focus]:bg-info-100 data-[focus]:text-info-700',
    success: 'data-[focus]:bg-success-100 data-[focus]:text-success-700',
    warning: 'data-[focus]:bg-warning-100 data-[focus]:text-warning-700',
    danger: 'data-[focus]:bg-danger-100 data-[focus]:text-danger-700',
    base: 'data-[focus]:bg-base-100 data-[focus]:text-base-700'
  };

  useEffect(() => {
    setEditValue(value);
    if (dataType === 'status') {
      setTags(value || []);
    }
  }, [value, dataType]);

  useEffect(() => {
    if (dataType === 'entity' && entityType && entityId) {
      fetchEntityData(entityType, entityId).then(data => {
        setEntityData(data);
        console.log(data);
      });
    }
  }, [dataType, entityType, entityId]);

  const handleLoadOptions = useCallback(
    async (searchQuery, pageNumber) => {
      if (loadOptions) {
        setLoading(true);
        try {
          const results = (await loadOptions(searchQuery, pageNumber)) || [];
          setAvailableOptions(prevOptions =>
            pageNumber === 1 ? results : [...prevOptions, ...results]
          );
          setHasMore(results.length === pageSize);
          setPage(pageNumber);
        } catch (error) {
          console.error('Error loading options:', error);
          setHasMore(false);
        } finally {
          setLoading(false);
        }
      }
    },
    [loadOptions]
  );

  const debouncedLoadOptions = useCallback(
    debounce(searchQuery => {
      handleLoadOptions(searchQuery, 1);
    }, debounceMs),
    [handleLoadOptions, debounceMs]
  );

  useEffect(() => {
    if (!loadOptions && options && options.length > 0) {
      setAvailableOptions(options);
    }
  }, [options, loadOptions]);

  useEffect(() => {
    const handleClickOutside = event => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleUserSelect = selectedUser => {
    setEditValue(selectedUser);
  };

  const handleUpdate = () => {
    let valueToUpdate = editValue;

    if (
      (dataType === 'search' || dataType === 'select') &&
      typeof editValue == 'object' &&
      editValue.value
    ) {
      valueToUpdate = editValue.value;
    }

    if (dataType === 'status') {
      valueToUpdate = tags;
    }

    if (editObject) {
      const updatedObject = {
        ...editObject,
        [editField.split('.')[1]]: valueToUpdate
      };

      onUpdate(editObjectName, updatedObject);
    } else {
      onUpdate(editField, valueToUpdate);
    }

    setEditing(false);
  };

  const handleLoadMore = () => {
    if (loadOptions && hasMore) {
      handleLoadOptions(query, page + 1);
    }
  };

  const renderEditInput = () => {
    switch (dataType) {
      case 'user-select':
        return (
          <UserSelector
            users={options}
            selectedUser={editValue}
            onSelect={handleUserSelect}
          />
        );
      case 'select':
        return (
          <DropdownInput
            value={
              options.find(
                option =>
                  option.label === editValue || option.value === editValue
              )?.value || editValue
            }
            onChange={value => {
              console.log(
                `Changing ${editField} from ${editValue} to ${value.target.value}`
              );
              setEditValue(value.target.value);
            }}
            disablePlaceholder
            options={options}
            className='flex-grow'
          />
        );
      case 'date':
        return (
          <DatePicker
            value={editValue}
            onChange={date => setEditValue(date)}
            placeholder='Select date'
            className='flex-grow'
          />
        );
      case 'dob':
        const [year, month, day] = editValue?.split('-') || ['', '', ''];
        console.log('DOB values:', { year, month, day });
        return (
          <div className='flex'>
            <Input
              type='text'
              value={day}
              onChange={e => {
                const newValue = `${year}-${month}-${e.target.value || ''}`;
                console.log('New DOB value after day change: ', newValue);
                setEditValue(newValue);
              }}
              placeholder='DD'
              size='sm'
              className='flex-grow max-w-[40px]'
            />
            <span className='pr-3 pt-1'>/</span>
            <Input
              type='text'
              value={month}
              onChange={e => {
                const newValue = `${year}-${e.target.value || ''}-${day}`;
                console.log('New DOB value after month change: ', newValue);
                setEditValue(newValue);
              }}
              placeholder='MM'
              size='sm'
              className='flex-grow max-w-[40px]'
            />
            <span className='pr-3 pt-1'>/</span>
            <Input
              type='text'
              value={year}
              onChange={e => {
                const newValue = `${e.target.value || ''}-${month}-${day}`;
                console.log('New DOB value after year change: ', newValue);
                setEditValue(newValue);
              }}
              placeholder='YYYY'
              size='sm'
              className='flex-grow max-w-[50px]'
            />
          </div>
        );
      case 'search':
        return (
          <ComboBox
            options={options}
            loadOptions={loadOptions}
            value={editValue}
            onChange={setEditValue}
            placeholder='Search...'
            className='flex-grow'
          />
        );
      case 'checkbox':
        return (
          <input
            type='checkbox'
            checked={editValue}
            onChange={e => setEditValue(e.target.checked)}
            className='size-5 rounded border-gray-300 text-brand-600 focus:ring-brand-600'
          />
        );
      case 'owner':
        return (
          <Link
            to={`/user-profiles/${value?.displayId}`}
            className='text-brand-600 hover:underline'
          >
            {formatProfile(value, 'sm')}
          </Link>
        );
      case 'year':
        return (
          <Input
            type='number'
            value={editValue}
            onChange={e => setEditValue(e.target.value)}
            size='md'
            className='flex-grow'
          />
        );
      case 'text':
      default:
        return (
          <Input
            type='text'
            value={editValue}
            onChange={e => setEditValue(e.target.value)}
            size='md'
            className='flex-grow'
          />
        );
    }
  };

  const renderLabelContent = () => (
    <>
      <Icon
        size={16}
        strokeWidth={1.8}
        className='text-base-500 mr-2 leading-6'
      />
      <span
        className={`text-sm font-medium text-base-950 ${
          inlineLabel ? `w-32` : 'flex-grow'
        }`}
      >
        {label}
      </span>
    </>
  );

  const calculateAge = dobString => {
    const [year, month, day] = dobString.split('-').map(Number);
    const dob = new Date(year, month - 1, day);
    const today = new Date();
    let age = today.getFullYear() - dob.getFullYear();
    const monthDiff = today.getMonth() - dob.getMonth();
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < dob.getDate())) {
      age--;
    }
    return age;
  };

  const renderFieldContent = () => {
    if (dataType === 'entity' && !entityData) {
      return <div className='flex items-center w-full'>Loading...</div>;
    }

    return (
      <div className='flex items-center w-full'>
        {!onlyExtension ? (
          <span className='text-base-600 flex-grow whitespace-normal'>
            <span className='inline-block w-full text-sm'>
              {dataType === 'followers' ? (
                <div className='flex items-center'>
                  <span className='inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800 mr-2'>
                    {value.length} followers
                  </span>
                  <SoftButton
                    size='sm'
                    onClick={() => {
                      const isFollowing = value.includes(userProfile.id);
                      const newFollowers = isFollowing
                        ? value.filter(id => id !== userProfile.id)
                        : [...value, userProfile.id];
                      onUpdate(editField, newFollowers);
                    }}
                    className={`transition-colors duration-200 ${
                      value.includes(userProfile.id)
                        ? 'hover:bg-danger-100 hover:text-danger-700'
                        : ''
                    }`}
                  >
                    {value.includes(userProfile.id) ? 'Unfollow' : 'Follow'}
                  </SoftButton>
                </div>
              ) : dataType === 'user-select' ? (
                <div className='flex items-center gap-x-2'>
                  <UserProfilePicture
                    firstName={value?.firstName ?? ''}
                    lastName={value?.lastName ?? ''}
                    profilePicture={value?.profilePhoto ?? ''}
                    size='sm'
                  />
                  <span>{value?.fullName ?? 'N/A'}</span>
                </div>
              ) : dataType === 'link' ? (
                <a
                  href={valueMetadata.url}
                  target='_blank'
                  rel='noopener noreferrer'
                  className='text-brand-600 hover:underline'
                >
                  {value}
                </a>
              ) : dataType === 'currency' ? (
                `£${parseFloat(value).toFixed(2)}`
              ) : dataType === 'date' ? (
                value ? (
                  new Date(value).toLocaleDateString()
                ) : (
                  'Not set'
                )
              ) : dataType === 'dob' ? (
                value ? (
                  `${new Date(value).toLocaleDateString()} (${calculateAge(
                    value
                  )} year old)`
                ) : (
                  'Not set'
                )
              ) : dataType === 'checkbox' ? (
                <input
                  type='checkbox'
                  checked={editValue}
                  readOnly
                  disabled
                  className='size-5 rounded border-gray-300 text-brand-600 focus:ring-brand-600'
                />
              ) : dataType === 'status' ? (
                <div className='flex flex-col'>
                  <div className='flex flex-wrap items-center'>
                    {value.map((tag, index) => (
                      <Badge key={index} colour='gray'>
                        {tag?.type}
                      </Badge>
                    ))}
                  </div>
                </div>
              ) : dataType === 'owner' ? (
                <Link
                  to={`/user-profiles/${value?.displayId}`}
                  className='text-brand-600 hover:underline'
                >
                  {formatProfile(value, 'sm')}
                </Link>
              ) : dataType === 'entity' ? (
                <Link
                  to={`/${entityData.entity.table}/${entityData.data.display_id}`}
                  className='text-brand-600 hover:underline'
                >
                  {entityData.data.name}
                </Link>
              ) : dataType === 'year' ? (
                isNaN(parseFloat(value)) ? (
                  'Not set'
                ) : (
                  `${parseFloat(value).toLocaleString()} year${
                    parseFloat(value) !== 1 ? 's' : ''
                  }`
                )
              ) : (
                value
              )}
            </span>
          </span>
        ) : null}
        {extension && extension(value, editValue, setEditValue)}
      </div>
    );
  };

  const renderButtons = () => (
    <div className='flex ml-2'>
      {editing && isEditable ? (
        <>
          <FilledButton
            onClick={handleUpdate}
            colour='base'
            size='sm'
            className='mr-2'
          >
            <Check size={18} />
          </FilledButton>
          <FilledButton
            onClick={() => {
              setEditValue(value);
              setEditing(false);
            }}
            colour='base'
            size='sm'
          >
            <X size={18} />
          </FilledButton>
        </>
      ) : (
        isEditable && (
          <SoftButton
            onClick={() => setEditing(true)}
            colour='base'
            size='sm'
            className='lg:opacity-0 lg:group-hover:opacity-100 transition-opacity duration-200'
          >
            <Pencil size={18} />
          </SoftButton>
        )
      )}
    </div>
  );

  const renderContent = () => (
    <>
      <div className={`flex flex-col mb-4 group`}>
        <div className={`flex items-center mb-1`}>
          {renderLabelContent()}
          {inlineLabel && (
            <div className='flex-grow'>
              {!inlineEditor && !editing && renderFieldContent()}
              {inlineEditor &&
                (!editing ? renderFieldContent() : renderEditInput())}
            </div>
          )}
          {renderButtons()}
        </div>
        {!inlineLabel &&
          (!editing ? (
            <div className='w-full'>{renderFieldContent()}</div>
          ) : (
            <div className='w-full'>{renderEditInput()}</div>
          ))}
        {inlineLabel && !inlineEditor && editing && (
          <div className='w-full'>{renderEditInput()}</div>
        )}
      </div>
    </>
  );

  return renderContent();
};

const fetchEntityName = async (entityType, entityId) => {
  try {
    // Fetch the @core/components entity to get the table name and entity name
    const { data: entity, error: coreEntityError } = await supabase
      .from('core_entities')
      .select('id, table, name')
      .eq('id', entityType)
      .single();

    if (coreEntityError) {
      console.error(
        `[Error] Fetching @core/components entity for type ${entityType}: `,
        coreEntityError
      );
      return null;
    }

    const tableName = entity.table;
    const entityName = entity.name;

    // Fetch the entity name from the specific table
    const { data, error } = await supabase
      .from(tableName)
      .select('*')
      .eq('id', entityId)
      .single();

    if (error) {
      console.error(`[Error] Fetching entity name for ${entityName}: `, error);
      return null;
    }

    return { data, entity };
  } catch (error) {
    console.error(`[Error] Fetching entity name for ${entityType}: `, error);
    return null;
  }
};

// Function to fetch entity data
const fetchEntityData = async (entityType, entityId) => {
  console.log('sdad');
  try {
    const { data, entity } = await fetchEntityName(entityType, entityId);
    console.log('Fetched Entity Data:', data, entity);
    return { data, entity };
  } catch (error) {
    console.error('Error fetching entity data:', error);
    return null;
  }
};

export default Field;
