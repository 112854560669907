import React, { useState, useEffect } from 'react';
import {
  Popup,
  Input,
  SoftButton,
  Badge,
  Pagination,
  FilledButton
} from '@core/components';
import { Search } from 'lucide-react';
import { supabase } from '../../../utilities/supabase';
import AiLogo from '../../../assets/+Ai.png';

const CompanyNumberPopup = ({ isOpen, onClose, initialCompany }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [companies, setCompanies] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedCompany, setSelectedCompany] = useState({
    id: null,
    name: ''
  });
  const [companiesPerPage, setCompaniesPerPage] = useState(5);

  useEffect(() => {
    setSearchTerm(initialCompany?.name);
    setCompanies([]);
    fetchCompanies(initialCompany?.name);
  }, [initialCompany]);

  const fetchCompanies = async searchTerm => {
    if (!searchTerm) return;

    setLoading(true);

    try {
      const { data: remoteCompanies, error: remoteError } =
        await supabase.functions.invoke('assistedtrace/company', {
          body: { company: searchTerm }
        });

      if (remoteError) throw remoteError;

      if (remoteCompanies.type === 'success') {
        const formattedCompanies = remoteCompanies.data.map(
          ({ id, name, address, status }) => ({
            id,
            name,
            address,
            status: status || ''
          })
        );

        setCompanies(formattedCompanies);
      }
    } catch (error) {
      console.error('Error fetching companies:', error);
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = newPage => {
    setCurrentPage(newPage);
  };

  const handleItemsPerPageChange = newItemsPerPage => {
    setCompaniesPerPage(newItemsPerPage);
    setCurrentPage(1); // Reset to first page when changing items per page
  };

  const indexOfLastCompany = currentPage * companiesPerPage;
  const indexOfFirstCompany = indexOfLastCompany - companiesPerPage;
  const currentCompanies = companies.slice(
    indexOfFirstCompany,
    indexOfLastCompany
  );
  const totalPages = Math.ceil(companies.length / companiesPerPage);

  return (
    <Popup
      isOpen={isOpen}
      onClose={onClose}
      title={
        <div className='flex items-center'>
          <img src={AiLogo} className='w-9 h-9 mr-2' />
          <span className='font-semibold text-black pt-[3px]'>
            Company Search
          </span>
        </div>
      }
      size='lg'
      footer={
        <div className='flex justify-end space-x-2 mt-1'>
          <SoftButton onClick={onClose}>Cancel</SoftButton>
          <FilledButton
            onClick={async () => {
              if (selectedCompany.id) {
                try {
                  const { error } = await supabase
                    .from('companies')
                    .update({
                      number: selectedCompany.id,
                      name: selectedCompany.name
                    })
                    .eq('id', initialCompany.id);

                  if (error) throw error;

                  console.log(
                    'Company ID and name updated successfully:',
                    selectedCompany.id,
                    selectedCompany.name
                  );

                  onClose();
                } catch (error) {
                  console.error('Error updating company ID and name:', error);
                }
              }
            }}
            colour='primary'
            disabled={!selectedCompany.id}
          >
            Change
          </FilledButton>
        </div>
      }
    >
      <div className='space-y-4'>
        <div className='flex gap-2'>
          <Input
            placeholder='Company Name'
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value)}
          />
          <SoftButton
            colour='primary'
            onClick={() => fetchCompanies(searchTerm)}
          >
            <Search size={16} />
          </SoftButton>
        </div>
        <div className='overflow-x-auto bg-white rounded-md w-full'>
          <table className='w-full divide-y divide-base-200'>
            <thead>
              <tr>
                <th className='py-3 px-3 text-left text-sm font-semibold text-base-700'>
                  ID
                </th>
                <th className='py-3 px-3 text-left text-sm font-semibold text-base-700'>
                  Company
                </th>
                <th className='py-3 px-3 text-left text-sm font-semibold text-base-700'>
                  Status
                </th>
                <th className='py-3 px-3 text-left text-sm font-semibold text-base-700'>
                  Address
                </th>
              </tr>
            </thead>
            <tbody className='divide-y divide-base-50'>
              {loading ? (
                <tr>
                  <td colSpan='4' className='text-center py-10'>
                    <div className='flex flex-col items-center justify-center gap-2'>
                      <Search className='text-base-500 w-11 h-11 animate-bounce duration-2000' />
                      <span className='text-base-600 flex items-center'>
                        <img src={AiLogo} className='w-6 h-6 mr-1' />
                        AGi is researching possible companies...
                      </span>
                      <span className='text-base-600 text-sm'>
                        This may take a moment or two, we are working hard in
                        the background...
                      </span>
                    </div>
                  </td>
                </tr>
              ) : currentCompanies.length === 0 ? (
                <tr>
                  <td colSpan={4} className='text-center py-10'>
                    <div className='flex flex-col items-center justify-center'>
                      <span className='text-base-600 mb-2'>
                        No data available. Consider conducting further research.
                      </span>
                    </div>
                  </td>
                </tr>
              ) : (
                currentCompanies.map(company => {
                  const isSelected = selectedCompany.id === company.id;
                  return (
                    <tr
                      key={company.id}
                      onClick={() =>
                        setSelectedCompany({
                          id: company.id,
                          name: company.name
                        })
                      }
                      className={`cursor-pointer ${
                        isSelected
                          ? 'bg-brand-100 hover:bg-brand-200'
                          : 'hover:bg-base-50'
                      }`}
                    >
                      <td className='py-2 px-3 text-sm'>{company.id}</td>
                      <td className='py-2 px-3 text-sm'>{company.name}</td>
                      <td className='py-2 px-3'>
                        <Badge
                          size='xs'
                          colour={
                            company.status.toLowerCase() === 'active'
                              ? 'success'
                              : company.status.toLowerCase() === 'dissolved'
                              ? 'danger'
                              : 'base'
                          }
                        >
                          {company.status.charAt(0).toUpperCase() +
                            company.status.slice(1).toLowerCase()}
                        </Badge>
                      </td>
                      <td className='py-2 px-3 text-sm truncate max-w-xs'>
                        {company.address}
                      </td>
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
            itemsPerPage={companiesPerPage}
            onItemsPerPageChange={handleItemsPerPageChange}
          />
        </div>
      </div>
    </Popup>
  );
};

export default CompanyNumberPopup;
