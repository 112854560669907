import React, { useEffect, useState, useCallback } from 'react';
import {
  Input,
  DropdownInput,
  FilledButton,
  UserProfilePicture
} from '@core/components';
import CoreEntityType from '../../../../models/CoreEntityType';
import { EntityTypeEnum } from '../../../../utilities/Enumerables';
import { supabase } from '../../../../utilities/supabase';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

const BasicDetails = ({ userProfileData, handleInputChange, setDisabled }) => {
  const [titleOptions, setTitleOptions] = useState([]);
  const [emailExists, setEmailExists] = useState(false);
  const [debounceTimeout, setDebounceTimeout] = useState(null);
  const [userData, setUserData] = useState({}); // Added userData state
  const navigate = useNavigate(); // Initialize useNavigate

  useEffect(() => {
    const fetchTitleOptions = async () => {
      try {
        const titles = await CoreEntityType.getByEntityId(
          EntityTypeEnum.UserTitle
        );
        setTitleOptions(
          titles.map(title => ({
            value: title.id,
            label: title.type
          }))
        );
      } catch (error) {
        console.error('Error fetching title options:', error);
      }
    };

    fetchTitleOptions();
  }, []);

  const checkEmailExists = useCallback(
    async email => {
      try {
        const { data: existingUser, error } = await supabase
          .from('user_profiles')
          .select('*')
          .ilike('email', email)
          .maybeSingle();

        if (error) throw error;

        const userExists = !!existingUser;
        setEmailExists(userExists);
        setDisabled(userExists); // Set disabled if user exists
        if (userExists) {
          setUserData(existingUser); // Set userData if user exists
        }
      } catch (error) {
        console.error('Error checking email existence:', error);
      }
    },
    [setDisabled]
  );

  const handleEmailChange = e => {
    const email = e.target.value;
    handleInputChange('email', email);

    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }

    const newTimeout = setTimeout(() => {
      checkEmailExists(email);
    }, 200); // 300ms debounce time

    setDebounceTimeout(newTimeout);
  };

  const handleGoToUser = () => {
    if (userData.display_id) {
      navigate(`/user-profiles/${userData.display_id}`);
    }
  };

  return (
    <div className='space-y-4'>
      <DropdownInput
        label='Title'
        options={titleOptions}
        onChange={e => handleInputChange('title', e.target.value)}
        placeholder='Select Title'
      />

      <Input
        label='First Name'
        value={userProfileData.firstName}
        onChange={e => handleInputChange('firstName', e.target.value)}
        required
      />

      <Input
        label='Last Name'
        value={userProfileData.lastName}
        onChange={e => handleInputChange('lastName', e.target.value)}
        required
      />

      <Input
        label='Email'
        value={userProfileData.email}
        onChange={handleEmailChange}
        required
      />

      {emailExists && (
        <div className='text-red-500'>
          <div className='mb-3'>
            The email address you entered already exists.
          </div>
          <div className='flex items-center space-x-4 text-base-900'>
            <div className='flex items-center'>
              <UserProfilePicture
                firstName={userData.first_name} // Use userData for first name
                lastName={userData.last_name} // Use userData for last name
                profilePicture={userData.profile_photo} // Use userData for profile photo
                size='md'
              />
              <span className='ml-2'>
                {userData.first_name} {userData.last_name} {/* Use userData */}
              </span>
            </div>
            <FilledButton colour='primary' onClick={handleGoToUser}>
              Go to User
            </FilledButton>
          </div>
        </div>
      )}
    </div>
  );
};

export default BasicDetails;
