import { useState, useEffect, useCallback, createContext, act } from 'react';
import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
  redirect
} from 'react-router-dom';
import { supabase } from './utilities/supabase';
import { LogoLoader } from '@core/components';
import Dashboard from './pages/dashboard/Dashboard';
import Login from './pages/auth/login/Login';
import Signup from './pages/auth/signup/Signup';
import ForgotPassword from './pages/auth/forgotPassword/ForgotPassword';
import Account from './pages/account/Account';
import ResetPassword from './pages/auth/resetPassword/ResetPassword';
import Loading from './pages/auth/loading/Loading';
import StylePages from './pages/styles/Styles';
import Error from './pages/error/Error';
import NotFound from './pages/error/404';
import UserProfile from './models/UserProfile';
import { NotificationProvider } from '@core/components';
import MaintenancePage from './pages/error/Maintenance';
import {
  initializeSentry,
  Sentry,
  sentryCreateBrowserRouter
} from './utilities/sentry';
import MFA from './pages/auth/2fa/2FA';
import XeroCallback from './pages/dashboard/settings/tabs/integrations/XeroCallback';
import { AiProvider } from '@core/components';

export const UserProfileContext = createContext(null);
export const ThemeContext = createContext(null);

const App = () => {
  const [session, setSession] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [showLoadingScreen, setShowLoadingScreen] = useState(false);
  const [userProfile, setUserProfile] = useState(null);
  const [theme, setTheme] = useState('light');
  const [isBackendAvailable, setIsBackendAvailable] = useState(true);
  const [showMFA, setShowMFA] = useState(false);
  const [passwordRecovery, setPasswordRecovery] = useState(false);

  useEffect(() => {
    // Load Google Maps JavaScript API
    const script = document.createElement('script');
    script.text = `(g=>{var h,a,k,p="The Google Maps JavaScript API",c="google",l="importLibrary",q="__ib__",m=document,b=window;b=b[c]||(b[c]={});var d=b.maps||(b.maps={}),r=new Set,e=new URLSearchParams,u=()=>h||(h=new Promise(async(f,n)=>{await (a=m.createElement("script"));e.set("libraries",[...r]+"");for(k in g)e.set(k.replace(/[A-Z]/g,t=>"_"+t[0].toLowerCase()),g[k]);e.set("callback",c+".maps."+q);a.src=\`https://maps.\${c}apis.com/maps/api/js?\`+e;d[q]=f;a.onerror=()=>h=n(Error(p+" could not load."));a.nonce=m.querySelector("script[nonce]")?.nonce||"";m.head.append(a)}));d[l]?console.warn(p+" only loads once. Ignoring:",g):d[l]=(f,...n)=>r.add(f)&&u().then(()=>d[l](f,...n))})({
      key: "AIzaSyAwNd9A7-DqCDa_X4BO3mIBMR9c8aX4eVQ",
      v: "weekly"
    });`;
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  const checkBackendServices = async () => {
    try {
      const { error } = await supabase
        .from('core_entities')
        .select('id')
        .limit(1);
      setIsBackendAvailable(!error);
    } catch (error) {
      console.error('Backend services are unavailable:', error);
      setIsBackendAvailable(false);
    }
  };

  const handleAuthChange = (event, session) => {
    if (
      event === 'SIGNED_IN' ||
      event === 'SIGNED_OUT' ||
      event === 'MFA_CHALLENGE_VERIFIED' ||
      event === 'PASSWORD_RECOVERY'
    ) {
      setSession(session);
      if (event === 'SIGNED_IN' || event === 'MFA_CHALLENGE_VERIFIED') {
        UserProfile.getByAuthId(session.user.id).then(
          async ({ data, error }) => {
            if (data) {
              const activeProfile = data[0];

              setUserProfile(activeProfile);

              if (!activeProfile || !activeProfile?.admin) {
                window.location.href =
                  'https://portal.pensiontracingservice.com';
              }

              Sentry.setUser({
                email: activeProfile?.email,
                fullName: activeProfile?.fullName
              });
              const {
                data: { currentLevel, nextLevel }
              } = await supabase.auth.mfa.getAuthenticatorAssuranceLevel();

              if (currentLevel === 'aal1' && nextLevel === 'aal2') {
                // User has MFA enrolled but needs to verify
                setShowMFA(true);
              } else if (currentLevel === 'aal2' && nextLevel === 'aal2')
                setShowMFA(false);

              // Check if the user is an admin and redirect if true
            } else if (error) {
              console.error('Error fetching user profile:', error);
            }
          }
        );
      } else if (event === 'PASSWORD_RECOVERY') {
        setPasswordRecovery(true);
      } else {
        setUserProfile(null);
        //redirect('/login');
      }
    }
  };

  useEffect(() => {
    const {
      data: { subscription }
    } = supabase.auth.onAuthStateChange(handleAuthChange);
    return () => subscription.unsubscribe();
  }, [handleAuthChange]);

  useEffect(() => {
    const checkSession = async () => {
      await checkBackendServices();
      if (!isBackendAvailable) return;

      const {
        data: { session }
      } = await supabase.auth.getSession();
      setSession(session);

      if (session) {
        const { data, error } = await UserProfile.getByAuthId(session.user.id);
        if (data) {
          const activeProfile = data[0];

          if (!activeProfile || !activeProfile?.admin) {
            window.location.href = 'https://portal.pensiontracingservice.com';
          }

          setUserProfile(activeProfile);

          Sentry.setUser({
            email: activeProfile?.email,
            fullName: activeProfile?.fullName
          });
        } else if (error) {
          console.error('Error fetching user profile:', error);
        }
        const userPreferences =
          JSON.parse(localStorage.getItem('user_preferences')) || {};
        setTheme(userPreferences.theme || 'light');
      }

      setIsLoading(false);
    };

    checkSession();
  }, [isBackendAvailable]);

  if (isLoading) return <LogoLoader logo={'/logo-star.png'} />;
  if (showLoadingScreen) return <Loading />;
  if (!isBackendAvailable) return <MaintenancePage />;
  if (passwordRecovery) return <Navigate to='/resetPassword' />;

  // if (
  //   window.location.hostname.includes('https://crm.pensiontracingservice.com')
  // ) {
  initializeSentry({
    enableFeedback: true,
    enableReplays: true,
    enableTracing: true
  });
  // }

  const router = sentryCreateBrowserRouter([
    {
      path: '/',
      errorElement: <Error />,
      children: [
        {
          path: 'connect-xero',
          element: session ? <XeroCallback /> : <Navigate to='/login' />
        },
        {
          index: true,
          element: !session ? (
            <Navigate to='/login' />
          ) : showMFA ? (
            <Navigate to='/mfa' />
          ) : (
            <Dashboard />
          )
        },
        {
          path: 'login',
          element: !session ? (
            <Login />
          ) : showMFA ? (
            <Navigate to='/mfa' />
          ) : (
            <Navigate to='/' />
          )
        },
        {
          path: 'mfa',
          element: !session ? (
            <Navigate to='/login' />
          ) : !showMFA ? (
            <Navigate to='/' />
          ) : (
            <MFA />
          )
        },
        {
          path: 'password',
          element: !session ? <ForgotPassword /> : <Navigate to='/' />
        },
        {
          path: 'resetPassword',
          element: !session ? <ResetPassword /> : <Navigate to='/' />
        },
        {
          path: 'signup',
          element: !session ? <Signup /> : <Navigate to='/' />
        },
        {
          path: 'styles',
          element: !session ? (
            <Navigate to='/login' />
          ) : showMFA ? (
            <Navigate to='/mfa' />
          ) : (
            <StylePages />
          )
        },
        {
          path: '/*',
          element: !session ? (
            <Navigate to='/login' />
          ) : showMFA ? (
            <Navigate to='/mfa' />
          ) : (
            <Dashboard />
          )
        }
      ]
    }
  ]);

  const handleAiRequest = async ({ template, selectedText, customPrompt }) => {
    // Your app-specific AI implementation here
    const { data: response } = await supabase.functions.invoke(
      'integrations/add-ai/text-completion',
      {
        body: {
          model: {
            provider: 1,
            id: 28
          },
          details: {
            template: template
          },
          input: {
            content: selectedText,
            prompt: customPrompt
          }
        }
      }
    );

    return response;
  };

  return (
    <UserProfileContext.Provider value={{ userProfile, setUserProfile }}>
      <NotificationProvider>
        <ThemeContext.Provider value={{ theme, setTheme }}>
          <AiProvider handleAiRequest={handleAiRequest}>
            <RouterProvider router={router} />
          </AiProvider>
        </ThemeContext.Provider>
      </NotificationProvider>
    </UserProfileContext.Provider>
  );
};

export default App;
