import Logo from '../../assets/logo-star.png';
import { Link } from 'react-router-dom';

const Auth = ({ title, onSubmit, children, type }) => {
  return (
    <div className='flex flex-col md:flex-row h-screen'>
      <div className='w-full md:w-1/2 bg-white p-6 md:p-12 flex flex-col justify-center'>
        <div className='max-w-md w-full mx-auto'>
          <div className='text-center mb-8'>
            <img src={Logo} alt='Logo' className='mx-auto mb-5 h-[100px]' />
            <h2 className='text-3xl font-bold mb-2 text-neutral-800'>
              {title}
            </h2>
          </div>
          <form className='space-y-4' onSubmit={onSubmit}>
            {children}
          </form>
          {type && (
            <p className='mt-8 text-center text-neutral-600 text-sm'>
              {type === 'login'
                ? "Don't have an account? "
                : type === 'signup'
                ? 'Already have an account? '
                : type === 'forgot'
                ? 'Remember your password? '
                : ''}
              <Link
                to={
                  type === 'login'
                    ? '/signup'
                    : type === 'signup' || type === 'forgot'
                    ? '/login'
                    : ''
                }
                className='font-medium text-brand hover:text-brand transition-colors duration-300'
              >
                {type === 'login'
                  ? 'Sign Up'
                  : type === 'signup' || type === 'forgot'
                  ? 'Log In'
                  : ''}
              </Link>
              {type !== 'forgot' && ' instead'}
            </p>
          )}
        </div>
      </div>
      <div className='hidden md:flex md:w-1/2 bg-brand items-center justify-center'></div>
    </div>
  );
};

export default Auth;
