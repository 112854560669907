import {
  User as UserIcon,
  Briefcase,
  Tags,
  CheckCircle,
  Building,
  Building2,
  FileSearch,
  DollarSign,
  Calendar,
  ClipboardList,
  Hash,
  Clock1,
  ClockIcon,
  PoundSterling
} from 'lucide-react';
import Entity from '../../../components/entity/Entity';
import Pension from '../../../models/Pension';
import CoreEntityType from '../../../models/CoreEntityType';
import Field from '../../../components/entity/Field';
import { EntityTypeEnum } from '../../../utilities/Enumerables';
import DateCard from '../../../components/entity/DateCard';
import { useEffect, useState } from 'react';
import PensionSearches from './tabs/Searches';
import { Link } from 'react-router-dom';

const PensionDetails = ({ currentUser }) => {
  const [statusOptions, setStatusOptions] = useState([]);

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const statusTypes = await CoreEntityType.getByEntityId(
          EntityTypeEnum.PensionStatus
        );

        setStatusOptions(
          statusTypes.map(status => ({
            value: status.id,
            label: status.description || status.type
          }))
        );
      } catch (error) {
        console.error('Error fetching options:', error);
      }
    };

    fetchOptions();
  }, []);

  const policyTypeOptions = [
    { value: 'final_salary', label: 'Final Salary' },
    { value: 'personal_pension', label: 'Personal Pension Plan' },
    { value: 'group_pension', label: 'Group Pension Plan' },
    { value: 'stakeholder', label: 'Stakeholder' },
    { value: 'section_32', label: 'Section 32 Buy Out' },
    { value: 's226', label: 'S226 Retirement Annuity Contracts' },
    { value: 'sipp', label: 'SIPP' },
    { value: 'contracted_in', label: 'Contracted In (Money Purchase)' },
    { value: 'contracted_out', label: 'Contracted Out (Money Purchase)' },
    { value: 'non_transferable', label: 'Non-Transferable Pension' }
  ];

  const infoItems = [
    {
      icon: Briefcase,
      label: 'Name',
      field: 'name',
      isEditable: true
    },
    {
      icon: UserIcon,
      label: 'Owner',
      displayField: 'owner',
      field: 'owner',
      dataType: 'owner',
      isEditable: false
    },
    {
      icon: CheckCircle,
      label: 'Status',
      displayField: 'status.type',
      editField: 'status.id',
      dataType: 'select',
      isEditable: true,
      options: statusOptions
    },
    {
      icon: Building,
      label: 'Company',
      displayField: 'company.name',
      dataType: 'search',
      isEditable: false,
      displayLink: data => `/companies/${data?.company?.id}`
    },
    {
      icon: Building2,
      label: 'Provider',
      displayField: 'provider.name',
      dataType: 'search',
      isEditable: false,
      displayLink: data => `/providers/${data?.provider?.id}`
    },
    {
      icon: FileSearch,
      label: 'Scheme',
      displayField: 'scheme.name',
      dataType: 'search',
      isEditable: false,
      displayLink: data => `/schemes/${data?.scheme?.id}`
    },
    {
      icon: PoundSterling,
      label: 'Value',
      displayField: 'metadata.value',
      editField: 'value',
      isEditable: true,
      editObject: 'metadata'
    },
    {
      icon: ClipboardList,
      label: 'Policy Type',
      displayField: 'metadata.policy_type',
      editField: 'policy_type',
      isEditable: true,
      editObject: 'metadata',
      dataType: 'select',
      options: policyTypeOptions,
      valueResolver: value => {
        const option = policyTypeOptions.find(opt => opt.value === value);
        return option ? option.label : value;
      }
    },
    {
      icon: Hash,
      label: 'Policy Number',
      displayField: 'metadata.policy_number',
      editField: 'policy_number',
      isEditable: true,
      editObject: 'metadata'
    },
    {
      icon: Calendar,
      label: 'Start Date',
      displayField: 'metadata.start_date',
      editField: 'start_date',
      isEditable: true,
      editObject: 'metadata',
      dataType: 'date'
    },
    {
      icon: Calendar,
      label: 'End Date',
      displayField: 'metadata.end_date',
      editField: 'end_date',
      isEditable: true,
      editObject: 'metadata',
      dataType: 'date'
    },
    {
      icon: ClockIcon,
      label: 'Contributed For',
      field: 'metadata',
      isEditable: false,
      onlyExtension: true,
      extension: data => {
        const startYear = data?.start_date
          ? new Date(data.start_date).getFullYear()
          : null;
        const endYear = data?.end_date
          ? new Date(data.end_date).getFullYear()
          : new Date().getFullYear();

        if (!startYear) return <span className='text-sm ml-2'>N/A</span>;

        const yearDiff = endYear - startYear;
        return (
          <span className='text-sm ml-2'>{`${yearDiff} ${
            yearDiff === 1 ? 'year' : 'years'
          }`}</span>
        );
      }
    }
  ];

  const accordionItems = [
    {
      title: 'Key Dates',
      content: ({ data }) => (
        <div className='grid grid-cols-2 md:grid-cols-3 gap-2'>
          <DateCard date={data.createdDate} label='Created' />
          <DateCard date={data.updatedDate} label='Updated' />
        </div>
      )
    }
  ];

  const progressItems = {
    title: 'Trace Progress',
    steps: statusOptions.map(status => ({
      label: status.label
    })),
    getValue: entity => {
      const currentStatusIndex = statusOptions.findIndex(
        status => status.value === entity?.status.id
      );
      return currentStatusIndex + 1;
    }
  };

  return (
    <Entity
      currentUser={currentUser}
      entityType={EntityTypeEnum.Pension}
      progressItems={progressItems}
      progressType={'continuous'}
      model={Pension}
      infoItems={infoItems}
      accordionItems={accordionItems}
    />
  );
};

export default PensionDetails;
