import React, { useState, useEffect } from 'react';
import {
  FilledButton,
  Input,
  DropdownInput,
  Checkbox,
  ComboBox
} from '@core/components';
import { Popup } from '@core/components';
import Search from '../../../models/Search';
import CoreEntityType from '../../../models/CoreEntityType';
import { EntityTypeEnum } from '../../../utilities/Enumerables';
import { formatProfile } from '../../../utilities/Formatting';
import { supabase } from '../../../utilities/supabase';

const CreateSearch = ({
  isOpen,
  onClose,
  onSearchCreated,
  user,
  search,
  trace
}) => {
  const [formData, setFormData] = useState({
    status: 75,
    trace: trace
      ? {
          label: trace.name,
          value: trace.id,
          owner: trace.owner.id
        }
      : null,
    provider: null,
    scheme: null
  });

  const [statusOptions, setStatusOptions] = useState([]);

  useEffect(() => {
    setFormData({
      status: search?.status?.id || 75,
      trace:
        search?.trace ||
        (trace
          ? {
              label: trace.name,
              value: trace.id,
              owner: trace.owner.id
            }
          : null),
      provider: search?.provider || null,
      scheme: search?.scheme || null
    });
  }, [search, user, trace]);

  useEffect(() => {
    const loadStatusOptions = async () => {
      try {
        const options = await CoreEntityType.getByEntityId(
          EntityTypeEnum.SearchStatus
        );
        const formattedOptions = options.map(option => ({
          label: option.type,
          value: option.id
        }));
        setStatusOptions(formattedOptions);
      } catch (error) {
        console.error('Error loading status options:', error);
      }
    };

    loadStatusOptions();
  }, []);

  const handleSubmit = async () => {
    try {
      const searchData = new Search({
        ...formData,
        status: { id: formData.status || 75 },
        trace: { id: formData.trace?.value },
        provider: { id: formData.provider?.value },
        scheme: { id: formData.scheme?.value },
        owner: { id: formData.trace?.owner?.id ?? formData.trace?.owner },
        metadata: {
          source: 'Manual'
        }
      });

      let savedSearch;

      if (search) {
        searchData.id = search.id;

        savedSearch = await searchData.update();
      } else {
        savedSearch = await searchData.insert();
      }
      onSearchCreated(savedSearch);
      handleClose();
    } catch (error) {
      console.error('Error saving search:', error);
    }
  };

  const handleClose = () => {
    setFormData({
      status: '',
      trace: trace
        ? {
            label: trace.name,
            value: trace.id,
            owner: trace.owner
          }
        : null,
      provider: null,
      scheme: null
    });
    onClose();
  };

  const loadTraceOptions = async query => {
    if (!query) return;
    try {
      const { data } = await supabase
        .from('traces')
        .select('id, name, owner')
        .ilike('name', `%${query}%`)
        .limit(10);

      return data.map(trace => ({
        label: trace.name,
        value: trace.id,
        owner: trace.owner
      }));
    } catch (error) {
      console.error('Error loading trace options:', error);
      return [];
    }
  };

  const loadProviderOptions = async query => {
    if (!query) return;
    try {
      const { data } = await supabase
        .from('providers')
        .select('id, name')
        .ilike('name', `%${query}%`)
        .limit(10);

      return data.map(provider => ({
        label: provider.name,
        value: provider.id
      }));
    } catch (error) {
      console.error('Error loading provider options:', error);
      return [];
    }
  };

  const loadSchemeOptions = async query => {
    if (!query) return;
    try {
      const { data } = await supabase
        .from('schemes')
        .select('id, name')
        .ilike('name', `%${query}%`)
        .limit(10);

      return data.map(scheme => ({
        label: scheme.name,
        value: scheme.id
      }));
    } catch (error) {
      console.error('Error loading scheme options:', error);
      return [];
    }
  };

  const handleInputChange = (field, value) => {
    setFormData(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const isSubmitDisabled = !formData.trace || !formData.provider;

  return (
    <Popup
      isOpen={isOpen}
      onClose={handleClose}
      title={search ? 'Edit Search' : 'New Search'}
      footer={
        <div className='flex justify-end space-x-2 mt-1'>
          <FilledButton type='button' colour='base' onClick={handleClose}>
            Cancel
          </FilledButton>
          <FilledButton onClick={handleSubmit} disabled={isSubmitDisabled}>
            {search ? 'Update Search' : 'Create Search'}
          </FilledButton>
        </div>
      }
    >
      <div className='space-y-6'>
        <div className='grid grid-cols-1 gap-4'>
          <DropdownInput
            label='Status'
            value={formData.status}
            options={statusOptions}
            onChange={e => handleInputChange('status', e.target.value)}
            disablePlaceholder
          />

          {trace ? (
            <Input label='Trace' value={trace.name} disabled={true} />
          ) : (
            <ComboBox
              label='Trace'
              value={formData.trace}
              loadOptions={loadTraceOptions}
              onChange={selected => handleInputChange('trace', selected)}
              placeholder='Search Traces...'
            />
          )}

          <ComboBox
            label='Provider'
            value={formData.provider}
            loadOptions={loadProviderOptions}
            onChange={selected => handleInputChange('provider', selected)}
            placeholder='Search Providers...'
          />

          <ComboBox
            label='Scheme'
            value={formData.scheme}
            loadOptions={loadSchemeOptions}
            onChange={selected => handleInputChange('scheme', selected)}
            placeholder='Search Schemes...'
          />
        </div>
      </div>
    </Popup>
  );
};

export default CreateSearch;
