import { supabase } from '../utilities/supabase';
import BaseModel from './BaseModel';

/**
 * Represents a User Profile in the system.
 * @extends BaseModel
 */
class UserProfile extends BaseModel {
  /** @type {string} The name of the database table for User Profiles */
  static table = 'user_profiles';
  /** @type {string} The SQL query to select User Profile data */
  static selectQuery = `*,
    title:user_profiles_title_fkey!inner(*),
    role:permission_groups!inner(*),
    segmentation:core_entity_types!user_profiles_segmentation_fkey!inner(*),
    status:core_entity_types!user_profiles_status_fkey!inner(*),
    signature:documents!user_profiles_signature_fkey(*)`;

  /**
   * Creates an instance of UserProfile.
   * @param {Object} data - The user profile data.
   */
  constructor(data = {}) {
    super(data);
    this.auth = data.auth || null;
    this.firstName = data.first_name || null;
    this.lastName = data.last_name || null;
    this.fullName = data.full_name || null; // Added fullName from database
    this.email = data.email || null;
    this.phoneNumber = data.phone_number || null;
    this.title = data.title || null;
    this.previousNames = data.previous_names || [];
    this.birthDate = data.birth_date || null;
    this.nationalInsurance = data.national_insurance || null;
    this.segmentation = data.segmentation
      ? { id: data.segmentation.id, type: data.segmentation.type }
      : null;
    this.marketing = data.marketing || {
      post: true,
      text: true,
      email: true
    };
    this.tags = data.tags || [];
    this.profilePhoto = data.profile_photo || null;
    this.displayId = data.display_id || null;
    this.role = data.role || null;
    this.status = data.status || null;
    this.ownerId = data.owner || null;
    this.owner = null;
    this.followers = data.followers || [];
    this.advisors = data.advisors || [];
    this.signature = data.signature || null;
    this.crmPreferences = data.crm_preferences || {};
    this.admin = data.admin ?? false;
  }

  /**
   * Fetch owner profile data and set it on the instance
   */
  async fetchOwner() {
    if (!this.ownerId) return;

    try {
      const { data, error } = await supabase
        .from(this.constructor.table)
        .select('id, first_name, last_name, full_name, email')
        .eq('id', this.ownerId)
        .single();

      if (error) throw error;

      this.owner = {
        id: data.id,
        firstName: data.first_name,
        lastName: data.last_name,
        fullName: data.full_name,
        email: data.email
      };
    } catch (error) {
      console.error('[Error] Fetching owner profile:', error);
    }
  }

  /**
   * Converts the UserProfile instance to a database-friendly format.
   * @returns {Object} The user profile data ready for database operations.
   */
  toDatabase() {
    return {
      ...super.toDatabase(),
      auth: this.auth,
      first_name: this.firstName,
      last_name: this.lastName,
      email: this.email,
      phone_number: this.phoneNumber,
      title: this.title?.id || undefined,
      previous_names: this.previousNames,
      birth_date: this.birthDate,
      national_insurance: this.nationalInsurance,
      segmentation: this.segmentation?.id || undefined,
      marketing: this.marketing,
      tags: this.tags?.map(tag => tag?.id || tag) || [],
      profile_photo: this.profilePhoto,
      role: this.role?.id || undefined,
      status: this.status?.id ?? this.status?.id ?? 1,
      owner: this.owner?.id ?? this.ownerId ?? undefined,
      followers: this.followers,
      advisors: this.advisors || [],
      crm_preferences: this.crmPreferences,
      admin: this.admin
    };
  }

  /**
   * Get a record by its ID.
   * @param {string|number} id - The ID of the record to fetch.
   * @returns {Promise<BaseModel>} A promise that resolves to a new instance of the model.
   */
  static async getById(id) {
    const profile = await this.fetchOne('display_id', id, this.selectQuery);
    await profile.fetchOwner();
    return profile;
  }

  /**
   * Fetch a user profile by auth ID.
   * @param {string} id - The auth ID to fetch.
   * @returns {Promise<UserProfile>} A promise that resolves to a new instance of UserProfile.
   */
  static async getByAuthId(id) {
    return this.getAll({ auth: { value: id } });
  }

  static async getByUniqueId(id) {
    const profile = await this.fetchOne('id', id);
    await profile.fetchOwner();
    return profile;
  }

  static async getAll(
    filters = {},
    page = 1,
    size = 10,
    sortBy = 'id',
    sortType = 'asc',
    count = true
  ) {
    console.log(filters);
    // Modify filters to include statuses and substatuses as tags
    if (filters.statuses || filters.substatuses) {
      filters.tags = filters.tags || {};
      const combinedValues = [
        ...(filters.statuses?.value || []),
        ...(filters.substatuses?.value || [])
      ].filter(value => value !== undefined); // Ensure no undefined values
      if (combinedValues.length > 0) {
        // Only push if there are valid values
        filters.tags = {
          value: combinedValues,
          label: 'Statuses',
          operator: 'cs',
          isOr: true,
          isArray: true,
          friendlyValue: [
            ...(filters.statuses?.friendlyValue || []),
            ...(filters.substatuses?.friendlyValue || [])
          ].filter(value => value !== undefined) // Ensure no undefined values
        };
      }
      delete filters.statuses;
      delete filters.substatuses;
    }

    console.log(filters);

    const result = await super.getAll(
      filters,
      page,
      size,
      sortBy,
      sortType,
      count
    );
    await Promise.all(result.data.map(profile => profile.fetchOwner()));
    return result;
  }

  /**
   * Check if the user has a specific permission for a given entity.
   * @param {number} entityId - The ID of the entity to check permissions for.
   * @param {number} permissionFlag - The permission flag to check (e.g., 1 for read, 2 for write, 4 for update, 8 for delete).
   * @returns {boolean} True if the user has the specified permission, false otherwise.
   */
  hasPermission(entityId, permissionFlag) {
    // Check if the user has permissions for the given entity
    if (this.role?.permissions && this.role.permissions[entityId]) {
      // Perform bitwise AND operation to check if the permission flag is set
      return (
        (this.role.permissions[entityId] & permissionFlag) === permissionFlag
      );
    }
    return false; // No permissions found for the entity
  }

  /**
   * Save a new name to the previous names array and update the database.
   * @param {string} name - The name to add to the previous names.
   * @returns {Promise<void>} A promise that resolves when the name is saved.
   */
  async addPreviousName(name) {
    try {
      // Add the new name to the previous names array
      this.previousNames.push(name);
      // Update the database with the new previous names array
      await this.update({
        previous_names: this.previousNames
      });
    } catch (error) {
      console.error('Error saving previous names:', error);
    }
  }

  /**
   * Remove a previous name by index and update the database.
   * @param {number} index - The index of the name to remove from the previous names.
   * @returns {Promise<void>} A promise that resolves when the name is removed.
   */
  async removePreviousName(index) {
    try {
      // Remove the name at the specified index from the previous names array
      if (index >= 0 && index < this.previousNames.length) {
        this.previousNames.splice(index, 1);
        // Await the update to ensure the database is updated
        await this.update({
          previous_names: this.previousNames
        });
      } else {
        console.error('Index out of bounds');
      }
    } catch (error) {
      console.error('Error removing previous name:', error);
    }
  }

  /**
   * Update the UserProfile instance.
   * @param {Object} data - The data to update.
   * @returns {Promise<UserProfile>} A promise that resolves to the updated UserProfile instance.
   */
  async update(data) {
    const updated = await super.update(data);
    await updated.fetchOwner();
    return updated;
  }
}

export default UserProfile;
