import React, { useEffect, useState } from 'react';
import { Popup, SoftButton } from '@core/components';
import { Disclosure } from '@headlessui/react';
import { ChevronUp, AlertCircle } from 'lucide-react';
import AutomationRun from '../../../models/AutomationRuns';

const RunsPopup = ({ isOpen, automation, onClose }) => {
  if (!automation) return;

  const [runs, setRuns] = useState([]);
  const [selectedRun, setSelectedRun] = useState(null);

  useEffect(() => {
    const fetchRuns = async () => {
      try {
        const { data, error } = await AutomationRun.getAll({
          automation: { operator: 'eq', value: automation.id }
        });

        if (error) {
          console.error('Error fetching automation runs:', error);
        } else {
          setRuns(data);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchRuns();
  }, [automation]);

  return (
    <Popup isOpen={isOpen} onClose={onClose} title='Automation Runs' size='xl'>
      <div className='flex'>
        {/* Left side - List of runs */}
        <div className='w-1/2 overflow-y-auto max-h-96 border-r border-gray-200'>
          {runs.length > 0 ? (
            <div className='space-y-4 me-5'>
              {runs.map(run => (
                <div
                  key={run.id}
                  className={`border border-gray-200 p-4 rounded-lg hover:bg-gray-50 cursor-pointer transition-colors ${
                    selectedRun?.id === run.id
                      ? 'bg-blue-50 border-blue-200'
                      : ''
                  }`}
                  onClick={() => setSelectedRun(run)}
                >
                  <div className='flex justify-between items-center'>
                    <div className='font-medium'>
                      {automation.entity.name} {run.entityId}
                      <span className='bg-gray-100 text-gray-600 text-sm px-2 py-0.5 rounded'>
                        <b>ID:</b> {run.id}
                      </span>
                    </div>
                    <span
                      className={`px-2 py-1 rounded-full text-sm ${
                        run.status == 104
                          ? 'bg-yellow-100 text-yellow-800'
                          : 'bg-green-100 text-green-800'
                      }`}
                    >
                      {run.status == 104 ? 'Running' : 'Complete'}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className='text-center text-gray-500 p-8'>
              No runs found for this automation
            </div>
          )}
        </div>

        <div className='w-1/2 p-4 overflow-y-auto max-h-96'>
          {selectedRun ? (
            <div className='space-y-4 -my-5'>
              <h2 className='font-medium mb-4'>Details</h2>
              <div className='bg-white rounded-lg border border-gray-200 p-4'>
                <div className='space-y-2 text-sm text-gray-500'>
                  <div>
                    <span className='font-bold'>ID:</span> {selectedRun.id}
                  </div>
                  <div className='flex gap-2 items-center'>
                    <span className='font-bold'>Entity: </span>
                    <span>{automation.entity.name}</span>
                    <SoftButton
                      onClick={() =>
                        (window.location.href = `/${automation.entity.table.toLowerCase()}/${
                          selectedRun.entityId
                        }`)
                      }
                    >
                      View
                    </SoftButton>
                  </div>
                  <div>
                    <span className='font-bold'>Started:</span>{' '}
                    {new Date(selectedRun.createdDate).toLocaleString()}
                  </div>
                </div>
              </div>
              <h2 className='font-medium mb-4'>Sends</h2>
              {automation.send.map((request, index) => (
                <div key={index} className='space-y-4'>
                  <div className='bg-white rounded-lg border border-gray-200'>
                    <Disclosure>
                      {({ open }) => (
                        <>
                          <Disclosure.Button className='flex w-full justify-between rounded-t-lg bg-white px-4 py-4 text-left text-sm font-medium hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-75'>
                            <span className='flex items-center gap-2'>
                              {request.name}
                              <span className='bg-gray-100 text-gray-600 text-xs px-2 py-0.5 rounded'>
                                Request
                              </span>
                            </span>
                            <ChevronUp
                              className={`${
                                open ? 'rotate-180 transform' : ''
                              } h-5 w-5 text-gray-500`}
                            />
                          </Disclosure.Button>
                          <Disclosure.Panel className='px-4 py-4 border-t border-gray-200'>
                            <div className='space-y-4'>
                              <div>
                                <div className='text-sm font-medium text-gray-600 mb-2'>
                                  URL:
                                </div>
                                <div className='flex gap-2'>
                                  <input
                                    type='text'
                                    value={request.method || 'GET'}
                                    disabled
                                    className='w-24 bg-gray-50 p-2 rounded-md border border-gray-200 text-sm'
                                  />
                                  <input
                                    type='text'
                                    value={request.url}
                                    disabled
                                    className='flex-1 bg-gray-50 p-2 rounded-md border border-gray-200 text-sm'
                                  />
                                </div>
                              </div>
                              <div>
                                <div className='text-sm font-medium text-gray-600 mb-2'>
                                  Headers:
                                </div>
                                {Object.entries(request.headers || {}).map(
                                  ([key, value], i) => (
                                    <div key={i} className='flex gap-2 mb-2'>
                                      <input
                                        type='text'
                                        value={key}
                                        disabled
                                        className='flex-1 bg-gray-50 p-2 rounded-md border border-gray-200 text-sm'
                                      />
                                      <input
                                        type='text'
                                        value={value}
                                        disabled
                                        className='flex-1 bg-gray-50 p-2 rounded-md border border-gray-200 text-sm'
                                      />
                                    </div>
                                  )
                                )}
                              </div>
                              <div>
                                <div className='text-sm font-medium text-gray-600 mb-2'>
                                  Body:
                                </div>
                                <textarea
                                  value={JSON.stringify(request.body, null, 2)}
                                  disabled
                                  className='w-full bg-gray-50 p-2 rounded-md border border-gray-200 font-mono text-sm'
                                  rows={5}
                                />
                              </div>
                            </div>
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  </div>
                  <div className='flex flex-col justify-center items-center'>
                    <div className='w-1 h-3 bg-gray-300'></div>
                    {selectedRun.responses &&
                    selectedRun.responses[index] &&
                    selectedRun.responses[index].status >= 200 &&
                    selectedRun.responses[index].status < 300 ? (
                      <div className='flex items-center gap-2 bg-green-50 p-2 rounded-lg'>
                        <div className='w-3 h-3 bg-green-500 rounded-full'></div>
                        <span className='text-green-700 text-sm'>
                          Success ({selectedRun.responses[index].status})
                        </span>
                      </div>
                    ) : (
                      <div className='flex items-center gap-2 bg-red-50 p-2 rounded-lg'>
                        <div className='w-3 h-3 bg-red-500 rounded-full'></div>
                        <span className='text-red-700 text-sm'>
                          Failed ({selectedRun.responses[index].status})
                        </span>
                      </div>
                    )}
                    <div className='w-1 h-3 bg-gray-300'></div>
                  </div>
                  {selectedRun.responses && selectedRun.responses[index] ? (
                    <div className='bg-white rounded-lg border border-gray-200'>
                      <Disclosure>
                        {({ open }) => (
                          <>
                            <Disclosure.Button className='flex w-full justify-between rounded-t-lg bg-white px-4 py-4 text-left text-sm font-medium hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-75'>
                              <span className='flex items-center gap-2'>
                                {request.name}
                                <span className='bg-gray-100 text-gray-600 text-xs px-2 py-0.5 rounded'>
                                  Response
                                </span>
                              </span>
                              <ChevronUp
                                className={`${
                                  open ? 'rotate-180 transform' : ''
                                } h-5 w-5 text-gray-500`}
                              />
                            </Disclosure.Button>
                            <Disclosure.Panel className='px-4 py-4 border-t border-gray-200'>
                              <div className='space-y-4'>
                                <div>
                                  <div className='text-sm font-medium text-gray-600 mb-2'>
                                    Headers:
                                  </div>
                                  {selectedRun.responses[index].headers?.map(
                                    (header, i) => (
                                      <div key={i} className='flex gap-2 mb-2'>
                                        <input
                                          type='text'
                                          value={header.field}
                                          disabled
                                          className='flex-1 bg-gray-50 p-2 rounded-md border border-gray-200 text-sm'
                                        />
                                        <input
                                          type='text'
                                          value={header.value}
                                          disabled
                                          className='flex-1 bg-gray-50 p-2 rounded-md border border-gray-200 text-sm'
                                        />
                                      </div>
                                    )
                                  )}
                                </div>
                                <div>
                                  <div className='text-sm font-medium text-gray-600 mb-2'>
                                    Response:
                                  </div>
                                  <pre className='bg-gray-50 p-3 rounded-md text-sm overflow-x-auto'>
                                    {JSON.stringify(
                                      selectedRun.responses[index].body,
                                      null,
                                      2
                                    )}
                                  </pre>
                                </div>
                              </div>
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                    </div>
                  ) : (
                    <div className='text-center py-2 rounded-md font-medium bg-red-50 text-red-700'>
                      Failed
                    </div>
                  )}
                  {index !== automation.send.length - 1 && (
                    <div className='flex flex-col justify-center items-center'>
                      <div className='w-1 h-10 bg-gray-300'></div>
                    </div>
                  )}
                </div>
              ))}
              <h2 className='font-medium mb-4'>Exits</h2>
              {selectedRun.status === 104 ? (
                <div className='flex items-center gap-2 bg-yellow-50 p-4 rounded-lg border border-yellow-200'>
                  <AlertCircle className='text-yellow-600' />
                  <span className='text-yellow-700'>
                    This flow is still running, no exits available.
                  </span>
                </div>
              ) : (
                automation.exit.map((request, index) => (
                  <div key={index} className='space-y-4'>
                    <div className='bg-white rounded-lg border border-gray-200'>
                      <Disclosure>
                        {({ open }) => (
                          <>
                            <Disclosure.Button className='flex w-full justify-between rounded-t-lg bg-white px-4 py-4 text-left text-sm font-medium hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-75'>
                              <span className='flex items-center gap-2'>
                                {request.name}
                                <span className='bg-gray-100 text-gray-600 text-xs px-2 py-0.5 rounded'>
                                  Request
                                </span>
                              </span>
                              <ChevronUp
                                className={`${
                                  open ? 'rotate-180 transform' : ''
                                } h-5 w-5 text-gray-500`}
                              />
                            </Disclosure.Button>
                            <Disclosure.Panel className='px-4 py-4 border-t border-gray-200'>
                              <div className='space-y-4'>
                                <div>
                                  <div className='text-sm font-medium text-gray-600 mb-2'>
                                    URL:
                                  </div>
                                  <div className='flex gap-2'>
                                    <input
                                      type='text'
                                      value={request.method || 'GET'}
                                      disabled
                                      className='w-24 bg-gray-50 p-2 rounded-md border border-gray-200 text-sm'
                                    />
                                    <input
                                      type='text'
                                      value={request.url}
                                      disabled
                                      className='flex-1 bg-gray-50 p-2 rounded-md border border-gray-200 text-sm'
                                    />
                                  </div>
                                </div>
                                <div>
                                  <div className='text-sm font-medium text-gray-600 mb-2'>
                                    Headers:
                                  </div>
                                  {Object.entries(request.headers || {}).map(
                                    ([key, value], i) => (
                                      <div key={i} className='flex gap-2 mb-2'>
                                        <input
                                          type='text'
                                          value={key}
                                          disabled
                                          className='flex-1 bg-gray-50 p-2 rounded-md border border-gray-200 text-sm'
                                        />
                                        <input
                                          type='text'
                                          value={value}
                                          disabled
                                          className='flex-1 bg-gray-50 p-2 rounded-md border border-gray-200 text-sm'
                                        />
                                      </div>
                                    )
                                  )}
                                </div>
                                <div>
                                  <div className='text-sm font-medium text-gray-600 mb-2'>
                                    Body:
                                  </div>
                                  <textarea
                                    value={JSON.stringify(
                                      request.body,
                                      null,
                                      2
                                    )}
                                    disabled
                                    className='w-full bg-gray-50 p-2 rounded-md border border-gray-200 font-mono text-sm'
                                    rows={5}
                                  />
                                </div>
                              </div>
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                    </div>
                    <div className='flex flex-col justify-center items-center'>
                      <div className='w-1 h-3 bg-gray-300'></div>
                      {selectedRun.exitResponses &&
                      selectedRun.exitResponses[index] &&
                      selectedRun.exitResponses[index].status >= 200 &&
                      selectedRun.exitResponses[index].status < 300 ? (
                        <div className='flex items-center gap-2 bg-green-50 p-2 rounded-lg'>
                          <div className='w-3 h-3 bg-green-500 rounded-full'></div>
                          <span className='text-green-700 text-sm'>
                            Success ({selectedRun.exitResponses[index].status})
                          </span>
                        </div>
                      ) : (
                        <div className='flex items-center gap-2 bg-red-50 p-2 rounded-lg'>
                          <div className='w-3 h-3 bg-red-500 rounded-full'></div>
                          <span className='text-red-700 text-sm'>
                            Failed ({selectedRun.responses[index].status})
                          </span>
                        </div>
                      )}
                      <div className='w-1 h-3 bg-gray-300'></div>
                    </div>
                    {selectedRun.exitResponses &&
                    selectedRun.exitResponses[index] ? (
                      <div className='bg-white rounded-lg border border-gray-200'>
                        <Disclosure>
                          {({ open }) => (
                            <>
                              <Disclosure.Button className='flex w-full justify-between rounded-t-lg bg-white px-4 py-4 text-left text-sm font-medium hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-75'>
                                <span className='flex items-center gap-2'>
                                  {request.name}
                                  <span className='bg-gray-100 text-gray-600 text-xs px-2 py-0.5 rounded'>
                                    Response
                                  </span>
                                </span>
                                <ChevronUp
                                  className={`${
                                    open ? 'rotate-180 transform' : ''
                                  } h-5 w-5 text-gray-500`}
                                />
                              </Disclosure.Button>
                              <Disclosure.Panel className='px-4 py-4 border-t border-gray-200'>
                                <div className='space-y-4'>
                                  <div>
                                    <div className='text-sm font-medium text-gray-600 mb-2'>
                                      Headers:
                                    </div>
                                    {selectedRun.exitResponses[
                                      index
                                    ].headers?.map((header, i) => (
                                      <div key={i} className='flex gap-2 mb-2'>
                                        <input
                                          type='text'
                                          value={header.field}
                                          disabled
                                          className='flex-1 bg-gray-50 p-2 rounded-md border border-gray-200 text-sm'
                                        />
                                        <input
                                          type='text'
                                          value={header.value}
                                          disabled
                                          className='flex-1 bg-gray-50 p-2 rounded-md border border-gray-200 text-sm'
                                        />
                                      </div>
                                    ))}
                                  </div>
                                  <div>
                                    <div className='text-sm font-medium text-gray-600 mb-2'>
                                      Response:
                                    </div>
                                    <pre className='bg-gray-50 p-3 rounded-md text-sm overflow-x-auto'>
                                      {JSON.stringify(
                                        selectedRun.exitResponses[index].body,
                                        null,
                                        2
                                      )}
                                    </pre>
                                  </div>
                                </div>
                              </Disclosure.Panel>
                            </>
                          )}
                        </Disclosure>
                      </div>
                    ) : (
                      <div className='text-center py-2 rounded-md font-medium bg-red-50 text-red-700'>
                        Failed
                      </div>
                    )}
                    {index !== automation.exit.length - 1 && (
                      <div className='flex flex-col justify-center items-center'>
                        <div className='w-1 h-10 bg-gray-300'></div>
                      </div>
                    )}
                  </div>
                ))
              )}
            </div>
          ) : (
            <div className='text-center text-gray-500 p-8'>
              Select a run to view details
            </div>
          )}
        </div>
      </div>
    </Popup>
  );
};

export default RunsPopup;
