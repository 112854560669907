import React, { useState, useCallback, useEffect } from 'react';
import {
  Popup,
  FilledButton,
  SoftButton,
  ComboBox,
  Badge,
  DropdownInput
} from '@core/components';
import Tag from '../../../models/Tag';
import { supabase } from '../../../utilities/supabase';
import {
  FilterOperatorEnum,
  EntityTypeEnum
} from '../../../utilities/Enumerables';
import CoreEntityType from '../../../models/CoreEntityType';

const ManageTags = ({ isOpen, onClose, entityType, entityId }) => {
  const [selectedTag, setSelectedTag] = useState(null);
  const [entityTags, setEntityTags] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [categories, setCategories] = useState([]);

  const handleSave = async () => {
    try {
      // Delete existing tags
      await supabase
        .from('tagged_entities')
        .delete()
        .eq('entity_type', entityType)
        .eq('entity_id', entityId);

      // Insert new tags
      const tagsToInsert = entityTags.map(tag => ({
        entity_type: entityType,
        entity_id: entityId,
        tag_id: tag.id
      }));

      if (tagsToInsert.length > 0) {
        await supabase.from('tagged_entities').insert(tagsToInsert);
      }

      onClose();
    } catch (err) {
      console.error('Error saving tags:', err);
    }
  };

  const handleRemoveTag = async tagId => {
    try {
      const updatedTags = entityTags.filter(tag => tag.id !== tagId);
      setEntityTags(updatedTags);
    } catch (err) {
      console.error('Error removing tag:', err);
    }
  };

  useEffect(() => {
    const fetchEntityTags = async () => {
      try {
        const { data: taggedEntities } = await supabase
          .from('tagged_entities')
          .select('*,tags(*,category:core_entity_types(*))')
          .eq('entity_type', entityType)
          .eq('entity_id', entityId);

        if (taggedEntities) {
          const fetchedTags = taggedEntities.map(te => ({
            id: te.tags.id,
            name: te.tags.name,
            colour: te.tags.colour,
            category: te.tags.category
          }));
          setEntityTags(fetchedTags);
        }
      } catch (error) {
        console.error('Error fetching entity tags:', error);
      }
    };

    const fetchCategories = async () => {
      try {
        const { data } = await CoreEntityType.getAll({
          entity_id: {
            value: EntityTypeEnum.TagCategory,
            operator: FilterOperatorEnum.EQUALS
          }
        });
        setCategories(data || []);
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    if (isOpen) {
      fetchEntityTags();
      fetchCategories();
    }
  }, [entityType, entityId, isOpen]);

  const loadTagOptions = useCallback(
    async (query, page) => {
      try {
        const filters = {
          name: { value: query, operator: FilterOperatorEnum.ILIKE },
          id: {
            value: `(${entityTags.map(tag => tag.id).join(',')})`,
            operator: FilterOperatorEnum.NOT_IN
          }
        };

        if (selectedCategory) {
          filters.category_id = {
            value: selectedCategory,
            operator: FilterOperatorEnum.EQ
          };
        }

        const { data } = await Tag.getAll(filters, page, 10);

        return data.map(tag => ({
          value: tag.id,
          label: tag.name,
          secondaryLabel: (
            <Badge
              size='xs'
              colour={tag.colour || tag.category?.metadata?.colour || 'primary'}
            >
              {tag.category?.type}
            </Badge>
          ),
          category: tag.category,
          colour: tag.colour
        }));
      } catch (error) {
        console.error('Error loading tag options:', error);
        return [];
      }
    },
    [entityTags, selectedCategory]
  );

  const handleAddTag = useCallback(
    selectedOption => {
      if (
        selectedOption &&
        !entityTags.some(t => t.id === selectedOption.value)
      ) {
        setEntityTags(prevTags => [
          ...prevTags,
          {
            id: selectedOption.value,
            name: selectedOption.label,
            colour: selectedOption.colour,
            category: selectedOption.category
          }
        ]);
        setSelectedTag(null);
      }
    },
    [entityTags]
  );

  return (
    <Popup isOpen={isOpen} onClose={onClose} title='Manage Tags'>
      <div className='p-4'>
        <div className='flex gap-2 items-center'>
          <div className='w-48'>
            <DropdownInput
              label='Filter by Category'
              placeholder='Select category'
              options={[
                { value: '', label: 'All Categories' },
                ...categories.map(cat => ({
                  value: cat.id,
                  label: cat.type
                }))
              ]}
              onChange={e => setSelectedCategory(e.target.value)}
              value={selectedCategory}
            />
          </div>
          <div className='flex-1'>
            <ComboBox
              loadOptions={loadTagOptions}
              label='Add Tag'
              placeholder='Search for tags'
              onChange={handleAddTag}
              value={selectedTag}
            />
          </div>
        </div>
        <h4 className='text-sm font-medium text-base-950 mt-8'>Current Tags</h4>
        <div className='mt-2 flex flex-wrap gap-2'>
          {entityTags.length > 0 ? (
            entityTags.map(tag => (
              <Badge
                key={tag.id}
                colour={
                  tag.colour || tag.category?.metadata?.colour || 'primary'
                }
                size='sm'
                onRemove={e => handleRemoveTag(tag.id)}
              >
                {tag.name}
              </Badge>
            ))
          ) : (
            <div className='w-full text-center text-neutral-500'>
              No tags added yet
            </div>
          )}
        </div>
      </div>
      <div className='flex justify-end p-4'>
        <SoftButton onClick={onClose} variant='secondary' className='mr-2'>
          Cancel
        </SoftButton>
        <FilledButton onClick={handleSave} variant='primary'>
          Save
        </FilledButton>
      </div>
    </Popup>
  );
};

export default ManageTags;
