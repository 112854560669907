import { useState, useEffect } from 'react';
import {
  Input,
  FilledButton,
  TextButton,
  ColourPicker,
  IconPicker,
  Popup
} from '@core/components';
import CoreEntityType from '../../../models/CoreEntityType';
import { EntityTypeEnum } from '../../../utilities/Enumerables';

export default function CreateDocCategory({ isOpen, onClose }) {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    type: '',
    description: '',
    metadata: {
      icon: 'Book',
      colour: 'gray-500',
      slug: ''
    }
  });
  const [isValid, setIsValid] = useState(false);
  const [isSlugManuallySet, setIsSlugManuallySet] = useState(false);

  const generateSlug = text => {
    return text
      .toLowerCase()
      .replace(/[^a-z0-9]+/g, '-')
      .replace(/(^-|-$)/g, '');
  };

  useEffect(() => {
    if (!isSlugManuallySet && formData.type) {
      handleChange('metadata.slug', generateSlug(formData.type));
    }
  }, [formData.type, isSlugManuallySet]);

  useEffect(() => {
    // Check if all required fields are filled
    const { type, description, metadata } = formData;
    const isFormValid = Boolean(
      type.trim() && description.trim() && metadata.slug.trim()
    );
    setIsValid(isFormValid);
  }, [formData]);

  const handleSubmit = async e => {
    e.preventDefault();
    setLoading(true);

    try {
      // Create new category using CoreEntityType model
      const category = new CoreEntityType({
        ...formData,
        entityId: EntityTypeEnum.GuideCategories,
        metadata: {
          ...formData.metadata,
          colour: formData.metadata.colour.replace('-500', '')
        }
      });
      await category.insert();

      onClose();
    } catch (error) {
      console.error('Error creating category:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (field, value) => {
    if (field.includes('.')) {
      const [parent, child] = field.split('.');
      setFormData(prev => ({
        ...prev,
        [parent]: {
          ...prev[parent],
          [child]: value
        }
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        [field]: value
      }));
    }
  };

  return (
    <Popup
      isOpen={isOpen}
      onClose={onClose}
      title='Create Documentation Category'
      size='sm'
      footer={
        <div className='flex justify-end gap-2'>
          <TextButton onClick={onClose} disabled={loading}>
            Cancel
          </TextButton>
          <FilledButton
            onClick={handleSubmit}
            loading={loading}
            disabled={!isValid || loading}
          >
            Create Category
          </FilledButton>
        </div>
      }
    >
      <div className='space-y-4'>
        <Input
          label='Category Name'
          value={formData.type}
          onChange={e => handleChange('type', e.target.value)}
          required
        />

        <Input
          label='Description'
          value={formData.description}
          onChange={e => handleChange('description', e.target.value)}
          required
        />

        <Input
          label='Slug'
          value={formData.metadata.slug}
          onChange={e => {
            const newSlug = e.target.value;
            handleChange('metadata.slug', newSlug);
            setIsSlugManuallySet(newSlug !== '');
          }}
          helperText='URL-friendly version of the category name'
          required
        />

        <div>
          <label className='block text-sm font-medium text-gray-700 mb-1'>
            Icon
          </label>
          <IconPicker
            selectedIcon={formData.metadata.icon}
            onChange={value => handleChange('metadata.icon', value)}
          />
        </div>

        <div>
          <label className='block text-sm font-medium text-gray-700 mb-1'>
            Colour
          </label>
          <ColourPicker
            showShades={false}
            colour={formData.metadata.colour}
            onChange={value => handleChange('metadata.colour', value)}
          />
        </div>
      </div>
    </Popup>
  );
}
