import { useState, useEffect, useContext } from 'react';
import { ChevronRight, ChevronLeft } from 'lucide-react';
import { NavLink, useNavigate } from 'react-router-dom';
import { UserProfileContext } from '../../App';
import { PERMISSIONS } from '../../utilities/Permissions';

const Sidebar = ({ items }) => {
  const [isExpanded, setIsExpanded] = useState(() => {
    const savedPreferences = localStorage.getItem('user_preferences');
    const preferences = savedPreferences ? JSON.parse(savedPreferences) : {};
    return preferences.sidebar_expanded ?? false;
  });
  const { userProfile: currentUser } = useContext(UserProfileContext);

  useEffect(() => {
    const savedPreferences = localStorage.getItem('user_preferences');
    const preferences = savedPreferences ? JSON.parse(savedPreferences) : {};
    preferences.sidebar_expanded = isExpanded;
    localStorage.setItem('user_preferences', JSON.stringify(preferences));
  }, [isExpanded]);

  const hasPermission = entityType => {
    return currentUser?.hasPermission(entityType, PERMISSIONS[entityType].VIEW);
  };

  const renderMenuItem = item => {
    if (!hasPermission(item.entityType)) {
      return null;
    }

    return (
      <NavLink
        key={item.name}
        to={item.path}
        className={({ isActive }) => `
          flex items-center p-2 rounded transition-colors duration-300 w-full min-w-10
          ${
            isActive
              ? 'bg-brand-800 text-white'
              : 'hover:bg-brand-800 text-white'
          }
        `}
      >
        <div className='w-6 h-6 flex items-center justify-center'>
          <item.icon size={20} className='flex-shrink-0 text-white' />
        </div>
        {isExpanded && (
          <span className='ml-2 mr-0 text-sm leading-6 whitespace-nowrap overflow-hidden transition-all duration-300 ease-in-out max-w-[240px]'>
            {item.name}
          </span>
        )}
      </NavLink>
    );
  };

  return (
    <div
      id='sideNav'
      className={`bg-brand-700 shadow-md h-screen ${
        isExpanded ? 'w-64' : 'w-16'
      } transition-all duration-300 ease-in-out flex flex-col `}
    >
      <div className={`flex h-12 shrink-0 items-center p-4 `}>
        <NavLink
          to='/'
          className='flex items-center justify-center h-full w-full'
        >
          <div className='relative h-10 w-full overflow-hidden'>
            <img
              alt='PTS'
              src='/logo-full-white.png'
              className='absolute top-1 left-0  min-w-[80px] max-w-[150px] overflow-hidden'
            />
          </div>
        </NavLink>
      </div>
      <div className='flex-grow overflow-y-auto scroller-brand'>
        <nav>
          <ul className='flex flex-col space-y-1 py-2 px-3 overflow-hidden'>
            {items.map(renderMenuItem)}
          </ul>
        </nav>
      </div>
      <div className='mt-auto flex flex-col py-2 px-3'>
        <div
          onClick={() => setIsExpanded(!isExpanded)}
          className='flex items-center p-2 hover:bg-brand-800 rounded transition-colors duration-300 w-full cursor-pointer text-white text-sm leading-6'
        >
          <div className='w-6 h-6 flex items-center justify-center'>
            {isExpanded ? (
              <ChevronLeft size={20} className='flex-shrink-0 text-white' />
            ) : (
              <ChevronRight size={20} className='flex-shrink-0 text-white' />
            )}
          </div>
          {isExpanded && (
            <span className='ml-4 mr-2 whitespace-nowrap overflow-hidden transition-all duration-300 ease-in-out max-w-[200px]'>
              {isExpanded ? 'Collapse' : 'Expand'}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
