import { useState, useEffect, useContext } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  LogoLoader,
  Breadcrumbs,
  Input,
  FilledButton,
  TextEditor,
  useNotification
} from '@core/components';
import { supabase } from '../../../../utilities/supabase';
import Guide from '../../../../models/Guide';
import CoreEntityType from '../../../../models/CoreEntityType';
import { getIconComponent } from '../../../../utilities/Formatting';
import { UserProfileContext } from '../../../../App';
import { EntityTypeEnum } from '../../../../utilities/Enumerables';

const CreateDoc = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const categorySlug = searchParams.get('category');
  const { userProfile } = useContext(UserProfileContext);
  const { addNotification } = useNotification();

  const [loading, setLoading] = useState(true);
  const [category, setCategory] = useState(null);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [content, setContent] = useState('');
  const [slug, setSlug] = useState('');
  const [isSlugManuallySet, setIsSlugManuallySet] = useState(false);

  const [aiResponseText, setAiResponseText] = useState('');

  const [editorInstance, setEditorInstance] = useState(null);
  const handleEditorReady = editor => {
    setEditorInstance(editor);
  };

  const generateSlug = text => {
    return text
      .toLowerCase()
      .replace(/[^a-z0-9]+/g, '-')
      .replace(/(^-|-$)/g, '');
  };

  useEffect(() => {
    if (!isSlugManuallySet && title) {
      setSlug(generateSlug(title));
    }
  }, [title, isSlugManuallySet]);

  useEffect(() => {
    const loadCategory = async () => {
      try {
        const categories = await CoreEntityType.getByEntityId(
          EntityTypeEnum.GuideCategories
        );
        const currentCategory = categories.find(
          cat => cat.metadata?.slug === categorySlug
        );

        if (!currentCategory) {
          throw new Error('Category not found');
        }

        setCategory(currentCategory);
      } catch (err) {
        addNotification({
          type: 'error',
          title: 'Error',
          description: 'Failed to load category'
        });
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    loadCategory();
  }, [categorySlug, addNotification]);

  const handleSave = async () => {
    try {
      if (!title.trim()) {
        throw new Error('Title is required');
      }

      const guide = new Guide({
        title: title.trim(),
        description: description.trim(),
        content,
        slug: slug,
        category: category,
        createdBy: userProfile,
        updatedBy: userProfile
      });
      await guide.insert();

      navigate(`/help/docs/${categorySlug}`);
    } catch (err) {
      addNotification({
        type: 'error',
        title: 'Error',
        description: err.message || 'Failed to create document'
      });
      console.error('Failed to create document:', err);
    }
  };

  async function handleAiRequest({ template, customPrompt, selectedText }) {
    try {
      // First insert the AI content node
      editorInstance.commands.createAiNode({
        originalContent: selectedText,
        content: '',
        status: 'pending'
      });

      const { data: response } = await supabase.functions.invoke(
        'integrations/add-ai/text-completion',
        {
          body: {
            model: {
              provider: 1,
              id: 28
            },
            details: {
              template: template
            },
            input: {
              content: selectedText,
              prompt: customPrompt
            }
          }
        }
      );

      if (!response.ok) {
        throw new Error('Failed to fetch AI response');
      }

      const reader = response.body.getReader();
      let accumulatedText = '';

      while (true) {
        const { done, value } = await reader.read();
        if (done) {
          // Update AI content node status to ready when stream is complete
          editorInstance.commands.updateAiContent({
            originalContent: selectedText,
            content: accumulatedText,
            status: 'idle'
          });
          setAiResponseText('');
          break;
        }

        const decodedChunk = new TextDecoder().decode(value, { stream: true });
        const lines = decodedChunk.split('\n');

        for (const line of lines) {
          if (line.trim() === '' || line === 'data: [DONE]') continue;

          try {
            const parsedLine = JSON.parse(line.replace('data: ', ''));
            if (parsedLine.choices[0]?.delta?.content) {
              accumulatedText += parsedLine.choices[0].delta.content;
              setAiResponseText(accumulatedText);
            }
          } catch (e) {
            console.error('Failed to parse chunk:', e);
          }
        }
      }

      console.log(accumulatedText);
    } catch (error) {
      console.error('Error during AI request:', error);
      // Remove AI content node on error
      editorInstance.commands.rejectAiContent();
    }
  }

  useEffect(() => {
    if (aiResponseText) {
      editorInstance.commands.updateAiContent({
        content: aiResponseText,
        status: 'pending'
      });
    }
  }, [aiResponseText, editorInstance]);

  if (loading) {
    return <LogoLoader logo='/logo-star.png' />;
  }

  const CategoryIcon = category?.metadata?.icon
    ? getIconComponent(category.metadata.icon)
    : null;

  return (
    <div className='max-w-6xl mx-auto p-6'>
      <div className='mb-6'>
        <Breadcrumbs
          pages={[
            { name: 'Help', href: '/help' },
            { name: 'Documentation', href: '/help/docs' },
            {
              name: category?.type || 'Uncategorized',
              href: `/help/docs/${categorySlug}`
            },
            {
              name: 'Create Guide',
              href: `/help/docs/create?category=${categorySlug}`,
              current: true
            }
          ]}
        />
      </div>

      <div className='mb-8'>
        <div className='flex items-center gap-3 mb-2'>
          {CategoryIcon && <CategoryIcon className='w-5 h-5 text-base-700' />}
          <span className='text-sm text-base-700'>
            {category?.type || 'Uncategorized'}
          </span>
        </div>
        <div className='flex justify-between items-center'>
          <h1 className='text-3xl font-medium mb-4 text-base-950'>
            Create Guide
          </h1>
          <div className='flex gap-2'>
            <FilledButton
              colour='base'
              onClick={() => navigate(`/help/docs/${categorySlug}`)}
            >
              Cancel
            </FilledButton>
            <FilledButton onClick={handleSave} colour='primary'>
              Save
            </FilledButton>
          </div>
        </div>
      </div>

      <div className='flex flex-col gap-6 border-t pt-8'>
        <div>
          <Input
            label='Title'
            placeholder='Enter guide title...'
            value={title}
            onChange={e => setTitle(e.target.value)}
            required
          />
          {title && (
            <div className='mt-1 text-sm text-base-500 flex items-center'>
              Slug:
              <Input
                value={slug}
                onChange={e => {
                  const newSlug = e.target.value;
                  setSlug(newSlug);
                  setIsSlugManuallySet(newSlug !== '');
                }}
                className='ml-1 bg-transparent focus:outline-none text-base-500'
              />
            </div>
          )}
        </div>
        <div>
          <Input
            label='Description'
            placeholder='Enter guide description...'
            value={description}
            onChange={e => setDescription(e.target.value)}
            required
          />
        </div>
        <div>
          <label className='block text-sm font-medium text-base-700 mb-2'>
            Content
          </label>
          <TextEditor
            value={content}
            onChange={setContent}
            onEditorReady={handleEditorReady}
            onAiRequest={handleAiRequest}
            className='min-h-[500px] max-h-[calc(100vh-300px)]'
          />
        </div>
      </div>
    </div>
  );
};

export default CreateDoc;
