import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Company from '../../../models/Company';
import EntityTable from '../../../components/tables/EntityTable';
import { formatDate } from '../../../utilities/Formatting';
import { Link } from 'react-router-dom';
import { Pencil, Trash, PlusCircle, SquareArrowOutUpRight } from 'lucide-react';
import {
  EntityTypeEnum,
  FilterOperatorEnum
} from '../../../utilities/Enumerables';
import CoreEntityType from '../../../models/CoreEntityType';
import { Modal, Alert, SoftButton } from '@core/components';
import { UserProfileContext } from '../../../App';
import { PERMISSIONS } from '../../../utilities/Permissions';
import CompanyNumberPopup from '../../../components/popups/companynumber/CompanyNumber';
import { supabase } from '../../../utilities/supabase';

const Companies = () => {
  document.title = 'Companies | CRM | PTS';

  const navigate = useNavigate();
  const [statusOptions, setStatusOptions] = useState([]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [companyToDelete, setCompanyToDelete] = useState(null);
  const [isCompanyNumberPopupOpen, setIsCompanyNumberPopupOpen] =
    useState(false);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const { userProfile: currentUser } = useContext(UserProfileContext);

  if (
    !currentUser.hasPermission(
      EntityTypeEnum.Company,
      PERMISSIONS[EntityTypeEnum.Company].VIEW
    )
  ) {
    return (
      <Alert
        title='Permission Denied'
        description='You do not have permission to view companies.'
        style='danger'
      />
    );
  }

  useEffect(() => {
    const fetchStatusOptions = async () => {
      try {
        const statuses = await CoreEntityType.getByEntityId(
          EntityTypeEnum.CompanyStatus
        );
        setStatusOptions(
          statuses.map(status => ({ value: status.type, key: status.id }))
        );
      } catch (error) {
        console.error('Error fetching status options:', error);
      }
    };

    fetchStatusOptions();
  }, []);

  const handleDeleteCompany = async () => {
    try {
      const { error } = await supabase
        .from('companies')
        .update({ status: 149 })
        .eq('id', companyToDelete.id);

      if (error) throw error;

      setIsDeleteModalOpen(false);
      setCompanyToDelete(null);
    } catch (error) {
      console.error('Error archiving company:', error);
    }
  };

  const menuItems = [
    {
      label: 'Open in new tab',
      icon: <SquareArrowOutUpRight size={14} />,
      onClick: data => window.open(`/companies/${data.id}`, '_blank')
    },
    {
      label: 'Delete',
      icon: <Trash size={14} className='text-danger-700' />,
      onClick: data => {
        setCompanyToDelete(data);
        setIsDeleteModalOpen(true);
      }
    }
  ];

  const handleRowClick = data => {
    navigate(`/companies/${data.id}`);
  };

  const handleCreate = () => {
    navigate('/companies/create');
  };

  const handleFindClick = (e, company) => {
    e.stopPropagation();
    setSelectedCompany(company);
    setIsCompanyNumberPopupOpen(true);
  };

  return (
    <>
      <div className='p-4'>
        <EntityTable
          name='Companies'
          customId='companies'
          filter={[
            {
              field: 'id',
              label: 'ID',
              type: 'text',
              operator: FilterOperatorEnum.EQUALS
            },
            {
              field: 'name',
              label: 'Company Name',
              type: 'text',
              operator: FilterOperatorEnum.ILIKE
            },
            {
              field: 'number',
              label: 'Company Number',
              type: 'text',
              operator: FilterOperatorEnum.ILIKE
            },
            {
              field: 'status',
              label: 'Status',
              type: 'select',
              options: statusOptions
            },
            {
              field: 'created_date',
              label: 'Created Date',
              type: 'daterange',
              operator: FilterOperatorEnum.BETWEEN
            },
            {
              field: 'updated_date',
              label: 'Updated Date',
              type: 'daterange',
              operator: FilterOperatorEnum.BETWEEN
            },
            {
              field: 'showArchived',
              label: 'Show Archived',
              hint: 'Show archived traces',
              type: 'conditional_toggle',
              operator: FilterOperatorEnum.IS,
              options: {
                value: 'true'
              }
            }
          ]}
          columns={[
            {
              label: 'ID',
              field: 'id',
              cell: value => (
                <Link
                  to={`/companies/${value}`}
                  className='text-brand-700 hover:text-brand-800'
                >
                  {value}
                </Link>
              )
            },
            { label: 'Company Name', field: 'name' },
            {
              label: 'Company Number',
              field: 'number',
              cell: (value, company) => (
                <div className='flex items-center'>
                  <span>{value}</span>
                  <SoftButton
                    className='ml-2'
                    onClick={e => handleFindClick(e, company)}
                  >
                    Find
                  </SoftButton>
                </div>
              )
            },
            { label: 'Status', field: 'status.type' },
            {
              label: 'Created Date',
              field: 'createdDate',
              cell: value => formatDate(value)
            },
            {
              label: 'Updated Date',
              field: 'updatedDate',
              cell: value => formatDate(value)
            }
          ]}
          menuItems={menuItems}
          model={Company}
          defaultFilters={{
            status: { value: '149', operator: FilterOperatorEnum.NOT_EQUALS }
          }}
          entityType={EntityTypeEnum.Company}
          onRowClick={handleRowClick}
          canCreate={true}
          onNewClick={handleCreate}
        />
      </div>
      <Modal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        title='Delete Company'
        style='danger'
        actions={[
          {
            label: 'Cancel',
            onClick: () => setIsDeleteModalOpen(false),
            style: 'base'
          },
          {
            label: 'Delete',
            onClick: handleDeleteCompany,
            style: 'danger'
          }
        ]}
      >
        <p className='text-sm text-base-600'>
          Are you sure you want to delete this company? This action cannot be
          undone.
        </p>
      </Modal>
      <CompanyNumberPopup
        isOpen={isCompanyNumberPopupOpen}
        onClose={() => setIsCompanyNumberPopupOpen(false)}
        initialCompany={selectedCompany}
      />
    </>
  );
};

export default Companies;
