import React, { useEffect, useState } from 'react';
import {
  useEditor,
  EditorContent,
  ReactNodeViewRenderer,
  BubbleMenu,
  FloatingMenu,
  NodeViewContent,
  NodeViewWrapper
} from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Placeholder from '@tiptap/extension-placeholder';
import Typography from '@tiptap/extension-typography';
import Link from '@tiptap/extension-link';
import Image from '@tiptap/extension-image';
import CodeBlockLowlight from '@tiptap/extension-code-block-lowlight';
import { DragHandle } from '@tiptap-pro/extension-drag-handle-react';
import TextAlign from '@tiptap/extension-text-align';
import CharacterCount from '@tiptap/extension-character-count';
import Table from '@tiptap/extension-table';
import TableCell from '@tiptap/extension-table-cell';
import TableHeader from '@tiptap/extension-table-header';
import TableRow from '@tiptap/extension-table-row';

import { common, createLowlight } from 'lowlight';
import {
  Bold,
  Italic,
  Strikethrough,
  Code,
  Eraser,
  Trash2,
  Heading1,
  Heading2,
  Heading3,
  Heading4,
  Heading5,
  Heading6,
  List,
  ListOrdered,
  FileCode,
  Quote,
  Minus,
  Undo,
  Redo,
  Type,
  X,
  Video as VideoIcon,
  Image as ImageIcon,
  GripVertical,
  AlignLeft,
  AlignRight,
  AlignCenter,
  AlignJustify,
  Sparkles,
  TextSearch,
  IterationCw,
  Smile,
  Briefcase,
  FoldVertical,
  Pen,
  TableIcon,
  ArrowLeft,
  ArrowRight
} from 'lucide-react';

// custom Extensions
import { SmilieReplacer } from '../../../utilities/tiptap/SmilieReplacer';
import { Video } from '../../../utilities/tiptap/video';
import AiContentNode from '../../../utilities/tiptap/AiContentNode';
import { Modal } from '../../feedback/alerts/Modal';
import { Input } from '../forms/Input';
import {
  FlatButton,
  SoftButton,
  TextButton,
  FilledButton
} from '../buttons/Button';
import { useAi } from '../../providers/AiProvider';

const CodeBlockComponent = ({ node, updateAttributes, extension }) => {
  const defaultLanguage = node.attrs.language;

  return (
    <NodeViewWrapper className='relative'>
      <select
        contentEditable={false}
        defaultValue={defaultLanguage}
        onChange={event => updateAttributes({ language: event.target.value })}
        className='absolute right-2 top-2 rounded-md px-1.5 py-1'
      >
        <option value='null'>auto</option>
        <option disabled>—</option>
        {extension.options.lowlight.listLanguages().map((lang, index) => (
          <option key={index} value={lang}>
            {lang}
          </option>
        ))}
      </select>
      <pre>
        <NodeViewContent as='code' />
      </pre>
    </NodeViewWrapper>
  );
};

// create a lowlight instance
const lowlight = createLowlight(common);

const TextEditor = ({
  label,
  labelHint,
  placeholder,
  characterLimit,
  value,
  onChange,
  className,
  hint,
  type = 'standard',
  onEditorReady,
  ...props
}) => {
  const [showVideoModal, setShowVideoModal] = useState(false);
  const [showImageModal, setShowImageModal] = useState(false);
  const [showAiBar, setShowAiBar] = useState(false);
  const [showTableMenu, setShowTableMenu] = useState(false);
  const [showDescribeInput, setShowDescribeInput] = useState(false);
  const [describeText, setDescribeText] = useState('');

  const [videoUrl, setVideoUrl] = useState('');
  const [imageUrl, setImageUrl] = useState('');

  const { handleAiRequest } = useAi();

  const extensions = [
    StarterKit.configure({
      codeBlock: false
    }),
    Placeholder.configure({
      placeholder: placeholder || 'Write something...'
    }),
    SmilieReplacer,
    Typography,
    Video,
    AiContentNode.configure({
      content: '',
      status: 'idle'
    }),
    Image.configure({
      HTMLAttributes: {
        class: 'w-full h-full rounded-lg'
      }
    }),
    CodeBlockLowlight.extend({
      addNodeView() {
        return ReactNodeViewRenderer(CodeBlockComponent);
      }
    }).configure({
      lowlight
    }),
    Link.configure({
      openOnClick: false,
      autolink: true,
      defaultProtocol: 'https'
    }),
    TextAlign.configure({
      types: ['heading', 'paragraph']
    }),
    Table.configure({
      resizable: true
    }),
    TableRow,
    TableHeader,
    TableCell,
    ...(characterLimit !== undefined
      ? [
          CharacterCount.configure({
            limit: characterLimit
          })
        ]
      : [])
  ];

  const editor = useEditor({
    editorProps: {
      attributes: {
        class: 'prose prose-neutral max-w-none focus:outline-none'
      },
      parseOptions: {
        preserveWhitespace: 'full'
      }
    },
    immediatelyRender: true,
    shouldRerenderOnTransaction: true,
    extensions,
    content: value,
    onUpdate: ({ editor }) => {
      const htmlContent = editor.getHTML();
      onChange(htmlContent);
    }
  });

  // Add this useEffect to notify parent when editor is ready
  useEffect(() => {
    if (editor && onEditorReady) {
      onEditorReady(editor);
    }
  }, [editor, onEditorReady]);

  useEffect(() => {
    if (editor && value !== editor.getHTML()) {
      editor.commands.setContent(value);
    }
  }, [value, editor]);

  if (!editor) {
    return null;
  }

  const addVideo = () => {
    if (videoUrl) {
      editor.commands.setVideo(videoUrl);
      setVideoUrl('');
      setShowVideoModal(false);
    }
  };

  const addImage = () => {
    if (imageUrl) {
      editor.chain().focus().setImage({ src: imageUrl }).run();
      setImageUrl('');
      setShowImageModal(false);
    }
  };

  const MenuBar = () => {
    if (!editor) {
      return null;
    }

    return (
      <div className='flex flex-col'>
        <div className='flex flex-wrap gap-1 p-1 bg-base-100 border-b border-base-300'>
          {/* Bold text formatting */}
          <button
            onMouseDown={e => {
              e.preventDefault();
              editor.chain().focus().toggleBold().run();
            }}
            className={`p-2 rounded hover:bg-base-200 ${
              editor.isActive('bold') ? 'bg-base-200' : ''
            }`}
            title='Bold'
          >
            <Bold size={18} />
          </button>
          {/* Italic text formatting */}
          <button
            onMouseDown={e => {
              e.preventDefault();
              editor.chain().focus().toggleItalic().run();
            }}
            className={`p-2 rounded hover:bg-base-200 ${
              editor.isActive('italic') ? 'bg-base-200' : ''
            }`}
            title='Italic'
          >
            <Italic size={18} />
          </button>
          {/* Strikethrough text formatting */}
          <button
            onMouseDown={e => {
              e.preventDefault();
              editor.chain().focus().toggleStrike().run();
            }}
            className={`p-2 rounded hover:bg-base-200 ${
              editor.isActive('strike') ? 'bg-base-200' : ''
            }`}
            title='Strikethrough'
          >
            <Strikethrough size={18} />
          </button>
          {/* Clear all text formatting */}
          <button
            onMouseDown={e => {
              e.preventDefault();
              editor.chain().focus().unsetAllMarks().run();
            }}
            className='p-2 rounded hover:bg-base-200'
            title='Clear Formatting'
          >
            <Eraser size={18} />
          </button>
          {/* Clear all block formatting */}
          <button
            onMouseDown={e => {
              e.preventDefault();
              editor.chain().focus().clearNodes().run();
            }}
            className='p-2 rounded hover:bg-base-200'
            title='Clear Nodes'
          >
            <Trash2 size={18} />
          </button>
          {/* Convert to paragraph */}
          <button
            onMouseDown={e => {
              e.preventDefault();
              editor.chain().focus().setParagraph().run();
            }}
            className={`p-2 rounded hover:bg-base-200 ${
              editor.isActive('paragraph') ? 'bg-base-200' : ''
            }`}
            title='Paragraph'
          >
            <Type size={18} />
          </button>
          {/* Align Left */}
          <button
            onMouseDown={e => {
              e.preventDefault();
              editor.chain().focus().setTextAlign('left').run();
            }}
            className={`p-2 rounded hover:bg-base-200 ${
              editor.isActive({ textAlign: 'left' }) ? 'bg-base-200' : ''
            }`}
            title='Align Left'
          >
            <AlignLeft size={18} />
          </button>
          {/* Align Center */}
          <button
            onMouseDown={e => {
              e.preventDefault();
              editor.chain().focus().setTextAlign('center').run();
            }}
            className={`p-2 rounded hover:bg-base-200 ${
              editor.isActive({ textAlign: 'center' }) ? 'bg-base-200' : ''
            }`}
            title='Align Center'
          >
            <AlignCenter size={18} />
          </button>
          {/* Align Right */}
          <button
            onMouseDown={e => {
              e.preventDefault();
              editor.chain().focus().setTextAlign('right').run();
            }}
            className={`p-2 rounded hover:bg-base-200 ${
              editor.isActive({ textAlign: 'right' }) ? 'bg-base-200' : ''
            }`}
            title='Align Right'
          >
            <AlignRight size={18} />
          </button>
          {/* Justify */}
          <button
            onMouseDown={e => {
              e.preventDefault();
              editor.chain().focus().setTextAlign('justify').run();
            }}
            className={`p-2 rounded hover:bg-base-200 ${
              editor.isActive({ textAlign: 'justify' }) ? 'bg-base-200' : ''
            }`}
            title='Justify'
          >
            <AlignJustify size={18} />
          </button>
          {/* Heading level 1 */}
          <button
            onMouseDown={e => {
              e.preventDefault();
              editor.chain().focus().toggleHeading({ level: 1 }).run();
            }}
            className={`p-2 rounded hover:bg-base-200 ${
              editor.isActive('heading', { level: 1 }) ? 'bg-base-200' : ''
            }`}
            title='Heading 1'
          >
            <Heading1 size={18} />
          </button>
          {/* Heading level 2 */}
          <button
            onMouseDown={e => {
              e.preventDefault();
              editor.chain().focus().toggleHeading({ level: 2 }).run();
            }}
            className={`p-2 rounded hover:bg-base-200 ${
              editor.isActive('heading', { level: 2 }) ? 'bg-base-200' : ''
            }`}
            title='Heading 2'
          >
            <Heading2 size={18} />
          </button>
          {/* Heading level 3 */}
          <button
            onMouseDown={e => {
              e.preventDefault();
              editor.chain().focus().toggleHeading({ level: 3 }).run();
            }}
            className={`p-2 rounded hover:bg-base-200 ${
              editor.isActive('heading', { level: 3 }) ? 'bg-base-200' : ''
            }`}
            title='Heading 3'
          >
            <Heading3 size={18} />
          </button>
          {/* Heading level 4 */}
          <button
            onMouseDown={e => {
              e.preventDefault();
              editor.chain().focus().toggleHeading({ level: 4 }).run();
            }}
            className={`p-2 rounded hover:bg-base-200 ${
              editor.isActive('heading', { level: 4 }) ? 'bg-base-200' : ''
            }`}
            title='Heading 4'
          >
            <Heading4 size={18} />
          </button>
          {/* Heading level 5 */}
          <button
            onMouseDown={e => {
              e.preventDefault();
              editor.chain().focus().toggleHeading({ level: 5 }).run();
            }}
            className={`p-2 rounded hover:bg-base-200 ${
              editor.isActive('heading', { level: 5 }) ? 'bg-base-200' : ''
            }`}
            title='Heading 5'
          >
            <Heading5 size={18} />
          </button>
          {/* Heading level 6 */}
          <button
            onMouseDown={e => {
              e.preventDefault();
              editor.chain().focus().toggleHeading({ level: 6 }).run();
            }}
            className={`p-2 rounded hover:bg-base-200 ${
              editor.isActive('heading', { level: 6 }) ? 'bg-base-200' : ''
            }`}
            title='Heading 6'
          >
            <Heading6 size={18} />
          </button>
          {/* Unordered (bullet) list */}
          <button
            onMouseDown={e => {
              e.preventDefault();
              editor.chain().focus().toggleBulletList().run();
            }}
            className={`p-2 rounded hover:bg-base-200 ${
              editor.isActive('bulletList') ? 'bg-base-200' : ''
            }`}
            title='Bullet List'
          >
            <List size={18} />
          </button>
          {/* Ordered (numbered) list */}
          <button
            onMouseDown={e => {
              e.preventDefault();
              editor.chain().focus().toggleOrderedList().run();
            }}
            className={`p-2 rounded hover:bg-base-200 ${
              editor.isActive('orderedList') ? 'bg-base-200' : ''
            }`}
            title='Numbered List'
          >
            <ListOrdered size={18} />
          </button>
          {/* Code block with syntax highlighting */}
          <button
            onMouseDown={e => {
              e.preventDefault();
              editor.chain().focus().toggleCodeBlock().run();
            }}
            className={`p-2 rounded hover:bg-base-200 ${
              editor.isActive('codeBlock') ? 'bg-base-200' : ''
            }`}
            title='Code Block'
          >
            <FileCode size={18} />
          </button>
          {/* Block quote */}
          <button
            onMouseDown={e => {
              e.preventDefault();
              editor.chain().focus().toggleBlockquote().run();
            }}
            className={`p-2 rounded hover:bg-base-200 ${
              editor.isActive('blockquote') ? 'bg-base-200' : ''
            }`}
            title='Block Quote'
          >
            <Quote size={18} />
          </button>
          {/* Horizontal rule (divider) */}
          <button
            onMouseDown={e => {
              e.preventDefault();
              editor.chain().focus().setHorizontalRule().run();
            }}
            className='p-2 rounded hover:bg-base-200'
            title='Horizontal Rule'
          >
            <Minus size={18} />
          </button>
          {/* Add Video */}
          <button
            onMouseDown={e => {
              e.preventDefault();
              setShowVideoModal(true);
            }}
            className='p-2 rounded hover:bg-base-200'
            title='Add Video'
          >
            <VideoIcon size={18} />
          </button>
          {/* Add Image */}
          <button
            onMouseDown={e => {
              e.preventDefault();
              setShowImageModal(true);
            }}
            className='p-2 rounded hover:bg-base-200'
            title='Add Image'
          >
            <ImageIcon size={18} />
          </button>
          {/* Table Controls */}
          <div className='relative'>
            <button
              onMouseDown={e => {
                e.preventDefault();
                setShowTableMenu(prev => !prev);
              }}
              className='p-2 rounded hover:bg-base-200'
              title='Table Controls'
            >
              <TableIcon size={18} />
            </button>

            {showTableMenu && (
              <div className='absolute left-0 mt-1 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-50'>
                <div className='py-1' role='menu'>
                  <button
                    className='w-full text-left px-4 py-2 text-sm hover:bg-base-100'
                    onMouseDown={e => {
                      e.preventDefault();
                      editor
                        .chain()
                        .focus()
                        .insertTable({ rows: 3, cols: 3, withHeaderRow: true })
                        .run();
                      setShowTableMenu(false);
                    }}
                  >
                    Insert Table (3x3)
                  </button>
                  <button
                    className='w-full text-left px-4 py-2 text-sm hover:bg-base-100'
                    onMouseDown={e => {
                      e.preventDefault();
                      editor.chain().focus().deleteTable().run();
                      setShowTableMenu(false);
                    }}
                  >
                    Delete Table
                  </button>
                  <button
                    className='w-full text-left px-4 py-2 text-sm hover:bg-base-100'
                    onMouseDown={e => {
                      e.preventDefault();
                      editor.chain().focus().addColumnBefore().run();
                      setShowTableMenu(false);
                    }}
                  >
                    Add Column Before
                  </button>
                  <button
                    className='w-full text-left px-4 py-2 text-sm hover:bg-base-100'
                    onMouseDown={e => {
                      e.preventDefault();
                      editor.chain().focus().addColumnAfter().run();
                      setShowTableMenu(false);
                    }}
                  >
                    Add Column After
                  </button>
                  <button
                    className='w-full text-left px-4 py-2 text-sm hover:bg-base-100'
                    onMouseDown={e => {
                      e.preventDefault();
                      editor.chain().focus().addRowBefore().run();
                      setShowTableMenu(false);
                    }}
                  >
                    Add Row Before
                  </button>
                  <button
                    className='w-full text-left px-4 py-2 text-sm hover:bg-base-100'
                    onMouseDown={e => {
                      e.preventDefault();
                      editor.chain().focus().addRowAfter().run();
                      setShowTableMenu(false);
                    }}
                  >
                    Add Row After
                  </button>
                </div>
              </div>
            )}
          </div>
          {/* Undo last change */}
          <button
            onMouseDown={e => {
              e.preventDefault();
              editor.chain().focus().undo().run();
            }}
            className='p-2 rounded hover:bg-base-200'
            title='Undo'
          >
            <Undo size={18} />
          </button>
          {/* Redo last undone change */}
          <button
            onMouseDown={e => {
              e.preventDefault();
              editor.chain().focus().redo().run();
            }}
            className='p-2 rounded hover:bg-base-200'
            title='Redo'
          >
            <Redo size={18} />
          </button>
        </div>
      </div>
    );
  };

  // Update ai request handlers to use context
  const handleAiAction = async (template, selectedText, customPrompt) => {
    if (!handleAiRequest) return;

    try {
      editor.commands.createAiNode({
        originalContent: selectedText,
        content: '',
        status: 'starting'
      });

      const response = await handleAiRequest({
        template,
        selectedText,
        customPrompt
      });

      if (!response.ok) {
        throw new Error('Failed to fetch AI response');
      }

      const reader = response.body.getReader();
      let accumulatedText = '';

      while (true) {
        const { done, value } = await reader.read();
        if (done) {
          editor.commands.updateAiContent({
            originalContent: selectedText,
            content: accumulatedText,
            status: 'completed'
          });
          break;
        }

        const decodedChunk = new TextDecoder().decode(value, { stream: true });
        const lines = decodedChunk.split('\n');

        for (const line of lines) {
          if (line.trim() === '' || line === 'data: [DONE]') continue;

          try {
            const parsedLine = JSON.parse(line.replace('data: ', ''));
            if (parsedLine.choices[0]?.delta?.content) {
              accumulatedText += parsedLine.choices[0].delta.content;
              editor.commands.updateAiContent({
                content: accumulatedText,
                status: 'running'
              });
            }
          } catch (e) {
            console.error('Failed to parse chunk:', e);
          }
        }
      }
    } catch (error) {
      console.error('Error during AI request:', error);
      editor.commands.rejectAiContent();
    }
  };

  const percentage =
    characterLimit !== undefined && editor
      ? Math.round(
          (100 / characterLimit) * editor.storage.characterCount.characters()
        )
      : 0;

  return (
    <div
      className={`flex flex-col border border-base-300 rounded-md overflow-hidden ${className}`}
    >
      <DragHandle editor={editor}>
        <GripVertical
          size={16}
          className='items-center cursor-grab flex -mr-1 my-1 text-center justify-center text-base-500'
        />
      </DragHandle>
      <MenuBar />
      <EditorContent editor={editor} className='overflow-y-auto flex-1 p-6' />
      {characterLimit && (
        <div
          className={`p-6 mt-4 character-count text-xs items-center gap-2 flex ${
            editor.storage.characterCount.characters() === characterLimit
              ? 'character-count--warning'
              : ''
          }`}
        >
          <svg
            height='20'
            width='20'
            viewBox='0 0 20 20'
            className='text-primary-700'
          >
            <circle r='10' cx='10' cy='10' fill='#e9ecef' />
            <circle
              r='5'
              cx='10'
              cy='10'
              fill='transparent'
              stroke='currentColor'
              strokeWidth='10'
              strokeDasharray={`calc(${percentage} * 31.4 / 100) 31.4`}
              transform='rotate(-90) translate(-20)'
            />
            <circle r='6' cx='10' cy='10' fill='white' />
          </svg>
          {editor.storage.characterCount.characters()} / {characterLimit}{' '}
          characters
          <br />
          {editor.storage.characterCount.words()} words
        </div>
      )}

      {editor && (
        <>
          <FloatingMenu editor={editor} tippyOptions={{ duration: 100 }}>
            <div className='relative shadow-xl bg-white border border-base-200/50 p-0.5 left-32 rounded-lg flex flex-row items-center justify-start backdrop-blur-sm'>
              <button
                onClick={() => setShowAiBar(!showAiBar)}
                className='p-2 rounded-lg hover:bg-base-100 transition-colors duration-200'
                title='AI Assistant'
              >
                <img
                  src='/+Ai_Logo.svg'
                  alt='AI Assistant'
                  className='w-5 h-5 opacity-90 hover:opacity-100'
                />
              </button>
              <div
                className={`transition-all duration-300 ease-in-out overflow-hidden ${
                  showAiBar ? 'w-96' : 'w-0'
                }`}
              >
                <div className='flex gap-3 px-2'>
                  <Input
                    className='max-w-64'
                    placeholder='Create with the ai assistant...'
                    size='sm'
                    value={describeText}
                    onChange={e => setDescribeText(e.target.value)}
                  />
                  <FilledButton
                    colour='primary'
                    size='md'
                    onClick={() => {
                      handleAiAction(24, null, describeText);
                      setDescribeText('');
                    }}
                  >
                    <ArrowRight size={18} className='stroke-[2.5]' />
                  </FilledButton>
                </div>
              </div>
            </div>
          </FloatingMenu>
          <BubbleMenu editor={editor} tippyOptions={{ duration: 100 }}>
            <div
              className={`shadow-lg bg-white border p-0.5 rounded-lg transition-all duration-300 ease-in-out ${
                showAiBar ? 'w-72' : 'w-10'
              } overflow-hidden`}
            >
              <div
                className={`flex transition-opacity duration-300 ${
                  showAiBar ? 'opacity-0 h-0' : 'opacity-100'
                }`}
              >
                <button
                  onClick={() => setShowAiBar(true)}
                  className='p-2 rounded-md hover:bg-base-200'
                  title='AI Assistant'
                >
                  <img
                    src='/+Ai_Logo.svg'
                    alt='AI Assistant'
                    className='w-[18px] h-[18px]'
                  />
                </button>
              </div>
              <div
                className={`transition-opacity duration-300 ${
                  showAiBar ? 'opacity-100' : 'opacity-0 h-0'
                }`}
              >
                <div className='flex flex-col gap-2 p-2'>
                  <div className='flex justify-between items-center'>
                    <div className='flex items-center gap-2'>
                      <img
                        src='/+Ai_Logo.svg'
                        alt='AI Assistant'
                        className='w-8 h8'
                      />
                      <span className='font-medium'>Assistant</span>
                    </div>
                    <button
                      onClick={() => setShowAiBar(false)}
                      className='p-1 rounded-md hover:bg-base-200'
                    >
                      <X size={16} />
                    </button>
                  </div>
                  <div className='flex flex-col gap-2'>
                    {showDescribeInput ? (
                      <>
                        <div className='flex items-center gap-2 mb-2'>
                          <FlatButton
                            onClick={() => setShowDescribeInput(false)}
                            colour='primary'
                            size='sm'
                          >
                            <ArrowLeft size={16} />
                          </FlatButton>
                          <span className='font-regular text-sm'>
                            Suggest Edits
                          </span>
                        </div>
                        <textarea
                          className={`block w-full min-w-0 border-0 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 px-2.5 py-1.5 text-sm sm:leading-6 rounded-md resize-none h-24`}
                          placeholder='Describe what changes you want to make...'
                          value={describeText}
                          onChange={e => setDescribeText(e.target.value)}
                        />
                        <FilledButton
                          className='w-full justify-center items-center'
                          leftIcon={<Sparkles />}
                          onClick={() => {
                            const { view, state } = editor;
                            const { from, to } = view.state.selection;
                            const text = state.doc.textBetween(from, to, '');
                            handleAiAction(21, text, describeText);
                            setDescribeText('');
                            setShowDescribeInput(false);
                          }}
                        >
                          Generate
                        </FilledButton>
                      </>
                    ) : (
                      <>
                        <div className='flex-row w-full justify-center'>
                          <SoftButton
                            className='w-full justify-center items-center'
                            leftIcon={<Sparkles />}
                            onClick={() => setShowDescribeInput(true)}
                          >
                            Suggest edits
                          </SoftButton>
                        </div>
                        <div className='flex-row gap-2 justify-evenly mt-2'>
                          <div className='flex gap-2'>
                            <SoftButton
                              className='w-full justify-center items-center'
                              onClick={() => {
                                const { view, state } = editor;
                                const { from, to } = view.state.selection;
                                const text = state.doc.textBetween(
                                  from,
                                  to,
                                  ''
                                );
                                handleAiAction(22, text);
                                //console.log(text);
                              }}
                              leftIcon={<TextSearch />}
                            >
                              Proof Read
                            </SoftButton>
                          </div>
                        </div>
                        <div className='flex-col gap-2'>
                          <FlatButton
                            onClick={() => {
                              const { view, state } = editor;
                              const { from, to } = view.state.selection;
                              const text = state.doc.textBetween(from, to, '');
                              handleAiAction(18, text);
                              //console.log(text);
                            }}
                            className='w-full'
                            leftIcon={<Smile />}
                          >
                            Friendly
                          </FlatButton>
                          <FlatButton
                            onClick={() => {
                              const { view, state } = editor;
                              const { from, to } = view.state.selection;
                              const text = state.doc.textBetween(from, to, '');
                              handleAiAction(16, text);
                              //console.log(text);
                            }}
                            className='w-full'
                            leftIcon={<Briefcase />}
                          >
                            Professional
                          </FlatButton>
                          <FlatButton
                            onClick={() => {
                              const { view, state } = editor;
                              const { from, to } = view.state.selection;
                              const text = state.doc.textBetween(from, to, '');
                              handleAiAction(19, text);
                              //console.log(text);
                            }}
                            className='w-full'
                            leftIcon={<FoldVertical />}
                          >
                            Concise
                          </FlatButton>
                          <hr className='my-2 border-base-200' />
                          <FlatButton
                            onClick={() => {
                              const { view, state } = editor;
                              const { from, to } = view.state.selection;
                              const text = state.doc.textBetween(from, to, '');
                              handleAiAction(17, text);
                              //console.log(text);
                            }}
                            className='w-full'
                            leftIcon={<Type />}
                          >
                            Summary
                          </FlatButton>
                          <FlatButton
                            onClick={() => {
                              const { view, state } = editor;
                              const { from, to } = view.state.selection;
                              const text = state.doc.textBetween(from, to, '');
                              handleAiAction(20, text);
                              //console.log(text);
                            }}
                            className='w-full'
                            leftIcon={<List />}
                          >
                            Key Points
                          </FlatButton>
                          {/* <FlatButton
                          onClick={() => {
                            const { view, state } = editor;
                            const { from, to } = view.state.selection;
                            const text = state.doc.textBetween(from, to, '');
                            onAiRequest({ template: 23, selectedText: text });
                            //console.log(text);
                          }}
                          className='w-full'
                          leftIcon={<FileCode />}
                        >
                          Table
                        </FlatButton> */}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </BubbleMenu>
        </>
      )}

      <Modal
        isOpen={showVideoModal}
        onClose={() => setShowVideoModal(false)}
        title='Add Video URL'
        style='primary'
        actions={[
          {
            label: 'Insert Video',
            onClick: addVideo,
            style: 'primary'
          },
          {
            label: 'Cancel',
            onClick: () => setShowVideoModal(false),
            style: 'base'
          }
        ]}
      >
        <Input
          value={videoUrl}
          onChange={e => setVideoUrl(e.target.value)}
          placeholder='Enter video URL'
          autoFocus
        />
      </Modal>

      <Modal
        isOpen={showImageModal}
        onClose={() => setShowImageModal(false)}
        title='Add Image URL'
        style='primary'
        actions={[
          {
            label: 'Insert Image',
            onClick: addImage,
            style: 'primary'
          },
          {
            label: 'Cancel',
            onClick: () => setShowImageModal(false),
            style: 'base'
          }
        ]}
      >
        <Input
          value={imageUrl}
          onChange={e => setImageUrl(e.target.value)}
          placeholder='Enter image URL'
          autoFocus
        />
      </Modal>
    </div>
  );
};

export { TextEditor };
