import React, { useState } from 'react';
import { Search } from 'lucide-react';
import { Badge, Input, MultiSelectBox } from '@core/components';

const StageOne = ({ templates, categories, onTemplateSelect, onNewSMS }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedCategory, setSelectedCategory] = useState(null);

  return (
    <>
      <div className='mb-6 flex items-center space-x-4'>
        <div className='relative flex-grow'>
          <div className='absolute inset-y-0 left-0 pl-2 flex items-center'>
            <Search className='h-4 w-4 text-gray-400' />
          </div>
          <Input
            id='templateSearch'
            name='templateSearch'
            type='text'
            placeholder='Search templates...'
            className='bg-white text-gray-500 pl-8 pr-8 w-full'
            value={searchQuery}
            onChange={e => setSearchQuery(e.target.value)}
          />
        </div>
        <div className='w-64'>
          <MultiSelectBox
            options={categories}
            selectedOptions={selectedCategory ? [selectedCategory] : []}
            onChange={selected => setSelectedCategory(selected[0])}
            placeholder='Filter by category'
            isClearable={true}
          />
        </div>
      </div>
      <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6'>
        {templates
          .filter(template =>
            template.name.toLowerCase().includes(searchQuery.toLowerCase())
          )
          .filter(template =>
            selectedCategory
              ? template.categories.some(
                  cat => cat.id === selectedCategory.value
                )
              : true
          )
          .map(template => (
            <div
              key={template.id}
              className='border border-neutral-200 p-6 rounded-lg shadow-sm cursor-pointer hover:shadow-md transition-shadow duration-200 ease-in-out bg-white'
              onClick={() => onTemplateSelect(template)}
            >
              <h3 className='font-semibold text-lg mb-2 text-neutral-800'>
                {template.name}
              </h3>
              <p className='text-sm text-neutral-600 mb-4'>
                {template.message}
              </p>
              <div className='flex flex-wrap gap-2'>
                {template.categories.map(category => (
                  <Badge key={category.id} colour='primary' size='sm'>
                    {category.name}
                  </Badge>
                ))}
              </div>
            </div>
          ))}
        <div
          className={`border border-dashed border-neutral-300 p-6 rounded-lg cursor-pointer transition-colors duration-200 ease-in-out flex items-center justify-center ${
            templates.some(template => template.default)
              ? 'hover:border-brand-500 hover:bg-brand-50'
              : 'opacity-50 cursor-not-allowed'
          }`}
          onClick={onNewSMS}
          title={
            templates.some(template => template.default)
              ? 'Create new SMS'
              : 'No default template available'
          }
        >
          <div className='flex flex-col items-center justify-center'>
            <span className='text-lg font-semibold text-brand-600'>
              + New SMS
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default StageOne;
