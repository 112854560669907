import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeft, ArrowRight } from 'lucide-react';
import Company from '../../../models/Company';
import { FilledButton, SoftButton } from '@core/components';
import { UserProfileContext } from '../../../App';
import BasicDetails from './create/BasicDetails';
import AdditionalDetails from './create/AdditionalDetails';

const CreateCompany = () => {
  const navigate = useNavigate();
  const { userProfile } = useContext(UserProfileContext);

  const [stage, setStage] = useState(1);
  const [companyData, setCompanyData] = useState({
    name: '',
    companyId: '',
    owner: userProfile,
    emails: [],
    phones: [],
    previousNames: [],
    status: 78
  });

  const handleInputChange = (field, value) => {
    setCompanyData(prev => {
      if (field.includes('.')) {
        const [parent, child] = field.split('.');
        return {
          ...prev,
          [parent]: {
            ...prev[parent],
            [child]: value
          }
        };
      }
      return { ...prev, [field]: value };
    });
  };

  const handleSubmit = async (navigateToCompany = false) => {
    try {
      const newCompany = new Company({
        name: companyData.name,
        status: companyData.status || 78,
        number: companyData.companyId,
        emails: companyData.emails,
        phones: companyData.phones,
        previous_names: companyData.previousNames
      });

      const insertedCompany = await newCompany.insert();
      navigate(
        navigateToCompany ? `/companies/${insertedCompany.id}` : '/companies'
      );
    } catch (error) {
      console.error('Error creating company:', error);
    }
  };

  const handleNavigation = direction => {
    if (direction === 'back') {
      stage === 1 ? navigate('/companies') : setStage(prev => prev - 1);
    } else {
      setStage(prev => prev + 1);
    }
  };

  const stages = [
    {
      title: 'Basic Details',
      render: () => (
        <BasicDetails
          companyData={companyData}
          handleInputChange={handleInputChange}
        />
      )
    },
    {
      title: 'Additional Details',
      render: () => (
        <AdditionalDetails
          companyData={companyData}
          handleInputChange={handleInputChange}
        />
      )
    }
  ];

  const isFirstStageValid = () => {
    return companyData?.name?.trim() && companyData?.companyId?.trim();
  };

  return (
    <div className='bg-base-100 h-full overflow-y-auto'>
      <div className='max-w-4xl mx-auto px-4 py-8'>
        <SoftButton
          colour='primary'
          size='md'
          leftIcon={<ArrowLeft size={18} />}
          onClick={() => navigate('/companies')}
          className='mb-8'
        >
          Back to Companies
        </SoftButton>

        <div className='bg-white shadow-md rounded-lg overflow-visible'>
          <div className='px-6 py-4'>
            <h2 className='text-2xl font-bold'>Create New Company</h2>
          </div>

          <div className='p-6'>
            <div className='mb-8'>
              <div className='flex justify-between'>
                {stages.map((step, index) => (
                  <div
                    key={index}
                    className={`flex items-center ${
                      index < stage ? 'text-brand-700' : 'text-base-400'
                    }`}
                  >
                    <div
                      className={`w-8 h-8 rounded-full flex items-center justify-center ${
                        index < stage
                          ? 'bg-brand-700 text-white'
                          : 'bg-base-200'
                      }`}
                    >
                      {index + 1}
                    </div>
                    <span className='ml-2 text-sm font-medium'>
                      {step.title}
                    </span>
                  </div>
                ))}
              </div>
              <div className='mt-4 h-2 bg-base-200 rounded-full'>
                <div
                  className='h-full bg-brand-700 rounded-full transition-all duration-300 ease-in-out'
                  style={{ width: `${(stage / stages.length) * 100}%` }}
                ></div>
              </div>
            </div>

            {stages[stage - 1].render()}

            <div className='mt-8 flex justify-between'>
              <SoftButton
                colour='primary'
                size='lg'
                leftIcon={<ArrowLeft size={18} />}
                onClick={() => handleNavigation('back')}
                disabled={stage === 1}
              >
                Previous
              </SoftButton>
              {stage < stages.length ? (
                <FilledButton
                  colour='primary'
                  size='lg'
                  rightIcon={<ArrowRight size={18} />}
                  onClick={() => handleNavigation('next')}
                  disabled={stage === 1 && !isFirstStageValid()}
                >
                  Next
                </FilledButton>
              ) : (
                <div className='flex flex-row space-x-4'>
                  <FilledButton
                    colour='base'
                    size='lg'
                    onClick={() => handleSubmit(false)}
                  >
                    Finish
                  </FilledButton>
                  <FilledButton
                    colour='primary'
                    size='lg'
                    onClick={() => handleSubmit(true)}
                  >
                    Finish and Open
                  </FilledButton>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateCompany;
