import React, { useState, useEffect } from 'react';
import { Search } from 'lucide-react';
import { Badge, Input, MultiSelectBox } from '@core/components';

const StageOne = ({
  templates,
  categories,
  defaultCategories,
  onTemplateSelect,
  onNewEmail
}) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedCategory, setSelectedCategory] = useState(null);

  useEffect(() => {
    if (defaultCategories?.length > 0 && categories?.length > 0) {
      const defaultCategory = categories.find(
        cat => cat.value === defaultCategories[0]
      );
      if (defaultCategory) {
        setSelectedCategory(defaultCategory);
      }
    }
  }, [defaultCategories, categories]);

  return (
    <>
      <div className='mb-6 flex items-center space-x-4'>
        <div className='relative flex-grow'>
          <div className='absolute inset-y-0 left-0 pl-2 flex items-center'>
            <Search className='h-4 w-4 text-gray-400' />
          </div>
          <Input
            id='templateSearch'
            name='templateSearch'
            type='text'
            placeholder='Search templates...'
            className='bg-white text-gray-500 pl-8 pr-8 w-full'
            value={searchQuery}
            onChange={e => setSearchQuery(e.target.value)}
          />
        </div>
        <div className='w-64'>
          <MultiSelectBox
            loadOptions={() => categories}
            selectedOptions={selectedCategory ? [selectedCategory] : []}
            onChange={selected => setSelectedCategory(selected[0])}
            placeholder='Filter by category'
            colour='primary'
          />
        </div>
      </div>
      <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6'>
        {templates
          .filter(template =>
            template.name.toLowerCase().includes(searchQuery.toLowerCase())
          )
          .filter(template =>
            selectedCategory
              ? template.categories.some(
                  cat => cat.id === selectedCategory.value
                )
              : true
          )
          .map(template => (
            <div
              key={template.id}
              className='border border-base-200 p-4 rounded-lg shadow-sm cursor-pointer hover:shadow-md transition-shadow duration-200 ease-in-out bg-white'
              onClick={() => onTemplateSelect(template)}
            >
              <h4 className='font-semibold text-md mb-2 text-base-800'>
                {template.name}
              </h4>
              <p className='text-sm text-base-600 mb-4'>{template.subject}</p>
              <div className='flex flex-wrap gap-2'>
                {template.categories.map(category => (
                  <Badge key={category.id} colour='primary' size='sm'>
                    {category.name}
                  </Badge>
                ))}
              </div>
            </div>
          ))}
        <div
          className={`border border-dashed border-neutral-300 p-6 rounded-lg cursor-pointer transition-colors duration-200 ease-in-out flex items-center justify-center ${
            templates.some(template => template.default)
              ? 'hover:border-brand-500 hover:bg-brand-50'
              : 'opacity-50 cursor-not-allowed'
          }`}
          onClick={onNewEmail}
          title={
            templates.some(template => template.default)
              ? 'Create new email'
              : 'No default template available'
          }
        >
          <div className='flex flex-col items-center justify-center'>
            <span className='text-lg font-semibold text-brand-600'>
              + New Email
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default StageOne;
