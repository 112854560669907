import BaseModel from './BaseModel';

/**
 * Represents a Scheme in the system.
 * @extends BaseModel
 */
class Scheme extends BaseModel {
  /** @type {string} The name of the database table for Schemes */
  static table = 'schemes';
  /** @type {string} The SQL query to select Scheme data */
  static selectQuery =
    '*, status:core_entity_types!inner(*), provider:providers(*)';

  /**
   * Creates an instance of Scheme.
   * @param {Object} data - The scheme data.
   */
  constructor(data = {}) {
    super(data);
    this.name = data.name || null;
    this.previousNames = data.previous_names || [];
    this.status = data.status || null;
    this.emails = data.emails || [];
    this.phones = data.phones || [];
    this.signatures = data.signatures || {
      wet: true,
      digital: true,
      docusign: false
    };
    this.createdDate = data.created_date || new Date();
    this.updatedDate = data.updated_date || new Date();
    this.provider = data.provider || null;
  }

  /**
   * Converts the Scheme instance to a database-friendly format.
   * @returns {Object} The scheme data ready for database operations.
   */
  toDatabase() {
    return {
      ...super.toDatabase(),
      name: this.name,
      previous_names: this.previousNames,
      status: typeof this.status === 'object' ? this.status?.id : this.status,
      emails: this.emails,
      phones: this.phones,
      signatures: this.signatures,
      created_date: this.createdDate,
      updated_date: this.updatedDate,
      provider:
        typeof this.provider === 'object' ? this.provider?.id : this.provider
    };
  }

  /**
   * Get a record by its ID.
   * @param {string} id - The ID of the record to fetch.
   * @returns {Promise<Scheme>} A promise that resolves to a new instance of the model.
   */
  static async getById(id) {
    return this.fetchOne('id', id, this.selectQuery);
  }
}

export default Scheme;
