import React, { useState, useEffect } from 'react';
import {
  FilledButton,
  SoftButton,
  Popup,
  Input,
  TextButton,
  TextSkeleton
} from '@core/components';
import { supabase } from '../../../utilities/supabase';

const ViewAudience = ({
  isOpen,
  onClose,
  entityId,
  entityType,
  setRefresh
}) => {
  const [audiences, setAudiences] = useState([]);
  const [joinedAudiences, setJoinedAudiences] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isCreating, setIsCreating] = useState(false);
  const [newAudienceName, setNewAudienceName] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchAudiences();
    fetchJoinedAudiences();
  }, []);

  const fetchAudiences = async () => {
    try {
      const { data, error } = await supabase.functions.invoke('audience/view');

      if (error) throw error;

      console.log(data);

      setAudiences(data);
    } catch (error) {
      console.error('Error fetching audiences:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchJoinedAudiences = async () => {
    try {
      const { data, error } = await supabase.functions.invoke(
        'audience/search',
        {
          body: { reference: `${entityType}:${entityId}` }
        }
      );

      if (error) throw error;

      console.log(data);

      // Extract the audiences from the returned data structure
      const extractedAudiences = data.map(item => item.audiences);
      setJoinedAudiences(extractedAudiences);
    } catch (error) {
      console.error('Error fetching joined audiences:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleCreateNewAudience = async () => {
    setLoading(true);
    try {
      const { error } = await supabase.functions.invoke('audience/create', {
        body: { name: newAudienceName }
      });

      if (error) throw error;

      setNewAudienceName('');
      setIsCreating(false);
      fetchAudiences();
    } catch (error) {
      console.error('Error creating new audience:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleAddAudience = async audienceId => {
    try {
      setJoinedAudiences(prev => [
        ...prev,
        audiences.find(a => a.id === audienceId)
      ]);
      const { error } = await supabase.functions.invoke('audience/add', {
        body: { entityId, entityType, audienceId }
      });

      if (error) throw error;

      console.log('Audience added successfully');
      setRefresh('tab,icon');
      fetchJoinedAudiences();
    } catch (error) {
      console.error('Error adding audience:', error);
    } finally {
      setLoading(false);
    }
  };

  const filteredAudiences = audiences
    .filter(
      audience => !joinedAudiences.some(joined => joined.id === audience.id)
    )
    .filter(audience =>
      audience.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

  return (
    <Popup isOpen={isOpen} onClose={onClose} title='Audience' size='md'>
      {isCreating ? (
        <div className='mb-4'>
          <Input
            type='text'
            placeholder='Enter audience name...'
            value={newAudienceName}
            onChange={e => setNewAudienceName(e.target.value)}
          />
          <div className='flex justify-end mt-2'>
            <FilledButton onClick={handleCreateNewAudience}>Save</FilledButton>
            <TextButton onClick={() => setIsCreating(false)} className='ml-2'>
              Cancel
            </TextButton>
          </div>
        </div>
      ) : (
        <>
          <div className='mb-4'>
            <Input
              type='text'
              placeholder='Search audiences...'
              value={searchTerm}
              onChange={e => setSearchTerm(e.target.value)}
            />
          </div>
          {loading ? (
            <div className='grid grid-cols-2 gap-4 mb-4'>
              {[...Array(4)].map((_, index) => (
                <div
                  key={index}
                  className='p-4 border rounded-lg transition-colors duration-300 bg-gray-200 animate-pulse'
                >
                  <div className='flex flex-col space-y-2'>
                    <div className='w-3/4 h-4 bg-gray-300 rounded'></div>
                    <div className='w-1/2 h-4 bg-gray-300 rounded'></div>
                  </div>
                </div>
              ))}
            </div>
          ) : filteredAudiences.length === 0 ? (
            <p className='text-sm text-gray-500'>No audiences available.</p>
          ) : (
            <div className='grid grid-cols-2 gap-4 mh-[56vh] overflow-y-auto'>
              {filteredAudiences.map(audience => (
                <div
                  key={audience.id}
                  className='p-4 border rounded-lg transition-colors duration-300 bg-white border-gray-200 hover:bg-gray-50'
                >
                  <div className='flex items-center justify-between'>
                    <div className='flex flex-col'>
                      <span className='font-medium text-lg'>
                        {audience.name}
                      </span>
                      <span className='text-sm text-gray-500'>
                        {audience.description}
                      </span>
                    </div>
                    <SoftButton
                      onClick={() => handleAddAudience(audience.id)}
                      className='ml-2'
                    >
                      Add
                    </SoftButton>
                  </div>
                </div>
              ))}
            </div>
          )}
          <div className='flex justify-between mt-8'>
            <SoftButton onClick={() => setIsCreating(true)}>
              Create Audience
            </SoftButton>
            <FilledButton onClick={() => onClose()}>Close</FilledButton>
          </div>
        </>
      )}
    </Popup>
  );
};

export default ViewAudience;
