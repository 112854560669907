import React, { useState } from 'react';
import { Popup, FilledButton } from '@core/components';
import Task from '../../../models/Task';
import Details from './Details';

const CreateTask = ({ onClose, entityType, entityId, parentTask }) => {
  const [editedTask, setEditedTask] = useState({
    title: null,
    description: null,
    category: null,
    status: { id: '55' },
    priority: null,
    assignees: [],
    followers: [],
    entityType: entityType,
    entityId: entityId,
    dueDate: null,
    parent: parentTask ? parentTask.id : null,
    order: null
  });

  const handleSubmit = async () => {
    try {
      const taskData = {
        ...editedTask,
        assignees: editedTask.assignees.map(assignee => assignee.value),
        entity_type: entityType,
        entity_id: entityId
      };
      const newTask = new Task(taskData);
      await newTask.insert();
      onClose(newTask);
    } catch (error) {
      console.error('[Error] Creating Task: ', error);
      // Handle error (e.g., show error message to user)
    }
  };

  return (
    <Popup
      isOpen={true}
      onClose={onClose}
      title={`Create New ${
        parentTask ? `Child Task (Parent #${parentTask.id})` : 'Task'
      }`}
    >
      <div className='space-y-4'>
        <Details editedTask={editedTask} setEditedTask={setEditedTask} />
        <div className='flex justify-end space-x-2'>
          <FilledButton
            onClick={handleSubmit}
            colour='primary'
            disabled={!editedTask?.title?.trim()}
          >
            Create
          </FilledButton>
        </div>
      </div>
    </Popup>
  );
};

export default CreateTask;
