import React, { useState, useEffect, useCallback } from 'react';
import { ComboBox, Popup, FilledButton, OutlineButton } from '@core/components';
import { supabase } from '../../../utilities/supabase';
import CoreEntity from '../../../models/CoreEntity';

const AddPipelineItem = ({
  pipelineId,
  pipelineGroup = 1,
  onClose,
  onItemAdded
}) => {
  const [selectedEntity, setSelectedEntity] = useState(null);
  const [pipelineDetails, setPipelineDetails] = useState(null);
  const [entityDetails, setEntityDetails] = useState(null);

  useEffect(() => {
    const fetchPipelineDetails = async () => {
      const { data, error } = await supabase
        .from('pipelines')
        .select('*')
        .eq('id', pipelineId)
        .single();
      if (error) console.error('Error fetching pipeline details:', error);
      else setPipelineDetails(data);
    };

    fetchPipelineDetails();
  }, [pipelineId]);

  useEffect(() => {
    if (pipelineDetails?.entity) {
      const fetchEntityDetails = async () => {
        const entity = await CoreEntity.getById(pipelineDetails.entity);
        setEntityDetails(entity);
      };

      fetchEntityDetails();
    }
  }, [pipelineDetails]);

  const searchEntities = useCallback(async query => {
    if (entityDetails?.tableName && query.length >= 1) {
      const { data, error } = await supabase
        .from(entityDetails.tableName)
        .select(pipelineDetails.columns)
        .textSearch('fts', query.replaceAll(' ', '+').concat('', ':*'))
        .limit(10);
      if (error) {
        console.error('Error searching entities:', error);
        return [];
      }
      return data.map(entity => {
        let label;
        if (entityDetails.metadata?.pipelines.label_template) {
          // Replace placeholders in template with actual values
          label = entityDetails.metadata?.pipelines.label_template.replace(
            /\{(\w+)\}/g,
            (match, field) => {
              return entity[field] || '';
            }
          );
        } else {
          // Fallback to a default behavior
          label =
            entity.name ||
            entity.full_name ||
            `${entityDetails.displayName} #${entity.id}`;
        }

        return {
          value: entity.id,
          entityType: entityDetails.id,
          label: label.trim()
        };
      });
    }
    return [];
  });

  const handleSubmit = async e => {
    e.preventDefault();
    if (selectedEntity) {
      try {
        // Get the current highest order for this pipeline group
        const { data: currentItems, error: orderError } = await supabase
          .from('pipeline_entities')
          .select('order')
          .eq('pipeline_id', pipelineId)
          .eq('group', pipelineGroup)
          .order('order', { ascending: false })
          .limit(1);

        if (orderError) throw orderError;

        const nextOrder =
          currentItems.length > 0 ? currentItems[0].order + 1 : 0;

        // Insert new record into pipeline_entities join table
        const { error: insertError } = await supabase
          .from('pipeline_entities')
          .insert({
            pipeline_id: pipelineId,
            entity_id: selectedEntity.value,
            entity_type: selectedEntity.entityType,
            group: pipelineGroup,
            order: nextOrder
          });

        if (insertError) throw insertError;

        onItemAdded();
        onClose();
      } catch (error) {
        console.error('Error adding item to pipeline:', error);
        // Handle the error appropriately (e.g., show an error message to the user)
      }
    }
  };

  return (
    <Popup isOpen={true} onClose={onClose} title='Add Pipeline Item' size='sm'>
      <form onSubmit={handleSubmit}>
        <ComboBox
          loadOptions={searchEntities}
          value={selectedEntity}
          onChange={selected => {
            setSelectedEntity(selected);
          }}
          placeholder='Search entities...'
          label={`Select a ${entityDetails?.displayName || 'Entity'}`}
        />
        <div className='mt-4 flex justify-end'>
          <OutlineButton type='button' onClick={onClose} className='mr-2'>
            Cancel
          </OutlineButton>
          <FilledButton type='submit' disabled={!selectedEntity}>
            Add
          </FilledButton>
        </div>
      </form>
    </Popup>
  );
};

export default AddPipelineItem;
