import React, { useState, useEffect } from 'react';
import { SquareArrowOutUpRight, Eye, SearchIcon, Building } from 'lucide-react';
import EntityTable from '../../../../components/tables/EntityTable';
import {
  EntityTypeEnum,
  FilterOperatorEnum
} from '../../../../utilities/Enumerables';
import { Modal, SoftButton } from '@core/components';
import Trace from '../../../../models/Trace';
import CoreEntityType from '../../../../models/CoreEntityType';
import { formatDate, formatProfile } from '../../../../utilities/Formatting';
import { Link, useNavigate } from 'react-router-dom';

const ProviderTraces = ({ providerId }) => {
  const navigate = useNavigate();
  const [statusOptions, setStatusOptions] = useState([]);
  const [isCompaniesModalOpen, setIsCompaniesModalOpen] = useState(false);
  const [isSearchesModalOpen, setIsSearchesModalOpen] = useState(false);
  const [companiesList, setCompaniesList] = useState([]);
  const [searchesList, setSearchesList] = useState([]);

  useEffect(() => {
    const fetchStatusOptions = async () => {
      try {
        const statuses = await CoreEntityType.getByEntityId(
          EntityTypeEnum.TraceStatus
        );
        setStatusOptions(
          statuses.map(status => ({ value: status.type, key: status.id }))
        );
      } catch (error) {
        console.error('Error fetching status options:', error);
      }
    };

    fetchStatusOptions();
  }, []);

  const menuItems = [
    {
      label: 'Open in new tab',
      icon: <SquareArrowOutUpRight size={14} />,
      onClick: data => window.open(`/traces/${data.displayId}`, '_blank')
    }
  ];

  return (
    <>
      <EntityTable
        name='Traces'
        customId={`provider:${providerId}:traces`}
        fromFunction={true}
        filter={[
          { field: 'display_id', label: 'ID', type: 'text' },
          {
            field: 'name',
            label: 'Name',
            type: 'text',
            operator: FilterOperatorEnum.ILIKE
          },
          {
            field: 'p_first_name',
            label: 'Owner First Name',
            type: 'text',
            operator: FilterOperatorEnum.ILIKE
          },
          {
            field: 'p_last_name',
            label: 'Owner Last Name',
            type: 'text',
            operator: FilterOperatorEnum.ILIKE
          },
          {
            field: 'p_status',
            label: 'Status',
            type: 'select',
            options: statusOptions
          },
          {
            field: 'p_policy_number',
            label: 'Policy Number',
            type: 'text',
            operator: FilterOperatorEnum.ILIKE
          },
          {
            field: 'p_start_year',
            label: 'Start Year',
            type: 'number'
          },
          {
            field: 'p_end_year',
            label: 'End Year',
            type: 'number'
          },
          {
            field: 'created_date',
            label: 'Created Date',
            type: 'daterange',
            operator: FilterOperatorEnum.BETWEEN
          },
          {
            field: 'updated_date',
            label: 'Updated Date',
            type: 'daterange',
            operator: FilterOperatorEnum.BETWEEN
          },
          {
            field: 'showArchived',
            label: 'Show Archived',
            hint: 'Show archived traces',
            type: 'conditional_toggle',
            operator: FilterOperatorEnum.IS,
            options: {
              value: 'true'
            }
          }
        ]}
        columns={[
          {
            label: 'ID',
            field: 'displayId',
            foreignKey: { column: 'display_id' },
            cell: value => (
              <Link
                to={`/traces/${value}`}
                className='text-brand-700 hover:text-brand-800'
              >
                {value}
              </Link>
            )
          },
          { label: 'Name', field: 'name' },
          {
            label: 'Owner',
            field: 'owner',
            cell: value => formatProfile(value, 'sm')
          },
          {
            label: 'Status',
            field: 'status',
            cell: value => <span>{value.type}</span>
          },
          {
            label: 'Policy Number',
            field: 'metadata',
            sortable: false,
            cell: value => (value.policy ? value.policy : 'N/A')
          },
          {
            label: 'Start Year',
            field: 'metadata',
            sortable: false,
            cell: value => (value.start_year ? value.start_year : 'N/A')
          },
          {
            label: 'End Year',
            field: 'metadata',
            sortable: false,
            cell: value => (value.end_year ? value.end_year : 'N/A')
          },
          {
            label: 'Companies',
            field: 'companies',
            sortable: false,
            cell: companies => (
              <div className='flex items-center gap-2'>
                <span>{companies ? companies.length : 0}</span>
                {companies && companies.length > 0 && (
                  <SoftButton
                    onClick={e => {
                      e.stopPropagation();
                      setCompaniesList(companies);
                      setIsCompaniesModalOpen(true);
                    }}
                  >
                    <Building size={16} />
                  </SoftButton>
                )}
              </div>
            )
          },
          {
            label: 'Searches',
            field: 'searches',
            sortable: false,
            cell: searches => (
              <div className='flex items-center gap-2'>
                <span>{searches ? searches.length : 0}</span>
                {searches && searches.length > 0 && (
                  <SoftButton
                    onClick={e => {
                      e.stopPropagation();
                      setSearchesList(searches);
                      setIsSearchesModalOpen(true);
                    }}
                  >
                    <SearchIcon size={16} />
                  </SoftButton>
                )}
              </div>
            )
          },
          {
            label: 'Created Date',
            field: 'createdDate',
            cell: value => formatDate(value)
          },
          {
            label: 'Updated Date',
            field: 'updatedDate',
            cell: value => formatDate(value)
          }
        ]}
        menuItems={menuItems}
        model={Trace}
        entityType={EntityTypeEnum.Trace}
        defaultFilters={{
          p_provider: {
            value: providerId
          },
          p_status: { value: '150', operator: FilterOperatorEnum.NOT_EQUALS }
        }}
        sortDefault={{ field: 'display_id', order: 'asc' }}
        onRowClick={data => navigate(`/traces/${data.displayId}`)}
      />
      <Modal
        isOpen={isCompaniesModalOpen}
        onClose={() => setIsCompaniesModalOpen(false)}
        title={<h2 className='text-2xl'>Companies</h2>}
      >
        <div>
          {companiesList.map((company, index) => (
            <div key={index} className='flex items-center justify-between py-2'>
              <span className='truncate mr-4 flex-1 text-left'>
                {company.name}
              </span>
              <SoftButton
                onClick={() =>
                  window.open(`/companies/${company.id}`, '_blank')
                }
              >
                <Eye />
              </SoftButton>
            </div>
          ))}
        </div>
      </Modal>
      <Modal
        isOpen={isSearchesModalOpen}
        onClose={() => setIsSearchesModalOpen(false)}
        title={<h2 className='text-2xl'>Searches</h2>}
      >
        <div>
          {searchesList.map((search, index) => (
            <div key={index} className='flex items-center justify-between py-2'>
              <span className='truncate mr-4 flex-1 text-left'>
                {search.provider?.name || 'N/A'}
              </span>
              <SoftButton
                onClick={() =>
                  window.open(`/searches/${search.display_id}`, '_blank')
                }
              >
                <Eye />
              </SoftButton>
            </div>
          ))}
        </div>
      </Modal>
    </>
  );
};

export default ProviderTraces;
