import React, { useState } from 'react';
import {
  useNotification,
  OutlineButton,
  FilledButton,
  Input,
  Popup
} from '@core/components';
import { supabase } from '../../../utilities/supabase';

const TwoFAPopup = ({ onClose, isOpen }) => {
  const [tfaCode, setTfaCode] = useState('');
  const [tfaQrCode, setTfaQrCode] = useState('');
  const [tfaSecret, setTfaSecret] = useState('');
  const [factorId, setFactorId] = useState('');
  const [tfaFriendlyName, setTfaFriendlyName] = useState('');
  const [currentStep, setCurrentStep] = useState('addDevice');
  const { addNotification } = useNotification();

  const resetState = () => {
    setTfaCode('');
    setTfaQrCode('');
    setTfaSecret('');
    setFactorId('');
    setTfaFriendlyName('');
    setCurrentStep('addDevice');
  };

  const setupTwoFactor = async () => {
    if (!tfaFriendlyName) {
      addNotification({
        type: 'error',
        title: 'Error',
        description: 'Please enter a device name'
      });
      return;
    }

    try {
      const { data, error } = await supabase.auth.mfa.enroll({
        factorType: 'totp',
        friendlyName: tfaFriendlyName
      });
      if (error) throw error;

      setTfaQrCode(data.totp.qr_code);
      setTfaSecret(data.totp.secret);
      setFactorId(data.id);
      setCurrentStep('setupDevice');
    } catch (error) {
      console.error('Error setting up 2FA:', error);
      addNotification({
        type: 'error',
        title: 'Error',
        description: 'Failed to setup two-factor authentication'
      });
    }
  };

  const verifyTwoFactor = async () => {
    if (!tfaCode || tfaCode.length !== 6) {
      addNotification({
        type: 'error',
        title: 'Error',
        description: 'Please enter a valid 6-digit code'
      });
      return;
    }

    try {
      const { data, error } = await supabase.auth.mfa.challenge({ factorId });
      if (error) throw error;

      const { error: verifyError } = await supabase.auth.mfa.verify({
        factorId,
        code: tfaCode,
        challengeId: data.id
      });
      if (verifyError) throw verifyError;

      addNotification({
        type: 'success',
        title: 'Two-factor authentication device added',
        description: 'New authentication device has been added successfully.'
      });
      resetState();
      onClose();
    } catch (error) {
      console.error('Error verifying 2FA:', error);
      addNotification({
        type: 'error',
        title: 'Error',
        description: 'Failed to verify two-factor authentication code'
      });
    }
  };

  return (
    <Popup
      onClose={() => {
        resetState();
        onClose();
      }}
      isOpen={isOpen}
      title={
        currentStep === 'addDevice'
          ? 'Add 2FA App'
          : currentStep === 'setupDevice'
          ? 'Setup 2FA App'
          : 'Verify 2FA App'
      }
      size='sm'
    >
      <div className='space-y-4'>
        {currentStep === 'addDevice' && (
          <>
            <Input
              type='text'
              label='Device Name'
              value={tfaFriendlyName}
              onChange={e => setTfaFriendlyName(e.target.value)}
              placeholder='Enter a name for this device'
            />
            <div className='flex justify-end gap-x-4'>
              <OutlineButton
                colour='base'
                onClick={() => {
                  resetState();
                  onClose();
                }}
              >
                Cancel
              </OutlineButton>
              <FilledButton colour='primary' onClick={setupTwoFactor}>
                Continue
              </FilledButton>
            </div>
          </>
        )}
        {currentStep === 'setupDevice' && (
          <>
            <div className='text-sm text-neutral-600'>
              Scan this QR code with your authenticator app or manually enter
              the secret key below.
            </div>

            <div className='flex flex-col items-center'>
              <img id='qr-code-container' src={tfaQrCode} alt='QR Code' />
              <Input
                type='text'
                label='Secret Key'
                value={tfaSecret}
                readOnly
                onClick={e => e.target.select()}
                className='mt-2'
              />
            </div>

            <div className='flex justify-end gap-x-4 mt-4'>
              <OutlineButton
                colour='base'
                onClick={() => {
                  resetState();
                  onClose();
                }}
              >
                Cancel
              </OutlineButton>
              <FilledButton
                colour='primary'
                onClick={() => setCurrentStep('verifyDevice')}
              >
                Next
              </FilledButton>
            </div>
          </>
        )}
        {currentStep === 'verifyDevice' && (
          <>
            <div className='text-sm text-neutral-600'>
              Enter the verification code from your authenticator app to verify
              the device and add it to your account.
            </div>

            <Input
              type='text'
              label='Verification Code'
              value={tfaCode}
              onChange={e => setTfaCode(e.target.value)}
              placeholder='Enter 6-digit code'
              maxLength={6}
              className='mt-2'
            />

            <div className='flex justify-end gap-x-4 mt-4'>
              <OutlineButton
                colour='base'
                onClick={() => {
                  resetState();
                  onClose();
                }}
              >
                Cancel
              </OutlineButton>
              <FilledButton colour='primary' onClick={verifyTwoFactor}>
                Verify Device
              </FilledButton>
            </div>
          </>
        )}
      </div>
    </Popup>
  );
};

export default TwoFAPopup;
